<template>
    <div id="action-mail">
        <div class="action-mail-cell">
            <span class="action-mail-cell-title">邮件地址</span>
            <el-select
                v-model="unit.content.mails"
                size="small"
                multiple
                filterable
                allow-create
                placeholder="请输入或选择邮件地址"
            >
                <el-option
                    v-for="item in [
                        {
                            value: '发送给正在咨询的用户',
                            label: '发送给正在咨询的用户',
                        },
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>
        <div v-if="showMailNotExits" class="action-mail-d-cell">
            <div class="mail-not-exits">
                <div class="jump-cell-mail">
                    <div class="left-select">
                        <span class="left-select-title">如果未获取到邮箱</span>
                        <el-select
                            style="width: 120px"
                            v-model="unit.content.mailNotExitsActionType"
                            size="small"
                            @change="jumpTypeValueChange($event)"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in jumpTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>

                    <div
                        v-show="
                            unit.content.mailNotExitsActionType === 'NODE' ||
                            unit.content.mailNotExitsActionType === 'INTENT'
                        "
                        class="right-select"
                    >
                        <div
                            v-show="
                                unit.content.mailNotExitsActionType !==
                                'QUESTION'
                            "
                            class="empty"
                        ></div>
                        <el-select
                            v-show="
                                unit.content.mailNotExitsActionType === 'NODE'
                            "
                            v-model="unit.content.gotoContent"
                            @change="nodeSelectChange"
                            :popper-append-to-body="false"
                            filterable
                            size="small"
                            placeholder="请输入选择名称"
                        >
                            <el-option
                                v-for="item in nodeOptions"
                                :popper-class="
                                    'pop_my' + item.classPop + item.parentNum
                                "
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                        <el-cascader
                            v-show="
                                unit.content.mailNotExitsActionType === 'INTENT'
                            "
                            v-model="unit.content.gotoContent"
                            size="small"
                            :options="intentTree4Radio"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader>
                    </div>

                    <div
                        v-show="
                            unit.content.mailNotExitsActionType === 'QUESTION'
                        "
                        class="right-select-q"
                    >
                        <div class="right-select-entity">
                            <span class="right-select-entity-title"
                                >检测实体</span
                            >
                            <el-select
                                v-model="SYS_邮箱"
                                size="small"
                                disabled
                                placeholder=""
                            >
                                <el-option
                                    v-for="item in [
                                        {
                                            value: 'SYS_邮箱',
                                            label: 'SYS_邮箱',
                                        },
                                    ]"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="right-select-number">
                            <span class="right-select-number-title"
                                >反问次数</span
                            >
                            <el-select
                                size="small"
                                v-model="
                                    unit.content.questionContent.questionNum
                                "
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in noGetParamQNumber"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="showMailNotExits && unit.content.mailNotExitsActionType === 'QUESTION'"
            class="rhetorical-question-input"
        >
            <div class="tal">反问问题</div>
            <div
                class="rhetorical-question-input-cell"
                v-for="(q_item_text, q_item_text_index) in unit.content
                    .questionContent.questions"
                :key="q_item_text_index"
            >
                <el-input
                    autosize
                    type="textarea"
                    v-model="
                        unit.content.questionContent.questions[
                            q_item_text_index
                        ]
                    "
                    placeholder="请输入要反问的问题"
                ></el-input>
                <span
                    v-if="q_item_text_index === 0"
                    @click="handleActionQuestionCellTextItem('add')"
                    class="el-icon-circle-plus handle-icon"
                ></span>
                <span
                    v-if="q_item_text_index !== 0"
                    @click="
                        handleActionQuestionCellTextItem(
                            'delete',
                            q_item_text_index
                        )
                    "
                    class="el-icon-circle-close handle-icon handle-icon-delete"
                ></span>
            </div>
        </div>
        <div class="action-mail-cell">
            <span class="action-mail-cell-title">邮件主题</span>
            <el-input
                type="textarea"
                maxlength="120"
                placeholder="请输入邮件主题"
                v-model="unit.content.subject"
                :autosize="{ minRows: 1, maxRows: 4 }"
            >
            </el-input>
        </div>
        <div class="action-mail-upload">
            <span class="action-mail-cell-title">邮件内容</span>
            <div class="upload-attachments" v-if="uploading">
                 <el-button size="mini" :loading="true" type="primary"> 附件上传中 </el-button>
                 <span class="tips">（最大50M）</span>
            </div>
           
            <el-upload
                class="upload-attachments"
                v-if="!uploading"
                :action="importFileUrl"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                multiple
                :file-list="unit.content.attachments"
                :show-file-list="false"
            >
                <el-button size="mini" type="primary"> 上传附件 </el-button>
                <span class="tips">（最大50M）</span>
            </el-upload>
        </div>
        <div class="action-mail-cell">
            <ckeditor
                    ref="editorInline"
                :editor="editorInline"
                :config="editorConfig"
                v-model="unit.content.content"
                @focus="onEditorFocus"
            ></ckeditor>
        </div>
        <div class="simple-cell-satisfaction">
            <span class="title">邮件发送成功</span>
            <div class="action_form_cascader">
                <el-select
                    v-model="unit.content.onSuccess.scope"
                    size="small"
                    placeholder="请选择"
                    @change="submitSuccessChangeOptions"
                >
                    <el-option
                        v-for="item in jumpTypeAddContentOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div
            v-if="unit.content.onSuccess.scope === 'INTENT'"
            class="simple-cell-satisfaction"
        >
            <span class="title">选择意图</span>
            <div class="action_form_cascader">
                <el-cascader
                    v-model="unit.content.onSuccess.value"
                    size="small"
                    :options="intentTree4Radio"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath: false,
                        label: 'name',
                        value: 'id',
                    }"
                ></el-cascader>
            </div>
        </div>
        <div
            v-if="unit.content.onSuccess.scope === 'NODE'"
            class="simple-cell-satisfaction"
        >
            <span class="title">选择节点</span>
            <div class="action_form_cascader">
                <el-select
                    v-model="unit.content.onSuccess.value"
                    :popper-append-to-body="false"
                    filterable
                    size="small"
                    placeholder="请输入选择名称"
                >
                    <el-option
                        v-for="item in nodeOptions"
                        :key="item.id"
                        :popper-class="
                            'pop_my' + item.classPop + item.parentNum
                        "
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div
            v-if="unit.content.onSuccess.scope === 'CONTENT'"
            class="simple-cell-satisfaction"
        >
            <span class="title">发送内容</span>
            <div class="action_form_cascader">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 10 }"
                    v-model="unit.content.onSuccess.value"
                    maxlength="2000"
                    placeholder="请输入文字答案"
                ></el-input>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <span class="title">邮件发送失败</span>
            <div class="action_form_cascader">
                <el-select
                    v-model="unit.content.onFail.scope"
                    size="small"
                    placeholder="请选择"
                    @change="submitErrorChangeOptions"
                >
                    <el-option
                        v-for="item in jumpTypeAddContentOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div
            v-if="unit.content.onFail.scope === 'INTENT'"
            class="simple-cell-satisfaction"
        >
            <span class="title">选择意图</span>
            <div class="action_form_cascader">
                <el-cascader
                    v-model="unit.content.onFail.value"
                    size="small"
                    :options="intentTree4Radio"
                    :props="{
                        expandTrigger: 'hover',
                        emitPath: false,
                        label: 'name',
                        value: 'id',
                    }"
                ></el-cascader>
            </div>
        </div>
        <div
            v-if="unit.content.onFail.scope === 'NODE'"
            class="simple-cell-satisfaction"
        >
            <span class="title">选择节点</span>
            <div class="action_form_cascader">
                <el-select
                    v-model="unit.content.onFail.value"
                    :popper-append-to-body="false"
                    filterable
                    size="small"
                    placeholder="请输入选择名称"
                >
                    <el-option
                        v-for="item in nodeOptions"
                        :key="item.id"
                        :popper-class="
                            'pop_my' + item.classPop + item.parentNum
                        "
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div
            v-if="unit.content.onFail.scope === 'CONTENT'"
            class="simple-cell-satisfaction"
        >
            <span class="title">发送内容</span>
            <div class="action_form_cascader">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 10 }"
                    v-model="unit.content.onFail.value"
                    maxlength="2000"
                    placeholder="请输入文字答案"
                ></el-input>
            </div>
        </div>
    </div>
</template>
<script>
function holder(data) {
    return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
    return holder("_" + webHooId + "." + key);
};

import {
    lastEditor,
    currentEditor,
    changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
    let lastIndex = key.lastIndexOf("]");
    return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
    return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
    props: ["nodeOptions", "intentTree4Radio", "unit"],
    created() {
        this.fileList = [...this.unit.content.attachments];
    },
    data() {
        return {
            name: "action-mail",
            uploading: false,
            SYS_邮箱: "SYS_邮箱",
            importFileUrl:
                location.origin +
                "/api/oss/public/v1?&token=8c98087dfd2d48f856d8c95c09115def",
            fileList: [],
            // unit: {
            //     content: {
            //         mails: [], // 为null表示当前对话用户邮箱
            //         subject: "", // 主题
            //         content: "", // 邮件内容,富文本?
            //         attachments: [], // 附件url
            //         mailNotExitsActionType: "NODE", // QUESTION/INTENT/NODE
            //         questionContent: {
            //             questions: [""],
            //             questionNum: 1,
            //         },
            //         gotoContent: "", // 节点/意图ID
            //         onSuccess: {
            //             scope: "NODE", // INTENT, NODE, CONTENT
            //             value: "", // 意图ID/节点ID/回复文本内容
            //         },
            //         onFail: {
            //             scope: "NODE", // INTENT, NODE, CONTENT
            //             value: "", // 意图ID/节点ID/回复文本内容
            //         },
            //     },
            // },
            jumpTypeOptions: [
                {
                    value: "QUESTION",
                    label: "执行反问",
                },
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            jumpTypeAddContentOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "CONTENT",
                    label: "向用户发送内容",
                },
            ],
            noGetParamQNumber: [
                { name: 1, id: 1 },
                { name: 2, id: 2 },
                { name: 3, id: 3 },
                { name: 4, id: 4 },
                { name: 5, id: 5 },
                { name: "直到检测到实体", id: -1 },
            ],
            // 富文本
            editorDataInline: "",
            // editorInline: CKEDITOR.InlineEditor,
            editorInline: CKEDITOR.ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "fontSize",
                    "fontColor",
                    "fontBackgroundColor",
                    "alignment",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    // "indent",
                    // "outdent",
                    // "|",
                    // "imageUpload",
                    // "blockQuote",
                    // "insertTable",
                    // "mediaEmbed",
                    "undo",
                    "redo",
                ],
            },
            showMailNotExits: false,
        };
    },
    mounted() {
        let flag = false;
        if (this.unit.content.mails.length > 0) {
            this.unit.content.mails.forEach((item) => {
                if (item == "发送给正在咨询的用户") {
                    flag = true;
                }
            });
        }
        this.showMailNotExits = flag;
    },
    watch: {
        unit: {
            handler: function (val) {
                let flag = false;
                if (val.content.mails.length > 0) {
                    val.content.mails.forEach((item) => {
                        if (item == "发送给正在咨询的用户") {
                            flag = true;
                        }
                    });
                }
                this.showMailNotExits = flag;
            },
            deep: true,
        },
    },
    methods: {
        jumpTypeValueChange(e) {
            console.log(e);
            this.unit.content.mailNotExitsActionType = e;
            this.unit.content.gotoContent = "";
        },
        // 操作反问类型答案cell中单条文案
        handleActionQuestionCellTextItem(type, q_item_text_index) {
            if (type === "delete") {
                this.unit.content.questionContent.questions.splice(
                    q_item_text_index,
                    1
                );
            }
            if (type === "add") {
                this.unit.content.questionContent.questions.push("");
            }
        },
        // 附件操作
        handleRemove(file, fileList) {
            console.debug("remove file", file, fileList);
            this.unit.content.attachments = fileList;
        },
        handlePreview(file) {
            console.log(file);
        },
        beforeUpload(file) {
            let fileSize = file.size / 1024 / 1024 < 50;
            if (!fileSize) {
                this.$message.error("单个附件大小不能超过50MB");
                return false;
            } else {
                this.uploading = true;
            }
        },
        uploadSuccess(response, file, fileList) {
            console.log(response, file, fileList);
            // console.debug(
            //     "this.unit.content.attachments",
            //     this.unit.content.attachments
            // );
            if (response.code == 0) {
                this.uploading = false;
                changeEditor(this.$refs.editorInline.$_instance);
                console.debug(" this.$refs.editorInline", this.$refs.editorInline);
                console.debug(lastEditor, currentEditor);
                let command = currentEditor.commands.get("insertAskComponent");
                command.execute({
                    tag: "a",
                    options: {
                        href: response.data.pubUrl,
                        name: file.name,
                    },
                });
            }
            // this.unit.content.attachments = fileList;
            // console.debug(
            //     "this.unit.content.attachments",
            //     this.unit.content.attachments
            // );
        },
        filterFileList(fileList) {
            let tamplist = [];
            fileList.forEach((item) => {
                tamplist.push({
                    name: item.name,
                    url: item.response.data,
                });
            });
            return tamplist;
        },
        // 富文本
        onEditorFocus(zh, editor) {
            console.log(zh, editor);
            changeEditor(editor);
        },
        // 邮件发送结果
        submitSuccessChangeOptions() {
            this.unit.content.onSuccess.value = "";
        },
        submitErrorChangeOptions() {
            this.unit.content.onFail.value = "";
        },
    },
};
</script>
<style lang="less">
#action-mail {
    .ck-content {
        min-height: 120px !important;
        background-color: white;
    }
    .action-mail-upload {
        display: flex;
        justify-content: flex-start;
        // width: 595px;
        .action-mail-cell-title {
            height: 34px;
            line-height: 34px;
            margin-right: 16px;
        }
        .upload-attachments {
            text-align: left;
            margin-bottom: 10px;
            // button {
            //     font-size: 14px;
            // }
            .tips {
                color: tomato;
                font-size: 12px;
            }
        }
        .el-upload-list__item:first-child {
            margin-top: 2px !important;
        }
    }
    .action-mail-d-cell {
        .mail-not-exits {
            .jump-cell-mail {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                .left-select {
                    width: 120px;
                    flex: none;
                    text-align: left;
                    line-height: 32px;
                    height: 32px;
                }
                .right-select {
                    flex: auto;
                    margin-left: 8px;
                    .el-select {
                        width: 100%;
                    }
                    .empty {
                        height: 32px;
                    }
                }
                .right-select-q {
                    flex: auto;
                    display: flex;
                    justify-content: space-around;
                    margin-left: 8px;
                    .right-select-entity {
                        flex: auto;
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        text-align: left;
                        .right-select-entity-title {
                            line-height: 32px;
                            height: 32px;
                        }
                        .el-select {
                            flex: auto;
                            width: 100%;
                        }
                    }
                    .right-select-number {
                        flex: auto;
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        text-align: left;
                        margin-left: 8px;
                        .right-select-number-title {
                            line-height: 32px;
                            height: 32px;
                        }
                        .el-select {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .action-mail-cell {
        display: flex;
        justify-content: space-between;
        // width: 595px;
        margin-bottom: 8px;
        .action-mail-cell-title {
            width: 80px;
            flex: none;
            text-align: left;
            line-height: 32px;
            height: 32px;
        }
        .el-select {
            flex: auto;
        }
    }
    .rhetorical-question-input {
        .el-select--small {
            width: 100% !important;
        }
        .rhetorical-question-input-cell {
            display: flex;
            justify-content: flex-start;
            padding: 4px 0;
            .el-select--small {
                flex: none;
                width: 100% !important;
            }
            .el-textarea {
                width: 100%;
            }
            .handle-icon {
                text-align: right;
                width: 20px;
                flex: none;
                margin-left: 8px;
                font-size: 24px;
                margin-top: 0px;
            }
            .handle-icon-delete {
                color: red;
            }
        }
    }
    .simple-cell-satisfaction {
        display: flex;
        flex-direction: column;
        // width: 595px;
        .title {
            margin-top: 8px;
            text-align: left;
            margin-bottom: 8px;
        }
        .action_form_cascader {
            .el-cascader {
                width: 100% !important;
            }
            .el-select {
                width: 100% !important;
            }
        }
        .title-other {
            margin-bottom: 8px;
            color: grey;
            text-align: left;
        }
        .el-textarea {
            width: 100%;
            margin-bottom: 12px;
        }
        .option-sa {
            display: flex;
            justify-content: flex-start;
            .el-tag {
                margin-right: 8px;
            }
            margin-bottom: 12px;
        }
        .satisfaction-radio-box {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .el-tag {
                margin-right: 4px;
                cursor: pointer;
            }
        }
    }
}
</style>