<template>
    <div class="choose-dept-member">
        <popup @closeEvent="closeEvent" v-if="showPopup">
             <div slot="popup-name">{{$t('botIntent.chooseDeptMember.title')}}</div>
            <div slot="popup-tip">{{$t('botIntent.chooseDeptMember.tip')}}</div>
            <div slot="popup-con-c" class="popup-con-c">
                <div class="popup-content">
                    <div class="left-choose">
<!--                        <div class="top-header">
                            <div class="left">
                                <div :class="['item',activeName === 'member' ? 'active' : '']" @click="handleClick('member')">选择员工</div>
                                <div :class="['item',activeName === 'upDown' ? 'active' : '']" @click="handleClick('upDown')">选择上下游</div>
                            </div>
                            
                        </div>
                        <div class="content-tree">
                            &lt;!&ndash; <el-input
                                prefix-icon="el-icon-search"
                                placeholder="搜索员工,部门"
                                v-model="filterText">
                            </el-input> &ndash;&gt;
                            &lt;!&ndash; 员工的数据 &ndash;&gt;
                           <div v-show="activeName === 'member'" v-loading="loading" class="tree-box">
                                   <div v-for="item in treeData" :key="item.id">
                                        &lt;!&ndash; 员工懒加载 &ndash;&gt;
                                        <el-tree
                                            key="selectOrganizationTreeLazy"
                                            :data="[item]"
                                            id="selectOrganizationTreeLazy"
                                            class="filter-tree"
                                            node-key="id"
                                            :default-expanded-keys="defaultExpandedKeysMember"
                                            :expand-on-click-node="false"
                                            :ref="item.id+'TreeLazy'">
                                            <span
                                                class="custom-tree-node"
                                                slot-scope="{node,data}" @click.stop="onNodeClick(item,data,node)">
                                                <span class="custom-tree-node-left">
                                                       &lt;!&ndash; // 员工树结构的数据 type=0是员工，type=1是部门 &ndash;&gt;
                                                    <i 
                                                        :class="data.showChildren?'el-icon-arrow-down':'el-icon-arrow-right'" 
                                                        v-show="data.type == 1"
                                                        @click.stop="nodeExpand(data,node)">
                                                    </i>
                                                    <span
                                                        class="data-label"
                                                        v-if="item.originData&&
                                                        item.originData.wechatBotDTO&&
                                                        item.originData.wechatBotDTO.bindType===0&&
                                                        item.originData.wechatBotDTO.wechatType===0">
                                                        <open-data :type="data.type == 1 ? 'departmentName' : 'userName'" :openid="data.label"></open-data>
                                                    </span>
                                                    <span v-else class="data-label">{{data.label }}</span>
                                                </span>
                                                <span v-show="data.checked">
                                                    <i :class="['el-icon-check']"></i>
                                                </span>
                                            </span>
                                        </el-tree>
                                    </div>
                           </div>
                           &lt;!&ndash; 上下游的数据 &ndash;&gt;
                           <div v-show="activeName === 'upDown'" v-loading="loading" class="tree-box">
                               <el-tree
                                    class="filter-tree"
                                    node-key="id"
                                    :data="upDownData"
                                    :expand-on-click-node="false"
                                    :default-expand-all="false"
                                    :default-expanded-keys="defaultExpandedKeysUpDown"
                                    @node-click="onNodeClickUpDown"
                                    :props="props"
                                    ref="upDownTree">
                                    <span
                                        class="custom-tree-node"
                                        slot-scope="{node,data}">
                                        <span class="custom-tree-node-left">
                                            <i 
                                                :class="data.showChildren?'el-icon-arrow-down':'el-icon-arrow-right'" 
                                                v-show="data.type == 2"
                                                @click.stop="nodeExpandUpDown(data,node)">
                                            </i>
                                            <span class="data-label" >{{data.label}}</span>
                                        </span>
                                        <span v-show="data.checked">
                                            <i :class="['el-icon-check']"></i>
                                        </span>
                                    </span>
                                </el-tree>
                           </div>
                        </div>-->
                    <staff-customer
                        :isMultiple="true"
                        @onNodeClick="onNodeClick"
                        ref="staffCustomer"
                        :checkedList="checkedList"
                        :isChooseDeptMember="true"
                        dataType="all"
                    >
                    </staff-customer>
                    </div>
                    <div class="right-checked">
                        <div class="top-header">
                        <div class="item right">{{$t('botIntent.chooseDeptMember.selected')}}</div>
                        </div>
                        <div class="content-tree checked-list">
                            <div class="item" v-for="(item,index) in checkedList" :key="item.id" >
                                <div
                                    v-if="item.isWeWorkThirdData || (item.bindType === 0 && item.wechatType === 0)">
                                    <span>
                                         <!-- // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段 -->
                                        <open-data :type="item.selfType == 0 ? 'departmentName' : 'userName'" :openid="item.label"></open-data>
                                    </span>
                                </div>
                                <div v-else class="name">{{item.label?item.label:item.name}}</div>
                                <i @click="delChecked(item,index)" class="el-icon-close"></i>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button round @click="closeEvent">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" round @click="saveBtn">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import OpenData from '../../../components/openData.vue'
import popup from '../../../components/popup.vue'
import StaffCustomer from "@/components/staffCustomer";
export default {
    components:{ StaffCustomer, popup,OpenData},
    props:{
        showPopup:{
            type:Boolean,
            default:false
        },
        checkedData:{
            type:Array,
            default(){
                return[]
            }
        },
    },
    data(){
        return{
            activeName:'member',
            filterText: '',
            treeData: [],
            upDownTreeData:[],
            checkedList:[],
            isLazy:true,
            userId:'',
            lazyData:[],
            treeId:'',
            ids:[],
            defaultExpandedKeysUpDown:[],
            props: {
                isLeaf: 'isLeaf'
            },
            memberProps:{
                isLeaf: 'isLeaf'
            },
            loading:false,
            memberTrees:'',
            showChildren:false,
            defaultExpandedKeysMember:[],
            upDownData:[],
        }
    },
    watch: {
        
        showPopup(n){
            if(n){
                this.activeName = 'member';
                 if(this.checkedData.length > 0){
                    if(this.checkedData[0].type === 4){
                       this.checkedList = [];  
                    } else {
                        this.$nextTick(() => {
                            this.checkedData.forEach((item,index) => {
                                item.id = Number(item.id);
                            })
                            this.checkedList = [...this.checkedData];
                        })
                    }
                } else {
                    this.checkedList = [];  
                }
                this.checkedList.length > 0 && this.checkedList.forEach(v => {
                    this.ids.push(v.id);
                })
               /* this.getData(null);
                this.getUpDownData(null);*/
            }
        },
        // filterText(val) {
        //     if(this.activeName === 'member'){
        //       this.treeData.forEach(item=>{
        //         let ref = item.id+'TreeLazy'
        //         if(this.$refs[ref]){
        //           this.$refs[ref][0].filter(val);
        //         }
        //       })

        //     } else {
        //         this.$refs.upDownTree.filter(val);
        //     }
           
        // },
        activeName(n){
            if(this.filterText !== ''){
                if(n === 'member'){
                    this.$refs.memberTrees.filter(val);
                } else {
                    this.$refs.upDownTree.filter(val);
                }
            }
        },
        checkedData:{
            handler(n){
               
            },
            deep:true
        },
    },
    mounted(){
        this.userId = localStorage.getItem('_uid');
    },
    methods:{
        handleClick(datas){
            this.activeName = datas;
        },
        // 节点被展开
        nodeExpand(data,node){
            data.showChildren = !data.showChildren;
            if(data.showChildren){
                this.getData(data.id,data,node);
            } else {
                data.children = [];
            }
        },
       
        // 获取员工数据
        getData(departId,data,node){
            let axiosDatas = [];
            this.loading = true;
            let url = departId ? this.requestUrl.department.corpListLazy+'?departId='+ departId+"&userId="+this.userId : this.requestUrl.department.corpListLazy+'?userId='+this.userId
            this.FetchGet(url).then(res => {
              let ids =  this.checkedList.map(v=>{
                return v.id
              })
              this.ids = ids;
                axiosDatas = this.handlerCheckedSelf(res || [],this.ids,departId ? departId : -1);
                this.loading = false;
                if(departId){
                    data.children = [...data.children,...axiosDatas];
                    this.defaultExpandedKeysMember = [...this.defaultExpandedKeysMember,...[departId]];
                    data.showChildren = true;
                } else {
                    this.treeData = [...axiosDatas];
                    this.defaultExpandedKeysMember = [];
                }
                this.treeData.forEach(item=>{
                    if(!departId){
                        item.showChildren = false;
                        item.children = [];
                    }
                    this.$nextTick(()=>{
                        let ref = item.id+'TreeLazy'
                        setTimeout(()=>{
                            this.getNodeChecked(ref,item,ids);

                        },500)


                    })
                     
              })

            });
           
        },
        getNodeChecked(ref,data,ids){
          if(ids.indexOf(data.id) > -1){
            if (this.$refs[ref]&&this.$refs[ref][0]){
              this.$set(this.$refs[ref][0].getNode(data.id).data,'checked',true)
            }
          }
          if (data.children){
            data.children.forEach(item=>{
              this.getNodeChecked(ref,item,ids)
            })
          }
        },
        handlerCheckedSelf(datas,ids,parentId){
            datas.forEach(item => {
                item.checked = false;
                item.showChildren = false;
                if(item.type === 1){
                    item.selfType = 0;
                } else if (item.type === 0) {
                    item.selfType = 1;
                }
                 if(ids.indexOf(item.id) > -1){
                   let ref = item.id +'TreeLazy'
                     // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段

                   this.handlerCheckedSelf(item.children || [],ids,item.id || null);
                 }  else {
                    this.handlerCheckedSelf(item.children || [],ids,item.id || null);
                 }

            })
            return datas;
        },
        //  上下游
        nodeExpandUpDown(data,node){
            data.showChildren = !data.showChildren;
            if(data.showChildren){
                this.getUpDownData(data.id,data,node);
            } else {
                data.children = [];
            }
        },
        getUpDownData(departId,data,nodea){
             this.loading = true;
            let deptLit = [],memberList = [],_list = [];
            let url =  departId ? this.requestUrl.department.selectOrganizationById+'?id='+ departId+"&userId="+this.userId 
                    :  this.requestUrl.department.selectOrganizationById+'?userId='+this.userId
            this.FetchGet(url).then((res) => {
              
                deptLit = [... this.handlerTree( res.data || [],2)];
                
                
                if(departId){
                    if(deptLit.length > 0){
                        deptLit[0].showChildren = false;
                    } else {
                       data.showChildren = true;
                    }
                    this.defaultExpandedKeys = [departId];
                    this.FetchGet(this.requestUrl.department.getUpDownMemberData + '?organizationId=' + departId + '&userId=' + this.userId)
                        .then((res) => {
                            memberList = [... this.handlerTree(res.data|| [],3)];
                              this.$nextTick(() => {
                                    data.children = [...deptLit,...memberList];
                                    this.defaultExpandedKeysUpDown = [...this.defaultExpandedKeysUpDown,...[departId]];
                                    data.showChildren = true;
                                    setTimeout(() => {
                                        memberList = this.handlerChecked(data.children || [])
                                    })
                              })
                        });
                       
                } else {
                    this.upDownData = [...deptLit];
                    this.$nextTick(() => {
                        this.handlerChecked(this.upDownData || []);
                        this.defaultExpandedKeysUpDown = [];
                        this.upDownData.forEach(item=>{
                            if(!departId){
                                item.showChildren = false;
                                item.children = [];
                            }
                        })
                        
                    })

                }
                this.loading = false;
            });
        },
        // 编辑回显选中状态
        handlerChecked(data){
            data.forEach((item,index) => {
              let ref = item.id+'TreeLazy'
                this.checkedList.length > 0 && this.checkedList.forEach(v => {
                    if(item.id == v.id){
                        
                        // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                        if(v.type == 0 || v.type == 1){
                          if (this.$refs[ref]&&this.$refs[ref][0]){
                            this.$refs[ref][0].getNode(item).data.checked = true;
                          }
                        } else if (v.type == 2 || v.type == 3){
                            if (this.$refs.upDownTree.getNode(item.id)){
                               this.$refs.upDownTree.getNode(item.id).data.checked = true;
                            }
                        }
                        this.handlerTree(item.children || []);
                    } else {
                        this.handlerTree(item.children || []);
                    }
                })
            })
            return data;
        },
        // 懒加载时 , 拼接数据 获取treeData
        handlerTree(data,types){
            data.forEach(item => {
                item.checked = false;
                 // selfType -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                // 员工树结构的数据 type=0是员工，type=1是部门
                if(item.type === 1){
                    item.selfType = 0;
                } else if (item.type === 0) {
                    item.selfType = 1;
                }
                if(types && types === 2){
                    item.selfType = item.type = 2;
                    item.label = item.name;
                    item.isLeaf = false;
                } else if (types && types === 3) {
                    item.selfType = item.type = 3;
                    item.label = item.name;
                    item.isLeaf = true;
                }
                if (item.children && item.children.length > 0) {
                    this.handlerTree(item.children,types);
                }
            })
            return data;

        },
        // 搜索
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
         // 员工/点击tree
		onNodeClick(node,type,memberInfo) {
          console.debug('item',node,type,memberInfo)
      if(node.checked){
        let bindType = ""
        let wechatType = ""
        let rootNodeId = memberInfo.id
        if (memberInfo.originData&&memberInfo.originData.wechatBotDTO){
          bindType = memberInfo.originData.wechatBotDTO.bindType
          wechatType = memberInfo.originData.wechatBotDTO.wechatType
        }
        this.$set(node,'bindType',bindType)
        this.$set(node,'wechatType',wechatType)
        this.$set(node,'rootNodeId',rootNodeId)
        this.checkedList.push(node);
      } else {
        this.checkedList.forEach((v,i) => {
          if(node.id == v.id){
            this.checkedList.splice(i,1)
          }
        })
      }
           /* if(data.checked){
                this.checkedList.forEach((item,index) => {      
                    // 已经存在
                    if(item.id === data.id){
                        data.checked = false;
                        this.checkedList.splice(index,1)
                    }
                })
            } else {
                data.checked = true;
                let bindType = ""
                let wechatType = ""
                let rootNodeId = item.id
                if (item.originData&&item.originData.wechatBotDTO){
                  bindType =item.originData.wechatBotDTO.bindType
                  wechatType = item.originData.wechatBotDTO.wechatType
                }
                this.$set(data,'bindType',bindType)
                this.$set(data,'wechatType',wechatType)
                this.$set(data,'rootNodeId',rootNodeId)
                this.checkedList.push(data);
            }*/
            console.log(this.checkedList,'checkedListcheckedList');
		},
        // 上下游 点击tree
        onNodeClickUpDown(data,node,el) {
            if(data.checked){
                this.checkedList.forEach((item,index) => {      
                    // 已经存在
                    if(item.id === data.id){
                        data.checked = false;
                        this.checkedList.splice(index,1)
                    }
                })
            } else {
                data.checked = true;
                this.checkedList.push(data);
            }
           this.$forceUpdate();
		},
        // 数组去重
        removeDuplicateObj(arr) {
            let obj = {};
            arr = arr.reduce((newArr, next) => {
                obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
                return newArr;
            }, []);
            return arr;
        },
        // 删除选中
        delChecked(item,index){
          let ref= item.rootNodeId+'TreeLazy'
          console.debug('ref',ref)
          console.debug('refs',this.$refs[ref])
              // selfType -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
            if(item.selfType === 0 || item.selfType === 1){
                // this.$refs.memberTrees.setChecked(item,false,false);
              console.debug('staffCustomer',this.$refs.staffCustomer.$refs[ref])
              if (this.$refs.staffCustomer.$refs[ref] && this.$refs.staffCustomer.$refs[ref][0]){
                    if(this.$refs.staffCustomer.$refs[ref][0].getNode(item.id)){
                      this.$refs.staffCustomer.$refs[ref][0].getNode(item.id).data.checked = false;
                    }
              }
            } else if(this.$refs.staffCustomer.$refs.upDownTree && this.$refs.staffCustomer.$refs.upDownTree.getNode(item.id)){
              this.$refs.staffCustomer.$refs.upDownTree.getNode(item.id).data.checked = false;
            }
            this.checkedList.splice(index,1);
        },
        closeEvent(){
            this.$emit('update:showPopup',false)
        },
        saveBtn(){
            // type类型,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
            // 员工 type=0是员工，type=1是部门
            // 上下游  
            if(this.checkedList.length === 0){
                this.$message.error('请选择范围');
                // this.$message({
                //     showClose: true,
                //     message: '错了哦，这是一条错误消息',
                //     type: 'error',
                //     duration:1000000,
                //     dangerouslyUseHTMLString:true
                // });
            } else {
                this.$emit('chooseDeptMemberSuccess', this.checkedList);
            }
            
        }
    }

}
</script>

<style scoped lang="less">
.choose-dept-member{
    /deep/ .popup-floating-layer {
        z-index: 2000 !important;
        #popup-assembly{
            width: 800px !important;
        }
    }
    .popup-content{
        width: 700px;
        display: flex;
        justify-content: space-between;
        .left-choose{
            width: 60%;
          flex: none;
          padding-right: 20px;
          height: calc(60vh - 100px);
          overflow-y: scroll;
          /deep/.el-tabs__nav-wrap::after{
            background-color: #ffffff;
          }
        }
        .right-checked{
            width: 40%;
            margin-left: 20px;
          flex: none;
            
        }
        .top-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            color: #000000;
            font-size: 14px;
            width: 100%;
            .left{
                // width: 300px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;
                margin-right: 30px;
                .item{
                    font-size: 15px;
                    cursor: pointer;
                    &.active{
                        border-bottom: 2px solid #366AFF;
                        height: 35px;
                        line-height: 35px;
                        color: #366AFF;
                    }
                }
            }
            .right{
                width: 100%;
                font-size: 15px;
                text-align: center;
            }
            
        }
        .content-tree{
            margin-top: 20px;
            overflow: hidden;
            border-right: 1px solid #DCDFE6;
            .el-input{
                margin-bottom: 20px;
            }
            .tree-box{
                height: 350px;
                overflow-x: hidden;
                overflow-y: scroll;
            }
             /deep/ .el-tree{
                // overflow-y: scroll;
                .el-tree-node__expand-icon{
                    display: none;
                }
                .custom-tree-node {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 8px;
                    .el-icon-arrow-down{
                        padding: 4px;
                        color: #606266;
                        font-size: 14px;
                    }
                    .el-icon-arrow-right{
                        padding: 4px;
                        color: #606266;
                        font-size: 14px;
                    }
                   
                }

            }
            &.checked-list{
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                height: 350px;
                overflow-y: scroll;
                border: none;
                .item{
                    padding: 3px 8px;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    display: flex;
                    font-size: 12px;
                    color: #616161;
                    border: 1px solid #eaeaec;
                    background-color: #f4f4f5;
                    color: #909399;
                    border-radius: 20px;
                    font-size: 12px;
                    margin: 2px 0 2px 6px;
                    .name{
                        align-content: flex-start;
                        margin-right: 5px;
                        word-wrap:break-word;
                        word-break:break-all;
                    }
                    // span{
                    //     margin-right: 5px;
                    //     white-space: nowrap;
                    // }
                    .user-icon{
                        width: 26px;
                        height: 26px;
                        background: #87a6ff;
                        border-radius: 50%;
                        font-size: 12px;
                        text-align: center;
                        line-height: 26px;
                        color: #fff;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    
}
</style>