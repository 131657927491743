<template>
    <div style="width: 100vw;height: 100vh;z-index: 10000;position: fixed;top: 0;left: 0;background-color: rgba(0, 0, 0, 0.8);">
        <div @click="closeView" style="color: white;font-size: 30px;padding: 30px 30px 15vh;text-align: right;cursor: pointer;">
            X
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between">
            <div style="width: 60px"><i class="el-icon-arrow-left" style="font-size: 60px;color: white"
                    v-if="previewIndex !== 0" @click="preImage"></i></div>
            <img style="max-height: 70vh; max-width: 70vw;margin: 0 auto;display: flex" :src="previewImageUrl[previewIndex]"
                alt srcset />
            <div><i class="el-icon-arrow-right" style="font-size: 60px;color: white"
                    v-if="previewIndex !== previewImageUrl.length - 1" @click="nextImage"></i></div>
        </div>

    </div>
</template>

<script>
export default {
    name: "viewPicture",
    data () {
        return {};
    },
    props: ["previewIndex", "previewImageUrl"],
    methods: {
        preImage () {
            if (this.previewIndex !== 0) {
                this.previewIndex -= 1;
            }
        },
        nextImage () {
            if (this.previewIndex !== this.previewImageUrl.length - 1) {
                this.previewIndex += 1;
            }
        },
        closeView () {
            this.$emit("closeView");
        },
    },
};
</script>

<style scoped></style>