let lastEditor = null;
let currentEditor = null;

const changeEditor = function (current) {
    console.debug('change',lastEditor,currentEditor);

    if (currentEditor!=null && current.id === currentEditor.id){
        return;
    }
    lastEditor = currentEditor;
    currentEditor = current;
    console.debug('after change',lastEditor,currentEditor);

};
export {
    lastEditor,currentEditor,changeEditor
}
