var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"node-node-list"},_vm._l((JSON.parse(_vm.list)),function(childNodeItem,childNodeIndex){return _c('div',{key:childNodeIndex,staticClass:"node-node"},[_c('div',{staticClass:"node-pre"}),_c('div',{staticClass:"node-node-name"},[_c('span',[_vm._v("节点")]),_c('div',{staticClass:"name-text csp",on:{"click":function($event){return _vm.configAction(
                            _vm.intentId,
                            childNodeItem.id,
                            childNodeItem.name
                        )}}},[_vm._v(" "+_vm._s(childNodeItem.name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        ((_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                            !_vm.isOtherSkill) ||
                        (_vm.isOtherSkill && _vm.RP_Visible('OPEN_PROCESS_OTHER'))) && _vm.isPart != 1
                    ),expression:"\n                        ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                            !isOtherSkill) ||\n                        (isOtherSkill && RP_Visible('OPEN_PROCESS_OTHER'))) && isPart != 1\n                    "}],staticClass:"el-icon-delete delete-icon-node",on:{"click":function($event){return _vm.deleteNodeHandle(childNodeItem.id)}}})]),(_vm.hasChildren(childNodeItem.children))?_c('node-item',{attrs:{"list":JSON.stringify(childNodeItem.children),"intentId":_vm.intentId,"skillId":_vm.skillId,"indexArr":_vm.indexArr,"isOtherSkill":_vm.isOtherSkill}}):_vm._e(),_c('div',{staticClass:"add-node-box"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                        !(
                            (_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !_vm.isOtherSkill) ||
                            (_vm.isOtherSkill && _vm.RP_Visible('OPEN_PROCESS_OTHER'))
                        )
                    ),expression:"\n                        !(\n                            (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                                !isOtherSkill) ||\n                            (isOtherSkill && RP_Visible('OPEN_PROCESS_OTHER'))\n                        )\n                    "}],staticClass:"add-text-forb"},[_vm._v("+")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                        !childNodeItem.isAddEditing &&
                        ((_vm.RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                            !_vm.isOtherSkill) ||
                            (_vm.isOtherSkill && _vm.RP_Visible('OPEN_PROCESS_OTHER')))
                    ),expression:"\n                        !childNodeItem.isAddEditing &&\n                        ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&\n                            !isOtherSkill) ||\n                            (isOtherSkill && RP_Visible('OPEN_PROCESS_OTHER')))\n                    "}],staticClass:"add-text",on:{"click":function($event){return _vm.addNodeHandle(childNodeItem.id)}}},[_vm._v("+")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(childNodeItem.isAddEditing),expression:"childNodeItem.isAddEditing"}],staticClass:"add-input"},[_c('el-input',{attrs:{"maxlength":"20","placeholder":"请输入节点名称"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.confirmAdd(childNodeItem.id)}},model:{value:(_vm.nodeNameValue),callback:function ($$v) {_vm.nodeNameValue=$$v},expression:"nodeNameValue"}}),_c('span',{staticClass:"el-icon-success add-icon",on:{"click":function($event){return _vm.confirmAdd(childNodeItem.id)}}}),_c('span',{staticClass:"el-icon-error delete-icon",on:{"click":function($event){return _vm.concelAddNodeHandle(childNodeItem.id)}}})],1)])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }