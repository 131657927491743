<template>
    <div class="note-details" v-if="show">
        <el-drawer :title="$t('trainList.trainNode.trainDetail')" size="1210px" :visible.sync="show" :direction="direction" @close="close">
            <div class="exportBtn" @click="exprotDownload">
                <i class="iconfont guoran-tongyichicun-18-19-daochu"></i>
                {{$t('trainList.trainNode.export')}}
            </div>
            <div id="drawer-assembly-new">
                <div v-if="staticType != 1 && staticType != 2 && staticType != 3" class="box">
                    <div v-if="detailType == 'detail'" class="info-list">
                        <!-- <div class="one-column-ellipsis"><span>机器人：</span>{{ noteInfo.botName }}</div> -->
                        <div><span>{{$t('trainList.trainNode.versionNumber')}}：</span>{{ noteInfo.botVersion }}</div>
                        <div><span>{{$t('trainList.trainNode.trainTime')}}：</span>{{ noteInfo.trainTime }}</div>
                        <div><span>{{$t('trainList.trainNode.tagCount')}}：</span>{{ noteInfo.tagCount }}</div>
                    </div>
                    <div class="info-list">
                        <div><span>{{$t('trainList.correctAnnotation')}}：</span>{{ noteInfo.tagCorrectCount }}
                        </div>
                        <div><span>{{$t('trainList.annotationError')}}：</span>{{ noteInfo.tagIncorrectCount }}
                        </div>
                        <div><span>{{$t('trainList.trainNode.accuracy')}}：</span>{{ noteInfo.tagCorrectCount == 0 ? 0 : noteInfo.tagCorrectRate }}%</div>
                    </div>
                </div>
                <div class="note-details-search">
                    <el-input prefix-icon="guoran-tongyichicun-18-30-sousuo iconfont" size="small" v-model="keyword"
                        class="search-q" :placeholder="$t('trainList.trainNode.searchQuestion')" @input="emptySearch"
                        @keyup.enter.native="getQuestion('search')">
                        <!-- <i slot="suffix" class="el-input__icon el-icon-search" @click="getQuestion('search')"></i> -->
                    </el-input>
                    <el-date-picker v-if="detailType != 'detail'" size="small" v-model="dateValue"
                        class="search-q search-q-right" type="daterange" @change="changeNoteDetailDate"
                        range-separator="至" :start-placeholder="$t('trainList.trainNode.startDate')" :end-placeholder="$t('trainList.trainNode.endDate')">
                    </el-date-picker>
                </div>
                <el-table :height="'calc(100vh - ' + (300) + 'px)'" :data="tableData" style="width: 100%">
                    <el-table-column prop="question" :label="$t('trainList.trainNode.question')" align="left" min-width="180" class-name="question-name">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.type !== 'IMAGE'">{{ scope.row.question }}</span>
                                <el-image @click.native="viewImage(scope.row)" v-else
                                    :src="JSON.parse(scope.row.question).urls[0]" :fit="'cover'">
                                </el-image>
                            </div>
                            <div class="labels-box">
                                <div class="labels use"
                                    v-if="!(Number(scope.row.uselessNum) != 0 && Number(scope.row.usefulNum) != 0) && Number(scope.row.usefulNum) != 0 && scope.row.label && scope.row.label.indexOf('FEEDBACK_IS_USEFUL') !== -1">
                                  {{$t('trainList.questionList.feedback')}}{{isMnHr? $t('trainList.questionList.resolved') : $t('trainList.useful')}}</div>
                                <div class="labels no-use"
                                    v-if="!(Number(scope.row.uselessNum) != 0 && Number(scope.row.usefulNum) != 0) && Number(scope.row.uselessNum) != 0 && scope.row.label && scope.row.label.indexOf('FEEDBACK_IS_USELESS') !== -1">
                                  {{$t('trainList.questionList.feedback')}}{{isMnHr? $t('trainList.questionList.unresolved') : $t('trainList.useless') }}</div>
                                <div class="labels no-use" v-if="scope.row.status == 'IDENTIFY_ERROR_TAGED' || scope.row.status == 'NOT_IDENTIFY_TAGED'">{{$t('trainList.annotationError')}}</div>
                                <div class="labels use" v-if="scope.row.status == 'IDENTIFY_CORRECT_TAGED'">{{$t('trainList.correctAnnotation')}}</div>
                                <div class="labels long-info" v-if="Number(scope.row.uselessNum) != 0 && Number(scope.row.usefulNum) != 0">
                                    {{isMnHr? $t('trainList.questionList.resolved') : $t('trainList.useful') }}{{ Number(scope.row.usefulNum) }}；{{isMnHr? $t('trainList.questionList.unresolved') : $t('trainList.useless')}}{{ Number(scope.row.uselessNum) }}；{{$t('trainList.questionList.noFeedback')}}{{
        (Number(scope.row.hits)
        - Number(scope.row.usefulNum) - Number(scope.row.uselessNum))>0?(Number(scope.row.hits)
        - Number(scope.row.usefulNum) - Number(scope.row.uselessNum)):0 }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('trainList.trainNode.intentAfterAnnotation')" align="left" min-width="180">
                        <template slot-scope="scope">
                            <div style="display: flex;flex-direction: column;justify-content:center;height: 100%;">
                                <span style="line-height: 22px;">{{ scope.row.tagIntentName }}</span>
                                <div style="line-height: 16px;font-size: 12px;color: #909399;">
                                    {{ scope.row.tagIntentFullpath }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tagIntentName" :label="$t('trainList.trainNode.intentBeforeAnnotation')" align="left" min-width="180">
                        <!-- <template slot-scope="scope">
                            <div style="display: flex;flex-direction: column;justify-content:center;height: 100%;">
                                <span style="line-height: 22px;">{{ scope.row.intentName }}</span>
                                <div style="line-height: 16px;font-size: 12px;color: #909399;">
                                    {{ scope.row.intentFullpath }}</div>
                            </div>
                        </template> -->
                        <template slot-scope="scope">
                            <!-- <span style="display: inline-block;width: 10px;"></span> -->
                            <span
                                v-if="
                                    [4, 5].indexOf(scope.row.button) > -1 &&
                                    (scope.row.unrecognizedAction == null ||
                                        scope.row.unrecognizedAction == 0)
                                "
                                style="color: #FE5965"
                                >{{$t('trainList.questionList.unrecognized')}}</span
                            >
                            <span
                                v-if="
                                    [4, 5].indexOf(scope.row.button) > -1 &&
                                    scope.row.unrecognizedAction == 1
                                "
                                style="color: #f56c6c"
                                >{{$t('trainList.questionList.KeywordRecommend')}}</span
                            >
                            <span
                                v-if="
                                    [4, 5].indexOf(scope.row.button) > -1 &&
                                    scope.row.unrecognizedAction == 2
                                "
                                style="color: #FF7200"
                                >{{$t('trainList.questionList.noRecognizingRecommend')}}</span
                            >
                            <div style="display: flex;flex-direction: column;justify-content:center;height: 100%;"
                                v-if="!([4, 5].indexOf(scope.row.button) > -1 && (scope.row.unrecognizedAction == null || scope.row.unrecognizedAction == 0)) && 
                                !([4, 5].indexOf(scope.row.button) > -1 && scope.row.unrecognizedAction == 1) &&
                                !([4, 5].indexOf(scope.row.button) > -1 && scope.row.unrecognizedAction == 2)">
                                <span style="line-height: 22px;">{{ scope.row.intentName }}</span>
                                <div style="line-height: 16px;font-size: 12px;color: #909399;">{{scope.row.intentFullpath}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hits" :label="$t('trainList.questionList.hits')" align="left" width="60">
                    </el-table-column>
                    <el-table-column v-if="detailType != 'detail'" prop="botVersion" :label="$t('trainList.trainNode.trainVersion')" align="left" width="100">
                    </el-table-column>
                    <el-table-column prop="annotatorName" :label="$t('trainList.questionList.operator')" align="left" width="120">
                    </el-table-column>
                    <el-table-column prop="updateTimeStr" class-name="update-time-str" :label="$t('trainList.questionList.operationTime')" align="left"
                        width="150">
                    </el-table-column>
                    <el-table-column width="60" align="left" prop="" :label="$t('trainList.operation')">
                        <template slot-scope="scope">
                            <el-popover v-if="RP_Visible('OPEN_ML_RECORD_DELETE')" placement="top" width="200"
                                :popper-class="'train-detail-del'" v-model="scope.row.visible">
                                <p>{{$t('trainList.trainNode.removeQuestionTip')}}</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" @click="scope.row.visible = false">{{$t('common.cancel')}}</el-button>
                                    <el-button type="primary" size="mini"
                                        @click="deleteQuestion(scope.row)">{{$t('trainList.trainNode.remove')}}</el-button>
                                </div>
                                <el-tooltip class="item" effect="dark" :content="$t('trainList.trainNode.remove')" placement="right" :open-delay="300"
                                    slot="reference">
                                    <i class="guoran-quxiao iconfont"></i>
                                </el-tooltip>
                                <!-- <i class="guoran-quxiao iconfont" v-if="RP_Visible('OPEN_ML_RECORD_DELETE')" slot="reference"></i> -->
                            </el-popover>
                        </template>
                    </el-table-column>
                </el-table>
                <div :class="[total > 10 ? 'have-data' : '', 'pagination']">
                    <pagination :pageSize="pageSize" :currentPage="currentPage" :total="total"
                        @currentChange="pageChange">
                    </pagination>
                </div>
            </div>
        </el-drawer>
        <!-- <popup @closeEvent="close" :isNeedFooter="false">
            <div slot="popup-name">训练详情</div>
            <div slot="popup-con">
                <div class="box">
                    <div class="info-list">
                        <div class="one-column-ellipsis"><span>机器人：</span>{{noteInfo.botName}}</div>
                        <div><span>版本号：</span>{{noteInfo.botVersion}}</div>
                    </div>
                    <div class="info-list">
                        <div><span>训练时间：</span>{{noteInfo.trainTime}}</div>
                        <div><span>标注问题条数：</span>{{noteInfo.tagCount}}</div>
                    </div>
                </div>
                <el-input
                    prefix-icon="guoran-tongyichicun-18-30-sousuo iconfont"
                    v-model="keyword"
                    class="search-q"
                    placeholder="搜索问题"
                    @input="emptySearch"
                    @keyup.enter.native="getQuestion('search')"
                >
                </el-input>
                <el-table
                    :height="'calc(84vh - ' + (448) + 'px)'"
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                        prop="question"
                        label="问题"
                        align="left"
                        min-width="180"
                        class-name="question-name">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type !== 'IMAGE'">{{scope.row.question}}</span>
                            <el-image
                                @click.native="viewImage(scope.row)"
                                v-else
                                :src="JSON.parse(scope.row.question).urls[0]"
                                :fit="'cover'">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="tagIntentName"
                        label="意图"
                        align="left"
                        min-width="180">
                    </el-table-column>
                    <el-table-column
                        prop="annotatorName"
                        label="操作人"
                        align="left"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="updateTimeStr"
                        class-name="update-time-str"
                        label="操作时间"
                        align="left"
                        width="150">
                    </el-table-column>
                    <el-table-column
                        width="60"
                        align="left"
                        prop=""
                        label="操作">
                        <template slot-scope="scope">
                            <el-popover
                                v-if="RP_Visible('OPEN_ML_RECORD_DELETE')"
                                placement="top"
                                width="200"
                                :popper-class="'train-detail-del'"
                                v-model="scope.row.visible">
                                <p>移除问题后，该问题将会被移出到待标注问题中，移除后，需要重新训练机器人才能生效，是否移除该问题？</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" @click="scope.row.visible = false">取消</el-button>
                                    <el-button type="primary" size="mini"  @click="deleteQuestion(scope.row)">移除</el-button>
                                </div>
                                <el-tooltip  class="item" effect="dark" content="移除" placement="right" :open-delay="300" slot="reference">
                                    <i class="guoran-quxiao iconfont"></i>
                                </el-tooltip>
                            </el-popover> 
                        </template>
                    </el-table-column>
                </el-table>
                <div :class="[total > 10 ? 'have-data' : '','pagination']">
                    <pagination
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :total="total"
                        @currentChange="pageChange">
                    </pagination>
                </div>
            </div>
            <div slot="dialog-footer" v-if="false">
            </div>
        </popup> -->
        <imageView v-if="showImageViewer" :previewList.sync="previewList" :showViewer.sync="showImageViewer"
            :currentImage="currentImage" :isCanDel="false">
        </imageView>
    </div>
</template>

<script>
import Popup from '../../components/popupNew.vue';
import pagination from "../../components/Pagination.vue";
import imageView from '../../components/imageView.vue'
export default {
    props: {
        visibleDetails: {
            type: Boolean,
            default: false
        },
        rId: '',
        detailType: {
            type: String,
            default: 'detail'
        },
        staticType: {
            type: Number,
            default: 0
        },
        noteCurrentBot: '',
        newStartTime: '',
        newEndTime: '',
        trainKeyword: ''
    },
    components: { Popup, pagination, imageView },
    watch: {
        visibleDetails(n, o) {
            this.show = n;
            if (n === true) {
                if (this.newStartTime && this.newEndTime) {
                    this.dateValue = [this.newStartTime, this.newEndTime];
                }
                if (this.detailType == 'search') {
                    console.log(233, this.trainKeyword.trim());
                    this.keyword = this.trainKeyword.trim();
                }
                this.currentPage = 1;
                this.getData();
            }
        }
    },
    data() {
        return {
            show: false,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            keyword: '',
            noteInfo: {},
            tableData: [],
            loading: false,
            showImageViewer: false,
            previewList: [],
            currentImage: {},
            direction: 'rtl',
            dateValue: "",
            isMnHr: false,
            exportParam:{}
        }
    },
    mounted() {
         const allowedIds = [
            'b52d52cd3533409fb4b75af79868575f',
            '969a139de1df48c6965ff9014777fbae',
            '9209bdb27a0b460ea1eb04c54681b35e',
            'ab0f90737c8b4f2d85ba2157e4473110',
            '969a139de1df48c6965ff9014777fbae'
        ];

        if (allowedIds.includes(localStorage.getItem("_mainId"))) {
            this.isMnHr = true;
        }
    },
    methods: {
        changeNoteDetailDate() {
            console.log(274);
            this.getData();
        },
        // 预览图片
        viewImage(item) {
            this.currentImage = {
                imageUrl: JSON.parse(item.question).urls[0],
                sortNum: 0
            }
            this.previewList = JSON.parse(item.question).urls;
            this.showImageViewer = true;
        },
        close() {
            this.show = false;
            this.dateValue = "";
            this.keyword = "";
            this.$emit('close');
        },
        // 移除问题标注记录
        deleteQuestion(row) {
            console.log(334, this.noteInfo, this.noteInfo.id, this.rId);
            this.FetchPost(this.requestUrl.trainList.removeTag, {
                rId: row.trainId,
                tId: row.id
            }).then(res => {
                if (res.code === "0") {
                    this.getQuestion();
                    this.getData();
                } else {
                    this.$message.error(res.message);
                }
                row.visible = false;
            });
        },
        // 获取训练记录下的问题
        getQuestion(type) {
            console.log(this.newStartTime, this.newEndTime, this.dateValue);
            if (type) {
                this.currentPage = 1
            }
            let param = {
                keyword: this.keyword.trim(),
                // status: this.noteInfo.status,
                // orderType: 0,
                page: this.currentPage,
                size: this.pageSize,
                trained: true,
                orderType: 5
            }
            if (this.detailType == 'search') {
                if (this.staticType != 0) {
                    param.originIdentifyStatus = this.staticType;
                }
                // param.originIdentifyStatus = this.staticType;
                param.botId = this.noteCurrentBot;
                console.log(this.dateValue);
                if (this.dateValue && this.dateValue.length == 2) {
                    param.beginTagTime = new Date(this.dateValue[0]).getTime();
                    param.endTagTime = new Date(this.dateValue[1]).getTime() + 24*60*60*1000;
                }
            } else {
                param.botId = this.noteInfo.botId;
                param.botVersion = this.noteInfo.botVersion;
            }
            this.exportParam = param
            this.FetchPost(this.requestUrl.trainList.search, param).then(res => {
                if (res.code === "0") {
                    res.data.list.forEach(v => {
                        v.visible = false;
                        if (v.tagTime != null || v.tagTime != undefined) {
                            v.updateTimeStr = new Date(v.tagTime).Format("yyyy-MM-dd hh:mm:ss");
                        } else {
                            v.updateTimeStr = "";
                        }
                        if (v.annotatorInfo != null || v.annotator == -1) {
                            if (v.annotator == -1) {
                                v.annotatorName = '系统';
                            } else {
                                v.annotatorName = v.annotatorInfo.realName;
                            }
                            
                        } else {
                            v.annotatorName = "";
                        }

                        if (v.isIdentify === true && v.status === "NOT_TAG")
                            v.button = 1;
                        if (
                            v.isIdentify === true &&
                            v.status === "IDENTIFY_CORRECT_TAGED"
                        )
                            v.button = 2;
                        if (
                            v.isIdentify === true &&
                            v.status === "IDENTIFY_ERROR_TAGED"
                        )
                            v.button = 3;
                        if (
                            v.isIdentify === false &&
                            v.status === "NOT_IDENTIFY_TAGED"
                        )
                            v.button = 4;
                        if (
                            v.isIdentify === false &&
                            v.status === "NOT_TAG"
                        )
                            v.button = 5;
                        if (v.isIdentify === true && v.status === "NOT_TAG")
                            v.button = 6;
                    });
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        // 获取训练记录信息
        getData() {
            this.loading = true;
            if (this.detailType == 'search') {
                this.FetchPost(this.requestUrl.trainList.summaryTrainingRecords, {
                    beginTrainTime: null,
                    botId: this.noteCurrentBot,
                    endTrainTime: null,
                    page: 1,
                    size: 20
                }).then((res) => {
                    if (res.code === "0") {
                        this.noteInfo.tagCorrectCount = res.data.tagCorrectNum;
                        this.noteInfo.tagIncorrectCount = res.data.tagErrorNum;
                        this.noteInfo.tagCorrectRate = ((this.noteInfo.tagCorrectCount / (this.noteInfo.tagCorrectCount + this.noteInfo.tagIncorrectCount)) * 100).toFixed(2)
                    } else {
                        this.$message.error(res.message);
                    }
                })
                    .catch((err) => {
                        console.log(err);
                    });
                this.getQuestion();
            } else {
                this.FetchGet(this.requestUrl.trainList.trainDetails + '/' + this.rId).then(res => {
                    if (res.code === "0") {
                        this.noteInfo = res.data;
                        console.log(459, this.noteInfo);
                        this.noteInfo.trainTime = new Date(res.data.trainTime).Format("yyyy-MM-dd hh:mm:ss");
                        if (this.noteInfo.tagCorrectCount == null) {
                            this.noteInfo.tagCorrectCount = '';
                        }
                        if (this.noteInfo.tagIncorrectCount == null) {
                            this.noteInfo.tagIncorrectCount = '';
                        }
                        if (this.noteInfo.tagCorrectCount == '') {
                            this.noteInfo.tagCorrectRate = 0;
                        } else {
                            this.noteInfo.tagCorrectRate = this.noteInfo.tagCorrectCount / (this.noteInfo.tagCorrectCount + (this.noteInfo.tagIncorrectCount == '' ? 0 : this.noteInfo.tagIncorrectCount))
                            this.noteInfo.tagCorrectRate = (this.noteInfo.tagCorrectRate * 100).toFixed(2);
                        }
                        this.getQuestion();
                    } else {
                        this.loading = false;
                        this.$message.error(res.message);
                    }
                }).catch(() => {
                    this.loading = false;
                });
            }

        },
        emptySearch() {
            if (this.keyword === '') {
                this.getQuestion('search');
            }
        },
        pageChange(curr) {
            this.currentPage = curr;
            this.getData();
        },
        exprotDownload() {
            this.FetchPost(this.requestUrl.trainList.trainExport, this.exportParam).then(res => {
                if (res.code === "0") {
                    let link = document.createElement("a");
                    link.href = res.data;
                    let arr = res.data.split("/")
                    link.download = arr[arr.length - 1];
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    this.$message.error(res.message);
                }
            })
        }
    }
}
</script>
<style lang="less">
.el-popover.train-detail-del {
    z-index: 3333 !important;
}
</style>
<style lang="less" scoped>
.labels-box {
    display: flex;
    flex-wrap: wrap;

    .labels {
        height: 18px;
        line-height: 18px;
        background: #EFF2FF;
        border: 1px solid #DBE4FF;
        border-radius: 2px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #366AFF;
        margin-bottom: 6px;
        text-align: center;
        margin-right: 6px;

        &.worker {
            background-color: #DBE4FF;
            border-color: #DBE4FF;
            color: #366AFF;
            width: 55px;
        }

        &.artificial {
            background-color: #E4E5FF;
            border-color: #E4E5FF;
            color: #848AFF;
            width: 55px;
        }

        &.no-use {
            background-color: #FFDFE1;
            border-color: #FFDFE1;
            color: #FE5965;
            width: 75px;
        }

        &.use {
            background-color: #D4F1F0;
            border-color: #D4F1F0;
            color: #00C2BB;
            width: 75px;
        }

        &.long-info {
            background-color: #DBE4FF;
            border-color: #DBE4FF;
            color: #366AFF;
            min-width: 145px;
            padding: 0 8px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.note-details {
    text-align: left;
    position: relative;
    .exportBtn {
        position: absolute;
        width: 80px;
        height: 32px;
        background: #366AFF;
        border-radius: 5px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: 65px;
        top: 15px;
        i {
            margin-right: 5px;
        }
    }
    /deep/ #drawer-assembly-new {
        width: 1160px !important;
        margin: 0 auto;
        font-size: 14px;
        color: #000000;
        text-align: left;
        height: calc(100vh - 60px);

        // .popup-container-new{
        //     max-height: calc(84vh - 180px) !important;
        // }
        .box {
            background: #FBFCFD;
            border-radius: 5px;
            padding: 14px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info-list {
                display: flex;
                margin: 5px 0;

                >div {
                    flex: 1;
                }

                span {
                    color: #A9B3C6;
                }

            }
        }

        .note-details-search {
            margin-top: 14px;
            margin-bottom: 14px;
            display: flex;

            .search-q {
                flex: 1;

                .el-icon-search {
                    cursor: pointer;
                }

                // .el-input__inner {
                //     height: 38px;
                // }

                .el-input__prefix {
                    left: 10px;
                    top: -1px;

                    i {
                        font-size: 15px;
                    }
                }
            }

            .search-q-right {
                margin-left: 12px;
            }
        }


        .el-table {
            .el-table__body-wrapper th {
                border-bottom: 1px solid #E0E6F7;
                background-color: #fff;
            }

            .el-table__header {
                th {
                    border: none;
                }

            }

            .guoran-quxiao {
                color: #366AFF;
            }

            .update-time-str .cell {
                white-space: nowrap;
            }

            &::before {
                height: 0;
            }

            .el-image {
                width: 80px;
                height: 80px;
                border-radius: 10px;
                cursor: pointer;
            }

        }

        #pagination {
            position: static;
            box-shadow: none;
        }

        .pagination {
            padding-bottom: 30px;
            padding-top: 20px;
        }

        .dialog-footer {
            padding-top: 15px;
        }

        .have-data {
            // margin-top: 20px;
        }
    }
}
</style>