//guide.js
const steps = [
    {
        element: "#intentName",
        popover: {
          title: "机器人配置页面",
          description: "您已进入机器人配置页面，跟我一步一步看怎么配置一个机器人，首先看看有哪些配置项",
          position: "bottom"
        }
    },
    {
      element: "#knowledgebase",
      popover: {
        title: "1、知识库",
        description: "知识库用来配置机器人的识别问题的能力，配置的知识量越多，机器人就越聪明",
        position: "right"
      }
    },
    {
      element: "#chatflowdesign",
      popover: {
        title: "2、对话流程设计",
        description: "机器人能知道用户提了什么问题，但是怎么回复呢？进入对话流程设计中进行配置",
        position: "right"
      }
    },
    {
      element: "#chatbotsettings",
      popover: {
        title: "3、机器人设置",
        description: "流程设计完，机器人还需要进行一些简单配置，在机器人设置里进行配置",
        position: "right"
      }
    },
    {
      element: "#chatbotaccess",
      popover: {
        title: "4、机器人接入",
        description: "如果以上都设置完了，这是最后一步，可以将机器人接到到企业微信、小程序、公众号等渠道，就能马上使用了。",
        position: "right"
      }
    },
    {
      element: ".intent-list",
      popover: {
        title: "意图管理",
        description: "机器人的知识库是由一个一个的意图构成的，意图就是用户提问问题中心思想的提炼",
        position: "bottom"
      }
    },
    {
      element: ".intent",
      popover: {
        title: "意图识别配置",
        description: "点击进去看看怎么配置意图吧",
        position: "bottom"
      }
    }
  ]
   
  export default steps