<template>
    <div class="note-list">
        <div  class="note-list-table">
            <el-table
                ref="noteListTable"
                v-loading="loading"
                :height="'calc(100vh - ' + (287) + 'px)'"
                :data="tableData"
                stripe
                style="width: 100%"
            >
                <el-table-column prop="botName" :label="$t('trainList.trainNode.bot')" min-width="200px" class-name="bot-name" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="botVersion" :label="$t('trainList.trainNode.version')" align="left" class-name="edition-cell">
                    <template slot-scope="scope">
                        <i class="iconfont guoran-tongyichicun-banben"></i>
                        <span>{{scope.row.botVersion}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="time" :label="$t('trainList.trainNode.trainTime')" align="left" show-overflow-tooltip width="250px">
                    <template slot-scope="scope">
                        <i class="guoran-a-18-15 iconfont" style="color:#D2D8E3;margin-right:6px"></i>
                        <span>{{scope.row.time}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="left"
                    prop="tagCount"
                    :label="$t('trainList.trainNode.tagCount')"
                >
                </el-table-column>
                <el-table-column align="left" prop="status" :label="$t('trainList.trainNode.status')" class-name="train-status-cell" max-width="140px">
                    <template slot-scope="scope">
                        <div class="train-status finish" v-if="scope.row.status === 'TRAIN_SUCCESS'">{{$t('trainList.trainNode.trainingCompleted')}}</div>
                        <div class="train-status error" v-else-if="scope.row.status === 'TRAIN_ERROR'">{{$t('trainList.trainNode.trainFail')}}</div>
                        <div class="train-status ing" v-else>{{$t('trainList.trainNode.training')}}</div>
                    </template>
                </el-table-column>
                <el-table-column align="left" prop="" :label="$t('trainList.operation')" class-name="operation" width="80px">
                    <template slot-scope="scope">
                        <div>
                            <el-tooltip v-if="[''].indexOf(scope.row.status) > -1" class="item" effect="dark" :content="$t('trainList.trainNode.detail')" placement="top" :open-delay="300">
                                <i class="disabled iconfont guoran-tongyichicun-write-26-xiangqing"></i>
                            </el-tooltip>
                            <el-tooltip v-if="['TRAIN_SUCCESS'].indexOf(scope.row.status) > -1" class="item" effect="dark" :content="$t('trainList.trainNode.detail')" placement="top" :open-delay="300">
                                <i class="iconfont guoran-tongyichicun-write-26-xiangqing" @click="viewDetails(scope.row)"></i>
                            </el-tooltip>
                             <el-tooltip v-if="['TRAIN_ERROR'].indexOf(scope.row.status) > -1" class="item" effect="dark" :content="$t('trainList.retraining')" placement="top" :open-delay="300">
                                <i class="iconfont guoran-tongyichicun-16-12-shuaxin"></i>
                            </el-tooltip>
                            <!-- <i v-if="[''].indexOf(scope.row.status) > -1" class="disabled iconfont guoran-tongyichicun-write-26-xiangqing"></i>
                            <i v-if="['TRAIN_SUCCESS'].indexOf(scope.row.status) > -1" class="iconfont guoran-tongyichicun-write-26-xiangqing" @click="viewDetails(scope.row)"></i>
                            <i v-if="['TRAIN_ERROR'].indexOf(scope.row.status) > -1" class="iconfont guoran-tongyichicun-16-12-shuaxin"></i> -->
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <pagination
            :pageSize="pageSize"
            :currentPage="currentPage"
            :total="total"
            @currentChange="pageChange">
        </pagination>
        <note-details
            :visible-details="showDetails"
            :r-id="rId"
            :detailType="detailType"
            :staticType="staticType"
            :noteCurrentBot="noteCurrentBot"
            :newStartTime="newStartTime"
            :newEndTime="newEndTime"
            :trainKeyword="trainKeyword"
            @close="close"
        />
    </div>
</template>

<script>
import NoteDetails from "./noteDetails";
import pagination from "../../components/Pagination.vue";
export default {
    components: {
        NoteDetails,
        pagination
    },
    props: ["noteCurrentBot", "newStartTime", "newEndTime",'isShowTest','isShowHeathly'],
    data() {
        return {
            pageSize: 20,
            currentPage: 1,
            total: 0,
            tableData: [],
            showDetails: false,
            rId: "",
            loading: false,
            params:{},
            detailType: "detail", // detail 训练详情 search 搜索或下钻
            staticType: 0, // 下钻类型
            trainKeyword: "",
        };
    },
    mounted() {
        // this.getTrainList();
    },
    methods: {
        getActiveDetail(staticType, trainKeyword){
            console.log("note: ", staticType);
            this.staticType = staticType;
            this.detailType = "search";
            this.trainKeyword = trainKeyword;
            this.showDetails = true;
        },
        getActiveDetailWithKeyword(trainKeyword){
            this.staticType = 0;
            this.detailType = "search";
            this.trainKeyword = trainKeyword;
            console.log(118, this.trainKeyword);
            this.showDetails = true;
        },
        viewDetails(row) {
            this.rId = row.id;
            this.detailType = "detail";
            this.trainKeyword = "";
            this.showDetails = true;
        },
        close() {
            this.showDetails = false;
            this.getTrainList(this.params);
        },
        // 训练记录列表
        getTrainList(data) {
            this.loading = true;
            let param = data;
            param.page = this.currentPage;
            param.size = this.pageSize;
            param.beginTrainTime = data.beginTrainTime == null?null:new Date(data.beginTrainTime);
            param.endTrainTime = data.endTrainTime == null?null: new Date(data.endTrainTime);
            this.params = param;
            this.FetchPost(this.requestUrl.trainList.trainList, param)
                .then((res) => {
                    if (res.code === "0") {
                        this.tableData = [];
                        res.data.list.forEach((v) => {
                            v.time = new Date(v.createTime).Format(
                                "yyyy-MM-dd hh:mm:ss"
                            );
                            this.tableData.push(v);
                        });
                        this.total = res.data.total;
                        this.$emit('getSummaryTrainingRecords',param)
                        this.$nextTick(() => {
                            // 滚动到顶部
                            this.$refs.noteListTable.bodyWrapper.scrollTop = 0;
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        pageChange(curr) {
            this.currentPage = curr;
            this.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
        },
    },
    watch:{
        isShowTest(n){
            if(!n && !this.isShowHeathly){

            } else {
                this.$refs.noteListTable.doLayout();
            }
        },
        isShowHeathly(n){
            if(!isShowTest && !this.isShowHeathly){
                
            } else {
                this.$refs.noteListTable.doLayout();
            }
        }
    }
};
</script>

<style lang="less" scoped>
.note-list {
    // margin-top: 10px;
    // margin-right: 10px;
    height: calc(100vh - 287px);
    overflow: hidden;
    background: #ffffff;
    box-shadow: 18px -10px 18px 0px rgba(29,55,129,0.07);
  
    .note-list-table{
        /deep/ .el-table{
            .operation{
                padding-right: 22px;
                i{
                    cursor: pointer;
                    color: #366AFF;
                    &.disabled{
                        color: #B7C3DA;
                        cursor: no-drop;
                    }
                }
            }
            .bot-name{
                .cell{
                    padding-left: 22px;
                }
                
            }
            .edition-cell{
                i{
                    color: #A1B9FF;
                    margin-right: 4px;
                }
            }
            .train-status-cell{
                .train-status{
                    height: 22px;
                    background: #E1FAF9;
                    border-radius: 11px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //max-width: 80px;
                    padding: 0 4px;
                    &.ing{
                        background: #EFF5FF;
                        color: #458FFF;
                    }
                    &.finish{
                        background: #E1FAF9;
                        color: #00C2BB;
                    }
                    &.error{
                        background: #FFEBEC;
                        color: #FE5965;
                    }
                }
            }
            .el-table__body-wrapper{
                height: calc(100% - 48px);
            }
            th.gutter {
                display: table-cell !important
            }
        }
        
    }
    // .el-pagination {
    //     padding: 20px 60px;
    //     text-align: center;
    //     background-color: white;
    //     height: 64px;
    // }
    #pagination {
        bottom: -3px;
        height: 64px;
        z-index: 10 !important;
        border-radius: 0 !important;
    }
    
}

</style>