<template>
    <div class="actionQuestion">
        <div class="rhetorical-question-cell">
            <div class="rhetorical-question-input">
                <div class="tal">反问问题</div>
                <div
                    class="rhetorical-question-input-cell"
                    v-for="(q_item_text, q_item_text_index) in unit.content
                        .questions"
                    :key="q_item_text_index"
                >
                    <el-input
                        autosize
                        type="textarea"
                        v-model="unit.content.questions[q_item_text_index]"
                        placeholder="请输入要反问的问题"
                    ></el-input>
                    <span
                        v-if="q_item_text_index === 0"
                        @click="handleActionQuestionCellTextItem('add')"
                        class="el-icon-circle-plus handle-icon"
                    ></span>
                    <span
                        v-if="q_item_text_index !== 0"
                        @click="
                            handleActionQuestionCellTextItem(
                                'delete',
                                q_item_text_index
                            )
                        "
                        class="
                            el-icon-circle-close
                            handle-icon handle-icon-delete
                        "
                    ></span>
                </div>
            </div>
            <div class="rhetorical-question-entity">
                <div class="cell_55">
                    <div class="rhetorical-question-entity-cell">
                        <span>检测实体</span>
                        <el-cascader
                            placeholder="请搜索选择实体"
                            size="small"
                            v-model="unit.content.entity"
                            :options="entityOptions"
                            @change="handleChangeEntity"
                            filterable
                        ></el-cascader>
                    </div>
                    <div class="rhetorical-question-entity-cell">
                        <span>反问次数</span>
                        <el-select
                            size="small"
                            v-model="unit.content.questionNum"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in noGetParamQNumber"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                </div>

                <div class="rhetorical-question-entity-cell">
                    <span>如果实体已存在</span>
                </div>
                <div class="simple-cell-satisfaction">
                    <div class="jump-cell">
                        <el-select
                            v-model="unit.content.goToAction.scope"
                            size="small"
                            @change="questionJumpTypeValueChange"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in jumpTypeOptionsE"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <div class="right-select">
                            <el-select
                                v-show="
                                    unit.content.goToAction.scope === 'NODE'
                                "
                                v-model="unit.content.goToAction.value"
                                @change="nodeSelectChange"
                                :popper-append-to-body="false"
                                filterable
                                size="small"
                                placeholder="请输入选择名称"
                            >
                                <el-option
                                    v-for="item in nodeOptions"
                                    :popper-class="
                                        'pop_my' +
                                        item.classPop +
                                        item.parentNum
                                    "
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                            <el-cascader
                                v-show="
                                    unit.content.goToAction.scope === 'INTENT'
                                "
                                v-model="unit.content.goToAction.value"
                                :options="intentTree4Radio"
                                size="small"
                                :props="{
                                    expandTrigger: 'hover',
                                    emitPath: false,
                                    label: 'name',
                                    value: 'id',
                                }"
                            ></el-cascader>
                            <el-input
                                autosize
                                v-show="
                                    unit.content.goToAction.scope === 'TEXT'
                                "
                                type="textarea"
                                v-model="unit.content.goToAction.value"
                                maxlength="2000"
                                placeholder="请输入文字答案"
                            ></el-input>
                        </div>
                    </div>
                </div>
                <div class="rhetorical-question-entity-cell">
                    <span>如果检测不到实体</span>
                </div>
                <div class="simple-cell-satisfaction">
                    <div class="jump-cell">
                        <el-select
                            v-model="unit.content.entityNotFountAction.type"
                            size="small"
                            @change="questionJumpTypeValueChange"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in jumpTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <div class="right-select">
                            <el-select
                                v-if="
                                    unit.content.entityNotFountAction.type ===
                                    'GOTO_NODE'
                                "
                                v-model="
                                    unit.content.entityNotFountAction.gotoNode
                                        .id
                                "
                                @change="nodeSelectChange"
                                :popper-append-to-body="false"
                                filterable
                                size="small"
                                placeholder="请输入选择名称"
                            >
                                <el-option
                                    v-for="item in nodeOptions"
                                    :popper-class="
                                        'pop_my' +
                                        item.classPop +
                                        item.parentNum
                                    "
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                            <el-cascader
                                v-if="
                                    unit.content.entityNotFountAction.type ===
                                    'GOTO_INTENT'
                                "
                                v-model="
                                    unit.content.entityNotFountAction.gotoIntent
                                        .id
                                "
                                :options="intentTree4Radio"
                                size="small"
                                :props="{
                                    expandTrigger: 'hover',
                                    emitPath: false,
                                    label: 'name',
                                    value: 'id',
                                }"
                            ></el-cascader>
                            <el-input
                                autosize
                                v-if="
                                    unit.content.entityNotFountAction.type ===
                                    'SEND_MSG_TO_USER'
                                "
                                type="textarea"
                                v-model="
                                    unit.content.entityNotFountAction
                                        .sendMsgToUser.content
                                "
                                maxlength="2000"
                                placeholder="请输入文字答案"
                            ></el-input>
                        </div>
                    </div>
                    <div
                        v-if="
                            unit.content.entityNotFountAction.type ===
                            'RECOGNIZE_INTENT'
                        "
                        class="step-box"
                    >
                        <span>并在</span>
                        <el-input-number
                            size="mini"
                            :precision="0"
                            :min="1"
                            :max="100"
                            v-model="
                                unit.content.entityNotFountAction
                                    .recognizeIntent.roundToContinue
                            "
                            :step="1"
                        ></el-input-number>
                        <span
                            >轮对话内如果检测到对应实体，则继续走该意图的流程</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-show="false" class="b-add-rhetorical-question">
            <el-button @click="addActionQuestionCell()" type="text"
                >再添加一个反问</el-button
            >
        </div> -->
    </div>
</template>
<script>
export default {
    name: "aQuestion",
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "index",
        "entityOptions",
        "noGetParamQNumber",
    ],
    data() {
        return {
            jumpTypeOptionsE: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            jumpTypeOptions: [
                {
                    value: "RECOGNIZE_INTENT",
                    label: "识别用户意图",
                },
                {
                    value: "GOTO_NODE",
                    label: "跳转到节点",
                },
                {
                    value: "GOTO_INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "回复文本",
                },
            ],
        };
    },
    methods: {
        // 操作反问类型答案cell中单条文案
        handleActionQuestionCellTextItem(type, q_item_text_index) {
            if (type === "delete") {
                this.unit.content.questions.splice(q_item_text_index, 1);
            }
            if (type === "add") {
                console.log(this.index);
                this.unit.content.questions.push("");
            }
        },
        // 改版反问跳转类型切换
        questionJumpTypeValueChange() {
            // this.unit.content.entityNotFountAction.type = "";
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.actionQuestion {
    .step-box{
        text-align: left;
        margin-top: 8px;
        .el-input-number{
            width: 100px !important;
            margin: 0 4px;
        }
        .el-input {
            width: 30px;
        }
    }
    .el-tabs__content {
        padding: 8px 0 !important;
    }
    .el-tabs--border-card {
        border: none !important;
        box-shadow: none !important;
    }
    margin-top: 6px;

    .add {
        color: #366AFF;
        font-size: 24px;
        margin-left: 10px;
    }
    .del {
        color: red;
        font-size: 24px;
        margin-left: 10px;
    }
    .el-textarea {
        // width: 93%;
    }
    .rhetorical-question-input-cell-select {
        .el-select--small {
            width: 100% !important;
        }
    }
    .rhetorical-question-input {
        .el-select--small {
            width: 100% !important;
        }
        .rhetorical-question-input-cell {
            display: flex;
            justify-content: flex-start;
            padding: 4px 0;
            .el-select--small {
                flex: none;
                width: 100% !important;
            }
            .el-textarea {
                width: 100%;
            }
            .handle-icon {
                width: 40px;
                flex: none;
                margin-left: 8px;
                font-size: 24px;
                margin-top: 0px;
            }
            .handle-icon-delete {
                color: red;
            }
        }
    }
    .respose-video {
        display: flex;
        justify-content: space-around;
        .albumvideo {
            .pic_img {
                margin-bottom: 10px;
                margin: 0 auto;
                height: 148px;
                width: 248px;
                .el-upload {
                    height: 148px;
                    width: 248px;
                    border: 1px dashed #8c939d;
                    background-color: white;
                }
                .el-upload:hover {
                    border: 1px dashed skyblue;
                }
                .el-icon-plus {
                    margin-top: 60px;
                    font-size: 28px;
                    color: #8c939d;
                }
            }
        }
        .Upload_pictures {
            margin-top: 12px;
            color: #8c939d;
            font-size: 12px;
        }
    }
}
</style>