<template>
    <div id="trainList" v-loading="loading" :element-loading-text="$t('trainList.trainLoading')" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(20, 20, 20, 0.8)">
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="iconfont guoran-tongyichicun-jiqixuexi"></i>
                <span class="page-name">{{ activePageInfo.headerName }}</span>
                <el-button round v-if="activeTab === 'question' && RP_Visible('OPEN_ML_TRAIN')"
                    :disabled="Boolean(tagCount < 1 && trainStatus)" class="train-bot"
                    icon="iconfont guoran-tongyichicun-16-12-shuaxin" @click="trainBot">{{$t('trainList.retraining')}}</el-button>
            </div>
            <div slot="top-header-right" class="top-header-right">
                <!-- <div class="right-title" >
                    <el-button
                        v-if="
                            activeTab === 'question' && RP_Visible('OPEN_ML_TRAIN')
                        "
                        :disabled="Boolean(tagCount < 1 && trainStatus)"
                        class="train-bot"
                        type="primary"
                        size="small"
                        @click="trainBot"
                        >重新训练机器人</el-button>
                </div> -->
                <div class="right-title">
                    <p style="flex: 1" v-if="activeTab === 'question' || activeTab === 'feedbackNote'
                        "></p>
                </div>
                <div v-if="activeTab === 'note'" class="right-titl dateSelection-t">
                    <div class="left-quick-choose" v-if="!isShowTest && !isShowHeathly">
                        <div :class="['date', currentDate == 'yesterday' ? 'isActive' : '']" @click="yesterdayData">{{$t('trainList.yesterday')}}</div>
                        <div :class="['date', currentDate == 'today' ? 'isActive' : '']" @click="todayData">{{$t('trainList.today')}}</div>
                        <div :class="['date', currentDate == 'lastWeek' ? 'isActive' : '']" @click="lastWeekData">{{$t('trainList.lastWeek')}}</div>
                        <div :class="['date', currentDate == 'lastMonth' ? 'isActive' : '']" @click="lastMonthData">{{$t('trainList.lastMonth')}}
                        </div>
                        <div :class="['date', currentDate == 'all' ? 'isActive' : '']" @click="allData">{{$t('trainList.allDate')}}</div>
                    </div>
                    <el-date-picker prefix-icon="guoran-a-18-15 iconfont" v-model="dateSeleteData.value"
                        @change="datePickerChange" type="daterange" range-separator="至" :start-placeholder="$t('common.startDate')"
                        :end-placeholder="$t('common.endDate')">
                    </el-date-picker>
                </div>
                <div class="right-title" v-if="activeTab === 'note'">
                    <el-select v-model="noteCurrentBot" style="width: 155px;" @change="changeNoteBot">
                        <el-option v-for="item in botList" :key="item.name" :value="item.id" :label="item.name" />
                    </el-select>
                    <el-input class="search-note-intent" prefix-icon="iconfont guoran-tongyichicun-18-30-sousuo"
                        v-model="trainKeyword" size="small" :placeholder="$t('trainList.searchTrainQuestion')"
                        @keyup.enter.native="getNoteByKeyword">
                    </el-input>
                </div>
                <div class="right-title" v-if="activeTab === 'question'">
                    <el-button v-if="exportQVisible" @click="exportQues" :loading="exportBtnLoading" round
                        icon="iconfont guoran-tongyichicun-18-19-daochu"> {{$t('trainList.export')}}</el-button>
                    <el-select v-model="currentBot" style="width: 185px;margin-left: 10px;" @change="changeBot">
                        <el-option v-for="item in botList" :key="item.name" :value="item.id" :label="item.name" />
                    </el-select>

                    <el-input class="search-intent" prefix-icon="iconfont guoran-tongyichicun-18-30-sousuo"
                        v-show="activeTab === 'question'" v-model="keyword" size="small" :placeholder="$t('trainList.searchQuestion')"
                        @input.native="emptySearch" @keyup.enter.native="$refs.myQuestion.getQuestion()"
                        @click.stop.native="searchAllSkillFocus($event)">
                        <!-- <i
                            slot="suffix"
                            class="el-input__icon el-icon-search"
                            @click="$refs.myQuestion.getQuestion()"
                        ></i> -->
                    </el-input>
                    <el-button @click="showAdvSearch" round icon="iconfont guoran-gaojishaixuan">{{ !isShowTest &&
                        !isShowHeathly ? $t('trainList.advancedFiltering') : '' }}</el-button>
                </div>
                <div class="right-title" v-if="activeTab === 'feedbackNote'">
                    <el-select @change="changeCurrentBotApikey" v-model="currentBotApikey" size="small">
                        <el-option v-for="item in botList" :key="item.apiKey" :value="item.apiKey" :label="item.name" />
                    </el-select>
                </div>
                <div class="right-title" v-if="activeTab === 'orderToKnowledgeBase'">
                    <el-input class="search-intent" prefix-icon="iconfont guoran-tongyichicun-18-30-sousuo"
                        v-model="keyword" size="small" :placeholder="$t('trainList.searchOrder')" @input="emptySearch"
                        @keyup.enter.native="$refs.orderToKnowledgeBase.getOrderToKnowledgeBaseDate()"
                        @click.stop.native="searchAllSkillFocus($event)">
                    </el-input>
                    <!--  -->
                    <!-- @click.stop.native="searchAllSkillFocus($event)" -->
                </div>
            </div>
        </page-top-header>
        <div class="train-list-content" :class="$i18n.locale === 'en' ? 'train-list-content-en' :''">
            <el-tabs id="left-sub-nav" class="tl-el-tab" tab-position="left" v-model="activeTab" @tab-click="tabClick">
                <el-tab-pane name="question">
                    <span slot="label"><i class="iconfont guoran-tongyichicun-biaozhu"></i> {{$t('trainList.questionToBeAnnotated')}}</span>
                    <div v-if="JSON.stringify(advSearchData) != '{}'" class="adv-search-params">
                        <div class="tag-b" v-if="advSearchData.queryTypeObj != null">
                            <el-tag key="queryTypeObj" size="mini" @close="closeParam('queryTypeObj')" closable>
                              {{$t('trainList.questionType')}}：{{ advSearchData.queryTypeObj.text }}
                            </el-tag>
                        </div>
                        <div class="tag-b" v-if="advSearchData.intents != null">
                            <el-tag key="intents" size="mini" @close="closeParam('intents')" closable>意图：
                                {{
                                    advSearchData.intents
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/").length > 12
                                    ? advSearchData.intents
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/")
                                        .substring(0, 12) + "..."
                                    : advSearchData.intents
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/")
                                }}
                            </el-tag>
                        </div>
                        <div class="tag-b" v-if="advSearchData.lastMsgTime != null">
                            <el-tag key="lastMsgTime" size="mini" @close="closeParam('lastMsgTime')" closable>
                              {{$t('trainList.lastTime')}}：{{
                                    formatDateTime(
                                        advSearchData.lastMsgTime[0]
                                    )
                                }} 至 {{
    formatDateTime(advSearchData.lastMsgTime[1])
}}
                            </el-tag>
                        </div>
                        <div class="tag-b one-column-ellipsis" v-if="advSearchData.orgnation != null">
                            <el-tag key="orgnation" size="mini" @close="closeParam('orgnation')" closable>
                              {{$t('trainList.dept')}}：
                                <span class="data-label"
                                    v-if="advSearchData.orgnation[0].selfType === 0 || advSearchData.orgnation[0].selfType === 1">
                                    <span v-if="advSearchData.orgnation[0].staffType !== 'staff-t'">{{
                                        advSearchData.orgnation[0].label }}</span>
                                    <span v-else>
                                        <open-data
                                            :type="advSearchData.orgnation[0].selfType == 0 ? 'departmentName' : 'userName'"
                                            :openid="advSearchData.orgnation[0].label"></open-data>
                                    </span>
                                </span>
                                <span v-else>{{ advSearchData.orgnation[0].name }}</span>
                                <!-- {{
                                    advSearchData.orgnation
                                        .map((x) => {
                                            return x.label;
                                        })
                                        .join("/").length > 12
                                        ? advSearchData.orgnation
                                              .map((x) => {
                                                  return x.label;
                                              })
                                              .join("/")
                                              .substring(0, 12) + "..."
                                        : advSearchData.orgnation
                                              .map((x) => {
                                                  return x.label;
                                              })
                                              .join("/")
                                }} -->
                            </el-tag>
                        </div>
                        <div class="tag-b" v-if="advSearchData.noteUser != null">
                            <el-tag key="noteUser" size="mini" @close="closeParam('noteUser')" closable>
                              {{$t('trainList.operator')}}：{{
                                    advSearchData.noteUser
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/").length > 12
                                    ? advSearchData.noteUser
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/")
                                        .substring(0, 12) + "..."
                                    : advSearchData.noteUser
                                        .map((x) => {
                                            return x.name;
                                        })
                                        .join("/")
                                }}
                            </el-tag>
                        </div>
                        <div class="tag-b" v-if="advSearchData.lastNoteTime != null">
                            <el-tag key="lastNoteTime" size="mini" @close="closeParam('lastNoteTime')" closable>
                              {{$t('trainList.finallyMarkTime')}}：{{
                                    formatDateTime(
                                        advSearchData.lastNoteTime[0]
                                    )
                                }} 至 {{
    formatDateTime(
        advSearchData.lastNoteTime[1]
    )
}}
                            </el-tag>
                        </div>
                        <div class="tag-b" v-if="advSearchData.noteStetus != null">
                            <el-tag key="noteStetus" size="mini" @close="closeParam('noteStetus')" closable>
                              {{$t('trainList.isMarked')}}：{{ advSearchData.noteStetus.name }}
                            </el-tag>
                        </div>
                        <i @click="deleteAllAdv" class="iconfont guoran-a-16_01"></i>
                    </div>
                    <div :class="['guoran-index-tab', JSON.stringify(advSearchData) != '{}' ? 'have-search' : '']"
                        v-if="checkedIds.length === 0">
                        <div class="left">
                            <div @click="changeQwType('mark')"
                                :class="[qwType == 'mark' ? 'gruoran-index-tab-cell-active' : '', 'gruoran-index-tab-cell']">
                                <span>{{$t('trainList.toBeAnnotated')}}</span>
                                <div class="num">{{ noMarkedNum }}</div>
                            </div>
                            <div @click="changeQwType('ignore')"
                                :class="[qwType == 'ignore' ? 'gruoran-index-tab-cell-active' : '', 'gruoran-index-tab-cell']">
                                <span>{{$t('trainList.ignored')}}</span>
                                <div class="num">{{ ignoreNum }}</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="no-mark">{{$t('trainList.unmarked')}}：{{ OverviewData.notTagNum || 0 }}</div>
                            <div class="had-mark">{{$t('trainList.marked')}}：{{ OverviewData.tagAlreadyNum || 0 }}</div>
                            <div class="mark-right">{{$t('trainList.correctAnnotation')}}：{{ OverviewData.tagCorrectNum || 0 }}</div>
                            <div class="mark-error">{{$t('trainList.annotationError')}}：{{ OverviewData.tagErrorNum || 0 }}</div>
                            <div class="unrecognized-mark">{{$t('trainList.unrecognizedAnnotations')}}：{{ OverviewData.notRecognizeTagNum || 0 }}</div>
                        </div>
                    </div>
                    <bot-question :isShowSearch="JSON.stringify(advSearchData) != '{}'" ref="myQuestion" :botList="botList"
                        :topAdd="top" :bot-id="currentBot" :qwType="qwType" :keyword="keyword" :checkedIds="checkedIds"
                        :currentBotInfo="currentBotInfo" @refresh="changeStatus" @checkedIdsFn="checkedIdsFn"
                        @getOverview="getOverview" @getTagMsgNum="getTagMsgNum" />
                </el-tab-pane>
                <el-tab-pane v-if="RP_Visible('OPEN_ML_RECORD_VIEW')" :label="$t('trainList.trainingRecords')" name="note" class="note-tab">
                    <span slot="label"><i class="iconfont guoran-tongyichicun-xunlianjilu"></i> {{$t('trainList.trainingRecords')}}</span>
                    <div class="statistics-box">
                        <div @click="getActiveDetailList(0)" class="had-train item">
                            <div :class="['num', summaryTrainingRecordsData.haveBeenTrainedNum > 99 ? 'big' : '']">
                                {{ summaryTrainingRecordsData.haveBeenTrainedNum || 0 }}</div>
                            <div class="text">{{$t('trainList.trained')}}</div>
                        </div>
                        <div @click="getActiveDetailList(1)" class="mark-right item">
                            <div :class="['num', summaryTrainingRecordsData.tagCorrectNum > 99 ? 'big' : '']">
                                {{ summaryTrainingRecordsData.tagCorrectNum || 0 }}</div>
                            <div class="text">{{$t('trainList.correctAnnotation')}}</div>
                        </div>
                        <div @click="getActiveDetailList(2)" class="mark-error item">
                            <div :class="['num', summaryTrainingRecordsData.tagErrorNum > 99 ? 'big' : '']">
                                {{ summaryTrainingRecordsData.tagErrorNum || 0 }}</div>
                            <div class="text">{{$t('trainList.annotationError')}}</div>
                        </div>
                        <!-- <div @click="getActiveDetailList(3)" class="unrecognized-mark item">
                            <div :class="['num',summaryTrainingRecordsData.notRecognizeTagNum > 99 ? 'big' : '']">{{summaryTrainingRecordsData.notRecognizeTagNum || 0}}</div>
                            <div class="text">不识别标注</div>
                        </div> -->
                    </div>
                    <train-note :noteCurrentBot="noteCurrentBot" :newStartTime="newStartTime" :newEndTime="newEndTime"
                        :staticType="staticType" ref="myNote" @getSummaryTrainingRecords="getSummaryTrainingRecords"
                        :isShowTest="isShowTest" :isShowHeathly="isShowHeathly" />
                </el-tab-pane>
                <el-tab-pane label="答案反馈记录" name="feedbackNote">
                    <span slot="label"><i class="iconfont guoran-tongyichicun-daanfankuijilu"></i>{{$t('trainList.answerFeedbackRecord')}}</span>
                    <feedback-note ref="feedbackNote" :apikey="currentBotApikey" :bot-id="currentBot" :keyword="keyword" />
                </el-tab-pane>
                <el-tab-pane label="工单转知识库" name="orderToKnowledgeBase">
                    <span slot="label"><i class="iconfont guoran-zhuanzhishiku"></i>{{$t('trainList.orderToKnowledgeBase')}}</span>
                    <orderToKnowledgeBase ref="orderToKnowledgeBase" @changeLoding="changeLoding"></orderToKnowledgeBase>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-drawer :withHeader="false" :modal="false" custom-class="acv-search-drawer" :visible.sync="advSearchdrawer"
            :direction="direction" :before-close="handleClose">
            <div>
                <AdvSearch ref="advSearch" @closeAdvSerch="closeAdvSerch" @submitAdvSerch="submitAdvSerch"
                    :apikey="currentBotApikey" :botId="currentBot" :keyword="keyword" :advSearchData="advSearchData"
                    :advSearchdrawer="advSearchdrawer" />
            </div>
        </el-drawer>
    </div>
</template>
<script>
import pageTopHeader from '../../components/page-top-header.vue'
import TrainNote from "./note.vue";
import BotQuestion from "./question.vue";
import feedbackNote from "./feedbackNote.vue";
import AdvSearch from "./component/AdvSearch.vue";
import OpenData from "../../components/openData.vue";
import orderToKnowledgeBase from './orderToKnowledgeBase.vue';
export default {
    data () {
        return {
            trainKeyword: "", // 已训练问题搜索词

            dateSeleteData: [{ value: "" }],
            currentDate: "",
            newStartTime: "",
            newEndTime: "",
            noteCurrentBot: "",
            top: 0,
            advSearchdrawer: false,
            advSearchData: {},
            direction: "ttb",
            qwType: "mark",
            botList: [],
            currentBot: "",
            currentBotApikey: "", // 答案反馈记录 当前机器人
            keyword: "",
            activePageInfo: {
                // 当前页跳转信息
                name: "返回全部实体",
                // gotoPageName: "botIndex",
                // botId: 1,
                headerName: this.$t('trainList.title'),
                entityName: "",
            },
            activeTab: "question",
            tagCount: 0,
            trainStatus: true,
            loading: false,
            checkedIds: [],
            OverviewData: {
                "notTagNum": 0,          // 待标注问题数
                "tagAlreadyNum": 0,       // 已标注问题数
                "tagCorrectNum": 0,       // 标注正确问题数
                "tagErrorNum": 0,         // 标注错误问题数
                "notRecognizeTagNum": 0   // 不识别标注
            },
            summaryTrainingRecordsData: {
                "haveBeenTrainedNum": 0,   // 已训练数
                "tagCorrectNum": 0,     // 标注正确
                "tagErrorNum": 0,     // 标注错误
                "notRecognizeTagNum": 0  // 不识别标志
            },
            currentBotInfo: {},
            noMarkedNum: 0,
            ignoreNum: 0,
            isShowTest: false,
            isShowHeathly: false,
            exportBtnLoading: false,
            exportQVisible: false,
            mainId: '',
        };
    },
    components: {
        TrainNote,
        BotQuestion,
        feedbackNote,
        AdvSearch,
        pageTopHeader,
        OpenData,
        orderToKnowledgeBase
    },
    beforeDestroy () {
        this.FetchGet(
            this.requestUrl.trainList.noteCount + this.currentBot
        ).then((res) => {
            if (res.code === "0") {
                if (res.data > 0) {
                    this.$notify({
                        duration: 5000,
                        dangerouslyUseHTMLString: true,
                        message: `<p>${this.$t('trainList.noteMsg1')}<span style="color:#f56c6c">${res.data}</span>${this.$t('trainList.noteMsg2')}~</p>
                            <p style="text-align: right;margin-top:10px;cursor:pointer;color:#f56c6c" id="goToTrain">${this.$t('trainList.noteMsg3')}</p>
                        `,
                    });
                    document.getElementById("goToTrain").onclick = () => {
                        this.$router.push("/main/trainList");
                    };
                }
            } else {
                this.$message.error(res.message);
            }
        });
    },
    mounted () {
        // 滔搏导出
        this.mainId = localStorage.getItem('_mainId');
        if (this.mainId == '56bec4b273ca4534b8c6d05479908e81' || this.mainId == 'ab0f90737c8b4f2d85ba2157e4473110' || this.mainId == '86dc09da570948d2b4841122d732f373' || this.mainId == 'b52d52cd3533409fb4b75af79868575f' || this.mainId == '0420b8da34c7450bbc038d824c35f8d0') {
            this.exportQVisible = true;
        }

        this.qwType = 'mark'
        if (this.$route.query.type != undefined) {
            this.activeTab = this.$route.query.type;
        }
        let activeId = this.$route.query.BotId ? this.$route.query.BotId : "";
        let botApiKey = this.$route.query.scope ? this.$route.query.scope : "";
        this.getBotList(activeId, botApiKey);
        this.isShowTest = sessionStorage.getItem('isOpenTestDrawer') == 'true' ? true : false;
        this.isShowHeathly = sessionStorage.getItem('isOpenHeathlyDrawer') == 'true' ? true : false;
        this.$eventBus.$on("bot-heathly-visible", (visible) => {
            this.isShowHeathly = visible.flag;
            if (this.isShowHeathly) {
                this.isShowTest = false;
            }
        });
        this.$eventBus.$on("test-window-visible", (visible) => {
            this.isShowTest = visible;
            if (this.isShowTest) {
                this.isShowHeathly = false;
            }
        });
    },
    methods: {
        changeLoding(flag) {
            this.loading = flag
        },
        getActiveDetailList (staticType) {
            console.log('index: ', staticType);
            this.trainKeyword = "";
            this.$refs.myNote.getActiveDetail(staticType, "");
        },
        getNoteByKeyword () {
            if (this.trainKeyword.trim() != '') {
                this.$refs.myNote.getActiveDetailWithKeyword(this.trainKeyword);
            }
        },
        // 滔搏
        exportQues () {
            // this.exportBtnLoading = true;
            window.location.href = this.requestUrl.exportQ.exportQues + '?botId=' + this.currentBot + '&ignored=false';
            // this.FetchGet(this.requestUrl.exportQ.exportQues + '?botId=' + this.currentBot + '&ignored=false').then(res => {
            //     if (res.code === '0'){
            //        setTimeout(() => {
            //             window.location.href = res.data;
            //             this.$message({
            //                 message:'导出成功',
            //                 type:'success',
            //                 duration:1000
            //             })
            //             this.exportBtnLoading = false;
            //        },1000)
            //     } else {
            //         this.$message.error(res.message)
            //     }
            // })
        },
        getTagMsgNum (data) {
            this.noMarkedNum = data.noMarkedNum;
            this.ignoreNum = data.ignoreNum;
        },
        searchAllSkillFocus () {
            document.querySelector('.search-intent').classList.add('max-search-input');
        },

        // 获取待标注问题的统计数量
        getOverview (searchParams) {
            delete searchParams.status
            this.FetchPost(this.requestUrl.trainList.getOverview, searchParams).then((res) => {
                if (res.code === "0") {
                    this.OverviewData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 获取训练记录的统计数量
        getSummaryTrainingRecords (searchParams) {
            this.FetchPost(this.requestUrl.trainList.summaryTrainingRecords, searchParams).then((res) => {
                if (res.code === "0") {
                    this.summaryTrainingRecordsData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            })
                .catch((err) => {
                    console.log(err);
                });
        },
        checkedIdsFn (datas) {
            this.checkedIds = datas;
        },
        todayData () {
            this.currentDate = "today";
            let endTime = new Date().getTime();
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let todayStartTime = yy + "-" + mm + "-" + dd + " " + "0:0:0";
            let todayStartTimeDate = new Date(
                Date.parse(todayStartTime.replace(/-/g, "/"))
            );
            let startTime = todayStartTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        yesterdayData () {
            this.currentDate = "yesterday";
            var time = new Date().getTime() - 24 * 60 * 60 * 1000;
            var yesday = new Date(time); // 获取的是前一天日期
            yesday =
                yesday.getFullYear() +
                "-" +
                (yesday.getMonth() > 9
                    ? yesday.getMonth() + 1
                    : yesday.getMonth() + 1) +
                "-" +
                yesday.getDate();

            let yesterdayStartTime = yesday + " " + "0:0:0";
            let yesterdayEndTime = yesday + " " + "23:59:59";
            let yesterdayStartTimeDate = new Date(
                Date.parse(yesterdayStartTime.replace(/-/g, "/"))
            );
            let yesterdayEndTimeDate = new Date(
                Date.parse(yesterdayEndTime.replace(/-/g, "/"))
            );
            let startTime = yesterdayStartTimeDate.getTime();
            let endTime = yesterdayEndTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        lastWeekData () {
            this.currentDate = "lastWeek";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-6);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        lastMonthData () {
            this.currentDate = "lastMonth";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-29);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.dateSeleteData = [];
        },
        allData () {
            this.currentDate = "all";
            this.newStartTime = null;
            this.newEndTime = null;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: null,
                endTrainTime: null,
            });
            this.dateSeleteData = [];
        },
        datePickerChange () {
            if (this.dateSeleteData.value) {
                this.currentDate = "diy";
                let d = this.dateSeleteData.value[0];
                let startTimeDated =
                    d.getFullYear() +
                    "-" +
                    (d.getMonth() + 1) +
                    "-" +
                    d.getDate() +
                    " " +
                    d.getHours() +
                    ":" +
                    d.getMinutes() +
                    ":" +
                    d.getSeconds();
                let startTimeDate = new Date(
                    Date.parse(startTimeDated.replace(/-/g, "/"))
                );
                let startTime = startTimeDate.getTime();
                let end = this.dateSeleteData.value[1];
                let e = new Date(end);
                let endday =
                    e.getFullYear() +
                    "-" +
                    (e.getMonth() + 1) +
                    "-" +
                    e.getDate();
                let timestamp = new Date().getTime();
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let today = yy + "-" + mm + "-" + dd;
                let todaystamp = Date.parse(today);
                let enddaystamp = Date.parse(endday);
                let endTime = "";
                if (todaystamp === enddaystamp) {
                    endTime = timestamp;
                } else {
                    let endTimeDated =
                        e.getFullYear() +
                        "-" +
                        (e.getMonth() + 1) +
                        "-" +
                        e.getDate() +
                        " " +
                        "23:59:59";
                    let endTimeDate = new Date(
                        Date.parse(endTimeDated.replace(/-/g, "/"))
                    );
                    endTime = endTimeDate.getTime();
                }
                if (startTime > timestamp || endTime > timestamp) {
                    this.dateSeleteData = [];
                    this.$message.error("不能穿越哦，请重新选择：");
                } else {
                    this.newStartTime = startTime;
                    this.newEndTime = endTime;
                    this.$refs.myNote.getTrainList({
                        botId: this.noteCurrentBot,
                        beginTrainTime: this.newStartTime,
                        endTrainTime: this.newEndTime,
                    });
                }
            } else {
                this.yesterdayData();
            }
        },
        changeGetData () {
            switch (true) {
                case this.currentDate === "today":
                    this.todayData();
                    break;
                case this.currentDate === "yesterday":
                    this.yesterdayData();
                    break;
                case this.currentDate === "lastWeek":
                    this.lastWeekData();
                    break;
                case this.currentDate === "lastMonth":
                    this.lastMonthData();
                    break;
                case this.currentDate == "all":
                    this.allData();
                    break;
                case this.currentDate === "diy":
                    this.datePickerChange();
                    break;
            }
        },
        getDay (day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth (month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        formatDateTime (date) {
            var date = new Date(date);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            var h = date.getHours();
            var minute = date.getMinutes();
            minute = minute < 10 ? "0" + minute : minute;
            return y + "-" + m + "-" + d;
        },
        closeParam (type) {
            this.$delete(this.advSearchData, type);
            if (JSON.stringify(this.advSearchData) == "{}") {
                this.top = 0;
            } else {
                this.top = 50;
            }
            this.$refs.advSearch.clearAdvConditionCell(
                type,
                this.advSearchData
            );
            this.$refs.myQuestion.getQuestion(1, this.advSearchData);
        },
        deleteAllAdv () {
            this.advSearchData = {};
            this.top = 0;
            if (this.$refs.advSearch) {
                this.$refs.advSearch.clearAdvConditionAll();
            }
            this.$refs.myQuestion.getQuestion(1, {});
        },
        showAdvSearch () {
            this.advSearchdrawer = true;
            this.$nextTick(() => {
                this.$refs.advSearch.getAnnotator();
            })
        },
        closeAdvSerch () {
            this.advSearchdrawer = false;
            this.$refs.advSearch.clearAdvConditionAll();
        },
        submitAdvSerch (param) {
            this.advSearchData = JSON.parse(param);
            if (Object.keys(this.advSearchData).length == 0) {
                this.top = 0;
            } else {
                this.top = 50;
            }
            this.$refs.myQuestion.getQuestion(1, this.advSearchData);
            this.advSearchdrawer = false;
        },
        changeQwType (value) {
            this.qwType = value;
            this.$nextTick(() => {
                // this.$refs.myQuestion.getQuestion(1); // param 切换TAB改变页码
                if (Object.keys(this.advSearchData).length == 0) {
                    this.top = 0;
                } else {
                    this.top = 50;
                }
                this.$refs.myQuestion.getQuestion(1, this.advSearchData);
            });
        },
        changeCurrentBotApikey (data) {
            this.currentBotApikey = data;
            this.botList.forEach((item) => {
                if (item.apiKey == data) {
                    this.currentBot = item.id;
                }
            });
            console.log(data);
            this.$refs.feedbackNote.changeCurrentBotApikey(data);
        },
        // 获取机器人列表
        getBotList (id, apiKey) {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botList = res.data.list;
                    let apiKeys = ''
                    res.data.list.forEach((item, index) => {
                        if (this.$route.query.isHeathly == 1 && this.$route.query.botId) {
                            apiKeys = item.apiKey;
                        }
                    })
                    if (this.$route.query.isHeathly == 1 && this.$route.query.botId) {
                        this.currentBot = parseInt(this.$route.query.botId);
                        this.noteCurrentBot = parseInt(this.$route.query.botId);
                        this.currentBotApikey = apiKeys;
                    } else {
                        this.currentBot = id ? parseInt(id) : this.botList[0].id;
                        this.noteCurrentBot = id ? parseInt(id) : this.botList[0].id;
                        this.currentBotApikey = apiKey
                            ? apiKey
                            : this.botList[0].apiKey;
                    }
                    this.botList.forEach((item, index) => {
                        if (item.id === this.currentBot) {
                            this.currentBotInfo = item
                        }
                    })
                    /* this.currentBot = this.botList[0].id;*/
                    /*this.currentBotApikey = this.botList[0].apiKey;*/
                    this.changeStatus();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        tabClick () {
            console.log(this.activeTab, 'activeTab');
            if (this.activeTab === "note") {
                this.allData();
                this.getSummaryTrainingRecords()
            } else if (this.activeTab === "question") {
                this.$nextTick(() => {
                    // 滚动到顶部
                    document.querySelector('#q-table .el-table__body-wrapper').scrollTop = 0;
                })
            } else {
                this.qwType = 'mark';
                this.$nextTick(() => {
                    this.deleteAllAdv();
                    // this.$refs.myQuestion.getQuestion();
                })
            }
        },
        trainBot () {
            this.loading = true;
            this.FetchPost(this.requestUrl.trainList.trainTag, {
                bId: this.currentBot,
            }).then((res) => {
                if (res.code === "0") {
                    this.changeStatus();
                    this.$refs.myQuestion.getQuestion();
                    setTimeout(() => {
                        this.loading = false;
                        this.$notify({
                            title: this.$t('trainList.success'),
                            message:
                                this.$t('trainList.trainSuccess'),
                            type: "success",
                        });
                    }, 5000);
                } else {
                    this.loading = false;
                    this.$message.error(res.message);
                }
            });
        },
        getTrainStatus () {
            this.FetchGet(
                this.requestUrl.trainList.trainStatus + this.currentBot
            ).then((res) => {
                if (res.code === "0") {
                    if (res.data && res.data.status === "TRAINING")
                        this.trainStatus = true;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        getCount () {
            this.FetchGet(
                this.requestUrl.trainList.noteCount + this.currentBot
            ).then((res) => {
                if (res.code === "0") {
                    this.tagCount = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeNoteBot (val) {
            this.noteCurrentBot = val;
            this.$refs.myNote.getTrainList({
                botId: this.noteCurrentBot,
                beginTrainTime: this.newStartTime,
                endTrainTime: this.newEndTime,
            });
            this.tagCount = 0;
            this.trainStatus = true;
            this.getTrainStatus();
            this.getCount();
            this.getSummaryTrainingRecords();
        },
        changeBot (val) {
            this.$refs.myQuestion.currentPage = 1;
            this.$refs.myQuestion.selectAll(false); // 切换机器人时清除选中态
            this.changeStatus();
            this.botId = val;
            // this.deleteAllAdv(); // 切换机器人时重复调用接口
            this.botList.forEach((item, index) => {
                if (item.id === val) {
                    this.currentBotInfo = item;
                }
            })
        },
        changeStatus () {
            this.tagCount = 0;
            this.trainStatus = true;
            this.getTrainStatus();
            this.getCount();
        },
        emptySearch () {
            if (this.activeTab === "question") {
                if (this.keyword === "") this.$refs.myQuestion.getQuestion();
            }
            if (this.activeTab === "feedbackNote") {
                if (this.keyword === "")
                    this.$refs.myFeedbackNote.getFeedbackNote();
            }
            if (this.activeTab === "orderToKnowledgeBase") {
                this.$refs.orderToKnowledgeBase.keyword = this.keyword
            }
        },
    },
};
</script>
<style lang="less">
@import "./../../assets/less/train/list.less";

.train-list-content {
    .tl-el-tab>.el-tabs__content {
        background: white;
        overflow: hidden;
    }
}

.acv-search-drawer {
    // height: 400px !important;
    // width: 700px !important;
    top: 80px !important;
    left: unset !important;
    right: 150px !important;
    width: 1015px !important;
    height: 465px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;
    z-index: 2100;
}
</style>



