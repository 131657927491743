<template>
    <div class="order_to_knowledge_base">
        <div class="order_guoran_index_tab">
            <div class="left" v-if="checkedIds.length == 0">
                <div @click="throttle(changeQwType('mark'),)"
                    :class="[qwType == 'mark' ? 'gruoran-index-tab-cell-active' : '', 'gruoran-index-tab-cell']">
                    <span>{{$t('trainList.toBeAnnotated')}}</span>
                    <div class="num">{{ noMarkedNum }}</div>
                </div>
                <div @click="changeQwType('ignore')"
                    :class="[qwType == 'ignore' ? 'gruoran-index-tab-cell-active' : '', 'gruoran-index-tab-cell']">
                    <span>{{$t('trainList.ignored')}}</span>
                    <div class="num">{{ ignoreNum }}</div>
                </div>
            </div>
            <div class="left" v-else>
                <el-checkbox class="checkbox" v-model="checkAll" @change="selectAll">{{
                    checkAll === true ? $t('trainList.questionList.cancelSelect') : $t('trainList.questionList.checkAll')
                }}</el-checkbox>
            </div>
            <div class="right" v-if="checkedIds.length == 0">
                <div class="records_btn" @click="openRecords">
                    <i class="iconfont guoran-tongyichicun-xunlianjilu"></i>
                    {{$t('trainList.trainingRecords')}}
                </div>
                <div class="again_training" @click="train">
                    <i class="iconfont guoran-tongyichicun-16-12-shuaxin"></i>
                  {{$t('trainList.retraining')}}
                </div>
            </div>
            <div class="right" v-else>
                <span class="current-count">{{$t('trainList.questionList.selected')}}<i>{{ checkedIds.length }}</i>{{$t('trainList.questionList.strip')}}</span>
                <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.label')" placement="top" :open-delay="300">
                    <i class="tagging iconfont guoran-tongyichicun-biaozhu" @click="batchIntent"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.ignore')" placement="top" :open-delay="300">
                    <i class="tagging iconfont guoran-tongyichicun-hulve" @click="batchIgnore"></i>
                </el-tooltip>
            </div>
            <recordsTable v-if="recordShow" @openRecordsDetail="openRecordsDetail" @close="recordShow = false">
            </recordsTable>
        </div>
        <div class="order_list">
            <el-table class="table" v-loading="loading" :data="tableData" ref="table" @cell-mouse-enter="cellEnter"
                @cell-mouse-leave="cellLeave" :row-class-name="tableRowClassName" row-key="id">
                <el-table-column :label="$t('trainList.orderQuestion')" align="left" class-name="table_desc">
                    <template slot-scope="scope">
                        <el-checkbox v-show="scope.row.isActive || checkedIds.length" v-model="scope.row.checked"
                            @change="selectWorkOrder($event, scope.row)" />
                        <div v-if="scope.row.workOrderDesc && scope.row.workOrderDesc.search('<img') !== -1" class="has-img"
                            @click.stop="openShowImage(scope.row.workOrderDesc)">
                            <el-popover placement="top-start" width="200" trigger="hover" :ref="scope.row.id"
                                @after-enter="onPopoverShow(scope.row)">
                                <div @click.stop="openShowImage(scope.row.workOrderDesc)">
                                    <img :src="scope.row.loadImg" style="width: 200px;height: 200px;object-fit: contain">
                                </div>
                                <i slot="reference" class="iconfont guoran-a-18_huaban1fuben5"></i>
                            </el-popover>
                        </div>
                        <div v-if="scope.row.workOrderDesc">
                            <el-tooltip :content="scope.row.workOrderDesc | filterDesTip" placement="top-start"
                                effect="light">
                                <div v-if="compuDesTipLength(scope.row.workOrderDesc) >= 30" id="description"
                                    v-html="replaceImgDes(scope.row.workOrderDesc)" :disabled="false"></div>
                            </el-tooltip>
                            <div v-if="compuDesTipLength(scope.row.workOrderDesc) < 30" id="description"
                                v-html="replaceImgDes(scope.row.workOrderDesc)" :disabled="false"></div>
                        </div>
                        <div v-if="!scope.row.workOrderDesc">--</div>

                        <el-popover popper-class="table_prpper" placement="right" :visible-arrow="false" trigger="manual"
                            v-model="scope.row.isActive">
                            <el-button type="text" @click="openDetail(scope.row)" class="view-info">{{$t('trainList.viewDetail')}}</el-button>
                        </el-popover>
                    </template>


                </el-table-column>
                <el-table-column label="工单创建时间" align="left" width="170" class-name="table_updateTime">
                    <template slot="header" slot-scope="scope">
                        <div class="createTime_header" @click="sortChange">
                            <span>{{$t('trainList.orderCreateTime')}}</span>
                            <section>
                                <i class="el-icon-caret-top" :class="page.sort == 'ASC' && 'active'"></i>
                                <i class="el-icon-caret-bottom" :class="page.sort == 'DESC' && 'active'"></i>
                            </section>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <i class="guoran-a-18-15 iconfont"></i>
                        <span>{{ new Date(scope.row.workOrderCreateTime).Format("yyyy-MM-dd hh:mm") }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('trainList.operator')" align="left" width="150" class-name="operator_view">
                    <template slot-scope="scope">
                        <img v-if="scope.row.operatorPhoto && scope.row.operatorPhoto !== ''" :src="scope.row.operatorPhoto"
                            alt="" class="user-icon">
                        <div class="name-icon" v-else-if="scope.row.operatorName && scope.row.operatorName !== ''">
                            {{ scope.row.operatorName.slice(0, 1) }}</div>
                        <span class="operator_name">{{ scope.row.operatorName }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTimeStr" :label="$t('trainList.operationTime')" align="left" width="170" class-name="table_updateTime">
                    <template slot-scope="scope">
                        <i class="guoran-a-18-15 iconfont" v-if="scope.row.updateTime && scope.row.updateTime !== ''"></i>
                        <span v-if="scope.row.updateTime && scope.row.updateTime !== ''">{{ new Date(scope.row.updateTime).Format("yyyy-MM-dd hh:mm") }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="" :label="$t('trainList.operator')" align="left" width="120" class-name="order_operation">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" v-if="scope.row.tagIntentId == null" :content="$t('trainList.questionList.label')"
                            placement="top" :key="scope.row.id" :open-delay="300">
                            <el-button type="text" @click="openMark(scope.row)"
                                icon="iconfont guoran-tongyichicun-biaozhu 1">
                            </el-button>
                        </el-tooltip>
                        <template v-if="scope.row.tagIntentId">
                            <el-button type="text" v-if="!scope.row.isActive">
                                {{$t('trainList.marked')}}
                            </el-button>
                            <el-tooltip class="item" effect="dark" v-if="scope.row.isActive"
                                :content="$t('trainList.marked') + '：' + scope.row.tagIntentFullPath + '/' + scope.row.tagIntentName"
                                placement="top" :key="scope.row.id" :open-delay="300">
                                <el-button type="text" @click="openMark(scope.row)">
                                  {{$t('trainList.questionList.remark')}}
                                </el-button>
                            </el-tooltip>
                        </template>
                        <el-tooltip class="item" effect="dark" v-if="!scope.row.isNeglect" :content="$t('trainList.questionList.ignore')" placement="top"
                            :open-delay="300" :key="scope.row.id">
                            <el-popconfirm popper-class="popconfirm_orderIngore" :cancel-button-text='$t("common.cancel")'
                                cancel-button-type="round" :confirm-button-text='$t("common.confirm")' confirm-button-type="Primary"
                                icon="guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe iconfont"
                                icon-color="#FE5965" :title="$t('trainList.questionList.ignoreTip')" @onConfirm="ignore(scope.row, true)">
                                <i slot="reference" class="iconfont guoran-tongyichicun-hulve"></i>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :pageSize="page.pageSize" :currentPage="page.currentPage" :total="page.total"
                @currentChange="pageChange">
            </pagination>
        </div>
        <viewPicture :previewIndex="0" :previewImageUrl="previewImageUrl" @closeView="showPreview = false"
            v-if="showPreview">
        </viewPicture>
        <el-drawer size="500px" direction="rtl" :visible.sync="replyDrawer" :destroy-on-close="true" :with-header="false"
            :modal="false">
            <div class="reply_content_o">
                <div class="reply_head">
                    <section>
                        <span class="icon">
                            <i class="iconfont guoran-tongyichicun-write-26-xiangqing"></i>
                        </span>
                        <span>{{$t('trainList.viewDetail')}}</span>
                    </section>
                    <i @click="replyDrawer = false" class="iconfont guoran-tongyichicun-guanbi1"></i>
                </div>
                <div class="reply_cont" v-if="replyDrawer">
                    <workOrderDescContent :orderId="orderId"></workOrderDescContent>
                </div>
            </div>
        </el-drawer>
        <el-drawer size="1175px" direction="rtl" :destroy-on-close="true" :visible.sync="detailDrawer" :modal="false"
            :with-header="false">
            <div class="drawer-content-o" style="height: 100%">
                <workOrderTrainDetail v-if="detailDrawer" ref="workOrderTrainDetail" @closeDrawer="detailDrawer = false"
                    @trainRemove="changeSuccess" @openDetail="openDetail"></workOrderTrainDetail>
            </div>
        </el-drawer>
        <workOrderAttentionDiagram ref="attentionDiagram" v-if="attentionDiagramShow" :orderId="orderId" :checkedIds="checkedIds"
            @changeSuccess="changeSuccess" @closeEvent="attentionDiagramShow = false" @addIntention="addIntention">
        </workOrderAttentionDiagram>
        <workOrderAddIntent ref="workOrderAddIntent" @changeSuccess="changeSuccess" v-if="intentShow" :checkedIds="checkedIds"
            :orderId="orderId" @closeEvent="intentShow = false"></workOrderAddIntent>

    </div>
</template>
<script>
import imageView from '../../components/imageView.vue'
import pagination from "../../components/Pagination.vue";
import workOrderTrainDetail from './component/workOrderTrainDetail.vue';
import workOrderAttentionDiagram from './component/workOrderAttentionDiagram.vue';
import workOrderAddIntent from './component/workOrderAddIntent.vue';
import recordsTable from './component/recordsTable.vue';
import workOrderDescContent from './component/workOrderDescContent.vue';
import viewPicture from '../../components/viewPicture.vue'
export default {
    components: {
        imageView,
        pagination,
        workOrderTrainDetail,
        workOrderAttentionDiagram,
        workOrderAddIntent,
        recordsTable,
        workOrderDescContent,
        viewPicture
    },
    data () {
        return {
            activeBot: '',
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0,
                sort: 'DESC' //ASC
            },
            qwType: 'mark',
            noMarkedNum: 0,
            ignoreNum: 0,
            loading: false,
            tableData: [],
            detailDrawer: false,
            replyDrawer: false,
            recordShow: false,
            previewImageUrl: '',
            showPreview: false,
            orderId: "",
            checkAll: false,
            checkedIds: [],
            keyword: '',
            timer: null,
            trainLoading: false,
            intentShow:false,
            attentionDiagramShow:false
        }
    },
    filters: {
        filterDesTip (str) {
            return str.replace(/<img[^>]*>/gm, '').replace(/<figure(?:(?!<\/figure>).|\n)*?<\/figure>/gm, '').replace(/<p>/gm, '').replace(/<\/p>/gm, '').replace(/&nbsp;/gm, '').replace(/<span[^>]*\s+class="[^"]*\ask-component-img-box\b[^>]*">.*<\/span>/g, '【图片】').replace('<span class="ask-component-placeholder-container"></span>', '').replace(/<br[^>]*>/gm, '');
        }
    },
    watch: {
        keyword (value) {
            if (value === '') {
                this.getOrderToKnowledgeBaseDate()
            }
        }
    },
    mounted () {
        this.getOrderToKnowledgeBaseDate()
    },
    methods: {
        changeSuccess () {
            this.checkedIds = []
            this.getOrderToKnowledgeBaseDate()
        },
        openShowImage (description) {
            let desc = JSON.parse(JSON.stringify(description))
            if (desc) {
                let imgReg = /<img.*?(?:>|\/>)/gi
                let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
                let arr = desc.match(imgReg)
                let srcArr = []
                if (arr) {
                    for (let i = 0; i < arr.length; i++) {
                        let src = arr[i].match(srcReg)
                        if (src && src[1] && src[1].indexOf('http') !== -1) {
                            srcArr.push(src[1])
                        }
                    }
                }
                this.previewImageUrl = srcArr
                this.showPreview = true
                return srcArr
            }
        },
        onPopoverShow (item, index) {
            let refId = index ? item.id + index : item.id
            this.$nextTick(() => {
                this.$set(item, 'loadImg', this.getSrc(item.workOrderDesc))
                this.$refs[refId].updatePopper()
                this.$forceUpdate()
            })
        },
        getSrc (description, e) {
            let desc = JSON.parse(JSON.stringify(description))
            if (desc) {
                let imgReg = /<img.*?(?:>|\/>)/gi
                let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
                let arr = desc.match(imgReg)
                let srcArr = []
                if (arr) {
                    for (let i = 0; i < arr.length; i++) {
                        let src = arr[i].match(srcReg)
                        if (!src) {
                            src = arr[i].match(span_srcReg)
                        }
                        if (src && src[1] && src[1].indexOf('http') !== -1) {
                            srcArr.push(src[1])
                        }
                    }
                }
                return srcArr[0]
            }
        },
        compuDesTipLength (str) {
            return str.replace(/<img[^>]*>/gm, '').replace(/<figure(?:(?!<\/figure>).|\n)*?<\/figure>/gm, '').replace(/<p>/gm, '').replace(/<\/p>/gm, '').replace(/&nbsp;/gm, '').length;
        },
        replaceImgDes (des) {
            let imgReg = /<img.*?(?:>|\/>)/gi
            return des.replace(imgReg, '')
        },
        throttle: function (func, delay) {
            let timer = null;

            return function (...args) {
                if (!timer) {
                    func.apply(this, args);
                    timer = setTimeout(() => {
                        timer = null;
                    }, delay);
                }
            };
        },
        changeQwType (value) {
            this.qwType = value;
            this.$nextTick(() => {
                this.getOrderToKnowledgeBaseDate()
            })
        },
        cellEnter (row) {
            this.tableData.forEach((v) => {
                this.$set(v, 'isActive', false)
                if (this.checkedIds.length === 0 && row.id == v.id) {
                    this.$set(v, 'isActive', true)
                }
            });
        },
        cellLeave () {
            this.tableData.forEach((v) => {
                this.$set(v, 'isActive', false)
            });
        },
        selectWorkOrder (value, row) {
            if (value) {
                if (!this.checkedIds.includes(row.id)) {
                    this.checkedIds.push(row.id)
                }
            } else {
                this.checkedIds = this.checkedIds.filter(id => { return id != row.id })
            }
            let count = 0
            this.tableData.forEach(item => {
                if (item.checked) count++
            })
            if (count == this.tableData.length) {
                this.checkAll = true
            } else {
                this.checkAll = false
            }
            if (this.checkedIds.length == 0) {
                this.tableData.forEach((v) => {
                    v.isActive = false;
                });
            }
        },
        selectAll (value) {
            if (value) {
                this.tableData.forEach(item => {
                    item.checked = value
                    if (!this.checkedIds.includes(item.id)) {
                        this.checkedIds.push(item.id)
                    }
                })
            } else {
                this.checkedIds = []
            }
        },
        tableRowClassName () {

        },
        pageChange (value) {
            this.page.currentPage = value
            this.getOrderToKnowledgeBaseDate()
        },
        getOrderToKnowledgeCount () {
            this.$http.get("/api/intent/workOrderToKnowledge/list/total").then(res => {
                if (res.data.code == 0) {
                    this.noMarkedNum = res.data.data.todoWorkOrderTotal
                    this.ignoreNum = res.data.data.ignoreWorkOrderTotal
                }
            })
        },
        getOrderToKnowledgeBaseDate () {
            let url = `/api/intent/workOrderToKnowledge/search?pageSize=${this.page.pageSize}&pageNumber=${this.page.currentPage}&workOrderSort=${this.page.sort}&keyword=${this.keyword}&isNeglect=${this.qwType == 'mark' ? 'false' : 'true'}`
            // 查询工单转知识接口
            this.$http.get(url).then(res => {
                if (res.data.code == 0) {
                    let count = 0
                    this.tableData = res.data.data.list.map(item => {
                        this.$set(item, 'checked', this.checkedIds.includes(item.id))
                        if (item.checked) {
                            count++
                        }
                        return item
                    })
                    if (count == res.data.data.list.length) {
                        this.checkAll = true
                    }
                    this.page.total = res.data.data.total
                    this.getOrderToKnowledgeCount()
                    this.$refs.table.doLayout()
                }
            })
        },
        // 忽略待标注 单条
        ignore (row) {
            this.$http.put("/api/intent/workOrderToKnowledge/ignore", [row.id], {
                'Content-Type': 'application/json',
            }).then(res => {
                if (res.data.code == '0') {
                    this.$message.success("该工单已移动至忽略列表")
                    this.getOrderToKnowledgeBaseDate()
                }
            })
        },
        openDetail (row) {
            this.orderId = row.workOrderId
            this.replyDrawer = true
        },
        sortChange () {
            if (this.page.sort == 'ASC') {
                this.page.sort = 'DESC'
            } else {
                this.page.sort = 'ASC'
            }
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                this.getOrderToKnowledgeBaseDate()
            }, 500);
        },
        throttleSort () {

        },
        openMark (row) {
            this.attentionDiagramShow = true
            this.$nextTick(() =>{
                this.$refs.attentionDiagram.workOrderData = row
            })
        },
        addIntention (workOrderData) {
            this.attentionDiagramShow = false
            this.intentShow = true
            this.$nextTick(() => {
                this.$refs.workOrderAddIntent.workOrderData = workOrderData
            })
        },
        openRecords () {
            this.recordShow = true
        },
        batchIntent () {
            this.attentionDiagramShow = true
        },
        batchIgnore () {
            // 批量忽略
            this.$http.put("/api/intent/workOrderToKnowledge/ignore", this.checkedIds, {
                'Content-Type': 'application/json',
            }).then(res => {
                if (res.data.code == '0') {
                    this.$message.success("该工单已移动至忽略列表")
                    this.getOrderToKnowledgeBaseDate()
                }
            })
        },
        // 重新训练
        train () {
            this.$emit("changeLoding", true)
            this.$http.post("/api/intent/workOrderToKnowledge/train").then(res => {
                this.$emit("changeLoding", false)
                if (res.data.code == "0") {
                    this.$message.success("训练成功！")
                    this.getOrderToKnowledgeBaseDate()
                } else if (res.data.code == "500") {
                    this.$message.warning("请先标注，再训练！")
                }
            })
        },
        openRecordsDetail (data) {
            this.detailDrawer = true
            this.$nextTick(() => {
                this.$refs.workOrderTrainDetail.trainVersionData = data
                this.$refs.workOrderTrainDetail.getTrainByWorkOrderList()
            })
        }
    }
}
</script>
<style lang="less" scoped>
.popconfirm_orderIngore {
    .el-popconfirm__action {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .el-button {
            width: 49px;
            height: 28px;
            font-size: 12px;
            border-radius: 14px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &.el-button--round {
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                color: #616161;
            }

            &.el-button--Primary {
                background: #366AFF;
                color: #ffff;
                border: none;
            }
        }
    }
}

.order_to_knowledge_base {
    width: 100%;
    height: 100%;

    .order_guoran_index_tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        // position: absolute;
        // top: 98px;
        z-index: 9;
        height: 50px;
        width: calc(100% - 16px);
        padding-right: 10px;
        position: relative;

        &.have-search {
            // top: 51px; 
        }

        .left {
            display: flex;
            height: 50px;
            align-items: center;

            >div:first-child {
                margin-right: 30px;

                .el-badge>span {
                    margin-left: 16px;
                }
            }

            .checkbox {
                margin-left: 20px;
                margin-right: 10px;
            }

            .gruoran-index-tab-cell {
                display: flex;
                cursor: pointer;
                margin-right: 20px;
                border-bottom: 3px #fff solid;
                height: 47px;
                align-items: center;

                span {
                    margin-left: 14px;
                    color: #A3ADC6;
                    white-space: nowrap;
                }

                .num {
                    margin-left: 6px;
                    height: 17px;
                    color: #A3ADC6;
                    border-radius: 8px;
                    font-size: 12px;
                    color: #A3ADC6;
                    padding: 0 4px;
                    background-color: #fff;
                    border: 1px solid #C7D7FF;
                    white-space: nowrap;
                    min-width: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.hover {
                    color: #366AFF;
                }
            }

            .gruoran-index-tab-cell-active {
                border-bottom-color: #366AFF;

                .num {
                    background: #749BFF;
                    color: #fff;
                    border: 1px solid #749BFF;
                }

                span {
                    color: #366AFF;
                }
            }
        }

        /deep/.left {
            .el-checkbox {
                .el-checkbox__inner {
                    width: 16px;
                    height: 16px;
                    border: 2px solid #EBEDF4;
                    border-radius: 2px;

                    &::after {
                        top: 0px;
                        border-width: 2px;
                    }

                }

                .is-checked .el-checkbox__inner {
                    border: none;
                    border: 2px solid #366AFF;
                }
            }

        }

        .right {
            display: flex;
            height: 50px;
            align-items: center;

            .current-count {
                flex: 1;
                text-align: right;

                i {
                    padding: 0 5px;
                    color: #366AFF;
                    font-style: normal;
                }
            }

            >div {
                padding: 0 10px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                margin: 0 5px;
                cursor: pointer;

                i {
                    font-size: 14px;
                    margin-right: 3px;
                }
            }

            .records_btn {
                color: #366AFF;
                border: 1px solid #A1B9FF;
                background: #FFFFFF;
            }

            .again_training {
                background: #366AFF;
                color: #FFFFFF;
                border: 1px solid #366AFF;
            }

            .tagging {
                color: #616161;
                width: 32px;
                height: 31px;
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-radius: 5px;
                text-align: center;
                line-height: 31px;
                cursor: pointer;
                font-size: 16px;
                margin: 0 8px;
            }
        }
    }
}

.order_list {
    background: #ffffff;
    overflow: auto;

    #pagination {
        bottom: -3px;
        height: 64px;
        z-index: 10 !important;
        border-radius: 0 !important;
    }

    .table {
        height: calc(100vh - 230px);
    }

    /deep/.el-table {
        &::before {
            z-index: inherit;
        }

        .el-table__fixed,
        .el-table__fixed-right {
            bottom: 0px;
            height: 100%;

            &::before {
                height: 0;
            }
        }

        .el-table__fixed-right-patch {
            background-color: #F6F8FD;
        }

        .el-table__header-wrapper {
            height: 50px;
            line-height: 50px;
            background-color: #F6F8FD;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;

            .el-table__header,
            tr,
            .has-gutter,
            th {
                background-color: #F6F8FD !important;
                background: #F6F8FD;
            }


        }

        .el-table__header {
            .first-filter-header-cell {
                display: flex;
                align-items: center;

                .el-select {
                    width: 140px;
                    margin-right: 20px;

                    .el-input {
                        width: 140px;

                        .el-input__inner {
                            height: 32px;
                            width: 140px;
                        }

                        .el-input__suffix {
                            top: -2px;
                        }
                    }
                }

                .same-question-sort-tips {
                    color: #000;
                }

                .evaluate-table-switch {
                    display: flex;
                    flex-direction: row;
                    height: 20px;
                    justify-content: space-between;
                    cursor: pointer;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    padding: 0;

                    .el-switch {
                        text-align: center;
                        display: flex;
                    }

                    .el-switch__core {
                        height: 20px;
                        border-radius: 12px;
                    }

                    .switch-open-icon {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 2px;

                        i {
                            color: white;
                            font-size: 16px;
                        }
                    }

                    .switch-close-icon {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 2px;

                        i {
                            color: #6E6B7B;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    i {
                        color: #366AFF;
                        font-size: 18px;
                    }
                }
            }
        }

        .el-table__body-wrapper {
            height: calc(100% - 50px);
            overflow-y: auto;

            // 滚动条样式
            &::-webkit-scrollbar {
                height: 6px;
                width: 6px;
                background-color: rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px !important;
                background-color: #BFCEEC;
            }


        }

        th .cell {
            height: 50px;
            line-height: 50px;
        }

        .el-switch {
            display: inline;
            line-height: unset;
            height: 24px;
            padding: 0;
        }

        .el-table__header {
            th {
                padding: 0;
                height: 50px;
                line-height: 50px;
                background-color: #F6F8FD;

                div {
                    line-height: 50px;
                }

            }
        }

        /deep/.el-table__body {
            .el-table__body-wrapper {
                height: calc(100% - 50px);
                overflow-y: auto;
            }
        }

        .el-table__body {


            .table-fixed {

                /deep/.el-table__fixed,
                .el-table__fixed-right {
                    bottom: 0px;
                    height: 100%;

                    &::before {
                        height: 0;
                    }
                }

                /deep/.el-table__fixed-right-patch {
                    background-color: #F6F8FD;
                }
            }

            .normal-row,
            .image-row {
                .cell {
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    // padding: 0;
                    height: 60px;
                    line-height: 60px;

                    .guoran-a-18-15 {
                        margin-right: 6px;
                        color: #D2D8E3;
                        margin-top: 2px;
                        font-size: 17px;
                    }
                }


            }

            .image-row {
                .cell {
                    height: 110px;
                    line-height: 110px;
                }
            }

            .show-source-other-bot {
                .cell {
                    height: 120px;
                    line-height: 100px;
                }
            }

            .intent-name-td {
                .cell {
                    display: block;
                }
            }

            tr td {
                padding: 0;
                height: 60px;
                cursor: pointer;
            }

            .user-icon {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 6px;
            }

            .name-icon {
                width: 26px;
                height: 26px;
                background: #366AFF;
                border-radius: 50%;
                margin-right: 6px;
                text-align: center;
                line-height: 26px;
                color: #fff;
                display: inline-block;
            }

            .el-button {
                font-weight: 400;
            }

            .selection-column-box {
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                align-items: center;

                .el-checkbox {
                    margin-right: 10px;

                    .el-checkbox__inner {
                        width: 16px;
                        height: 16px;
                        border: 2px solid #EBEDF4;
                        border-radius: 2px;

                        &::after {
                            top: 0px;
                            border-width: 2px;
                        }

                    }

                    .is-checked .el-checkbox__inner {
                        border: none;
                        border: 2px solid #366AFF;
                    }
                }

                .selection-column {
                    display: flex;

                    .one-column-ellipsis {
                        line-height: normal;
                        margin-right: 6px;
                    }

                    &.image-cell {
                        display: flex;
                    }

                    .el-image {
                        width: 80px;
                        height: 80px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-left: 4px;
                    }

                    .is-new-move-intent {
                        display: inline-block;
                        width: 7px;
                        height: 7px;
                        background: #FE5965;
                        border-radius: 50%;
                        margin-right: 2px;
                    }

                    .image-row-cell {
                        display: flex;
                        align-items: center;
                    }
                }

                .source-other-bot {
                    width: 120px;
                    height: 18px;
                    background: #799CFF;
                    border-radius: 3px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 18px;
                    text-align: center;
                    margin-top: 5px;
                    padding-bottom: 10px;

                    i {
                        color: #B2C6FF;
                    }
                }
            }

            .order_operation {
                .cell {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    color: #366AFF;
                    // justify-content: flex-start;

                    .iconfont {
                        font-size: 16px;
                        margin-right: 14px;
                    }

                    .guoran-tongyichicun-kaiqi1 {
                        font-size: 18px;
                    }

                    .guoran-tongyichicun-guanbi1 {
                        font-size: 13px;
                    }

                    .guoran-tongyichicun-hulve {
                        font-size: 17px;
                        color: #C9D0E5;
                    }

                    .intent-name {
                        line-height: 20px;
                        margin-right: 4px;
                        text-align: left;
                        // display: inline-block;
                        width: 68px;
                    }
                }
            }

            .q-first-c {
                display: flex;
                flex-wrap: wrap;
            }

            .labels {
                height: 18px;
                line-height: 18px;
                background: #EFF2FF;
                border: 1px solid #DBE4FF;
                border-radius: 2px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                margin-bottom: 6px;
                text-align: center;
                margin-right: 6px;

                &.worker {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    width: 55px;
                }

                &.artificial {
                    background-color: #E4E5FF;
                    border-color: #E4E5FF;
                    color: #848AFF;
                    width: 55px;
                }

                &.no-use {
                    background-color: #FFDFE1;
                    border-color: #FFDFE1;
                    color: #FE5965;
                    width: 75px;
                }

                &.use {
                    background-color: #D4F1F0;
                    border-color: #D4F1F0;
                    ;
                    color: #00C2BB;
                    width: 75px;
                }

                &.long-info {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    min-width: 145px;
                    padding: 0 8px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .two-column {
                display: flex;
                align-items: flex-end;

                .left,
                .right {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .left {
                    margin-right: 10px;
                }

                &.start {
                    align-items: flex-start;
                }
            }

            &.max-h {
                height: 100px;
                line-height: 100px;
            }
        }

        .el-select,
        .el-input {
            padding-left: 0;
        }

        .reset-tag,
        .view-info {
            padding: 0;
        }

        .reset-tag {
            margin-right: 14px;
        }

        .correct,
        .intent-name {
            color: #366AFF;
        }

        .correct {
            margin-right: 14px;
        }

        .open-sort {
            // float: left;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            padding-left: 0;

            // .el-switch {
            //     margin-top: -10px;
            //     display: inline-block;
            //     line-height: unset;
            //     height: 30px;
            // }
            p {
                height: 20px;
                line-height: 20px;
            }

            .el-switch__label {
                line-height: 20px;
            }

            margin-left: 10px;

            .el-switch {
                height: 24px;

                .el-switch__core {
                    height: 24px;
                    border-radius: 12px;
                }

                .el-switch__core:after {
                    width: 14px;
                    height: 14px;
                    left: 5px;
                    top: 3px;
                }

                .el-switch__label--left {
                    i {
                        color: #6e6b7b;
                        font-weight: 800;
                        font-size: 14px;
                    }

                    position: absolute;
                    left: 15px;
                    top: 1px;
                    z-index: 2;

                }

                .el-icon-check {
                    color: #F6F8FD;
                    font-weight: 800;
                    position: absolute;
                }

                &.is-checked {
                    .el-switch__core:after {
                        left: 40px;
                        top: 3px;
                    }

                    .el-icon-check {
                        position: absolute;
                        left: 5px;
                        top: 3px;
                        z-index: 2;
                        font-size: 14px;
                        color: #fff;
                        font-weight: 800;
                    }

                    .el-icon-close {
                        color: #fff;
                    }
                }

            }
        }
    }

    .guoran-tongyichicun-kaiqi1 {
        color: #366AFF;
        cursor: pointer;
    }

    .el-icon-close:not(.l-dialog__close) {
        margin-left: 10px;
        color: #366AFF;
        cursor: pointer;
    }

    .guoran-tongyichicun-guanbi1 {
        font-size: 12px;
    }

    .el-checkbox {
        padding: 0px !important;
    }

    &.show-search {
        #q-table {
            margin-top: 110px;
        }
    }
}

.createTime_header {
    display: flex;
    align-items: center;
    cursor: pointer;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        i {
            margin: -3px 0;
        }

        .active {
            color: #366AFF;
        }
    }
}

/deep/.table_updateTime {
    .cell {
        width: 100%;
        height: 36px;
        display: flex !important;
        align-items: center;

        .iconfont {
            color: #D2D8E3;
            margin-right: 5px;
        }
    }
}

/deep/.operator_view {
    .cell {
        width: 100%;
        height: 36px;
        display: flex !important;
        align-items: center;

        .operator_name {
            width: calc(100% - 32px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}



/deep/.table_desc {

    .cell {
        display: flex;
        align-items: center;
        padding-left: 20px;

        .table_prpper {
            width: 85px;
            min-width: 85px;
            height: 35px;
            background: #FFFFFF;
            box-shadow: 0px 0px 9px 0px rgba(57, 63, 79, 0.21);
            border-radius: 5px;
            padding: 0;
            text-align: center;
            line-height: 35px;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: 400;
                color: #366AFF;
                font-size: 14px;
                font-family: Microsoft YaHei;
            }
        }

        .el-checkbox {
            margin-right: 10px;

            .el-checkbox__inner {
                width: 16px;
                height: 16px;
                border: 2px solid #EBEDF4;
                border-radius: 2px;

                &::after {
                    top: 0px;
                    border-width: 2px;
                }

            }

            .is-checked .el-checkbox__inner {
                border: none;
                border: 2px solid #366AFF;
            }
        }

        .has-img {
            display: flex;
            align-items: center;

            /deep/.item-c {
                margin-top: 0;
                margin-right: 0;
            }

            i {
                font-size: 18px;
                color: #D2D8E3;
                margin-right: 6px;
                display: flex;
            }
        }

        #description {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-height: 20px;
            display: flex;

            /deep/ h4 {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            /deep/p {
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                white-space: nowrap;
                line-height: 20px;
                max-width: 410px;
            }

            /deep/video {
                height: 0 !important;
            }

            /deep/img {
                display: none;
                pointer-events: none !important;
            }

            /deep/a {
                pointer-events: none !important;
            }
        }
    }

}

.training_records {
    position: absolute;
}

.reply_content_o {
    width: 100%;
    height: 100vh;
    background: #F5F7FB;

    .reply_head {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 12px;

        i {
            font-size: 12px;
        }

        section {
            display: flex;
            align-items: center;

            .icon {
                width: 22px;
                height: 22px;
                background: #366AFF;
                border-radius: 50%;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
            }

            span {
                font-size: 16px;
            }
        }

    }

    .reply_cont {
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
    }
}</style>
