<!--待标注问题-->
<template>
    <div class="question-list">
    <!-- <div :style="{ 'margin-top': topAdd + 'px' }" class="question-list"> -->
        <div class="batch-title" v-if="checkedIds.length">
            <el-checkbox v-model="checkAll" @change="selectAll">{{
                checkAll === true ? $t('trainList.questionList.cancelSelect') : $t('trainList.questionList.checkAll')
            }}</el-checkbox>
            <span class="current-count">{{$t('trainList.questionList.selected')}}<i>{{ checkedIds.length }}</i>{{$t('trainList.questionList.strip')}}</span>
            <template v-if="currentQ[0].isIdentify === true" >
                <el-tooltip  class="item" effect="dark" :content="$t('trainList.correctAnnotation')" placement="top" :open-delay="300">
                    <i class="iconfont guoran-tongyichicun-kaiqi1" @click="tagIntent(currentQ, true, 1)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="$t('trainList.annotationError')" placement="top" :open-delay="300">
                    <i class="iconfont guoran-tongyichicun-guanbi1" @click="tagIntent(currentQ, false, 1)"></i>
                </el-tooltip>
            </template>
           <template v-else>
                <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.label')" placement="top" :open-delay="300">
                    <i class="tagging iconfont guoran-tongyichicun-biaozhu" @click="tagIntent(currentQ, false, 1)"></i>
                </el-tooltip>
           </template>
           <template v-if="qwType == 'mark'">
                <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.ignore')" placement="top" :open-delay="300">
                    <i class="tagging iconfont guoran-tongyichicun-hulve" @click="ignoreBatch(currentQ)"></i>
                </el-tooltip>
           </template>
            <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.moveToOther')" placement="top" :open-delay="300">
                <i class="tagging iconfont guoran-tongyichicun-youjiantou" @click="moveToOtherBot({},'batch')"></i>
            </el-tooltip>
        </div>
        <el-table
            ref="questionListTable"
            id="q-table"
            v-loading="loading"
            :height="'calc(100vh - ' + (isShowSearch || checkedIds.length > 0 ? 280 : 230) + 'px)'"
            :data="tableData"
            @cell-mouse-enter="cellEnter"
            @cell-mouse-leave="cellLeave"
            :row-class-name="tableRowClassName"
            
        >
            <el-table-column
                min-width="460"
                style="background:#F6F8FD;"
                fixed="left"
                label=""
                prop="question"
            >
                <!-- :render-header="renderquestion" -->
                <template slot="header" slot-scope="scope">
                    <div class="first-filter-header-cell">
                        <el-select v-model="queryType" :placeholder="$t('common.selectPlaceholder')" @change="changeIntentType($event,scope)">
                            <el-option
                                v-for="item in filters"
                                :key="item.value"
                                :label="$t('trainList.questionList.' + item.value)"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        <span class="same-question-sort-tips">{{$t('trainList.questionList.sameQuestionSort')}}：</span>
                        <div class="evaluate-table-switch">
                            <el-switch
                                @click.native.prevent="changeSort('switch')"
                                :width="42"
                                v-model="sort"
                                active-color="#366AFF"
                                inactive-color="#E2E2E2">
                            </el-switch>
                            <span
                                class="switch-open-icon"
                                @click="changeSort('open')"
                                v-if="sort">
                                <i class="iconfont guoran-a-16-17"></i>
                            </span>
                            <span
                                class="switch-close-icon"
                                @click="changeSort('close')"
                                v-if="!sort">
                                <i class="arsenal_icon arsenalcuo1"></i>
                            </span>
                        </div>
                    </div>
                </template>
                <template slot-scope="scope">
                    <div class="selection-column-box">
                        <el-checkbox
                            v-show="scope.row.isActive || checkedIds.length"
                            v-model="scope.row.checked"
                            :disabled="Boolean(scope.row.disabled && checkedIds.length)"
                            @change="selectQuestion($event, scope.row)"
                        />
                        <div>
                            <div class="selection-column q-first-c">
                                <template v-if="scope.row.type !== 'IMAGE'">
                                    <el-tooltip
                                        v-if="scope.row.question.length > 20"
                                        effect="dark"
                                        :content="scope.row.question"
                                        placement="top-start">
                                        <div class="one-column-ellipsis">
                                            <span class="is-new-move-intent" v-if="scope.row.unfold == 'false'"></span>
                                            {{ scope.row.question }}
                                        </div>
                                    </el-tooltip>
                                    <div v-else class="one-column-ellipsis">
                                        <span class="is-new-move-intent" v-if="scope.row.unfold == 'false'"></span>
                                        {{scope.row.question}}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="image-row-cell">
                                            <span class="is-new-move-intent" v-if="scope.row.unfold == 'false'"></span>
                                            <el-image
                                                @click.native="viewImage(scope.row)"
                                                :src="JSON.parse(scope.row.question).urls[0]"
                                                :fit="'cover'">
                                            </el-image>
                                    </div>
                                </template>
                                <!-- 标签 -->
                                <template>
                                    <div class="labels worker" v-if="scope.row.label && scope.row.label.indexOf('WORK_ORDER') !== -1">{{$t('trainList.questionList.transferOrder')}}</div>
                                    <!-- <div class="labels artificial" v-if="scope.row.label && scope.row.label.indexOf('CUSTOMER') !== -1">转人工</div> -->
                                    <div class="labels use" v-if="!(Number(scope.row.uselessNum) != 0 && Number(scope.row.usefulNum) != 0) && Number(scope.row.usefulNum) != 0 && scope.row.label && scope.row.label.indexOf('FEEDBACK_IS_USEFUL') !== -1">{{$t('trainList.questionList.feedback')}}{{isMnHr? $t('trainList.questionList.resolved') : $t('trainList.useful')}}</div>
                                    <div class="labels no-use" v-if="!(Number(scope.row.uselessNum) != 0 && Number(scope.row.usefulNum) != 0) && Number(scope.row.uselessNum) != 0 && scope.row.label && scope.row.label.indexOf('FEEDBACK_IS_USELESS') !== -1">{{$t('trainList.questionList.feedback')}}{{isMnHr? $t('trainList.questionList.unresolved') : $t('trainList.useless') }}</div>
                                    <div class="labels no-use" v-if="scope.row.status == 'IDENTIFY_ERROR_TAGED' || scope.row.status == 'NOT_IDENTIFY_TAGED'">{{$t('trainList.annotationError')}}</div>
                                    <div class="labels use" v-if="scope.row.status == 'IDENTIFY_CORRECT_TAGED'">{{$t('trainList.correctAnnotation')}}</div>
                                    <div class="labels long-info" v-if="Number(scope.row.uselessNum) != 0 && Number(scope.row.usefulNum) != 0">{{isMnHr? $t('trainList.questionList.resolved') : $t('trainList.useful') }}{{Number(scope.row.usefulNum)}}；{{isMnHr? $t('trainList.questionList.unresolved') : $t('trainList.useless') }}{{Number(scope.row.uselessNum)}}；{{$t('trainList.questionList.noFeedback') }}{{(Number(scope.row.hits) - Number(scope.row.usefulNum) -Number(scope.row.uselessNum)) > 0 ?(Number(scope.row.hits) - Number(scope.row.usefulNum) -Number(scope.row.uselessNum)): 0}}</div>
                                </template>
                            </div>
                            <div class="source-other-bot" v-if="scope.row.lastBotName && scope.row.lastBotName !== '' && scope.row.moveUserName && scope.row.moveUserName !== '' " >
                              {{$t('trainList.questionList.derivedFromOtherBot')}}
                                <el-tooltip
                                    effect="dark"
                                    :content="'由' + scope.row.moveUserName + '从' + scope.row.lastBotName + '移动'"
                                    placement="top-start">
                                    <i class="el-icon-warning"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                show-overflow-tooltip
                :label="$t('trainList.questionList.intent')"
                min-width="240"
                align="left"
                prop="intentName"
                class-name="intent-name-td"
            >
                <template slot-scope="scope">
                    <!-- <span style="display: inline-block;width: 10px;"></span> -->
                    <span
                        v-if="
                            [4, 5].indexOf(scope.row.button) > -1 &&
                            (scope.row.unrecognizedAction == null ||
                                scope.row.unrecognizedAction == 0)
                        "
                        style="color: #FE5965"
                        >{{$t('trainList.questionList.unrecognized')}}</span
                    >
                    <span
                        v-if="
                            [4, 5].indexOf(scope.row.button) > -1 &&
                            scope.row.unrecognizedAction == 1
                        "
                        style="color: #f56c6c"
                        >{{$t('trainList.questionList.KeywordRecommend')}}</span
                    >
                    <span
                        v-if="
                            [4, 5].indexOf(scope.row.button) > -1 &&
                            scope.row.unrecognizedAction == 2
                        "
                        style="color: #FF7200"
                        >{{$t('trainList.questionList.noRecognizingRecommend')}}</span
                    >
                    <div style="display: flex;flex-direction: column;justify-content:center;height: 100%;"
                        v-if="!([4, 5].indexOf(scope.row.button) > -1 && (scope.row.unrecognizedAction == null || scope.row.unrecognizedAction == 0)) && 
                        !([4, 5].indexOf(scope.row.button) > -1 && scope.row.unrecognizedAction == 1) &&
                        !([4, 5].indexOf(scope.row.button) > -1 && scope.row.unrecognizedAction == 2)">
                        <span style="line-height: 22px;">{{ scope.row.intentName }}</span>
                        <div style="line-height: 16px;font-size: 12px;color: #909399;">{{scope.row.intentFullpath}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="time"
                :label="$t('trainList.lastTime')"
                align="left"
                min-width="200"
                :render-header="renderDate"
            >
            <template slot-scope="scope">
                <i class="guoran-a-18-15 iconfont"></i>
                <span>{{scope.row.time}}</span>
            </template>
            </el-table-column>
            <el-table-column
                prop="hits"
                :label="$t('trainList.questionList.hits')"
                align="left"
                width="110"
                :render-header="renderCount"
            >
                <template slot-scope="scope">
                    <el-popover
                        :width="$i18n.locale === 'en' ? '130' : '90'"
                        :popper-class="'bot-syudy-look-history'"
                        placement="right"
                        :visible-arrow="false"
                        trigger="manual"
                        v-model="scope.row.isActive">
                        <el-button type="text" @click="viewAnswer(scope.row)" class="view-info">{{$t('trainList.questionList.viewChatHistory')}}</el-button>
                        <!-- <span  style="cursor:pointer">{{ scope.row.hits }} </span>  -->
                    </el-popover>
                    <!-- <el-button type="text" @click="viewAnswer(scope.row)" v-if="scope.row.isActive === true" class="view-info"
                        >查看聊天记录</el-button
                    >
                    <span v-else>{{ scope.row.hits }} </span> -->
                    <span>{{ scope.row.hits }}</span>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('trainList.questionList.operator')"
                align="left"
                width="150"
            >
                <template slot-scope="scope">
                    <img :src="scope.row.annotatorNameIcon" alt="" class="user-icon" v-if="scope.row.annotatorName !== '' && scope.row.annotatorNameIcon !== ''">
                    <div class="name-icon" v-if="scope.row.annotatorName !== '' && scope.row.annotatorNameIcon === ''">{{scope.row.annotatorName.slice(0,1)}}</div>
                    <span>{{ scope.row.annotatorName }} </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="updateTimeStr"
                :label="$t('trainList.questionList.operationTime')"
                align="left"
                width="170"
            >
                <template slot-scope="scope">
                    <i class="guoran-a-18-15 iconfont" v-if="scope.row.updateTimeStr && scope.row.updateTimeStr !== ''"></i>
                    <span>{{ scope.row.updateTimeStr }} </span>
                </template>
            </el-table-column>
            <el-table-column prop="" :label="$t('trainList.operation')" align="center" :min-width="$i18n.locale === 'en' ? '200' : '170'" fixed="right" class-name="question-operation">
                <template slot-scope="scope">
                    <!-- <span style="display: inline-block;width: 30px;"></span> -->
                    <template  v-if="[5].indexOf(scope.row.button) > -1 && RP_Visible('OPEN_ML_MARK')">
                        <el-tooltip  class="item" effect="dark" :content="$t('trainList.questionList.label')" placement="top" :key="scope.row.id" :open-delay="300">
                            <el-button
                                type="text"
                                :disabled="Boolean(checkedIds.length)"
                                @click="tagIntent(scope.row, false, 0)"
                                icon="iconfont guoran-tongyichicun-biaozhu 1">
                            </el-button>
                        </el-tooltip>
                    </template>
                    
                    <template v-if="[1].indexOf(scope.row.button) > -1">
                        <el-tooltip
                            :content="$t('trainList.questionList.identifyCorrectly')"
                            placement="top"
                            effect="dark"
                            :key="scope.row.id"
                            :open-delay="300"
                        >
                            <i
                                class="el-icon-check"
                                @click="tagIntent(scope.row, true, 0)"
                            ></i>
                        </el-tooltip>
                        <el-tooltip
                            :content="$t('trainList.questionList.recognitionError')"
                            placement="bottom"
                            effect="dark"
                            :open-delay="300"
                            :key="scope.row.id"
                        >
                            <i
                                class="el-icon-close"
                                @click="tagIntent(scope.row, false, 0)"
                            ></i>
                        </el-tooltip>
                    </template>
                    <el-tooltip v-if="[6].indexOf(scope.row.button) > -1 && RP_Visible('OPEN_ML_MARK')" class="item" effect="dark" :content="$t('trainList.correctAnnotation')" placement="top" :open-delay="300" :key="scope.row.id">
                        <i class="guoran-tongyichicun-kaiqi1 iconfont" @click="tagIntent(scope.row, true, 0)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="[6].indexOf(scope.row.button) > -1 && RP_Visible('OPEN_ML_MARK')" class="item" effect="dark" :content="$t('trainList.annotationError')" placement="top" :open-delay="300" :key="scope.row.id">
                        <i class="guoran-tongyichicun-guanbi1 iconfont" @click="tagIntent(scope.row, false, 0)"></i>
                    </el-tooltip>
                    <template v-if="[2].indexOf(scope.row.button) > -1">
                        <el-button
                            v-if="
                                scope.row.isActive === true &&
                                RP_Visible('OPEN_ML_MARK')
                            "
                            type="text"
                            class="reset-tag"
                            @click="tagIntent(scope.row, false, 0,'again')"
                            >{{$t('trainList.questionList.remark')}}</el-button
                        >
                        <span v-else class="correct">{{$t('trainList.questionList.identifyCorrectly')}}</span>
                    </template>
                    <template v-if="[3, 4].indexOf(scope.row.button) > -1">
                        <template v-if="scope.row.isActive === true">
                            <el-tooltip
                                v-if="
                                    scope.row.tagIntentName.length > 10 &&
                                    RP_Visible('OPEN_ML_MARK')
                                "
                                class="item"
                                effect="dark"
                                :content="scope.row.tagIntentName"
                                placement="top-start"
                                :open-delay="300"
                                :key="scope.row.id"
                            >
                                <el-button
                                    type="text"
                                    class="reset-tag"
                                    @click="tagIntent(scope.row, false, 0,'again')"
                                    >{{$t('trainList.questionList.remark')}}</el-button
                                >
                            </el-tooltip>
                            <el-button
                                v-else
                                type="text"
                                class="reset-tag"
                                @click="tagIntent(scope.row, false, 0,'again')"
                                >{{$t('trainList.questionList.remark')}}</el-button
                            >
                        </template>
                        <template v-else>
                            <div class="intent-name two-column-ellipsis">
                                {{scope.row.tagIntentName}}
                                <!-- {{scope.row.tagIntentName.length > 10? scope.row.tagIntentName.substring(0,10) + "...": scope.row.tagIntentName}} -->
                            </div>
                        </template>
                    </template>
                    <template v-if="qwType == 'mark'">
                        <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.ignore')" placement="top" :open-delay="300" :key="scope.row.id">
                            <el-popconfirm
                                v-if="RP_Visible('OPEN_ML_MARK')"
                                popper-class="popconfirm-ingore"
                                :cancel-button-text="$t('common.cancel')"
                                cancel-button-type="round"
                                :confirm-button-text="$t('common.confirm')"
                                confirm-button-type="Primary"
                                icon="guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe iconfont"
                                icon-color="#FE5965"
                                :title="$t('trainList.questionList.ignoreTip')"
                                @confirm="ignore(scope.row)"
                                >
                                <i slot="reference" class="iconfont guoran-tongyichicun-hulve" v-if="RP_Visible('OPEN_ML_MARK')"></i>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                    <el-tooltip class="item" effect="dark" :content="$t('trainList.questionList.moveToOther')" placement="top" :open-delay="300" :key="scope.row.id">
                        <i class="guoran-tongyichicun-youjiantou iconfont" @click="moveToOtherBot(scope.row,'single')"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <pagination
            :pageSize="pageSize"
            :currentPage="currentPage"
            :total="total"
            @currentChange="pageChange">
        </pagination>
        <el-drawer
            size="975px"
            direction="rtl"
            :visible.sync="drawer"
            :with-header="false"
        >
            <div class="drawer-content-o" style="height: 100%">
                <feedback-detail
                    v-if="drawer"
                    viewTypeParent="question"
                    @closeFeedbackDetailBox="closeFeedbackDetailBox"
                    :activeItem="activeItem"
                />
            </div>
        </el-drawer>
        <tagging ref="myTaggging" :currentBotInfo="currentBotInfo" @close="close" />
        <imageView
            v-if="showImageViewer"
            :previewList.sync="previewList"
            :showViewer.sync="showImageViewer"
            :currentImage="currentImage"
            :isCanDel="false">
        </imageView>
        <popup @closeEvent="closeEvent" :haveTips="true" v-if="moveToOtherBotVisible" class="move-to-other-bot-visible">
            <div slot="popup-name">{{$t('trainList.questionList.moveToOther')}}</div>
            <div slot="popup-tip">{{$t('trainList.questionList.moveToOtherTip')}}</div>
            <div slot="popup-con">
                <div class="move-to-other-bot-move-question" v-if="moveToOtherBotData.id">
                    <span v-if="moveToOtherBotData.type !== 'IMAGE'">{{moveToOtherBotData.question}}</span>
                    <el-image
                        v-else
                        :src="JSON.parse(moveToOtherBotData.question).urls[0]"
                        :fit="'cover'">
                    </el-image>
                </div>
                <div style="color:#616161;"> {{$t('trainList.questionList.selectBot')}}</div>
                <div class="self-select-content">
                    <img
                        v-show="activeBot && activeBot !== ''"
                        class="imgbtype"
                        :src="getBotProfilePhoto"
                        alt=""
                    />
                    <el-select
                        :class="[activeBot && activeBot !== '' ?  'self-select-content-show-img' : 'self-select-content-hide-img','self-select-content-select']"
                        filterable
                        v-model="activeBot"
                        :placeholder="$t('trainList.questionList.selectBotPlaceholder')"
                        @change="selectBot"
                        @visible-change="visibleChange"
                        size="small"
                    >
                        <el-option
                            v-for="item in otherBotList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                            <div
                                class="uf-top-bot-select"
                                style="display: flex; align-items: center"
                            >
                                <img
                                    height="16px"
                                    style="margin-right: 6px; margin-top: -3px"
                                    :src="item.botProfilePhoto"
                                    alt=""
                                    srcset=""
                                />
                                <span style="float: left">{{ item.name }}</span>
                            </div>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button class="confirm-btn" type="primary" @click="confirmMoveIntent" round style="padding:0 !important;">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import pagination from "../../components/Pagination.vue";
import Tagging from "./tagging";
import TaggingPopover from "./taggingPopover";
import FeedbackDetail from "./component/FeedbackDetail";
import imageView from '../../components/imageView.vue'
import Popup from '../../components/popupNew.vue';
export default {
    props: ["botId", "keyword", "qwType", "topAdd","currentBotInfo","botList", "isShowSearch"],
    components: {
        Tagging,
        TaggingPopover,
        FeedbackDetail,
        pagination,
        imageView,
        Popup
    },
    data() {
        return {
            botListWithNoAuth: [],
            isMnHr: false,
            drawer: false,
            activeItem: {},
            pageSize: 20,
            currentPage: 1,
            total: 0,
            queryType: 100, // 全部问题100 不识别问题 0 已识别问题 1
            sort: false, // 相似问题排序
            orderType: 0, //  0：时间倒序 1：时间正序 2：频次倒序 3：频次正序 4：相似问法
            currentIntent: "", // 当前意图
            checkAll: false,
            intentionList: [],
            tableData: [],
            tagType: false, // 标注正确还是错误，重新标注和叉是false,勾是true
            currentQ: [],
            checkedIds: [], // 选中的
            taggingStatus: "", // 选中问题的标注状态
            loading: false,
            // tabelHeight: "calc(100vh - 200px" - this.topAdd + "px)",
            advSearchObjParent: null,
            showImageViewer:false,
            previewList:[],
            currentImage:{},
            moveToOtherBotVisible:false,
            moveToOtherBotData:{},
            otherBotList:[],
            activeBot:'',
            getBotProfilePhoto:'',
            filters : [
                {
                    value: 100,
                    text: "全部问题",
                },
                {
                    value: 0,
                    text: "不识别问题",
                },
                {
                    value: 1,
                    text: "已识别问题",
                },
                {
                    value: 2,
                    text: "不识别推荐",
                },
                {
                    value: 3,
                    text: "关键词推荐",
                },
                {
                    value: 'WORK_ORDER',
                    text: "触发转工单问题",
                },
                {
                    value: 'CUSTOMER',
                    text: "触发转人工问题",
                },
                {
                    value: 'FEEDBACK_IS_USEFUL',
                    text: "触发有用的问题",
                },
                {
                    value: 'FEEDBACK_IS_USELESS',
                    text: "触发无用的问题",
                },
            ]
            
        };
    },
    watch: {
        botId(n, o) {
            this.getIntentList();
        },
        checkedIds:{
            handler(n){
                this.$emit('checkedIdsFn',n)
            },
            deep:true
        },
        // botList:{
            // handler(n){
                // this.otherBotList = n.filter(item => {
                //     return item.id !== this.botId
                // })
            // },
            // deep:true,
        // }
            
    },
    mounted() {
        // this.getIntentList();
        // this.getQuestion();
        const allowedIds = [
            'b52d52cd3533409fb4b75af79868575f',
            '969a139de1df48c6965ff9014777fbae',
            '9209bdb27a0b460ea1eb04c54681b35e',
            'ab0f90737c8b4f2d85ba2157e4473110',
            '969a139de1df48c6965ff9014777fbae'
        ];

        if (allowedIds.includes(localStorage.getItem("_mainId"))) {
            this.isMnHr = true;
        }
        this.getBotListWithNoAuth();

    },
    methods: {
        // 获取机器人列表
        getBotListWithNoAuth() {
            this.FetchGet(this.requestUrl.bot.getBotList + "?filterPrivilege=false", {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botListWithNoAuth = res.data.list;
                    this.otherBotList = this.botListWithNoAuth.filter(item => {
                        return item.id !== this.botId;
                    })
                } else {
                    this.$message.error(res.message);
                }
                console.log(this.otherBotList);
            });
        },
        changeSort(value){
            if(value === 'open'){
                this.sort = false;
            } else if (value === 'close'){
                this.sort = true;
            } 
            this.orderType = this.sort ? 4 : 0
            this.getQuestion();
        },
        changeIntentType(e,scope){
            console.log(e,scope);
             this.getQuestion(1);

        },
         // 移动到其他机器人
        moveToOtherBot(item,type){
            console.log(item,type);
            this.moveToOtherBotData = item;
            this.activeBot = '';
            this.moveToOtherBotVisible = true;
        },
        selectBot(val) {
            console.log(this.activeBot,'this.activeBot');
            // this.activeBot = val;
            this.otherBotList.forEach(item => {
                if(val === item.id){
                    this.getBotProfilePhoto = item.botProfilePhoto;
                }
            })
            this.$forceUpdate()
        },
        visibleChange(value){
            console.log(value,'value');
            document.querySelector('.imgbtype').style.opacity = value ? 0.4 : 1;
            
        },
        closeEvent(){
            this.moveToOtherBotVisible = false;
        },
        confirmMoveIntent(){
            let ids = this.moveToOtherBotData.id ? [this.moveToOtherBotData.id] : this.checkedIds;
            this.FetchPost(this.requestUrl.trainList.intentMove + '?goalBotId=' + this.activeBot, ids).then((res) => {
                if (res.code === "0") {
                    this.$message.success('移动成功')
                    this.moveToOtherBotVisible = false;
                    this.checkedIds = [];
                    this.getQuestion();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 预览图片
        viewImage(item){
            this.currentImage = {
                imageUrl:JSON.parse(item.question).urls[0],
                sortNum:0
            }
            this.previewList = JSON.parse(item.question).urls;
            this.showImageViewer = true;
        },
        // 设置table  class
        tableRowClassName({row, rowIndex}){
            let name= ''
            if(row.type === 'IMAGE'){
                name = 'image-row';
                if(row.lastBotName && row.lastBotName !== '' && row.moveUserName && row.moveUserName !== '' ){
                    name = 'image-row show-source-other-bot';
                }
            } else {
                name = 'normal-row'
            }
            return name
        },
         // 获取待标注问题和已忽略问题的总数
        getTagMsgNum(){
            this.FetchGet(this.requestUrl.trainList.getTagMsgNum+'?botId='+this.botId).then((res) => {
                if (res.code === "0") {
                    this.$emit('getTagMsgNum',{
                        noMarkedNum:res.data.noMarkedNum,
                        ignoreNum:res.data.ignoredNum
                    })
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        closeFeedbackDetailBox() {
            this.drawer = false;
        },
        viewAnswer(item) {
            this.activeItem = item;
            this.drawer = true;
        },
        // 忽略待标注 批量
        ignoreBatch(currentQ) {
            this.FetchPost(this.requestUrl.trainList.batchIgnoreTag, {
                list: this.checkedIds,
            }).then((res) => {
                if (res.code === "0") {
                    this.getQuestion();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 忽略待标注 单条
        ignore(row) {
            this.FetchPut(this.requestUrl.trainList.ignoreTag, row.id, {}).then(
                (res) => {
                    if (res.code === "0") {
                        this.getQuestion();
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 筛选
        selectQuestion(val, row) {
            this.taggingStatus = row.isIdentify;
            this.tableData.forEach((v) => {
                v.disabled = false;
                if (v.isIdentify !== row.isIdentify) v.disabled = true;
            });
            if (val === true) {
                this.checkedIds.push(row.id);
                this.currentQ.push(row);
            } else {
                const ind = this.checkedIds.indexOf(row.id);
                this.checkedIds.splice(ind, 1);
                this.currentQ.splice(ind, 1);
            }
        },
        cellEnter(row) {
            this.tableData.forEach((v) => {
                v.isActive = false;
            });
            if (this.checkedIds.length === 0) row.isActive = true;
        },
        cellLeave(row) {
            this.tableData.forEach((v) => {
                v.isActive = false;
            });
        },
        close() {
            this.checkedIds = [];
            this.currentQ = [];
            this.getQuestion();
            this.$emit("refresh");
        },
        // 获取机器人下的意图
        getIntentList() {
            console.log('getIntentList');
            this.FetchGet(
                this.requestUrl.trainList.intentTree +
                    "?bId=" +
                    this.botId +
                    "&filterType=1"
            ).then((res) => {
                if (res.code === "0") {
                    res.data.forEach((val) => {
                        let arr = [];
                        val.children.forEach((v) => {
                            arr = arr.concat(v.children);
                        });
                        val.children = arr;
                    });
                    this.intentionList = res.data;
                    this.getQuestion();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        formatAdvSearchParam(param, advObj) {
            // 问题类型
            if (advObj.queryTypeObj) {
                param.identifyStatus = advObj.queryTypeObj.value;
                this.queryType = advObj.queryTypeObj.value;
                if (this.queryType == 0) {
                    param.unrecognizedAction = 0;
                    param.identifyStatus = 0;
                }else if (this.queryType == 1) {
                    param.unrecognizedAction = 0;
                    param.identifyStatus = 1;
                }  else if (this.queryType == 2) {
                    param.unrecognizedAction = 2;
                    param.identifyStatus = 0;
                } else if (this.queryType == 3) {
                    param.unrecognizedAction = 1;
                    param.identifyStatus = 0;
                } else if (this.queryType == 100) {
                    delete param.unrecognizedAction;
                    param.identifyStatus = null;
                }  else  {
                    delete param.unrecognizedAction;
                    param.identifyStatus = null;
                    param.questionType = this.queryType;
                }
            } else {
                console.log('无值');
                if(param.identifyStatus){

                } else {
                     param.identifyStatus = null;
                }
                // if(!flag){
                //     this.queryType = advObj.queryTypeObj.value;
                // } else {
                //     this.queryType = 100;
                // }
                delete param.questionType
            }
            // 被识别意图
            if (advObj.intents != undefined) {
                param.intentIds = advObj.intents
                    .map((intent) => {
                        return intent.id;
                    })
                    .join(",");
            }
            // 最后消息时间
            if (advObj.lastMsgTime != undefined) {
                param.beginMsgTime = advObj.lastMsgTime[0];
                param.endMsgTime = advObj.lastMsgTime[1];
                if (param.beginMsgTime == param.endMsgTime) {
                    let end = new Date(param.endMsgTime).getTime() + 24*60*60*1000;
                    param.endMsgTime = new Date(end);
                }
            }
            // 最后消息部门
            if (advObj.orgnation != undefined) {
                param.departmentId = advObj.orgnation[0].id;
            }
            // 最后标注时间
            if (advObj.lastNoteTime != undefined) {
                param.beginTagTime = advObj.lastNoteTime[0];
                param.endTagTime = advObj.lastNoteTime[1];
                if (param.beginTagTime == param.endTagTime) {
                    let end = new Date(param.endTagTime).getTime() + 24*60*60*1000;
                    param.endTagTime = new Date(end);
                }
            }
            // 最后消息部门
            if (advObj.noteStetus != undefined) {
                param.status = advObj.noteStetus.status;
            }
            // 最后消息部门
            if (advObj.noteUser != undefined) {
                param.annotators = (advObj.noteUser.map(item => {return item.id})).join(",");
            }
            return param;
        },
        // 获取待标注问题
        getQuestion(setPage1, advSearchObj) {
            this.getTagMsgNum();
            if (setPage1 == 1) {
                this.currentPage = 1;
            }
            this.loading = true;
            let param = {
                botId: this.botId,
                intentId: this.currentIntent,
                keyword: this.keyword.trim(),
                orderType: this.orderType,
                page: this.currentPage,
                size: this.pageSize,
                ignored: this.qwType == "mark" ? 0 : 1,

            };
            if (advSearchObj) {
                this.advSearchObjParent =  JSON.parse(JSON.stringify(advSearchObj));
            } else {
                if(this.advSearchObjParent){
                    this.advSearchObjParent = Object.assign(this.advSearchObjParent,param);
                } else {
                    this.advSearchObjParent = JSON.parse(JSON.stringify(param));
                }
            }
            if (this.advSearchObjParent) {
                let obj = JSON.parse(JSON.stringify(this.advSearchObjParent));
                if(!this.advSearchObjParent.queryTypeObj){
                    obj.queryTypeObj = {value:this.queryType};
                }
                param = this.formatAdvSearchParam(param,Object.assign(obj,param));
            }
            this.getData(param)
        },
        getData(param){
            this.FetchPost(this.requestUrl.trainList.search, param)
                .then((res) => {
                    if (res.code === "0") {
                        this.tableData = [];
                        res.data.list.forEach((v) => {
                            if (v.isIdentify === true && v.status === "NOT_TAG")
                                v.button = 1;
                            if (
                                v.isIdentify === true &&
                                v.status === "IDENTIFY_CORRECT_TAGED"
                            )
                                v.button = 2;
                            if (
                                v.isIdentify === true &&
                                v.status === "IDENTIFY_ERROR_TAGED"
                            )
                                v.button = 3;
                            if (
                                v.isIdentify === false &&
                                v.status === "NOT_IDENTIFY_TAGED"
                            )
                                v.button = 4;
                            if (
                                v.isIdentify === false &&
                                v.status === "NOT_TAG"
                            )
                                v.button = 5;
                            if (v.isIdentify === true && v.status === "NOT_TAG")
                                v.button = 6;
                            v.isActive = false;
                            v.checked = false;
                            v.disabled = false;
                            v.time = new Date(v.createTime).Format(
                                "yyyy-MM-dd hh:mm"
                            );
                            if (v.tagTime != null || v.tagTime != undefined) {
                                v.updateTimeStr = new Date(v.tagTime).Format(
                                    "yyyy-MM-dd hh:mm"
                                );
                            } else {
                                v.updateTimeStr = "";
                            }
                            if (v.annotatorInfo != null || v.annotator == -1) {
                                if (v.annotator == -1) {
                                    v.annotatorName = '系统';
                                } else {
                                    v.annotatorName = v.annotatorInfo.realName;
                                }
                                v.annotatorNameIcon = v.annotatorInfo && v.annotatorInfo.profilePhoto && v.annotatorInfo.profilePhoto !== '' ? v.annotatorInfo.profilePhoto : 'https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png';
                            } else {
                                v.annotatorName = "";
                                v.annotatorNameIcon = 'https://static.guoranbot.com/cdn-office-website/images/default_avt_ui.png'
                            }
                            if(this.checkedIds.indexOf(v.id) !== -1){
                                v.checked = true;
                            }
                            v.unfold = JSON.stringify(v.unfold)
                            this.tableData.push(v);
                        });
                        this.total = res.data.total;
                        this.$emit('getOverview',param)
                        console.log(this.tableData,'this.tableData');
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                    
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        pageChange(curr) {
            this.currentPage = curr;
            if (this.advSearchObjParent != null && JSON.stringify(this.advSearchObjParent) != "{}") {
                this.getQuestion( this.currentPage, this.advSearchObjParent);
            } else {
                this.getQuestion();
            }
        },
        // 全选
        selectAll(val) {
            this.checkedIds = [];
            this.currentQ = [];
            if (val === true) {
                this.tableData.forEach((v) => {
                    if (this.taggingStatus === v.isIdentify) {
                        v.checked = true;
                        this.checkedIds.push(v.id);
                        this.currentQ.push(v);
                    }
                });
            } else {
                this.taggingStatus = "";
                this.tableData.forEach((v) => {
                    v.checked = false;
                    v.disabled = false;
                });
            }
        },
        tagIntent(row, isRight, type,titleType) {
            this.$refs.myTaggging.botId = this.botId;
            if (isRight === true) {
                // 标注正确
                this.$refs.myTaggging.isRight = isRight;
                // this.$refs.myTaggging.intentId = row.intentId;
                // this.$refs.myTaggging.intentName = row.intentName;
                this.$refs.myTaggging.type = type;
                if (type) {
                    this.$refs.myTaggging.tId = this.checkedIds.join(",");
                    this.$refs.myTaggging.isIdentify = row[0].isIdentify;
                    this.checkedIds = [];
                } else {
                    this.$refs.myTaggging.tId = row.id;
                    this.$refs.myTaggging.isIdentify = row.isIdentify;
                }
                this.$refs.myTaggging.tagIntent("tab");
            } else {
                this.$refs.myTaggging.getIntentListByBotId(this.botId,row);
                this.$refs.myTaggging.open(row, isRight, type, this.checkedIds,titleType);
            }
        },
        renderIntention(h, { column }) {
            return h(
                "el-select",
                {
                    props: {
                        value: this.currentIntent,
                        size: "small",
                        filterable: true,
                    },
                    class: "select-intention",
                    on: {
                        change: (val) => {
                            this.currentIntent = val;
                            this.getQuestion();
                        },
                    },
                },
                [
                    h("el-option", {
                        props: {
                            value: "",
                            label: "全部意图",
                            key: "",
                        },
                    }),
                    this.intentionList.map((item) => {
                        return h(
                            "el-option-group",
                            {
                                props: {
                                    label: item.name,
                                    key: item.id,
                                },
                                style: {
                                    width: "300px",
                                },
                            },
                            [
                                item.children.map((v) => {
                                    return h("el-option", {
                                        props: {
                                            value: v.id,
                                            label: v.name,
                                            key: v.id,
                                        },
                                    });
                                }),
                            ]
                        );
                    }),
                ]
            );
        },
        renderquestion(h, { column }) {
            const filters = [
                {
                    value: 100,
                    text: "全部问题",
                },
                {
                    value: 0,
                    text: "不识别问题",
                },
                {
                    value: 1,
                    text: "已识别问题",
                },
                {
                    value: 2,
                    text: "不识别推荐",
                },
                {
                    value: 3,
                    text: "关键词推荐",
                },
                {
                    value: 'WORK_ORDER',
                    text: "触发转工单问题",
                },
                {
                    value: 'CUSTOMER',
                    text: "触发转人工问题",
                },
                {
                    value: 'FEEDBACK_IS_USEFUL',
                    text: "触发有用的问题",
                },
                {
                    value: 'FEEDBACK_IS_USELESS',
                    text: "触发无用的问题",
                },
            ];
            return h("div", {
                class: "first-column",
            },[
                h(
                    "el-select",
                    {
                        props: {
                            value: this.queryType,
                            size: "small",
                        },
                        class: "select-question",
                        on: {
                            change: (val) => {
                                this.queryType = val;
                                this.getQuestion(1);
                            },
                        },
                    },
                    [
                        filters.map((item) => {
                            return h("el-option", {
                                props: {
                                    value: item.value,
                                    label: item.text,
                                },
                            });
                        }),
                    ]
                ),
                h(
                    "div",
                    {
                        class: "open-sort",
                    },
                    [
                        h("span", this.$t('trainList.questionList.sameQuestionSort') + ':'),
                        h("el-switch", {
                            props: {
                                "active-text": "开",
                                "inactive-text": "关",
                                "active-icon-class":"el-icon-check",
                                "inactive-icon-class":"el-icon-close",
                                width:42,
                                value: this.sort,
                            },
                            on: {
                                change: (val) => {
                                    this.sort = val;
                                    if (val === true) {
                                        this.orderType = 4;
                                    } else {
                                        this.orderType = 0;
                                    }
                                    this.getQuestion();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        renderDate(h) {
            return h("div", [
                // h("i",{class: "guoran-a-18-15 iconfont",}),
                h("span", this.$t('trainList.lastTime'),),
                h(
                    "span",
                    {
                        class: "caret-wrapper",
                    },
                    [
                        h("i", {
                            class: "sort-caret ascending",
                            style: {
                                borderBottomColor:
                                    !this.sort && this.orderType === 1
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 1;
                                    this.getQuestion();
                                },
                            },
                        }),
                        h("i", {
                            class: "sort-caret descending",
                            style: {
                                borderTopColor:
                                    !this.sort && this.orderType === 0
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 0;
                                    this.getQuestion();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        renderCount(h) {
            return h("div", [
                h("span", this.$t('trainList.questionList.hits')),
                h(
                    "span",
                    {
                        class: "caret-wrapper",
                    },
                    [
                        h("i", {
                            class: "sort-caret ascending",
                            style: {
                                borderBottomColor:
                                    !this.sort && this.orderType === 3
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 3;
                                    this.getQuestion();
                                },
                            },
                        }),
                        h("i", {
                            class: "sort-caret descending",
                            style: {
                                borderTopColor:
                                    !this.sort && this.orderType === 2
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    if (!this.sort) this.orderType = 2;
                                    this.getQuestion();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
    },
};
</script>

<style lang="less">
@import "../../assets/less/main/common.less";
.popconfirm-ingore{
    .el-popconfirm__action{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        .el-button{
            width: 49px;
            height: 28px;
            font-size: 12px;
            border-radius: 14px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &.el-button--round{
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                color: #616161;
            }
            &.el-button--Primary{
                background: #366AFF;
                color: #ffff;
                border: none;
            }
        }
    }
}
#q-table {
    // margin-top: 50px;
    display: inline-grid;
    // padding: 0 20px;
}
.el-popover.bot-syudy-look-history{
    width: 114px;
    min-width: 114px;
    height: 47px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 0px rgba(57,63,79,0.21);
    border-radius: 5px;
    padding: 0;
    text-align: center;
    line-height: 47px;
    bottom: 6px;
    left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        font-weight: 400;
        color: #366AFF;
        font-size: 14px;
        font-family: Microsoft YaHei;
    }
}

.question-list {
    background: #ffffff;
    overflow: auto;
    #pagination {
        bottom: -3px;
        height: 64px;
        z-index: 10 !important;
        border-radius: 0 !important;
    }
   .el-table {
        &::before{
            z-index: inherit;
        }
        .el-table__fixed,.el-table__fixed-right{
            bottom: 0px;
            height: 100%;
            &::before{
                height: 0;
            }
        }
        .el-table__fixed-right-patch{
            background-color: #F6F8FD;
        }
        .el-table__header-wrapper {
            height: 50px;
            line-height:50px;
            background-color: #F6F8FD;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            .el-table__header,tr,.has-gutter,th{
                background-color: #F6F8FD !important;
            }
            
            
        }
        .el-table__header{
                .first-filter-header-cell{
                    display: flex;
                    align-items: center;
                    .el-select{
                        width: 140px;
                        margin-right: 20px;
                        .el-input{
                            width: 140px;
                            .el-input__inner{
                                height: 32px;
                                width: 140px;
                            }
                            .el-input__suffix{
                                top:-2px;
                            }
                        }
                    }   
                    .same-question-sort-tips{
                        color:#000;
                    }
                    .evaluate-table-switch {
                        display: flex;
                        flex-direction: row;
                        height: 20px;
                        justify-content: space-between;
                        cursor: pointer;
                        position: relative;
                        text-align: center;
                        align-items: center;
                        padding: 0;
                        .el-switch {
                            text-align: center;
                            display: flex;
                        }
                        .el-switch__core{
                            height: 20px;
                            border-radius: 12px;
                        }
                        .switch-open-icon {
                            position: absolute;
                            z-index: 2;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 2px;
                
                            i {
                                color: white;
                                font-size: 16px;
                            }
                        }
                
                        .switch-close-icon {
                            position: absolute;
                            z-index: 2;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 2px;
                            i {
                                color: #6E6B7B;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                
                        i {
                            color: #366AFF;
                            font-size: 18px;
                        }
                    }
                }
            }
        .el-table__body-wrapper{
              // 滚动条样式
            &::-webkit-scrollbar {
                height: 6px;
                width: 6px;
                background-color: rgba(0,0,0,0);
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 6px!important;
                background-color:#BFCEEC;
            }
            
        }
        th .cell {
            height: 50px;
            line-height: 50px;
        }
        .el-switch {
            display: inline;
            line-height: unset;
            height: 24px;
            padding: 0;
        }
        .el-table__header{
            th{
                padding: 0;
                height: 50px;
                line-height: 50px;
                background-color: #F6F8FD;
                div{
                    line-height: 50px;
                }
                    
            }
        }
        .el-table__body {
            .table-fixed{
                /deep/.el-table__fixed,.el-table__fixed-right{
                    bottom: 0px;
                    height: 100%;
                    &::before{
                        height: 0;
                    }
                }
                /deep/.el-table__fixed-right-patch{
                    background-color: #F6F8FD;
               }
            }
            .cell {
                // padding: 14px 0;
            }
            
            .normal-row,.image-row{
                .cell{
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    // padding: 0;
                    height: 60px;
                    line-height: 60px;
                    .guoran-a-18-15{
                        margin-right: 6px;
                        color: #D2D8E3;
                        margin-top: 2px;
                        font-size: 17px;
                    }
                }
               
                
            }
            .image-row{
                .cell{
                    height: 110px;
                    line-height: 110px; 
                }
            }
            .show-source-other-bot{
               .cell{
                    height: 120px;
                    line-height: 100px; 
                } 
            }
            .intent-name-td{
                .cell{
                    display: block;
                }
            }
            tr td{
                padding: 0;
            }
            .user-icon{
                width: 26px;
                height: 26px;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 6px;
            }
            .name-icon{
                width: 26px;
                height: 26px;
                background: #366AFF;
                border-radius: 50%;
                margin-right: 6px;
                text-align: center;
                line-height: 26px;
                color: #fff;
            }
            .el-button{
                font-weight: 400;
            }
            .selection-column-box{
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                align-items: center;
                .el-checkbox{
                    margin-right: 10px;
                    .el-checkbox__inner{
                        width: 16px;
                        height: 16px;
                        border: 2px solid #EBEDF4;
                        border-radius: 2px;
                        &::after{
                            top: 0px;
                            border-width: 2px;
                        }
                            
                    }
                    .is-checked .el-checkbox__inner{
                        border: none;
                        border: 2px solid #366AFF;
                    }
                }
                .selection-column{
                    display: flex;
                    .one-column-ellipsis{
                        line-height: normal;
                        margin-right: 6px;
                    }
                    &.image-cell{
                        display: flex;
                    }
                    
                    .el-image{
                        width: 80px;
                        height: 80px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-left: 4px;
                    }
                    .is-new-move-intent{
                        display: inline-block;
                        width: 7px;
                        height: 7px;
                        background: #FE5965;
                        border-radius: 50%;
                        margin-right: 2px;
                    }
                    .image-row-cell{
                        display: flex;
                        align-items: center;
                    }
                }
                .source-other-bot{
                    width: 120px;
                    height: 18px;
                    background: #799CFF;
                    border-radius: 3px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 18px;
                    text-align: center;
                    margin-top: 5px;
                    padding-bottom: 10px;
                    i{
                        color: #B2C6FF;
                    }
                }
            }
           
            .question-operation{
                .cell{
                    display: flex;
                    justify-content: center;
                    align-items:center;
                    color:#366AFF;
                    // justify-content: flex-start;
                
                    .iconfont{
                        font-size: 16px;
                        margin-right: 14px;
                    }
                    .guoran-tongyichicun-kaiqi1{
                        font-size: 18px;
                    }
                    .guoran-tongyichicun-guanbi1{
                        font-size: 13px;
                    }
                    .guoran-tongyichicun-hulve{
                        font-size: 17px;
                    }
                    .intent-name{
                        line-height: 20px;
                        margin-right: 4px;
                        text-align: left;
                        // display: inline-block;
                        width: 68px;
                    }
                }
            }

            .q-first-c {
                display: flex;
                flex-wrap: wrap;
            }

            .labels{
                height: 18px;
                line-height: 18px;
                background: #EFF2FF;
                border: 1px solid #DBE4FF;
                border-radius: 2px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                margin-bottom: 6px;
                text-align: center;
                margin-right: 6px;
                &.worker{
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    //width: 55px;
                  padding: 0 4px;
                }
                &.artificial{
                    background-color: #E4E5FF;
                    border-color:#E4E5FF;
                    color: #848AFF;
                    //width: 55px;
                  padding: 0 4px;
                }
                &.no-use{
                    background-color: #FFDFE1;
                    border-color:#FFDFE1;
                    color: #FE5965;
                    //width: 75px;
                    padding: 0 4px;
                }
                &.use{
                    background-color: #D4F1F0;
                    border-color:#D4F1F0;;
                    color: #00C2BB;
                    //width: 75px;

                }
                &.long-info {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    min-width: 145px;
                    padding: 0 8px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .two-column{
                display: flex;
                align-items: flex-end;
            .left,.right{
                display: flex;
                    align-items: center;
                    flex-direction: column;
            } 
            .left{
                margin-right: 10px;
            }
            &.start{
                align-items: flex-start;
            }
            }
            &.max-h{
                height: 100px;
                line-height: 100px;
            }
        }
        .el-select,
        .el-input {
            padding-left: 0;
        }
        .reset-tag,
        .view-info {
            padding: 0;
        }
        .reset-tag{
            margin-right: 14px;
        }
        .correct,.intent-name{
            color: #366AFF;
        }
        .correct{
            margin-right: 14px;
        }
        .open-sort {
            // float: left;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            padding-left: 0;
            // .el-switch {
            //     margin-top: -10px;
            //     display: inline-block;
            //     line-height: unset;
            //     height: 30px;
            // }
            p {
                height: 20px;
                line-height: 20px;
            }
            .el-switch__label {
                line-height: 20px;
            }
            margin-left: 10px;
            .el-switch{
                height: 24px;
                .el-switch__core{
                    height: 24px;
                    border-radius: 12px;
                }
                .el-switch__core:after{
                    width: 14px;
                    height: 14px;
                    left: 5px;
                    top: 3px;
                }
                .el-switch__label--left{
                    i{
                        color: #6e6b7b;
                        font-weight: 800;
                        font-size: 14px;
                    }
                    position: absolute;
                    left: 15px;
                    top: 1px;
                    z-index: 2;

                }
                .el-icon-check{
                    color: #F6F8FD;
                    font-weight: 800;
                    position: absolute;
                }

                &.is-checked{
                    .el-switch__core:after{
                        left: 40px;
                        top: 3px;
                    }
                    .el-icon-check{
                        position: absolute;
                        left: 5px;
                        top: 3px;
                        z-index: 2;
                        font-size: 14px;
                        color: #fff;
                        font-weight: 800;
                    }
                    .el-icon-close{
                        color: #fff;
                    }
                }

            }
        }
    }
    
    .guoran-tongyichicun-kaiqi1 {
        color: #366AFF;
        cursor: pointer;
    }
    .el-icon-close:not(.l-dialog__close){
        margin-left: 10px;
        color: #366AFF;
        cursor: pointer;
    }
    .guoran-tongyichicun-guanbi1{
        font-size: 12px;
    }
    .el-checkbox {
        padding: 0px !important;
    }
    &.show-search{
        #q-table {
            margin-top: 110px;
        }
    }
}
.batch-title {
    display: flex;
    width: calc(100% - 40px);
    height: 50px;
    // position: absolute;
    background: #fff;
    z-index: 999;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0 20px;
    line-height: 50px;
    align-items: center;
    .current-count {
        flex: 1;
        text-align: right;
        i {
            padding: 0 5px;
            color: #366AFF;
            font-style: normal;
        }
    }
    .tagging,.guoran-tongyichicun-kaiqi1,.guoran-tongyichicun-guanbi1 {
        color:#616161;
        width: 32px;
        height: 31px;
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
        text-align: center;
        line-height: 31px;
        cursor: pointer;
        font-size: 16px;
    }
    .guoran-tongyichicun-guanbi1{
        font-size: 12px;
    }
    .el-checkbox{
        margin-right: 12px;
        .el-checkbox__inner{
            width: 16px;
            height: 16px;
            border: 2px solid #EBEDF4;
            border-radius: 2px;
            &::after{
                top: 0px;
                border-width: 2px;
            }
                
        }
        .is-checked .el-checkbox__inner{
            border: none;
            border: 2px solid #366AFF;
        }
            
    }
    .iconfont{
        margin-left: 12px;
    }
}
.select-question {
    float: left;
    width: 150px;
    .el-input {
        padding-right: 0;
    }
}
.select-intention {
    width: 180px;
    .el-input {
        padding-right: 0;
    }
}
.move-to-other-bot-visible{
    text-align: left;
    .move-to-other-bot-move-question{
        padding: 10px;
        background: #FBFCFD;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 20px;
        span{
            display: inline-block;
            text-align: left;
            color: #616161;
        }
    }
    .el-image{
        width: 100px;
        height: 100px;
         border-radius: 10px;
    }
    
    .self-select-content{
        position: relative;
        margin-top: 10px;
        .imgbtype{
            width: 16px;
            height: 16px;
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 5;
        }
        .uf-top-bot-select {
            display: flex;
            align-items: center;
        }
        .self-select-content-select{
            width: 100%;
            .el-input__inner{
                height: 38px;
                width: 100%;
                color: #606266;
            }
        }
        .self-select-content-show-img{
            .el-input__inner {
                padding-left: 30px;
            }
        }
    }
}
    
</style>