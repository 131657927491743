<template>
    <popup class="attention_diagram_dialog" @closeEvent="close">
        <div slot="popup-name">{{ titleType === 'again' ? $t('trainList.questionList.remark') : $t('trainList.questionList.label') }}</div>
        <div slot="popup-tip">{{$t('trainList.markOrderWithIntent')}}</div>
        <div slot="popup-con">
            <div class="attention_diagram_content">
                <div class="intention_content">
                    <div class="intention_search">
                        <div class="search_input">
                            <i class="el-icon-search"></i>
                            <el-input size="small" v-model="keyWord" @input="changeKeyWord"
                                @keyup.enter.native="searchIntent" :placeholder="$t('botIntent.searchOrSelectIntent')"></el-input>
                        </div>
                        <div class="add_intention" @click="addIntention">
                            {{$t('trainList.newAddIntent')}}
                            <div class="ai">
                              {{$t('trainList.AIRecommend')}}
                            </div>
                        </div>
                    </div>
                    <div class="intention_tree">
                        <template v-if="!seachFlag">
                            <div class="bot_list_item" v-for="bot in botList" :key="bot.key">
                                <div class="bot_label" @click="botLabelClick(bot)">
                                    <i v-show="!bot.loading" class="el-icon-caret-right el-tree-node__expand-icon"
                                        :class="bot.expanded ? 'expanded' : ''"></i>
                                    <i v-show="bot.loading" class="el-icon-loading"></i>
                                    <div class="bot_name">
                                        {{ bot.name }}
                                    </div>
                                </div>
                                <div class="bot_content" v-show="bot.expanded" :key="bot.id + bot.key">
                                    <el-tree :data="bot.children" :props="{
                                        children: 'children',
                                        label: 'name'
                                    }" @node-click="(data, node) => { handleNodeClick(data, node, bot) }">
                                        <span class="custom-tree-node" slot-scope="{ node, data }">
                                            <span>{{ node.label }}</span>
                                            <i v-if="currentIntent.id == data.id" class="el-icon-check"></i>
                                        </span>
                                    </el-tree>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="intent_list_item" v-for="intent in intentList" :key="intent.key"
                                @click="intentClick(intent)">
                                <div class="intent_label">
                                    <div class="intent_name">
                                        {{ intent.name }}
                                        <p class="intent_bot_name">({{ intent.botName + '/' + intent.skillName + "/" +
                                            intent.typeName }})</p>
                                    </div>
                                    <i v-if="currentIntent.id == intent.id" class="el-icon-check"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="work_order_desc_content"
                    v-if="checkedIds.length == 0 && Object.keys(workOrderData).length != 0">
                    <workOrderDescContent :orderId="workOrderData.workOrderId"></workOrderDescContent>
                </div>
            </div>
        </div>
        <div slot="dialog-footer">
            <el-button @click="close" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
            <el-button class="confirm-btn" type="primary" @click="sureMarkOrder" round
                style="padding:0 !important;">{{$t('common.confirm')}}</el-button>
        </div>
    </popup>
</template>

<script>
import Popup from '../../../components/popup.vue';
import workOrderDescContent from './workOrderDescContent.vue';
export default {
    props: ["checkedIds"],
    components: { Popup, workOrderDescContent },
    data () {
        return {
            intentData: [],
            skillId: '',
            classList: [],
            keyWord: '',
            classId: '',
            currentIntent: {},
            currentIntentBot: {},
            workOrderData: {},
            fullPath: '',
            seachFlag: false,
            intentList: [],
            botList: []
        }
    },
    watch: {

    },
    mounted () {
        this.getBotList()
    },
    methods: {
        onNodeClick (data, node) {
            if (data.type === "recommendIntent" || data.type === "skill" || data.type === "class") return false;
            this.filterText = data.name;
            this.intentId = data.id;
            this.isCheck = true;
            this.visible = false;
        },
        filterNode (value, data) {
            if (!value) return true;
            if (data.name.indexOf(value) !== -1) {
                if (data.id === "recommendIntent") {
                    return false;
                }
                return true;
            }
        },
        //新建意图
        addIntent () {
        },
        botLabelClick (bot) {
            if (!bot.expanded) {
                if (bot.children.length == 0) {
                    bot.loading = true
                    this.getIntentListByBotId(bot)
                } else {
                    bot.expanded = true
                }
            } else {
                bot.expanded = false
            }
            this.$forceUpdate()
        },
        getBotList () {
            this.$http.get("/api/bot/list?page=1&size=100").then(res => {
                if (res.data.code == 0) {
                    this.botList = res.data.data.list.map(item => {
                        this.$set(item, 'children', [])
                        this.$set(item, 'expanded', false)
                        this.$set(item, 'loading', false)
                        return item
                    })
                }
            })
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId (bot) {
            this.$http.get(this.requestUrl.trainList.intentTree + '?bId=' + bot.id,)
                .then((res) => {
                    if (res.data.code == "0") {
                        this.$set(bot, 'children', res.data.data)
                        this.$set(bot, 'key', new Date().getTime())
                        this.$set(bot, 'expanded', true)
                        this.$set(bot, 'loading', false)
                        this.$forceUpdate()
                        // nodeTree.forEach((item, index) => {
                        //     item.type = 'skill';
                        //     item.children && item.children.forEach((v, i) => {
                        //         v.type = 'class';
                        //         v.children && v.children.forEach((_Item, _Index) => {
                        //             _Item.type = 'intent';
                        //         })
                        //     })
                        // })
                        // '?apiKey='+this.currentBotInfo.apiKey+'&question='+this.questionName
                        // this.FetchPost(this.requestUrl.trainList.recommendedIntent, {
                        //     apiKey: this.currentBotInfo.apiKey,
                        //     question: this.questionName,
                        //     type: data.type,
                        //     botId: bId
                        // })
                        //     .then((res2) => {
                        //         if (res2.code === "0") {
                        //             console.log(res2, 'res');
                        //             let recommandIntent = [{
                        //                 name: '推荐标注意图',
                        //                 id: 'recommendIntent',
                        //                 type: 'recommendIntent',
                        //                 children: []
                        //             }];
                        //             if (res2.data && res2.data.length > 0) {
                        //                 res2.data.forEach((item, index) => {
                        //                     recommandIntent[0].children.push(Object.assign(item, {
                        //                         type: 'intent'
                        //                     }))
                        //                 })
                        //             } else {
                        //                 recommandIntent = [];
                        //             }

                        //             this.intentTree4Radio = [...recommandIntent, ...nodeTree];
                        //         } else {
                        //             this.intentTree4Radio = [...nodeTree];
                        //         }
                        //     })
                        //     .catch((err) => {
                        //         this.intentTree4Radio = [...nodeTree];
                        //         this.$message(err);
                        //         this.intentTree4Radio = [...nodeTree];
                        //     });
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取意图名称
        changeIntent (val) {
            this.intentTree4Radio.forEach(skill => {
                if (skill.children != null) {
                    skill.children.forEach(_class => {
                        if (_class.children != null) {
                            _class.children.forEach(intent => {
                                if (intent.id == val) {
                                    this.intentName = intent.name;
                                }
                            })
                        }
                    })
                }
            })
        },
        close () {
            this.$emit("closeEvent")
        },
        sureMarkOrder () {
            if (this.currentIntent && this.currentIntent.id && this.currentIntentBot && this.currentIntentBot.id) {
                this.$http.put("/api/intent/workOrderToKnowledge/mark", {
                    ids: this.checkedIds.length == 0 ? [this.workOrderData.id] : this.checkedIds,
                    tagIntentId: this.currentIntent.id,
                    tagIntentName: this.currentIntent.name,
                    tagBotId: this.currentIntentBot.id,
                    tagIntentFullPath: this.fullPath
                }).then(res => {
                    if (res.data.code == '0') {
                        this.$message.success(this.$t('trainList.annotationSuccess'))
                        this.close()
                        this.currentIntent = {}
                        this.currentIntentBot = {}
                        this.$emit("changeSuccess")
                    }
                })
            } else {
                this.close()
                this.currentIntent = {}
                this.currentIntentBot = {}
            }

        },
        // 搜索意图
        searchIntent () {
            if (this.keyWord != "") {
                this.seachFlag = true
                this.$http.get("/api/intent/searchAllBotIntent?keyword=" + this.keyWord).then(res => {
                    if (res.data.code == 0) {
                        this.intentList = res.data.data
                    }
                })
            }
        },
        addIntention () {
            this.$emit('addIntention', this.workOrderData)
        },
        handleNodeClick (data, node, bot) {
            if (!data.id.includes('S') && !data.id.includes('T')) {
                this.currentIntent = data
                this.currentIntentBot = bot
                this.fullPath = ''
                this.loopSetFullpath(node.parent)
                this.fullPath = bot.name + '/' + this.fullPath
                console.log(this.fullPath, 'this.fullPaththis.fullPaththis.fullPath');
            }
        },
        changeKeyWord () {
            if (this.keyWord == '') {
                this.seachFlag = false
                this.intentList = []
            }
        },
        intentClick (intent) {
            this.$set(this, 'currentIntent', {
                id: intent.id,
                name: intent.name
            })
            this.$set(this, 'currentIntentBot', {
                id: intent.botId,
                name: intent.botName
            })
            this.fullPath = intent.botName + '/' + intent.skillName + '/' + intent.typeName
        },
        loopSetFullpath (node) {
            if (node.data && node.level > 0) {
                if (this.fullPath == '') {
                    this.fullPath = node.data.name
                } else {
                    this.fullPath = node.data.name + '/' + this.fullPath
                }
                this.loopSetFullpath(node.parent)
            }
        }
    }
}
</script>
<style lang="less">
.el-popover.search-intent-poprver {
    z-index: 3333 !important;

    .el-tree {
        overflow-y: auto;
        color: #606266;

        .el-tree-node__content {
            height: 33px !important;
            display: flex;
            align-items: center;
        }

        .custom-tree-node {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer !important;
            height: 33px;

            &.disabled {
                cursor: not-allowed !important;
            }
        }
    }
}
</style>
<style lang="less">
.attention_diagram_dialog {
    text-align: left;

    #popup-assembly {
        width: 800px !important;
    }

    .attention_diagram_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 600px;
        background: #ffffff;
        padding-top: 10px;

        .intention_content {
            width: 340px;
            height: 100%;
            margin-right: 15px;
            flex: 1 0;

            .intention_search {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .search_input {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #E0E6F7;

                    .el-icon-search {
                        color: #D2D8E3;
                        font-size: 14px;
                    }

                    .el-input {
                        width: 100%;

                        .el-input__inner {
                            border: none;
                            padding: 0 4px;
                        }
                    }

                }

                .add_intention {
                    width: 75px;
                    height: 28px;
                    background: #FFFFFF;
                    border-radius: 15px;
                    border: 1px solid #A1B9FF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    color: #366AFF;
                    font-size: 14px;
                    flex: none;
                    cursor: pointer;

                    .ai {
                        background: linear-gradient(90deg, #5C86FF, #8854FF);
                        border-radius: 7px;
                        color: #FFFFFF;
                        position: absolute;
                        top: -10px;
                        right: -15px;
                        font-size: 12px;
                        padding: 0 3px;
                    }
                }
            }

            .intention_tree {
                height: calc(100% - 40px);
                width: 100%;
                margin-top: 7px;
                overflow: hidden;
                overflow-y: auto;

                .bot_list_item {
                    width: 100%;
                    height: auto;

                    .bot_label {
                        display: flex;
                        align-items: center;
                        height: 32px;
                        cursor: pointer;
                        color: #616161;

                        i {
                            margin: 8px;
                        }

                        .expanded {
                            transform: rotate(90deg)
                        }
                    }
                }

                .bot_content {
                    width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    padding-left: 12px;

                    .el-tree-node__content {
                        height: 32px;
                        cursor: pointer;

                        .custom-tree-node {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer !important;

                            i {
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .intent_list_item {
                    width: 100%;
                    height: auto;

                    .intent_label {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: auto;
                        cursor: pointer;
                        color: #616161;

                        .intent_name {
                            padding: 3px 0;

                            .intent_bot_name {
                                margin-top: 5px;
                                color: #999999;
                                font-size: 12px;
                            }
                        }
                    }

                    .intent_label:hover {
                        background: #F5F7FA;
                    }
                }

            }

        }

        .work_order_desc_content {
            width: 330px;
            height: 100%;
            margin-left: 15px;
        }
    }

    .question-name {
        background-color: #FBFCFD;
        padding: 0 10px;
        border-radius: 5px;

        .text {
            height: 38px;
            display: flex;
            align-items: center;
        }

        .el-image {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }

        &.image-box {
            height: 120px;
            display: flex;
            align-items: center;
        }
    }

    .select-intent-content {
        width: 100%;
        margin: 16px 0 32px;
        display: flex;
        flex-direction: column;

        .top-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            color: #A3ADC6;

            .choose-tips {
                color: #000;
            }

            .add-intent {
                color: #366AFF;
                cursor: pointer;
                margin-left: 2px;

                &.botLimitUse {
                    opacity: 0.5;
                    cursor: no-drop;
                }
            }
        }

        .el-cascader {
            width: 100%;
        }

        .el-input {
            cursor: pointer;

            .el-input__suffix {
                right: 10px;
                top: 10px;

                .el-icon-arrow-down {
                    font-size: 18px;
                }
            }
        }


    }

    .el-dialog__header {
        padding: 0 !important;
    }

    .select-title {
        text-align: left;
        height: 32px;
        line-height: 32px;
    }

    .select-intent {
        display: flex;
        border: 1px solid #DCDFE6;
        margin-top: 2px;
        border-radius: 4px;
        text-align: left;

        >div {
            max-height: 50vh;
            overflow: auto;

            p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 32px;
                padding: 0 5px;
                line-height: 32px;
                border-bottom: 1px solid #DCDFE6;
                cursor: pointer;
            }

            p:hover {
                background: #366AFF;
                color: #ffffff;
            }

            p:last-child {
                border-bottom: none;
            }

            .ischecked {
                background: #366AFF;
                color: #ffffff;
            }
        }

        .knowledge-base {
            width: 150px;
            border-right: 1px solid #DCDFE6;
        }

        .intention {
            flex: 1;
            height: auto;
        }
    }

    .intent-footer {
        margin-top: 10px;
        text-align: right;
    }
}</style>