import { render, staticRenderFns } from "./displayWholeDrawer.vue?vue&type=template&id=492fe5ed&scoped=true&"
import script from "./displayWholeDrawer.vue?vue&type=script&lang=js&"
export * from "./displayWholeDrawer.vue?vue&type=script&lang=js&"
import style0 from "./displayWholeDrawer.vue?vue&type=style&index=0&id=492fe5ed&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492fe5ed",
  null
  
)

export default component.exports