<template>
<el-popover
    v-model="visibleIntent"
    placement="left"
    class="tagging-poprver"
    width="400">
    <p class="select-title">选择知识库</p>
    <el-select v-model="knowledgeBase" size="small" placeholder="请搜索或选择知识库">
        <el-option value="1" label="111" />
    </el-select>
    <el-input
        style="margin-top: 10px"
        v-model="intentionName"
        size="small"
        placeholder="请搜索或选择意图"
        suffix-icon="el-icon-search">
    </el-input>
    <div class="select-intent">
        <div class="knowledge-base">
            <p>推荐</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
        </div>
        <div class="intention">
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
            <p>我是可能的意图名称1</p>
        </div>
    </div>
    <div class="intent-footer">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button size="mini" type="primary">标注</el-button>
    </div>
    <!-- <span slot="reference" class="reset-tag">标注</span> -->
</el-popover>
</template>

<script>
export default {
    props: ['visibleIntent'],
    data() {
        return {
            // visibleIntent: false,
            knowledgeBase: '', //知识库
            intentionName: '', // 搜索意图名称
        }
    },
    methods: {
        close() {
            this.$emit('closePopover');
        }
    }
}
</script>

<style lang="less">
.tagging-poprver .el-popover{
    right: 50px;
}
.select-title{
    text-align: left;
    height: 32px;
    line-height: 32px;
}
.select-intent{
    display: flex;
    border: 1px solid #DCDFE6;
    // border-bottom: none;
    margin-top: 2px;
    border-radius: 4px;
    > div{
        max-height: 165px;
        overflow: auto;
        p{
            height: 32px;
            padding: 0 5px;
            line-height: 32px;
            border-bottom: 1px solid #DCDFE6;
            cursor: pointer;
        }
        p:hover{
            background: #F5F7FA;
            color: #606266;
        }
        p:last-child{
            border-bottom: none;
        }
    }
    .knowledge-base{
        width: 150px;
        border-right: 1px solid #DCDFE6;
    }
    .intention{
        flex: 1;
    }
}
.intent-footer{
    margin-top: 10px;
    text-align: right;
}
</style>