<template>
  <div id="action-confirm-value">
    <div class="simple-cell-satisfaction">
      <div class="title">
        <span>描述</span>
      </div>
      <div class="action-confirm-des">
        <div class="single-cell html-cell">
          <div class="cell-in-content">
            <ckeditor
              :editor="editorInline"
              :config="editorConfig"
              v-model="unit.content.template"
              @focus="onEditorFocus"
            ></ckeditor>
          </div>
          <div
            @click="templateConfigVisibleHandle('confirm-placeholder')"
            class="insert-param"
          >
            插入变量
          </div>
        </div>
      </div>
    </div>
    <div class="simple-cell-satisfaction">
      <div class="title">
        <span>如果用户确认</span>
      </div>
      <div class="jump-cell">
        <el-select
          v-model="unit.content.goToAction.scope"
          size="small"
          @change="jumpTypeValueChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in jumpTypeOptions"
            :key="item.value"
            :label="$t('common.' + item.value)"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="right-select">
          <el-select
            v-show="unit.content.goToAction.scope === 'NODE'"
            v-model="unit.content.goToAction.value"
            @change="nodeSelectChange"
            :popper-append-to-body="false"
            filterable
            size="small"
            placeholder="请输入选择名称"
          >
            <el-option
              v-for="item in nodeOptions"
              :popper-class="'pop_my' + item.classPop + item.parentNum"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-cascader
            v-show="unit.content.goToAction.scope === 'INTENT'"
            v-model="unit.content.goToAction.value"
            size="small"
            :options="intentTree4Radio"
            :props="{
              expandTrigger: 'hover',
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
          ></el-cascader>
          <!-- <el-select
            v-show="unit.content.goToAction.scope === 'INTENT'"
            v-model="unit.content.goToAction.value"
            filterable
            size="small"
            placeholder="请输入选择名称"
          >
            <el-option
              v-for="item in intentOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
function holder(data) {
  return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
  return holder("_" + webHooId + "." + key);
};

import {
  lastEditor,
  currentEditor,
  changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
  let lastIndex = key.lastIndexOf("]");
  return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
  return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
  name: "action-confirm-value",
  props: ["unit", "nodeOptions", "intentOptions", "unitIndex", "intentTree4Radio"],
  data() {
    return {
      jumpTypeOptions: [
        {
          value: "NODE",
          label: "跳转到节点",
        },
        {
          value: "INTENT",
          label: "跳转到意图",
        },
      ],
      editorDataInline: "",
      editorInline: CKEDITOR.InlineEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
        ],
      },
    };
  },
  methods: {
    jumpTypeValueChange() {
      this.unit.content.goToAction.value = "";
    },
    templateConfigVisibleHandle(type) {
      let param = { type: type };
      param.unitIndex = this.unitIndex;
      param.lastEditor = lastEditor;
      this.$emit("templateConfigVisibleHandle", param);
    },
    onEditorFocus(zh, editor) {
      changeEditor(editor);
    },
    addConfirmDataResponse() {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span-editable",
        options: {
          name: "单个webhook返回值",
          editable: false,
          data: webHookDataHolder(51, "data.name"),
        },
      });
    },

    addConfirmDataEntity(data, editable) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span-editable",
        options: {
          name: data.name,
          editable: editable,
          data: entityDataHolder(data.name),
          type: data.inputType,
          editable_class: "arsenal_icon arsenalkebianji"
        },
      });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#action-confirm-value {
  .simple-cell-satisfaction {
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 8px;
      text-align: left;
      margin-bottom: 8px;
    }
    .action-confirm-des {
      .html-cell {
        border-radius: 6px;
        display: flex;
        background-color: white;
        min-height: 50px;
        .cell-in-content {
          flex: auto;
          display: flex;
          justify-content: space-between;
          .ck-content {
            width: 100%;
          }
        }
        .insert-param {
          flex: none;
          width: 20px;
          background-color: #366AFF;
          color: white;
          cursor: pointer;
          writing-mode: vertical-lr;
          padding: 15px;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
}
</style>
