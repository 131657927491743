<template>
    <div id="entityManage">
        <div class="content-header">
            <div class="leftInfo cursor">
                <!--<span @click="goTo" class="gotoPage">-->
                    <!--<em class="el-icon-d-arrow-left"></em>-->
                    <!--{{activePageInfo.name}}-->
                <!--</span>-->
                <span class="headerName">{{activePageInfo.headerName}}</span>
                <div class="header-r-box">
                    <el-input
                        autocomplete="off"
                        new-password="true"
                        class="header-r-input"
                        size="medium"
                        placeholder="请输入实体名称"
                        v-model="activePageInfo.entityName"
                        @change="search"
                    >
                        <i
                                class="el-icon-search"
                                slot="suffix"
                                @click="search">
                        </i>
                    </el-input>
                    <el-input style="position: fixed; bottom: -999999px"></el-input>
                </div>
            </div>
        </div>
        <div class="botContent">
            <el-tabs id="left-sub-nav" tab-position="left" v-model="activeTab" @tab-click="tabClick">
                <el-tab-pane label="自定义实体" name="custom">
                    <entity-base :isDefault="false" ref="custom"></entity-base>
                </el-tab-pane>
                <el-tab-pane label="官方实体" name="general">
                    <entity-base :isDefault="true" ref="general"></entity-base>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import EntityBase from "./entityBase";
export default {
    data() {
        return {
            activePageInfo: {
                // 当前页跳转信息
                name: "返回全部实体",
                // gotoPageName: "botIndex",
                // botId: 1,
                headerName: "实体管理",
                entityName:''
            },
            activeTab:'custom'
        };
    },
    components: {
        EntityBase
    },
    methods: {
        search(value){
            // console.log("search",value,this.activePageInfo.entityName)
            this.$refs[this.activeTab].search(this.activePageInfo.entityName)
        },
        tabClick(){
            this.$refs[this.activeTab].getEntityList()
        }
    }
};
</script>
<style lang="less">
@import "./../../assets/less/entity/entityManage.less";
</style>



