<template>
    <div class="intent-node-name">
        <div
            class="handle"
            @click="closeIntentNodeList(skillIndex, classIndex, intentIndex)"
        >
            <span v-show="!JSON.parse(intentItem).show && !isPart">+</span>
            <span v-show="JSON.parse(intentItem).show && !isPart">-</span>
        </div>
        <div :id="'intentSpan' + JSON.parse(intentItem).id" class="name-show">
            <span class="node-type-show" id="intentNames">意图</span>
            <a
                href="javascript:void(0)"
                @click="
                    tobotIntentQa(
                        JSON.parse(intentItem).id,
                        JSON.parse(intentItem).name
                    )
                "
            >
                <span
                    v-if="JSON.parse(intentItem).name.length > 20"
                    :class="'intentSpan' + JSON.parse(intentItem).id"
                    >{{ JSON.parse(intentItem).name.slice(0, 20) }}...</span
                >
            </a>
            <a
                href="javascript:void(0)"
                @click="
                    tobotIntentQa(
                        JSON.parse(intentItem).id,
                        JSON.parse(intentItem).name
                    )
                "
            >
                <span
                    v-if="JSON.parse(intentItem).name.length <= 20"
                    :class="'intentSpan' + JSON.parse(intentItem).id"
                    >{{ JSON.parse(intentItem).name }}</span
                >
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: ["skillIndex", "classIndex", "intentIndex", "intentItem", "isPart"],

    data() {
        return {};
    },
    components: {},
    methods: {
        closeIntentNodeList(skillIndex, classIndex, intentIndex) {
            this.$emit(
                "closeIntentNodeList",
                skillIndex,
                classIndex,
                intentIndex
            );
        },
        tobotIntentQa(id, name) {
            this.$emit("tobotIntentQa", id, name);
        },
    },
};
</script>