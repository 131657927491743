<template>
    <div id="trans-qywx">
        <div class="mgt10 simple-cell-satisfaction-inline">
            <div class="title">
                <span>转人工文案</span>
            </div>
            <div class="action-confirm-des">
                <el-input
                    size="small"
                    v-model="unit.content.text[0]"
                    maxlength="200"
                    placeholder="请输入转人工文案"
                ></el-input>
            </div>
        </div>
        <div class="simple-cell-satisfaction">
            <div class="title">
                <span>如果人工客服不在线</span>
            </div>
            <div class="jump-cell">
                <el-select
                    style="width: 150px"
                    v-model="unit.content.offlineAction.scope"
                    size="small"
                    @change="jumpTypeValueChange($event)"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in jumpTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <div class="right-select">
                    <el-select
                        v-show="unit.content.offlineAction.scope === 'NODE'"
                        v-model="unit.content.offlineAction.value"
                        @change="nodeSelectChange"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择节点"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                    <el-cascader
                        v-show="unit.content.offlineAction.scope === 'INTENT'"
                        v-model="unit.content.offlineAction.value"
                        size="small"
                        :options="intentTree4Radio"
                        placeholder="请选择意图"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                    <el-input
                        autosize
                        v-show="
                            unit.content.offlineAction.scope ===
                            'SEND_MSG_TO_USER'
                        "
                        type="textarea"
                        v-model="unit.content.offlineAction.value"
                        maxlength="2000"
                        placeholder="请输入向用户发送内容"
                    ></el-input>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "trans-qywx",
    props: ["unit", "intentOptions", "nodeOptions", "intentTree4Radio"],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "向用户发送内容",
                },
            ],
        };
    },
    methods: {
        jumpTypeValueChange(e) {
            this.unit.content.offlineAction.value = "";
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
#trans-qywx {
    .simple-cell-satisfaction-inline {
        margin-bottom: 8px;
        .title {
            width: 70px;
            flex: none;
            text-align: left;
            line-height: 32px;
            height: 32px;
        }
        .action-confirm-des {
            flex: auto;
            display: flex;
            justify-content: flex-start;
            .el-input {
                width: 100%;
            }
        }
    }
    .mgt10 {
        margin-top: 12px;
    }
}
</style>