<template>
    <div class="ai-config-helper" v-if="showAiConfigHelper">
        <div class="ai-config-helper-header">
            <section class="echatIcon"  v-if="isE()">
                <i class="iconfont guoran-askbotdandutubiao2"></i>
                <i class="iconfont guoran-eBot-wenzi"></i>
            </section>  
            <i v-else class="iconfont guoran-askbot1"></i>
            <div class="right">
                <el-checkbox v-model="checked" @change="changeCheck">{{$t('botIntent.aiConfigHelper.automatic')}}</el-checkbox>
                <i class="iconfont guoran-tongyichicun-suoxiao" @click="close"></i>
            </div>
        </div>
        <div class="ai-config-helper-tips" v-if="recommendIntentList.length > 0">{{$t('botIntent.aiConfigHelper.tip1')}}</div>
        <div class="ai-config-helper-intent-list">
            <template v-if="recommendIntentList.length > 0">
                <div 
                    class="ai-config-helper-intent-item" 
                    v-for="(item,index) in recommendIntentList" 
                    :key="index">
                    <div class="intent-name-box">
                        <span class="intent-name two-column-ellipsis">{{item.relatedIntent}}</span>
                        <div class="add-intent-btn"  @click="addIntent(item)">{{$t('botIntent.aiConfigHelper.addIntent')}}</div>
                    </div>
                    <div class="example-question-box">
                        <span class="example-question" v-if="item.relatedExampleQuestion.length < 18">{{item.relatedExampleQuestion}}</span>
                        <el-tooltip class="item" effect="dark" :content="item.relatedExampleQuestion" placement="top-start" v-else :open-delay="1000">
                            <span class="example-question one-column-ellipsis">{{item.relatedExampleQuestion}}</span>
                        </el-tooltip>
                        <div class="ignore-btn" @click="ignore(item)">{{$t('botIntent.aiConfigHelper.ignore')}}</div>
                    </div>
                </div>
            </template>
            <div class="no-data" v-else>
                <img src="../../../assets/images/no-data.png" alt="">
                <div class="tips">{{$t('botIntent.aiConfigHelper.tip2')}}</div>
            </div>
        </div>
        <errorDialog @closeEvent="ignoreIntentPopup = false" v-if="ignoreIntentPopup">
            <div slot="popup-container" class="popup-container">
                <div class="delete-title">{{$t('botIntent.aiConfigHelper.ignoreIntentTitle')}}</div>
                <div>{{$t('botIntent.aiConfigHelper.ignoreIntentTip')}}</div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="ignoreIntentPopup = false"
                    size="small"
                    style="font-size:14px "
                    round
                    plain>
                    {{$t('common.cancel')}}
                </el-button>
                <el-button
                    @click="ignoreIntentFn"
                    round
                    style="background-color: #fe5965;color: white;font-size:14px;border-color:#fe5965"
                    size="small">
                    {{$t('botIntent.aiConfigHelper.ignore')}}
                </el-button>
            </div>
        </errorDialog>
    </div> 
</template>

<script>
import errorDialog from "../../../components/errorDialog.vue";
export default {
    components:{errorDialog},
    data(){
        return {
            checked:false,
            recommendIntentList:[],
            ignoreIntentPopup:false,
            timer:null,
            showAiConfigHelper:false,
            maxId:"", // 返回的推荐列表第一条记录的id，用于下次查询只查询最新的
            currentItem:{},
            isAddIntentOpen:false,
            showType:'', // '' 默认  addIntent 添加意图后的打开  'clickOpen' 点击打开
            oldMaxId:""
        }
    },
    props:{
        apiKey:{
            type:String,
            default:''
        }
    },
    watch:{
        showAiConfigHelper(n){
            // this.clearTimeoutFN();
            if(n){
                // this.getRecommendIntent(this.showType);
                
            } 
        },
        checked(n){
            this.clearTimeoutFN();

            if(n){
                // this.getRecommendIntent(this.showType);
                // if(this.recommendIntentList.length > 0){

                // } else {
                //     this.showType = '';
                //     this.getRecommendIntent(this.showType);
                // }
            } 
        }
    },
    mounted(){
        setTimeout(() => {
            this.maxId = '';
            this.oldMaxId = ''
            this.getRelatedIntentOpen();
        },1000)
    },
    beforeDestroy(){
        this.clearTimeoutFN();
    },
    methods:{
        clearTimeoutFN(){
            clearTimeout(this.timer);
        },
        // 获取推荐意图
        getRecommendIntent(type){
            let url = '';
            if(this.maxId !== ''){
                url = this.requestUrl.intent.getRelatedIntent + '?apiKey=' + this.apiKey + '&maxId=' + this.maxId;
            } else {
                url = this.requestUrl.intent.getRelatedIntent + '?apiKey=' + this.apiKey;
            }
            this.FetchGet(url).then(res => {
                if (res.code === "0") {
                    res.data.list.forEach(item => {
                        item.relatedExampleQuestion = item.relatedExampleQuestion ? item.relatedExampleQuestion : ''
                    })
                    if(this.maxId !== ''){
                        this.recommendIntentList = [...res.data.list,...this.recommendIntentList];
                    } else {
                        this.recommendIntentList = res.data.list;
                    }
                    if(res.data.list.length > 0){
                        this.oldMaxId = this.maxId;
                        this.maxId = res.data.list[0].id;
                    }
                    if(this.recommendIntentList.length === 0 && (this.showType === '' || type === '')){
                        this.showAiConfigHelper = false;
                    } 
                    // else if (this.recommendIntentList.length > 0 && (this.showType === '' || type === '')) {
                    //     debugger
                    //     this.showAiConfigHelper = true;
                    // }
                     if (res.data.list.length > 0 && (this.showType === '' || type === '')) {
                        if(this.showAiConfigHelper || this.checked){
                            this.showAiConfigHelper = true;
                        }
                        this.$nextTick(() => {
                            if(this.showAiConfigHelper){
                                document.querySelector('.ai-config-helper-intent-list').scrollTop = 0;
                            }
                        })
                    }
                    // && this.checked
                    if((type === 'addIntent' || this.showType === 'addIntent') ){
                        if(res.data.list.length > 0){
                            if(this.oldMaxId !== this.maxId && this.showType === 'addIntent'){
                                this.isAddIntentOpen = true;
                                this.showAiConfigHelper = true;
                                this.$nextTick(() => {
                                    if(this.showAiConfigHelper){
                                        document.querySelector('.ai-config-helper-intent-list').scrollTop = 0;
                                    }
                                })
                                this.showType = '';
                            } else  {
                                this.showType = 'addIntent';
                            }
                        } else {
                            this.showType = 'addIntent';
                        }
                    }
                    this.timerFn(this.showType);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        timerFn(type){
            this.clearTimeoutFN();
            this.timer = setTimeout(() => {
                this.getRecommendIntent(type);
            },3000)
        },
        // 获取自动弹窗
        getRelatedIntentOpen(){
            this.FetchGet(this.requestUrl.intent.relatedIntentOpen).then(res => {
                if (res.code === "0") {
                    // this.showAiConfigHelper = res.data;
                    this.checked = res.data;
                    this.showType = '';
                    console.log('获取自动弹窗');
                    this.clearTimeoutFN();
                    this.getRecommendIntent(this.showType);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        close(){
            this.showAiConfigHelper = false;
        },
        changeCheck(){
            this.FetchPost(this.requestUrl.intent.setRelatedIntentOpen + '?autoOpen=' + this.checked).then(res => {
                if (res.code === "0") {
                    this.$message.success(this.checked ? this.$t('botIntent.aiConfigHelper.automaticMsg') : this.$t('botIntent.aiConfigHelper.cancelAutomaticMsg'));
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        addIntent(item){
            this.currentItem = item;
            this.$emit('addIntent','AIConfigHelper',{
                intentName:item.relatedIntent,
                exampleQuestion:item.relatedExampleQuestion,
                id:item.id
            })
        },
        // 忽略
        ignore(item){
            this.currentItem = item;
            this.ignoreIntentPopup = true;
        },
        ignoreIntentFn(item){
            this.FetchPost(this.requestUrl.intent.ignoreRelated + '?id=' + this.currentItem.id).then(res => {
                console.log(res);
                if (res.code === "0") {
                    this.$message.success(this.$t('botIntent.aiConfigHelper.ignoreMsg'))
                    this.ignoreIntentPopup = false;
                    this.handlerData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        handlerData(){
            this.recommendIntentList.forEach((item,index) => {
                if(item.id === this.currentItem.id){
                    this.recommendIntentList.splice(index,1);
                }
            })
            if(this.maxId === this.currentItem.id){
                this.maxId = this.recommendIntentList[0].id;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.ai-config-helper{
    width: 322px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57,63,79,0.21);
    border-radius: 5px;
    position: fixed;
    bottom: 25px;
    right: 50px;
    padding: 14px 14px 0;
    .ai-config-helper-header{
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .echatIcon {
            display: flex;
            align-items: center;
            .guoran-askbotdandutubiao2 {
                font-size: 24px;
                margin-right:3px ;
            }
        }
        i{
            color: #366AFF;
        }
        .guoran-askbot1{
            font-size: 28px;
        }
        .right{
            text-align: center;
            /deep/.el-checkbox{
                margin-right: 12px;
                .el-checkbox__label{
                    color: #A9B3C6;
                    font-size: 12px;
                }
                .el-checkbox__inner{
                    border-color: #B6BECE;
                }
                &.is-checked{
                    .el-checkbox__inner{
                        border-color: #366AFF;
                    }
                }
                
            }
        }
    }
    .ai-config-helper-tips{
        color: #A9B3C6;
        margin-bottom: 12px;
        text-align: left;
        height: 15px;
    }
    .ai-config-helper-intent-list{
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior:smooth; 
        .ai-config-helper-intent-item{
            background: #F5F7FB;
            border-radius: 5px;
            margin-bottom: 14px;
            padding: 6px 14px 12px;
            font-size: 14px;
            .intent-name-box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                line-height: 20px;
                .intent-name{
                    color: #000000;
                    margin-right: 10px;
                    text-align: left;
                }
                .add-intent-btn{
                    flex-shrink: 0;
                    width: 70px;
                    height: 29px;
                    background: #366AFF;
                    border-radius: 14px;
                    font-size: 12px;
                    color:#fff;
                    text-align: center;
                    line-height: 29px;
                    cursor: pointer;
                }
            }
            .example-question-box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 13px;
                .example-question{
                    font-size: 12px;
                    color: #A9B3C6;
                    margin-right: 10px;
                    text-align: left;
                }
                .ignore-btn{
                    color: #666666;
                    font-size: 12px;
                    cursor: pointer;
                    flex-shrink: 0;
                    width: 70px;
                    text-align: center;
                }
            }

        }
        .no-data{
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
                width: 270px;
            }
            .tips{
                color: #A9B3C6;
                font-size: 12px;
            }
        }
    }
    /deep/#delete-popup-assembly{
        width: 510px !important;
    }
}
</style>