<template>
    <popup class="work_order_add_intent" @closeEvent="close">
        <div slot="popup-name">{{$t('trainList.newAddIntent')}}</div>
        <!-- <div slot="popup-tip">将工单问题标注到意图中</div> -->
        <div slot="popup-con">
            <div class="attention_diagram_content">
                <div class="intention_content">
                    <p>
                        <span class="title">{{$t('botIntent.intentName')}}<span style="color:red;">*</span></span>
                    </p>
                    <el-input v-model="intent.name" size="small" placeholder="请输入"></el-input>
                    <p>
                        <span class="title">{{$t('botIntent.intentDes')}}</span>
                    </p>
                    <el-input v-model="intent.description" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }"
                        placeholder="请输入"></el-input>
                    <p>
                        <span class="title">{{$t('botIntent.exampleQuestion')}}<span style="color:red;">*</span></span>
                    </p>
                    <el-input v-model="intent.questionText" size="small" placeholder="请输入"></el-input>
                    <p>
                        <span class="title">{{$t('botIntent.belongBot')}}<span style="color:red;">*</span></span>
                    </p>
                    <el-cascader v-model="intent.classification" style="width: 100%" size="small" :props="{
                        emitPath: false,
                        lazy: true,
                        lazyLoad: cascaderLazyLoad
                    }" :options="classificationOption" ref="cascader" @expand-change="handleChange">
                        <template slot-scope="{ node, data }">
                            <div class="cascader_label">
                                <span>{{ data.label }}</span>
                            </div>
                        </template>
                    </el-cascader>
                    <p>
                        <span class="title">{{$t('botIntent.answer')}}</span>
                    </p>
                    <el-radio-group v-model="intent.answerType">
                        <el-radio :label="0">{{$t('botIntent.richText')}}</el-radio>
                        <el-radio :label="1">{{$t('botIntent.text')}}</el-radio>
                    </el-radio-group>
                    <ckeditor v-if="intent.answerType === 0" v-model="answerHtmlText" :editor="ckeditor.editor"
                        :config="ckeditor.editorConfig" @ready="(editor) => { return currentEditor = editor }"></ckeditor>
                    <el-input v-if="intent.answerType === 1" v-model="answerText" type="textarea"
                        :autosize="{ minRows: 8, maxRows: 8 }" placeholder="请输入"></el-input>
                </div>
                <div class="work_order_desc_content" v-if="checkedIds.length == 0 && Object.keys(workOrderData).length != 0">
                    <workOrderDescContent :orderId="workOrderData.workOrderId" :checked="true" @aiCreate="aiCreate">
                    </workOrderDescContent>
                </div>
                <div class="ai_created_intent" v-if="checkedIds.length == 0">
                    <p>
                        <span class="title">{{$t('botIntent.extractIntentName')}}</span>
                        <span class="title_btn" @click="intent.name = aiIntentName">{{$t('botIntent.use')}}</span>
                    </p>
                    <el-input v-model="aiIntentName" size="small" :placeholder="$t('botIntent.toBeExtracted')" readonly></el-input>
                    <p>
                        <span class="title">{{$t('botIntent.extractExp')}}</span>
                        <span class="title_btn" @click="intent.questionText = aiQuestion">{{$t('botIntent.use')}}</span>
                    </p>
                    <el-input v-model="aiQuestion" size="small" :placeholder="$t('botIntent.toBeExtracted')" readonly></el-input>
                    <p>
                        <span class="title">{{$t('botIntent.extractAnswer')}}</span>
                        <span class="title_btn" @click="setAiAnswer">{{$t('botIntent.use')}}</span>
                    </p>
                    <el-input v-model="aiAnswer" type="textarea" :autosize="{ minRows: 8, maxRows: 8 }" size="small"
                        :placeholder="$t('botIntent.toBeExtracted')" readonly></el-input>
                </div>
            </div>
            <!-- 可能与已有的以下意图相似 -->
            <popup @closeEvent="closeSimilIntentDialogVisible" v-if="similIntentDialogVisible" :haveTips="false"
                id="same-intnet-go-look">
                <div slot="popup-name">{{$t('addIntent.prompt')}}</div>
                <div slot="popup-con">
                    <div class="intent-link-title">
                        {{$t('addIntent.promptTip')}}
                    </div>
                    <div>
                        <div v-for="intent in similIntentList" :key="intent.id" class="intent-link-cell">
                            <span class="intent-link-cell-name">{{ intent.name }}{{ intent.intentID }}</span>
                            <el-button @click="gotoNewIntentPage(intent)" type="text">{{$t('addIntent.toView')}}</el-button>
                        </div>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button @click="closeSimilIntentDialogVisible" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
                    <el-button type="primary" @click="addIntentExiSimil" class="confirm-btn">{{$t('botIntent.continueCreate')}}</el-button>
                </div>
            </popup>
        </div>
        <div slot="dialog-footer">
            <el-button @click="close" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
            <el-button class="confirm-btn" type="primary" @click="sureAddIntent" round
                style="padding:0 !important;" :disabled="addLoding">
                <i class="el-icon-loading" v-if="addLoding" style="margin:5px;"></i>{{$t('addIntent.saveMarked')}}
            </el-button>
        </div>

    </popup>
</template>

<script>
import Popup from '../../../components/popup.vue';
import CKEDITOR from "ckeditor";
import workOrderDescContent from './workOrderDescContent.vue';
import { MyCustomUploadAdapterPlugin } from '@/views/fissionMarketing/components/ckeditor.js'
import { requestUrl } from '../../../api/requestUrl'
import TurndownService from 'turndown';
export default {
    components: {
        Popup,
        workOrderDescContent
    },
    props: ["checkedIds"],
    data () {
        return {
            aiIntentName: '',
            answerType: 0,
            currentEditor: null,
            editorInline: CKEDITOR.InlineEditor,
            intent: {
                name: '',
                questionText: '',
                description: '',
                answerType: 0,
            },
            answerText: '',
            answerHtmlText: '',
            ckeditor: {
                currentCallBack: () => { }, // 当前回调
                editor: CKEDITOR.ClassicEditor,
                editorConfig: {
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    toolbar: ["imageUpload"],
                    askPluginListener: [
                        {
                            event: "BUTTON",
                            process: this.beforeButtonInsert,
                        },
                    ], // 注册监听
                },
            },
            botList: [],
            classificationOption: [],
            currentClassify: {},
            currentClassifyBot: {},
            similIntentDialogVisible: false,
            similIntentList: [],
            workOrderData: {},
            fullPath: '',
            addLoding:false,
        }
    },
    mounted () {
        this.getBotList()
    },
    methods: {
        setAiAnswer () {
            if (this.intent.answerType === 0) {
                this.answerHtmlText = this.aiAnswer
            } else {
                this.answerText = this.aiAnswer
            }
        },
        gotoNewIntentPage (item) {
            let routeUrl = this.$router.resolve({
                path: "/main/intentQa",
                query: {
                    bid: this.currentClassifyBot.value,
                    bName: this.currentClassifyBot.label,
                    intentId: item.id,
                    intentName: item.name,
                    refApiKey: this.currentClassifyBot.refApiKey,
                    isDefault: item.isDefault,
                    apiKey: this.currentClassifyBot.apiKey
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                "/#" +
                routeUrl.resolved.fullPath,
                "_blank"
            );
        },
        // 关闭相似意图
        closeSimilIntentDialogVisible () {
            this.similIntentDialogVisible = false;
            this.addIntentDiaVisible = true;
        },
        cascaderLazyLoad (node, resolve) {
            if (node.level == 1) {
                this.getSkillList(node.data.value, resolve)
            }
            if (node.level == 2) {
                this.getTypeList(node.data.value, resolve)
            }
        },
        getBotList () {
            this.$http.get("/api/bot/list?page=1&size=100").then(res => {
                if (res.data.code == 0) {
                    this.classificationOption = res.data.data.list.map(item => {
                        return {
                            apiKey: item.apiKey,
                            value: item.id,
                            label: item.name,
                            children: []
                        }
                    })

                }
            })
        },
        // 获取技能list
        getSkillList (bid, resolve) {
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: bid,
                filterInvisible: false,
            }).then((res) => {
                if (res.code === "0") {
                    let children = res.data.map((res) => {
                        return { label: res.name, value: res.id, children: [] };
                    });
                    resolve(children.filter(item => { return item.label != '特殊规则' }))
                    // this.classificationOption = JSON.parse(JSON.stringify(list))
                    // this.getTypeList(this.classificationOption[0].value);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取分类list
        getTypeList (skillId, resolve) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: skillId,
            }).then((res) => {
                if (res.code === "0") {
                    let children = res.data.map((res) => {
                        return { label: res.name, value: res.id, leaf: true };
                    });
                    resolve(children)
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        handleChange (value) {
            // this.getTypeListChildren(value[0]);
        },
        getTypeListChildren (id, type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: id,
            }).then((res) => {
                if (res.code === "0") {
                    this.classificationOption.forEach((item, index) => {
                        if (item.value === id) {
                            let test = res.data.map((res) => {
                                return { label: res.name, value: res.id };
                            });
                            item.children = JSON.parse(JSON.stringify(test));
                        }
                    })
                }
            });
        },
        beforeButtonInsert (data) {
            console.log(data, 'datadata');
        },
        close () {
            this.$emit("closeEvent", false)
        },
        sureAddIntent () {
            // 获取选中节点
            let node = this.$refs.cascader.getCheckedNodes()[0]
            if (!node || node.length == 0) {
                this.$message.warning(this.$t('botIntent.intentTypeRequired'))
                return
            }
            if (!this.intent.name) {
                this.$message.warning(this.$t('botIntent.intentNameRequired'))
                return
            }
            if (!this.intent.questionText) {
                this.$message.warning(this.$t('botIntent.expRequired'))
                return
            }
            this.currentClassify = node.data
            this.currentClassifyBot = node.pathNodes[0].data
            this.fullPath = ''
            for (let index = 0; index < node.pathNodes.length; index++) {
                let n = node.pathNodes[index].data
                this.fullPath = this.fullPath + (this.fullPath != '' ? '/' + n.label : n.label)
            }
            // 先交验相似意图
            let intentName = encodeURIComponent(this.intent.name)
            let exampleQuestion = encodeURIComponent(this.intent.questionText)
            let description = encodeURIComponent(this.intent.description)
            this.addLoding = true
            this.FetchGet(requestUrl.intent.getSimilarIntent, {
                name: intentName,
                description: description,
                exampleQuestion: exampleQuestion,
                tid: this.currentClassify.value,
                apiKey: this.currentClassifyBot.apiKey,
            }).then((res) => {
                if (res.code === "0") {
                    if (res.data.length == 0) {
                        this.addIntentExiSimil();
                    } else {
                        this.similIntentList = res.data;
                        this.similIntentDialogVisible = true;
                        this.addLoding = false
                    }
                    // this.activeClass = this.classification;
                } else {
                    this.$message.error(res.message);
                    this.addLoding = false
                }
            })
            .finally(() => {
                this.addIntentLoading = false;
            });
        },
        addIntentExiSimil () {
            this.FetchPost(this.requestUrl.intent.addIntent, {
                name: this.intent.name,
                exampleQuestion: this.intent.questionText,
                isShare: 0,
                tid: this.currentClassify.value,
                apiKey: this.currentClassifyBot.apiKey,
                templateId: '',
                config: '[]',
            }).then((res) => {
                if (res.code === "0") {
                    // 查询到当前创建的当前节点信息
                    this.seachIntentNode(res.data)
                } else {
                    this.$message.error(res.message);
                    this.addLoding = false
                }
            });
        },
        seachIntentNode (intentId) {
            // 查询到当前节点后更新名称 并 保存答案
            this.$http.get(`/api/v1/dialog/process?intentId=${intentId}`).then(res => {
                if (res.data.code == 0) {
                    this.updateIntentName(res.data.data.initNode, intentId)
                    if (this.intent.answerType === 0 && this.answerHtmlText) {
                        this.addIntentAnswer(res.data.data.initNode, intentId, "answer_rich_text")
                    }
                    if (this.intent.answerType === 1 && this.answerText) {
                        this.addIntentAnswer(res.data.data.initNode, intentId, "answer_text")
                    }
                    this.markIntent(intentId);
                }
            })
        },
        updateIntentName (node, intentId) {
            this.$http.put(`/api/v1/dialog/process/node/name/${node.id}`, {
                intentId: intentId,
                name: '回复'
            })
        },
        addIntentAnswer (node, intentId, type) {
            let params = this.setParams(type)
            params.intentId = intentId
            this.$http.put("/api/v1/dialog/process/node/action/" + node.id, params).then(res => {
                if (res.data.code == 0) {
                    // 在 意图 和 回复 标注完成后再去完成标注
                    // this.markIntent(intentId);
                }
            })
        },
        markIntent (intentId) {
            this.$http.put("/api/intent/workOrderToKnowledge/mark", {
                ids: this.checkedIds.length == 0 ? [this.workOrderData.id] : this.checkedIds,
                tagIntentId: intentId,
                tagIntentName: this.intent.name,
                tagBotId: this.currentClassifyBot.value,
                tagIntentFullPath: this.fullPath
            }).then(res => {
                if (res.data.code == '0') {
                    this.addLoding = false
                    this.$message.success(this.$t('trainList.annotationSuccess'))
                    this.$emit("closeEvent", false)
                    this.$emit("changeSuccess")
                    // this.initDate()
                }
            })
        },
        initDate () {
            this.$set(this, 'intent', {
                name: '',
                questionText: '',
                description: '',
                answerType: 0,
            })
            this.aiIntentName = ''
            this.answerType = 0
            this.answerText = ''
            this.answerHtmlText = ''
            this.currentClassify = {}
            this.currentClassifyBot = {}
            this.similIntentDialogVisible = false
            this.similIntentList = []
            this.workOrderData = {}
            this.fullPath = ''
            this.botList = []
        },
        setParams (type) {
            let params = null
            const turnDownService = new TurndownService();
            if (type == "answer_rich_text") {
                params = {
                    actionList: [
                        {
                            conditionList: [],
                            content: {
                                html: this.answerHtmlText,
                                version: 1,
                                recommend: {
                                    "list": [
                                        {
                                            "id": "",
                                            "type": 0,
                                            "text": "",
                                            "ext": {
                                                "intentValue": [

                                                ]
                                            }
                                        }
                                    ],
                                    "status": false,
                                    "description": ""
                                },
                                markdown: turnDownService.turndown(this.answerHtmlText)
                            },
                            "type": "answer_rich_text",
                            "effectiveTime": {
                                "range": "PERMANENT",
                                "startTime": "",
                                "endTime": "",
                                "repeat": [
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7"
                                ],
                                "filterHolidays": true,
                                "balanceHolidays": true,
                                "timeRange": [
                                    "2019-12-14T16:00:00.000Z",
                                    "2100-12-15T15:59:59.000Z"
                                ]
                            }
                        }
                    ],
                    intentId: ""
                }
            }
            if (type == "answer_text") {
                params = {
                    "actionList": [
                        {
                            "conditionList": [],
                            "content": {
                                "list": [
                                    `<p>${this.answerText}</p>`
                                ],
                                "extInfos": [
                                    {
                                        "expectedIntention": "",
                                        "notAsExpectedReply": ""
                                    }
                                ],
                                "version": 1,
                                "recommend": {
                                    "list": [
                                        {
                                            "id": "",
                                            "type": 0,
                                            "text": "",
                                            "ext": {
                                                "intentValue": [

                                                ]
                                            }
                                        }
                                    ],
                                    "status": false,
                                    "description": ""
                                }
                            },
                            "type": "answer_text",
                            "effectiveTime": {
                                "range": "PERMANENT",
                                "startTime": "",
                                "endTime": "",
                                "repeat": [
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7"
                                ],
                                "filterHolidays": true,
                                "balanceHolidays": true,
                                "timeRange": [
                                    "2019-12-14T16:00:00.000Z",
                                    "2100-12-15T15:59:59.000Z"
                                ]
                            }
                        }
                    ],
                    "intentId": ""
                }
            }
            return params
        },
        handleNodeClick (data, bot) {
            if (!data.id.includes('S')) {
                this.currentClassify = data
                this.currentClassifyBot = bot
            }
        },
        aiCreate (ticketGenerateQa) {
            let data = ticketGenerateQa
            console.log(data,'datadata');
            this.aiIntentName = data[0].question
            this.aiQuestion = data[0].question
            this.aiAnswer = data[0].answer
        }
    }
}
</script>

<style lang="less" scoped>
.work_order_add_intent {
    text-align: left;

    /deep/#popup-assembly {
        width: 1100px !important;

        .popup-header {
            padding-bottom: 12px;
        }
    }

    .attention_diagram_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 490px;


        .intention_content {
            width: 320px;
            height: 100%;
            background: #FBFCFD;
            padding: 12px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;
            flex: 1 0;

            // margin-right: 15px;
            p {
                display: flex;
                color: #616161;
                margin-top: 10px;
                height: 30px;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0 2px;

                .title_btn {
                    font-size: 12px;
                    color: #366AFF;
                    background: #FFFFFF;
                    border-radius: 30px;
                    border: 1px solid #366AFF;
                    padding: 1px 8px;
                    cursor: pointer;
                }
            }

            /deep/.ck {
                .ck-content {
                    min-height: 150px;
                }
            }

        }

        .work_order_desc_content {
            width: 320px;
            height: 100%;
            margin: 0 12px;
            // margin-left: 15px;
        }

        .ai_created_intent {
            width: 320px;
            height: 100%;
            background: #FBFCFD;
            border-radius: 3px;
            padding: 12px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;

            p {
                display: flex;
                color: #616161;
                margin-top: 10px;
                height: 30px;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0 2px;

                .title_btn {
                    font-size: 12px;
                    color: #366AFF;
                    background: #FFFFFF;
                    border-radius: 30px;
                    border: 1px solid #366AFF;
                    padding: 1px 8px;
                    cursor: pointer;
                }
            }
        }
    }

    .el-dialog__header {
        padding: 0 !important;
    }
}

.intent-link-title {
    margin: 16px 0;
    text-align: left;
    font-weight: 800;
}

.intent-link-cell {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
}
</style>
<style lang="less">
.add_intention_tree {
    height: 100%;
    width: 100%;
    margin-top: 7px;
    overflow: hidden;
    overflow-y: auto;

    .bot_list_item {
        width: 100%;
        height: auto;

        .bot_label {
            display: flex;
            align-items: center;
            height: 32px;
            cursor: pointer;
            color: #616161;

            i {
                margin: 8px;
            }

            .expanded {
                transform: rotate(90deg)
            }
        }
    }

    .bot_content {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding-left: 12px;

        .el-tree-node__content {
            height: 32px;
            cursor: pointer;

            .custom-tree-node {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer !important;

                i {
                    margin-right: 8px;
                }
            }
        }
    }
}
</style>