<template>
    <div class="work_order_desc_list">
        <div class="message_content" :style="{ paddingBottom: checked ? '40px' : '0' }">
            <div class="message" v-for="message in messageList" :class="message.type == 'reply' ? '' : 'msg_right'"
                :key="message.id" :style="{
                    justifyContent: message.type != 'reply' && checked && 'space-between'
                }">
                <div class="checkbox" v-if="checked">
                    <!-- checked &&  -->
                    <el-checkbox v-if="message.type != 'reply'" :value="true" disabled></el-checkbox>
                    <el-checkbox v-else v-model="message.checked" @change="checkChange"></el-checkbox>
                </div>
                <div class="msg_body">
                    <div v-html="message.text"></div>
                    <div class="msg_file_body" v-for="(file, fileIndex) in message.files" :key="fileIndex">
                        <div class="attch-item-left">
                            <span @click.stop="downLoad(file, file.name)"><i
                                    class="iconfont guoran-tongyichicun-xiazai"></i></span>
                            <img src="@/assets/images/fileIcon/video.png" v-if="setSrc(file.name) == 'video'" />
                            <img src="@/assets/images/fileIcon/image.png" v-else-if="setSrc(file.name) == 'image'" />
                            <img src="@/assets/images/fileIcon/txt.png" v-else-if="setSrc(file.name) == 'txt'" />
                            <img src="@/assets/images/fileIcon/word.png" v-else-if="setSrc(file.name) == 'word'" />
                            <img src="@/assets/images/fileIcon/pdf.png" v-else-if="setSrc(file.name) == 'pdf'" />
                            <img src="@/assets/images/fileIcon/ppt.png" v-else-if="setSrc(file.name) == 'ppt'" />
                            <img src="@/assets/images/fileIcon/excel.png" v-else-if="setSrc(file.name) == 'excel'" />
                            <i v-else class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"
                                style="color:#366AFF;margin: 5px;"></i>
                            <!-- @click="openDialog(file, file.name)" -->
                            <div class="fileName" @click="openDialog(file, file.name)" v-if="file">
                                <template v-if="typeof file === 'string'">
                                    <template v-if="file.indexOf('[') !== -1">
                                        {{ JSON.parse(file)[index] }}
                                    </template>
                                </template>
                                <template v-else>
                                    {{ file.name }}
                                </template>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="tips" v-if="checked">
            <div class="ai_create" @click="aiCreate" v-if="!aiLoading">
                {{$t('trainList.largeModelExtraction')}}
            </div>
            <div class="ai_loading" v-if="aiLoading">
                <i class="iconfont guoran-Loading"></i>{{$t('trainList.extracting')}}
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="800px" :append-to-body="true" :close-on-click-modal="false"
            custom-class="look-dialog">
            <template v-if="fieldType === 'VIDEO'">
                <video :src="dialogUrl" controls></video>
            </template>
            <template v-else-if="fieldType === 'IMAGE'">
                <img :src="dialogUrl" />
            </template>
            <div v-else-if="fieldType === 'txt' || fieldType === 'doc'" style="height: 60vh">
                <embed :src="dialogUrl" width="100%" height="100%" />
            </div>
            <div v-else style="height: 60vh">
                <iframe :src="dialogUrl" width="100%" height="100%"></iframe>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["orderId", "checked"],
    data () {
        return {
            messageList: [],
            remarks: [],
            aiLoading: false,
            dialogUrl: "",
            dialogVisible: false,
            fieldType: "",
        }
    },
    mounted () {
        this.getRelyList()
    },
    methods: {
        //根据上传类型设置图标
        setSrc (fileName) {
            let file = ""
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            if (fileType === '.mp4' || fileType === '.MP4') {
                file = 'video'
            }
            else if (fileType === '.jpg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG') {
                file = 'image'
            }
            else if (fileType == '.txt') {
                file = 'txt'
            }
            else if (fileType == '.ppt' || fileType == '.pptx') {
                file = 'ppt'
            }
            else if (fileType == '.doc' || fileType == '.docx') {
                file = 'word'
            }
            else if (fileType == '.xls' || fileType == '.xlsx') {
                file = 'excel'
            }
            else if (fileType == '.pdf') {
                file = 'pdf'
            }
            return file
        },
        getRelyList () {
            this.$http.get("/bearing-api/order-change-log/findWorkOrderAndReply?workOrderId=" + this.orderId).then(res => {
                if (res.data.code == 0) {
                    console.log(res, 'resres');
                    let workOrderDetail = res.data.data.workOrderDetail
                    let workOrderChangeLogs = res.data.data.workOrderChangeLogs
                    this.messageList.push({
                        files: workOrderDetail.extInfo && workOrderDetail.extInfo.attachmentList ? workOrderDetail.extInfo.attachmentList : [],
                        text: workOrderDetail.description,
                        type: 'desc'
                    })
                    for (let index = workOrderChangeLogs.length - 1; index >= 0; index--) {
                        let item = workOrderChangeLogs[index]
                        if (item.newVal && !item.deleted) {
                            if (item.newVal.changeType == "REPLY" && item.newVal._private) {
                                this.setNewValIsPrivate(item, item.operatorId)
                            }
                            if (item.respondent && item.respondent.changeType == 'REPLY') {
                                item.respondent.newVal.isPrivate = false
                            }
                            if (!this.messageList.some(e => e && e.id == item.id) && !item.newVal.isPrivate) {
                                item.newVal.content = item.newVal.content ? item.newVal.content.replace(/img src/g, "img onclick='previewImage(this)' src") : ''
                                this.messageList.push({
                                    files: item.newVal.extInfo && item.newVal.extInfo.attachments ? item.newVal.extInfo.attachments : [],
                                    text: this.getReplayText(item.newVal.content, item.newVal.passwordStatus),
                                    type: 'reply',
                                    checked: false
                                })
                            }
                        }
                    }
                }
            })
        },
        setNewValIsPrivate (el, operatorId) {
            let _uid = localStorage.getItem('_uid')
            // 默认都不是 私密状态
            el.newVal.isPrivate = false
            // 先判断是不是我回复，再判断是不是回复我
            if (operatorId != _uid) {
                //当前不是我回复的
                el.newVal.isPrivate = true
            }
            // 不是我回复的，
            if (el.newVal.isPrivate && el.respondent && el.respondent.operatorId) {
                // 是回复我的
                if (el.respondent.operatorId == _uid) {
                    el.newVal.isPrivate = false
                }
            }
            // 不是我回复的 也不是回复我的，判断我是不是被回复的受理人
            if (el.newVal.isPrivate && el.newVal.replyConfig && el.newVal.replyConfig.extInfo && el.newVal.replyConfig.extInfo.list && el.newVal.replyConfig.extInfo.list.length > 0) {
                el.newVal.isPrivate = !el.newVal.replyConfig.extInfo.list.some(item => { return item.memberId == _uid })
            }
        },
        getReplayText (content, type) {
            let msg = content ? content.replaceAll("&nbsp;", "") : ''
            if (msg.includes('class="ask-component-placeholder"')) {
                // 不可以查看的情况下
                if (!type) {
                    let div = document.createElement('div')
                    div.innerHTML = msg
                    let list = Array.from(div.querySelectorAll('.ask-component-placeholder'))
                    for (let index = 0; index < list.length; index++) {
                        console.log(list[index].innerText);
                        list[index].innerText = list[index].innerText.replace(/[^]/g, '*')
                    }
                    msg = div.innerHTML
                    div = null
                }
            }
            return msg
        },
        checkChange () {
            this.remarks = []
            this.messageList.forEach(item => {
                if (item.checked) {
                    this.remarks.push(item.text)
                }
            })
        },
        aiCreate () {
            if (this.remarks.length == 0) {
                this.$message.warning("请先选择要抽取的内容！")
                return
            }
            this.aiLoading = true
            this.$http.post("/wisdom-node-api/chat-database/generateTicketQA", {
                description: this.messageList[0].text,
                remarks: this.remarks
            }).then(res => {
                this.aiLoading = false
                if (res.data.success) {
                    try {
                        let ticketGenerateQa = JSON.parse(res.data.ticketGenerateQa)
                        this.$message.success("抽取成功，抽取结果在右侧展示")
                        this.$emit('aiCreate', ticketGenerateQa)
                    } catch {
                        this.$message.warning("抽取失败，可能存在无法抽取的内容")
                    }
                }
            })
        },
        openDialog (item, fileName) {
            let url = typeof item == 'object' ? item.url : item
            this.fieldType = ""
            const fileType = fileName.substring(fileName.lastIndexOf('.'));
            this.dialogUrl = url;
            if (fileType === '.mp4' || fileType === '.MP4') {
                this.fieldType = 'VIDEO'
                this.dialogVisible = true;
            }
            else if (fileType === '.jpg' || fileType === '.png' || fileType === '.JPG' || fileType === '.PNG' || fileType === '.jpeg') {
                let imgList = []
                this.attachmentList.forEach(element => {
                    let name = element.name
                    const type = name.substring(name.lastIndexOf('.'));
                    if (type === '.jpg' || type === '.png' || type === '.JPG' || type === '.PNG') {
                        imgList.push(typeof element == 'object' ? element.url : element);
                    }
                });
                this.$emit('previewImg', imgList, url)
            }
            else {
                let previewUrl = this.previewUrl(url)
                window.open(previewUrl, 'target');
            }
        },
        previewUrl (oldUrl) {
            let url = process.env.VUE_APP_ENV === 'production' ? 'https://kkfileview.askbot.cn/onlinePreview?url=' : 'https://test.open.askbot.cn/kkfileview/onlinePreview?url='
            if (url != '') {
                if (decodeURIComponent(oldUrl) == oldUrl) {
                    url += btoa(encodeURIComponent(oldUrl))
                    url += '&encodeURIComponent=true'
                } else {
                    url += btoa(encodeURIComponent(decodeURIComponent(oldUrl)))
                    url += '&encodeURIComponent=true'
                }
            }
            return url
        },
        downLoad (item, name) {
            let url = typeof item == 'object' ? item.url : item
            if (decodeURIComponent(url) != url) {
                url = decodeURIComponent(url)
            }
            this.$http.post('/knowledge-api/temporary-certificate/or-origin?expired=10', url, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.data) {
                    let a = document.createElement('a')
                    a.href = res.data
                    a.download = name
                    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.work_order_desc_list {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #F5F7FB;
    position: relative;
    padding-block: #F5F7FB;

    .message_content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        padding: 12px;
    }

    .message {
        width: 100%;
        height: auto;
        margin-bottom: 12px;
        display: flex;
        overflow: hidden;

        .checkbox {
            width: 15px;
            height: 15px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .msg_body {
            max-width: 80%;
            background: #FFFFFF;
            border-radius: 0 25px 25px 25px;
            box-sizing: border-box;
            padding: 16px;
            font-size: 14px;
            color: #000000;
            text-align: left;

            .msg_file_body {
                width: 100%;
                font-size: 14px;
                color: #000000;
                text-align: left;
                margin-top: 15px;

                .guoran-tongyichicun-xiazai {
                    font-size: 13px;
                    color: #366aff;
                    margin-right: 5px;
                    cursor: pointer;
                }

                .attch-item-left {
                    display: flex;
                    align-items: center;
                    flex: none;
                    width: calc(100% - 20px);

                    img {
                        width: 22px;
                        height: 22px;
                        margin-right: 4px;
                        cursor: default !important;
                    }

                    .fileName {
                        cursor: pointer;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 12px;
                        color: #616161;
                    }
                }
            }

            /deep/p {
                width: 100%;
                word-wrap: break-word;
                word-break: break-all;
                white-space: pre-wrap;
                // white-space:nowrap;
            }

            /deep/ img {
                max-width: 100%;
                // max-height: 240px;
            }
        }


    }

    .tips {
        width: 100%;
        height: 40px;
        background: #F5F7FB;
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ai_create {
        width: 80%;
        height: 30px;
        background: #366AFF;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        cursor: pointer;
    }

    .ai_loading {
        width: 80%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: #366AFF;
            margin-right: 5px;
            animation: rotate 3s linear infinite;
        }

    }

    .msg_right {
        justify-content: flex-end;

        .msg_body {
            background: linear-gradient(180deg, #3682FF, #366AFF);
            border-radius: 25px 0 25px 25px;
            color: #FFFFFF;

            .msg_file_body {
                .attch-item-left {
                    .fileName {
                        color: #FFFFFF !important;
                    }

                    i {
                        color: #FFFFFF !important;
                    }
                }

                .guoran-tongyichicun-xiazai {
                    color: #FFFFFF !important;
                }
            }


        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>