<template>
  <div id="feedback">
    <div class="mgt10 simple-cell-satisfaction-inline">
      <div class="title">
        <span>如果反馈</span>
      </div>
      <div class="action-confirm-des">
        <el-input
            size="small"
            :disabled="true"
            v-model="unit.content.positiveFeedback.text"
            maxlength="8"
            placeholder="请输入"
          ></el-input>
      </div>
    </div>
    <div class="simple-cell-satisfaction">
      <div class="jump-cell">
        <el-select
          style="width:120px"
          v-model="unit.content.positiveFeedback.scope"
          size="small"
          @change="jumpTypeValueChange($event, 'positive')"
          placeholder="请选择"
        >
          <el-option
            v-for="item in jumpTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="right-select">
          <el-select
            v-show="unit.content.positiveFeedback.scope === 'NODE'"
            v-model="unit.content.positiveFeedback.value"
            @change="nodeSelectChange"
            :popper-append-to-body="false"
            filterable
            size="small"
            placeholder="请输入选择名称"
          >
            <el-option
              v-for="item in nodeOptions"
              :popper-class="'pop_my' + item.classPop + item.parentNum"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-cascader
            v-show="unit.content.positiveFeedback.scope === 'INTENT'"
            v-model="unit.content.positiveFeedback.value"
            size="small"
            :options="intentTree4Radio"
            :props="{
              expandTrigger: 'hover',
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
          ></el-cascader>
          <el-input
            autosize
            v-show="unit.content.positiveFeedback.scope === 'TEXT'"
            type="textarea"
            v-model="unit.content.positiveFeedback.value"
            maxlength="2000"
            placeholder="请输入文字答案"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="simple-cell-satisfaction-inline mgt10">
      <div class="title">
        <span>如果反馈</span>
      </div>
      <div class="action-confirm-des">
        <el-input
            size="small"
            :disabled="true"
            v-model="unit.content.negativeFeedback.text"
            maxlength="8"
            placeholder="请输入"
          ></el-input>
      </div>
    </div>
    <div class="simple-cell-satisfaction">
      <div class="jump-cell">
        <el-select
          style="width:120px"
          v-model="unit.content.negativeFeedback.scope"
          size="small"
          @change="jumpTypeValueChange($event, 'negative')"
          placeholder="请选择"
        >
          <el-option
            v-for="item in jumpTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="right-select">
          <el-select
            v-show="unit.content.negativeFeedback.scope === 'NODE'"
            v-model="unit.content.negativeFeedback.value"
            @change="nodeSelectChange"
            :popper-append-to-body="false"
            filterable
            size="small"
            placeholder="请输入选择名称"
          >
            <el-option
              v-for="item in nodeOptions"
              :popper-class="'pop_my' + item.classPop + item.parentNum"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-cascader
            v-show="unit.content.negativeFeedback.scope === 'INTENT'"
            v-model="unit.content.negativeFeedback.value"
            size="small"
            :options="intentTree4Radio"
            :props="{
              expandTrigger: 'hover',
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
          ></el-cascader>
          <el-input
            autosize
            v-show="unit.content.negativeFeedback.scope === 'TEXT'"
            type="textarea"
            v-model="unit.content.negativeFeedback.value"
            maxlength="2000"
            placeholder="请输入文字答案"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "feedback",
  props: ["unit", "intentOptions", "nodeOptions", "intentTree4Radio"],
  data() {
    return {
      name: "feedback",
      jumpTypeOptions: [
        {
          value: "NODE",
          label: "跳转到节点",
        },
        {
          value: "INTENT",
          label: "跳转到意图",
        },
        {
          value: "TEXT",
          label: "回复文本",
        },
        {
          value: "NONE",
          label: "无动作",
        },
      ],
    };
  },
  methods: {
    jumpTypeValueChange(e, type){
      console.log(e, type)
      if (type == "positive") {
        this.unit.content.positiveFeedback.value = "";
      } else {
        this.unit.content.negativeFeedback.value = "";
      }
    }
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#feedback {
  .simple-cell-satisfaction-inline {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-bottom: 4px;
    .title {
      width: 70px;
      flex: none;
      text-align: left;
      line-height: 32px;
      height: 32px;
    }
    .action-confirm-des {
      flex: auto;
      display: flex;
      justify-content: flex-start;
      .el-input {
        width: 150px;
      }
    }
  }
  .mgt10 {
      margin-top: 12px;
  }
}
</style>