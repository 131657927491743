<template>
    <div class="about">
        <h1>数据概览</h1>
    </div>
</template>
<script>
export default {
    name: "about",
    methods: {},
    mounted() {
        this.FetchPost("query", {
            query: "你好123",
            openId: "123",
            appId: "wxe7985a3d339996c5",
            env: "test"
        }).then(re => {
            if (re.status == 200) {
                // console.log(re);
            } else {
                // this.$message.error(re.msg.msg);
            }
        });
    }
};
</script>