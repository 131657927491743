<template>
    <transition name="el-zoom-in-top">
        <div class="intent-node-list">
            <div
                class="intent-node-add"
                v-if="!JSON.parse(intentItem).hasProcess"
            >
                <div class="node-pre-first-add">
                    <a
                        href="Javascript: void(0)"
                        class="intent-add-first-node"
                        v-show="!JSON.parse(intentItem).isAddEditing"
                        @click="
                            addNodeHandleFirst(
                                JSON.parse(intentItem).id,
                                JSON.parse(intentItem).id,
                                [skillIndex, classIndex, intentIndex],
                                'pre'
                            )
                        "
                        >+</a
                    >
                    <div
                        v-show="JSON.parse(intentItem).isAddEditing"
                        class="add-input"
                    >
                        <el-input
                            v-model="nodeNameValue"
                            maxlength="20"
                            placeholder="请输入节点名称"
                            @keyup.enter.native="
                                confirmAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    JSON.parse(intentItem).id,
                                    [skillIndex, classIndex, intentIndex],
                                    nodeNameValue,
                                    'pre'
                                )
                            "
                        ></el-input>
                        <span
                            class="el-icon-success add-icon"
                            @click="
                                confirmAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    JSON.parse(intentItem).id,
                                    [skillIndex, classIndex, intentIndex],
                                    nodeNameValue,
                                    'pre'
                                )
                            "
                        ></span>
                        <span
                            class="el-icon-error delete-icon"
                            @click="
                                concelAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    JSON.parse(intentItem).id,
                                    [skillIndex, classIndex, intentIndex],
                                    'pre'
                                )
                            "
                        ></span>
                    </div>
                </div>
            </div>
            <div
                class="intent-node"
                v-for="(nodeItem, nodeIndex) in JSON.parse(intentItem).children"
                :key="nodeIndex"
                v-if="JSON.parse(intentItem).show"
            >
                <div class="node-pre-first">执行意图流程</div>
                <div class="node-node-name">
                    <span id="show-node">节点</span>
                    <div
                        class="name-text csp"
                        @click="
                            configAction(
                                JSON.parse(intentItem).id,
                                nodeItem.id,
                                skillItem.id,
                                nodeItem.name,
                                [
                                    skillIndex,
                                    classIndex,
                                    intentIndex,
                                    nodeIndex,
                                ],
                                0
                            )
                        "
                    >
                        {{ nodeItem.name }}
                    </div>
                    <div
                        class="el-icon-delete delete-icon-node"
                        v-if="
                            ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !(
                                    !skillItem.display &&
                                    skillItem.name == '特殊规则'
                                )) ||
                            (!skillItem.display &&
                                skillItem.name == '特殊规则' &&
                                RP_Visible('OPEN_PROCESS_OTHER'))) && !isPart
                        "
                        @click="
                            deleteNodeHandleFirst(
                                JSON.parse(intentItem).id,
                                nodeItem.id
                            )
                        "
                    ></div>
                </div>
                <recursive
                    :list="JSON.stringify(nodeItem.children)"
                    :indexArr="
                        JSON.stringify([
                            skillIndex,
                            classIndex,
                            intentIndex,
                            nodeIndex,
                        ])
                    "
                    :isPart="isPart"
                    :intentId="JSON.parse(intentItem).id"
                    :skillId="skillItem.id"
                    :isOtherSkill="
                        !skillItem.display && skillItem.name == '特殊规则'
                    "
                ></recursive>
                <div class="add-node-box">
                    <span
                        v-show="
                            !(
                                (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                    !(
                                        !skillItem.display &&
                                        skillItem.name == '特殊规则'
                                    )) ||
                                (!skillItem.display &&
                                    skillItem.name == '特殊规则' &&
                                    RP_Visible('OPEN_PROCESS_OTHER'))
                            )
                        "
                        class="add-text-forb"
                        >+</span
                    >
                    <span
                        v-show="
                            !nodeItem.isAddEditing &&
                            ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !(
                                    !skillItem.display &&
                                    skillItem.name == '特殊规则'
                                )) ||
                                (!skillItem.display &&
                                    skillItem.name == '特殊规则' &&
                                    RP_Visible('OPEN_PROCESS_OTHER')))
                        "
                        class="add-text"
                        @click="
                            addNodeHandleFirst(
                                JSON.parse(intentItem).id,
                                JSON.parse(intentItem).id,
                                [skillIndex, classIndex, intentIndex],
                                'first'
                            )
                        "
                        >+</span
                    >
                    <div v-show="nodeItem.isAddEditing" class="add-input">
                        <el-input
                            v-model="nodeNameValue"
                            maxlength="20"
                            placeholder="请输入节点名称"
                            @keyup.enter.native="
                                confirmAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    nodeItem.id,
                                    [skillIndex, classIndex, intentIndex],
                                    nodeNameValue,
                                    'first'
                                )
                            "
                        ></el-input>
                        <span
                            class="el-icon-success add-icon"
                            @click="
                                confirmAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    nodeItem.id,
                                    [skillIndex, classIndex, intentIndex],
                                    nodeNameValue,
                                    'first'
                                )
                            "
                        ></span>
                        <span
                            class="el-icon-error delete-icon"
                            @click="
                                concelAddNodeHandleFirst(
                                    JSON.parse(intentItem).id,
                                    JSON.parse(intentItem).id,
                                    [skillIndex, classIndex, intentIndex],
                                    'first'
                                )
                            "
                        ></span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import recursive from "./chatFlowNodeComponent";
export default {
    props: [
        "skillIndex",
        "classIndex",
        "intentIndex",
        "intentItem",
        "classItem",
        "skillItem",
        "isPart"
    ],

    data() {
        return {
            nodeNameValue: "",
        };
    },
    components: {
        recursive,
    },
    methods: {
        addNodeHandleFirst(id, _id, indexArr, pre) {
            this.$emit("addNodeHandleFirst", id, _id, indexArr, pre);
            console.log(this.intentItem);
            console.log(this.skillItem);
        },
        confirmAddNodeHandleFirst(id, _id, indexArr, nodeNameValue, pre) {
            this.$emit(
                "confirmAddNodeHandleFirst",
                id,
                _id,
                indexArr,
                nodeNameValue,
                pre
            );
            this.nodeNameValue = "";
        },
        concelAddNodeHandleFirst(id, _id, indexArr, pre) {
            this.$emit("concelAddNodeHandleFirst", id, _id, indexArr, pre);
            this.nodeNameValue = "";
        },
        configAction(
            intentId,
            nodeId,
            skillId,
            nodeName,
            indexArr,
            isInitNode
        ) {
            this.$emit(
                "configAction",
                intentId,
                nodeId,
                'S'+skillId,
                nodeName,
                indexArr,
                isInitNode
            );
        },
        deleteNodeHandleFirst(intentId, nodeId) {
            this.$emit('deleteNodeHandleFirst', intentId, nodeId);
        },
    },
};
</script>