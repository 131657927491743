<template>
    <div v-loading="loading" class="close-intent-baili-update">
        <div v-if="isDefaultIntents.length > 0 && relationIntentListHandleType == 'deleteB'" class="intent-relation-content">
            <div class="no-opration-tips">
              {{$t('botIntent.relationIntentList.tip')}}
            </div>
            <div class="is-default-intents" v-if="isDefaultIntents && isDefaultIntents.length > 0">
                <span
                    v-for="(intent, index) in isDefaultIntents"
                    :key="index"
                    >{{ intent.showName }}</span
                >
            </div>
        </div>

        <div class="intent-relation-content">
            <div
                v-if="
                    (relationIntentListHandleType == 'openS') &&
                    intentList.length == 0
                "
                class="grzh-tip"
            >
                <!-- <span class="tip-pre el-icon-warning"></span> -->
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.openTip')}}</span
                >
            </div>
            <div
                v-if="relationIntentListHandleType == 'openS' &&
                    intentList.length == 0 &&
                    sHandleHasChildren"
                class="grzh-tip1"
            >
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.openReason')}}</span
                >
                <br />
                <div class="delete-reason">
                    <el-select
                        v-model="reasonValue"
                        multiple
                        :placeholder="$t('botIntent.relationIntentList.reasonPlaceholder')"
                    >
                        <el-option
                            v-for="item in reasonptions"
                            :key="item.id"
                            :label="item.reason"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <el-input
                        v-if="reasonValue.indexOf(0) != -1"
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 5 }"
                        :placeholder="$t('common.inputPlaceholder')"
                        v-model="reasonText"
                    >
                    </el-input>
                </div>
            </div>
            <div
                v-if="
                    (relationIntentListHandleType == 'closeS' ||
                        relationIntentListHandleType == 'closeB') &&
                    intentList.length == 0
                "
                class="grzh-tip"
            >
                <!-- <span class="tip-pre el-icon-warning"></span> -->
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.closeTip')}}</span
                >
            </div>
            <div
                v-if="
                    relationIntentListHandleType == 'closeS' &&
                    intentList.length == 0 &&
                    sHandleHasChildren
                "
                class="grzh-tip1"
            >
                <span class="tip-content tips-title-dialog"
                    >{{$t('botIntent.relationIntentList.closeReason')}}</span
                >
                <br />
                <div class="delete-reason">
                    <el-select
                        v-model="reasonValue"
                        multiple
                        :placeholder="$t('botIntent.relationIntentList.reasonPlaceholder')"
                    >
                        <el-option
                            v-for="item in reasonptions"
                            :key="item.id"
                            :label="item.reason"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <el-input
                        v-if="reasonValue.indexOf(0) != -1"
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 5 }"
                        :placeholder="$t('common.inputPlaceholder')"
                        v-model="reasonText"
                    >
                    </el-input>
                </div>
            </div>
            <div
                v-if="
                    (relationIntentListHandleType == 'deleteS' ||
                        relationIntentListHandleType == 'deleteB') &&
                    intentList.length == 0
                "
                class="grzh-tip"
            >
                <!-- <span class="tip-pre el-icon-warning"></span> -->
                <span class="tip-content-delete"
                    >{{$t('botIntent.relationIntentList.deleteIntentTip')}}</span
                >
            </div>
            <div
                v-if="
                    relationIntentListHandleType == 'deleteS' &&
                    intentList.length == 0 &&
                    sHandleHasChildren
                "
                class="grzh-tip1"
            >
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.deleteReason')}}</span
                >
                <br />
                <div class="delete-reason">
                    <el-select
                        v-model="reasonValue"
                        multiple
                        :placeholder="$t('botIntent.relationIntentList.reasonPlaceholder')"
                    >
                        <el-option
                            v-for="item in reasonptions"
                            :key="item.id"
                            :label="item.reason"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <el-input
                        v-if="reasonValue.indexOf(0) != -1"
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 5 }"
                        :placeholder="$t('common.inputPlaceholder')"
                        v-model="reasonText"
                    >
                    </el-input>
                </div>
            </div>
            <div
                v-if="
                    (relationIntentListHandleType == 'deleteS' ||
                        relationIntentListHandleType == 'deleteB') &&
                    intentList.length != 0
                "
                class="grzh-tip"
            >
                <!-- <span class="tip-pre el-icon-warning"></span> -->
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.usedTip')}}</span
                >
            </div>
            <div
                v-if="
                    (relationIntentListHandleType == 'closeS' ||
                        relationIntentListHandleType == 'closeB') &&
                    intentList.length != 0
                "
                class="grzh-tip"
            >
                <!-- <span class="tip-pre el-icon-warning"></span> -->
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.usedTip')}}</span
                >
            </div>
            <div
                v-if="
                    relationIntentListHandleType == 'openB'"
                class="grzh-tip"
            >
                <!-- <span class="tip-pre el-icon-warning"></span> -->
                <span class="tip-content"
                    >{{$t('botIntent.relationIntentList.openIntentTip')}}</span
                >
            </div>
            <div
                v-if="
                    (relationIntentListHandleType == 'openB' ||
                        relationIntentListHandleType == 'closeB' ||
                        relationIntentListHandleType == 'deleteB') &&
                    intentList.length == 0 && hasIsParent()
                "
                class="bReason"
            >
                <span class="b-handle-reason-tips">{{$t('botIntent.relationIntentList.templateIntentReason')}}</span>
                <div class="b-reason-header">
                    <div class="b-reason-h-cell-name">
                        <span v-if="relationIntentListHandleType == 'openS' || relationIntentListHandleType == 'openB'">待打开意图</span>
                        <span v-if="relationIntentListHandleType == 'closeS' || relationIntentListHandleType == 'closeB'">待关闭意图</span>
                        <span v-if="relationIntentListHandleType == 'deleteS' || relationIntentListHandleType == 'deleteB'">待删除意图</span>
                    </div>
                    <div class="b-reason-h-cell-reason">
                        <span class="title">{{$t('botIntent.relationIntentList.operationReason')}}</span>
                        <div class="input-content">
                            <el-select
                                v-model="reasonValueB"
                                v-if="hasIsParent()"
                                @change="changeReasonValueB"
                                multiple
                                :placeholder="$t('botIntent.relationIntentList.reasonPlaceholder')"
                            >
                                <el-option
                                    v-for="item in reasonptions"
                                    :key="item.id"
                                    :label="item.reason"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <el-input
                                v-if="reasonValueB.indexOf(0) != -1"
                                type="textarea"
                                @input="changeReasonTextB"
                                :autosize="{ minRows: 1, maxRows: 3 }"
                                :placeholder="$t('common.inputPlaceholder')"
                                v-model="reasonTextB"
                            >
                            </el-input>
                        </div>
                    </div>
                </div>
                <div class="b-reason-c-box">
                    <div
                        v-for="(intent, index) in handleIntentList"
                        :key="intent.name"
                        class="b-reason-content"
                    >
                        <div class="b-reason-c-cell-name">
                            <span>{{ intent.name }}</span>
                        </div>
                        <div class="b-reason-c-cell-reason">
                            <div
                                class="input-content"
                                v-if="isParentIntents.indexOf(intent.id) == -1"
                            >
                                {{$t('botIntent.relationIntentList.noTemplateIntent')}}
                            </div>
                            <div
                                v-if="isParentIntents.indexOf(intent.id) != -1"
                                class="input-content"
                            >
                                <el-select
                                    v-model="intent.reasonValue"
                                    @change="changeReasonValue($event, index)"
                                    multiple
                                    :placeholder="$t('botIntent.relationIntentList.reasonPlaceholder')"
                                >
                                    <el-option
                                        v-for="item in reasonptions"
                                        :key="item.id"
                                        :label="item.reason"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                                <el-input
                                    v-if="intent.reasonValue.indexOf(0) != -1"
                                    type="textarea"
                                    @input="changeReasonText($event, index)"
                                    :autosize="{ minRows: 1, maxRows: 3 }"
                                    :placeholder="$t('common.inputPlaceholder')"
                                    v-model="intent.reasonText"
                                >
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="intentList.length != 0 && relationIntentListHandleType != 'openB' && relationIntentListHandleType != 'openS'" class="intent-relation-content">
            <div class="title">
                <span class="handle-title">{{$t('botIntent.intentName')}}</span>
                <span class="relation-title">{{$t('botIntent.modifyLocation')}}</span>
            </div>
            <div
                v-for="(cell, index) in intentList"
                :key="index"
                class="relation-content"
            >
                <div class="handle-name">{{ cell.intent.name }}</div>
                <div class="relation-name">
                    <div class="relation-setting-content" v-if="cell.relationIntents && cell.relationIntents.length > 0">
                        <div
                            v-for="(
                                relationIntent, rIndex
                            ) in cell.relationIntents"
                            @click="gotoChatFlow(relationIntent)"
                            :key="rIndex"
                            class="relation-name-cell"
                        >
                            {{ relationIntent.name }}
                        </div>
                    </div>

                    <div class="relation-setting-content" v-if="cell.relationSettings && cell.relationSettings.length > 0">
                        <span
                            v-for="(
                                relationSetting, sIndex
                            ) in cell.relationSettings == null
                                ? []
                                : cell.relationSettings"
                            @click="gotoSettings(relationSetting)"
                            :key="sIndex"
                            class="relation-name-cell"
                        >
                            {{ relationSetting.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="intent-selected-content dialog-footer">
            <div v-if="intentList.length == 0 || relationIntentListHandleType == 'openB' || relationIntentListHandleType == 'openS'" class="copy-intent-footer">
                <el-button @click="close"  class="cancel-btn" round>{{$t('common.cancel')}}</el-button>
                <el-button @click="handle" type="primary" class="confirm-btn" round :loading="btnLoading"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
            <div v-if="intentList.length != 0 && (relationIntentListHandleType != 'openB' && relationIntentListHandleType != 'openS')" class="copy-intent-footer">
                <el-button @click="close"  round class="cancel-btn">{{$t('common.close')}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "intentIds",
        "apiKey",
        "relationIntentListHandleType",
        "intentOptions",
        "sHandleHasChildren",
        "isParentIntents",
        'operationType',
        "relationIntentListVisible"
    ],
    data() {
        return {
            intentList: [],
            handleIntentList: [],
            loading: false,
            isDefaultIntents: [],
            reasonptions: [],
            reasonValueB: [],
            reasonTextB: "",
            reasonValue: [],
            reasonText: "",
            btnLoading:false,
        };
    },
    methods: {
        hasIsParent() {
            let flag = false;
            this.handleIntentList.forEach((item) => {
                if (this.isParentIntents.indexOf(item.id) != -1) {
                    flag = true;
                }
            });
            return flag;
        },
        formatReasonParam(ids) {
            let param = [];
            this.reasonptions.forEach((item) => {
                if (ids.indexOf(item.id) != -1) {
                    param.push(item);
                }
            });
            return param;
        },
        getIntentNoticeReasons() {
            this.FetchGet(this.requestUrl.intentNotice.getIntentNoticeReasons, {
                botId: this.$route.query.id,
            })
                .then((res) => {
                    if (res.code === "0") {
                        if (
                            this.relationIntentListHandleType == "closeS" ||
                            this.relationIntentListHandleType == "closeB"
                        ) {
                            this.reasonptions = res.data.CLOSE;
                        }
                        if (
                            this.relationIntentListHandleType == "openS" ||
                            this.relationIntentListHandleType == "openB"
                        ) {
                            this.reasonptions = res.data.OPEN;
                        }
                        if (
                            this.relationIntentListHandleType == "deleteS" ||
                            this.relationIntentListHandleType == "deleteB"
                        ) {
                            this.reasonptions = res.data.DELETE;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        changeReasonValueB(val) {
            this.handleIntentList.forEach((item) => {
                item.reasonValue = val;
            });
        },
        changeReasonTextB(val) {
            this.handleIntentList.forEach((item) => {
                item.reasonText = val;
            });
        },
        changeReasonValue(val, index) {
            this.handleIntentList[index].reasonValue = val;
            this.handleIntentList = [...this.handleIntentList];
        },
        changeReasonText(val, index) {
            this.handleIntentList[index].reasonText = val;
            this.handleIntentList = [...this.handleIntentList];
        },
        close() {
            let flag = true;
            if(this.relationIntentListHandleType === 'openS'){
                flag = false;
            } else if(this.relationIntentListHandleType === 'deleteS'){
                flag = false;
            } else if(this.relationIntentListHandleType === 'closeS'){
                flag = false;
            } else {
                flag = true;
            }
            this.$emit("closeRealtionIntentList",flag);
        },
        formatBReasonParam(list) {
            let arr = [];
            list.forEach((item) => {
                arr.push({
                    description: item.reasonText,
                    intentId: item.id,
                    reasonInfos: this.formatReasonParam(item.reasonValue),
                });
            });
            return arr;
        },
        handle() {
            // 批量开启 openB
            if (this.relationIntentListHandleType == "openB") {
                this.batchOperation({
                    operateType: "CLOSE",
                    state: true,
                    intentIds: this.intentIds,
                    intentInfos: this.formatBReasonParam(this.handleIntentList),
                    apiKey:this.apiKey
                });
            }
             // 单个开启 openS
            if (this.relationIntentListHandleType == "openS") {
                this.$emit('update:operationType','card')
                this.$emit(
                    "confirmCloseIntent",
                    true,
                    this.intentIds[0],
                    true,
                    {
                        reasonValue: this.formatReasonParam(this.reasonValue),
                        reasonText: this.reasonText,
                    }
                );
            }
            // 单个删除 deleteS 
            if (this.relationIntentListHandleType == "deleteS") {
                this.btnLoading = true;
                this.$emit('update:operationType','card')
                this.$emit(
                    "confirmDeleteSIntent",
                    this.intentIds[0],
                    this.sHandleHasChildren,
                    {
                        reasonValue: this.formatReasonParam(this.reasonValue),
                        reasonText: this.reasonText,
                    }
                );
            }
            // 单个关闭 closeS 
            if (this.relationIntentListHandleType == "closeS") {
                this.$emit('update:operationType','card')
                this.$emit(
                    "confirmCloseIntent",
                    false,
                    this.intentIds[0],
                    this.sHandleHasChildren,
                    {
                        reasonValue: this.formatReasonParam(this.reasonValue),
                        reasonText: this.reasonText,
                    }
                );
            }
            // 批量关闭 closeB 
            if (this.relationIntentListHandleType == "closeB") {
                this.batchOperation({
                    operateType: "CLOSE",
                    state: false,
                    intentIds: this.intentIds,
                    intentInfos: this.formatBReasonParam(this.handleIntentList),
                });
            }
             // 批量删除 deleteB 
            if (this.relationIntentListHandleType == "deleteB") {
                this.batchOperation({
                    operateType: "DELETE",
                    intentIds: this.intentIds,
                    intentInfos: this.formatBReasonParam(this.handleIntentList),
                });
            }
        },
        filterIsDefaultIntents(intentIds) {
            let isDefaultIntents = [];
            this.handleIntentList = [];
            this.intentOptions.length != 0 &&
                this.intentOptions[0].children.forEach((skill) => {
                    if (skill.children != null) {
                        skill.children.forEach((_type) => {
                            if (_type.children != null) {
                                _type.children.forEach((intent) => {
                                    if (
                                        intentIds.indexOf(intent.id) != -1 &&
                                        intent.ext.isDefault
                                    ) {
                                        intent.showName =
                                            skill.name +
                                            "/ " +
                                            _type.name +
                                            "/ " +
                                            intent.name;
                                        isDefaultIntents.push(intent);
                                    }
                                    if (intentIds.indexOf(intent.id) != -1) {
                                        intent.reasonValue = [];
                                        intent.reasonText = "";
                                        this.handleIntentList.push(intent);
                                    }
                                });
                            }
                        });
                    }
                });
            return isDefaultIntents;
        },
        batchOperation(param) {
            this.btnLoading = true;
            this.FetchPost(this.requestUrl.intent.batchOperate, param).then(
                (res) => {
                    if (res.code === "0") {
                        this.btnLoading = false;
                        this.$emit("closeBatchOperation");
                        this.$emit("switchSkillCopy");
                        this.$emit("closeRealtionIntentList",false);
                        this.$emit("getIntentSyncProcessTree");
                        this.$message.success('操作成功');
                        this.$emit('update:operationType','')
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.message);
                    }
                }
            );
        },
        gotoSettings(relationSetting) {
            if (
                relationSetting.type == "KEYWORD_REC" ||
                relationSetting.type == "FAST_SELECT" ||
                relationSetting.type == "ANSWER_FEEDBACK"
            ) {
                let href = "";
                let hrefArr = window.location.href.split("tabIndex=skill");
                if (
                    hrefArr.length == 1 &&
                    window.location.href.indexOf(
                        "tabIndex=bot-setting&botSettingTab="+relationSetting.type
                    ) == -1
                ) {
                    href =
                        window.location.href +
                        "&tabIndex=bot-setting&botSettingTab="+relationSetting.type;
                } else {
                    href = window.location.href
                        .split("tabIndex=skill")
                        .join("tabIndex=bot-setting&botSettingTab="+relationSetting.type);
                }
                window.open(href, "_blank");
            }
            if (relationSetting.type == "START_NODE") {
                let href = window.location.href
                    .split("/main/botIntent?")
                    .join("/main/chatFlow?");
                window.open(href, "_blank");
            }
            if (relationSetting.type == "END_NODE") {
                let href = window.location.href
                    .split("/main/botIntent?")
                    .join("/main/chatFlow?toBottom=true&");
                window.open(href, "_blank");
            }
            if (relationSetting.type == "START_NODE_UNIFY_SET") {
                let wechatType = relationSetting.wechatType;
                let bindCode = relationSetting.bindCode;
                window.open('/#/main/link?openDrawerType=' + wechatType + '&bindCode=' + bindCode, "_blank");
            }
        },
        gotoChatFlow(relationIntent) {
            let routeUrl = this.$router.resolve({
                path: "/main/chatFlow",
                query: {
                    id: this.$route.query.id,
                    bid: this.$route.query.id,
                    bName: this.$route.query.name,
                    intentId: relationIntent.id,
                    intentName: relationIntent.name,
                    refApiKey: relationIntent.apiKey,
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                    "/#" +
                    routeUrl.resolved.fullPath,
                "_blank"
            );
            // this.$router.push({
            //     name: "chatFlow",
            //     query: {
            //         id: this.$route.query.id,
            //         bid: this.$route.query.id,
            //         bName: this.$route.query.name,
            //         intentId: relationIntent.id,
            //         intentName: relationIntent.name,
            //         refApiKey: relationIntent.apiKey,
            //     },
            // });
        },
    },
    watch:{
        relationIntentListVisible(n){
            if(n){
                this.loading = true;
                this.FetchPost(this.requestUrl.intent.checkProcessRelation, {
                    intentIds: this.intentIds,
                    apiKey: this.apiKey,
                }).then((res) => {
                    if (res.code === "0") {
                        // 获取展示内容/标示
                        this.intentList = res.data;
                        this.loading = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
                this.isDefaultIntents = this.filterIsDefaultIntents(this.intentIds);
                this.getIntentNoticeReasons();
            }
        }
    },
    mounted() {
       
    },
};
</script>

<style lang="less">
.close-intent-baili-update {
    .intent-relation-content {
        font-size: 14px;
        padding: 0 8px;
        margin-bottom: 28px;
        .is-default-intents {
            max-height: 150px;
            overflow: auto;
            margin-left: 8px;
            margin-bottom: 16px;
            background-color: #f2f6fc;
            padding: 6px;
            span {
                text-align: left;
                display: block;
                line-height: 28px;
            }
        }
        .no-opration-tips {
            line-height: 32px;
            text-align: left;
            margin-left: 8px;
        }
        .intent-selected-box {
            text-align: left;
            .inner-bottom-handle {
                margin-top: 16px;
                text-align: right;
            }
            .el-cascader {
                width: 100%;
            }
        }
        .bReason {
            .b-handle-reason-tips{
                line-height: 32px;
                height: 32px;
                display: block;
                text-align: left;
                margin-bottom: 6px;
                margin-left: 6px;
            }
            .b-reason-header {
                display: flex;
                justify-content: flex-start;
                text-align: left;
                // background-color: #f2f6fc;
                padding: 6px;
                margin-bottom: 12px;
                .b-reason-h-cell-name {
                    width: 224px;
                    flex: none;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: flex;
                    align-items: center;
                }
                .b-reason-h-cell-reason {
                    flex: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 12px;
                    .title {
                        width: 60px;
                        flex: none;
                        height: auto;
                    }
                    .input-content {
                        flex: auto;
                        .el-select,
                        .el-input {
                            width: 100% !important;
                        }
                        .el-select {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .b-reason-c-box {
                .b-reason-content {
                    display: flex;
                    justify-content: flex-start;
                    text-align: left;
                    // border-bottom: solid 2px #f2f6fc;
                    padding: 6px 0;
                    .b-reason-c-cell-name {
                        width: 200px;
                        flex: none;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;
                        align-items: center;

                        height: 20px;
                        line-height: 20px;
                        // height: 112px;
                        max-height: 112px;
                        background: #F6F8FD;
                        border-radius: 5px;
                        padding: 12px;
                        font-size: 14px;
                        color: #000000;


                    }
                    .b-reason-c-cell-reason {
                        flex: auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-left: 12px;
                        // height: 112px;
                        max-height: 112px;
                        background: #F6F8FD;
                        border-radius: 5px;
                        padding: 12px;
                        font-size: 14px;
                        color: #000000;
                        .title {
                            width: 30px;
                            flex: none;
                        }
                        .input-content {
                            flex: auto;
                            .el-select,
                            .el-input {
                                width: 100% !important;
                            }
                            .el-select {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
        .grzh-tip {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            // justify-content: center;
            .tip-pre {
                text-align: left;
                width: 30px;
                color: #e6a23c;
                font-size: 20px;
            }
            .tip-content {
                text-align: left;
                font-size: 14px;
                font-family: Microsoft YaHei;
                color: #A9B3C6;
                line-height: 24px;
            }
            .tip-content-delete{
                display: block;
                // margin: 0 auto;
                text-align: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                color: #A9B3C6;
                line-height: 24px;
            }
        }
        .grzh-tip1 {
            // padding: 12px;
            text-align: left;
            margin-bottom: 30px;
            font-size: 14px;
            .tip-pre {
                text-align: left;
                width: 30px;
                color: #e6a23c;
                font-size: 20px;
            }
            .tip-content {
                text-align: left;
            }
            .delete-reason {
                margin-top: 8px;
                .el-select {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }
        .title {
            display: flex;
            justify-content: flex-start;
            line-height: 34px;
            height: 34px;
            padding: 6px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #616161;
            line-height: 44px;
            .handle-title {
                width: 240px;
                flex: none;
                text-align: left;
                margin-right: 25px;
            }
            .relation-title {
                 width: 240px;
                flex: auto;
                text-align: left;
                margin-left: 12px;
            }
        }
        .relation-setting-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .relation-name {
                flex: auto;
                text-align: left;
                margin-left: 12px;
                .relation-name-cell {
                    color: #366AFF;
                    cursor: pointer;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }
        .relation-setting-content:hover {
            background-color: #f2f6fc;
        }
        .relation-content {
            display: flex;
            justify-content: flex-start;
            line-height: 24px;
            padding: 6px;
            .handle-name {
                flex: none;
                text-align: left;
                width: 200px;
                line-height: 20px;
                // height: 112px;
                max-height: 112px;
                background: #F6F8FD;
                border-radius: 5px;
                padding: 12px;
                font-size: 14px;
                color: #000000;
            }
            .relation-name {
                flex: auto;
                text-align: left;
                margin-left: 12px;
                //  width: 200px;
                // height: 112px;
                //  max-height: 112px;
                //  overflow: auto;
                background: #F6F8FD;
                border-radius: 5px;
                padding: 12px;
                padding-bottom: 0;
                font-size: 14px;
                color: #000000;
                .relation-name-cell {
                    color: #366AFF;
                    cursor: pointer;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    cursor: pointer;
                    height: 20px;
                    line-height: 20px;
                }
            }
        }
    }
    .intent-selected-content {
        height: 40px;
        padding-bottom: 30px;
        .copy-intent-footer {
            text-align: center;
            margin-top: 8px;

        }
          .cancel-btn{
            height: 37px;
            background: #FFFFFF;
            border: 2px solid #E0E6F7;
            border-radius: 19px;
            width: 99px;
            padding: 0;
            line-height: 37px;
        }
        .confirm-btn{
            height: 37px;
                width: 99px;
            background: #366AFF;
            border-radius: 19px;
                padding: 0;
            line-height: 37px;
        }
    }
}
</style>