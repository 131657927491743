<template>
    <div class="node-node-list">
        <div
            class="node-node"
            v-for="(childNodeItem, childNodeIndex) in JSON.parse(list)"
            :key="childNodeIndex"
        >
            <div class="node-pre"></div>
            <div class="node-node-name">
                <span>节点</span>
                <div
                    class="name-text csp"
                    @click="
                        configAction(
                            intentId,
                            childNodeItem.id,
                            childNodeItem.name
                        )
                    "
                >
                    {{ childNodeItem.name }}
                </div>
                <div
                    v-show="
                        ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                            !isOtherSkill) ||
                        (isOtherSkill && RP_Visible('OPEN_PROCESS_OTHER'))) && isPart != 1
                    "
                    class="el-icon-delete delete-icon-node"
                    @click="deleteNodeHandle(childNodeItem.id)"
                ></div>
            </div>
            <node-item
                :list="JSON.stringify(childNodeItem.children)"
                :intentId="intentId"
                :skillId="skillId"
                :indexArr="indexArr"
                :isOtherSkill="isOtherSkill"
                v-if="hasChildren(childNodeItem.children)"
            ></node-item>
            <div class="add-node-box">
                <span
                    v-show="
                        !(
                            (RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                                !isOtherSkill) ||
                            (isOtherSkill && RP_Visible('OPEN_PROCESS_OTHER'))
                        )
                    "
                    class="add-text-forb"
                    >+</span
                >
                <span
                    v-show="
                        !childNodeItem.isAddEditing &&
                        ((RP_Visible('OPEN_PROCESS_CUSTOMIZE') &&
                            !isOtherSkill) ||
                            (isOtherSkill && RP_Visible('OPEN_PROCESS_OTHER')))
                    "
                    class="add-text"
                    @click="addNodeHandle(childNodeItem.id)"
                    >+</span
                >
                <div v-show="childNodeItem.isAddEditing" class="add-input">
                    <el-input
                        v-model="nodeNameValue"
                        maxlength="20"
                        placeholder="请输入节点名称"
                        @keyup.enter.native="confirmAdd(childNodeItem.id)"
                    ></el-input>
                    <span
                        class="el-icon-success add-icon"
                        @click="confirmAdd(childNodeItem.id)"
                    ></span>
                    <span
                        class="el-icon-error delete-icon"
                        @click="concelAddNodeHandle(childNodeItem.id)"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bus from "./bus.js";
export default {
    name: "NodeItem",
    props: {
        list: "",
        intentId: "",
        skillId: "",
        isOtherSkill: false,
        indexArr: {
            type: String,
            required: true,
        },
        isPart: false
    },
    data() {
        return {
            nodeNameValue: "",
        };
    },
    methods: {
        hasChildren(children) {
            for (var i in children) {
                if (children[i]) {
                    if (children[i].show != "undefined") {
                        return true;
                    } else {
                        return children[i].show;
                    }
                }
            }
            return false;
        },
        confirmAdd(id) {
            Bus.$emit(
                "confirmAddNodeHandle",
                this.intentId,
                id,
                JSON.parse(this.indexArr),
                this.nodeNameValue
            );
        },
        addNodeHandle(id) {
            this.nodeNameValue = "";
            Bus.$emit(
                "addNodeHandle",
                this.intentId,
                id,
                JSON.parse(this.indexArr)
            );
        },
        concelAddNodeHandle(id) {
            Bus.$emit(
                "concelAddNodeHandle",
                this.intentId,
                id,
                JSON.parse(this.indexArr)
            );
        },
        deleteNodeHandle(id) {
            Bus.$emit("deleteNodeHandle", this.intentId, id);
        },
        configAction(intentId, id, name) {
            let isInitNode = "1";
            console.log(
                this.intentId,
                id,
                this.skillId,
                name,
                this.indexArr,
                1
            );
            Bus.$emit(
                "configAction",
                this.intentId,
                id,
                this.skillId,
                name,
                this.indexArr,
                isInitNode
            );
        },
    },
    mounted() {},
};
</script>

<style lang="less">
.node-node-list {
    margin-left: 180px;
    .node-node {
        // border-left: solid 2px #0099cc;
        margin: 14px 150px 20px 0;
        border-radius: 0 0 0 38px;
        .node-pre {
            height: 20px;
            width: 180px;
            margin-top: 50px;
            margin-left: -184px;
            border-left: solid 2px #0099cc;
            border-bottom: solid 2px #0099cc;
            text-align: center;
            color: #aaa;
            font-size: 12px;
            border-radius: 0px 0 0 38px;
        }
    }
}
</style>