<template>
  <div id="rich_text_pro_v2">
    <div class="rich_text_pro_cell_row">
      <span class="rich_text_pro_cell_title">选择卡片模版</span>
      <div class="rich_text_pro_cell_content">
        <el-select
          v-model="rich_text_pro_info.content.tamplateId"
          size="small"
          placeholder="请选择模版"
        >
          <el-option
            v-for="item in modelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="tamplate_image_box">
      <div class="tamplate_image">
        <img
          height="100%"
          src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2020/10/28/10/44/1eeb31f8-1d9b-4d70-8cfd-4750f48c95ee.png"
          alt=""
          sizes=""
          srcset=""
        />
      </div>
      <div class="tamplate_des">
        <p>卡片模板使用说明：</p>
        <p>1.支持自定义卡片样式，满足大部分消息卡片场景；</p>
        <p>2.如果卡片数量是多个，以轮播形式呈现。</p>
      </div>
    </div>
    <div class="rich_text_pro_cell_col">
      <span class="rich_text_pro_cell_title">卡片数量：</span>
      <div class="rich_text_pro_cell_content">
        <el-radio-group v-model="rich_text_pro_info.content.multiple" @change="multipleChange">
          <el-radio :label="false">单个卡片</el-radio>
          <el-radio :label="true">多个卡片</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div
      v-if="rich_text_pro_info.content.multiple == true"
      class="rich_text_pro_cell_row"
    >
      <span class="rich_text_pro_cell_title">选择数据来源</span>
      <div class="rich_text_pro_cell_content">
        <el-cascader
          size="small"
          @change="webhookMultivaluedCascChange"
          v-model="rich_text_pro_info.content.webhookId_key"
          :options="webhookMultivaluedCascList_"
          :props="{ expandTrigger: 'hover', label: 'name' }"
        ></el-cascader>
      </div>
    </div>
    <div class="rich_text_pro_cell_col" v-if="false && rich_text_pro_info.content.multiple == true">
      <span class="rich_text_pro_cell_title">样式：</span>
      <div class="rich_text_pro_cell_content">
        <el-radio v-model="rich_text_pro_info.content.style" label="slide"
          >轮播</el-radio
        >
        <el-radio v-model="rich_text_pro_info.content.style" label="list"
          >列表</el-radio
        >
      </div>
    </div>
    <div
      class="handle-insert-box"
      v-if="
        rich_text_pro_info.content.multiple == false ||
        rich_text_pro_info.content.webhookId_key.length > 0
      "
    >
      <el-button
        :disabled="rich_text_pro_info.content.content.htmlList.length == 0"
        type="primary"
        @click="templateConfigVisibleHandle('placeholder')"
        size="mini"
        plain
        >变量</el-button
      >
      <el-button
        :disabled="rich_text_pro_info.content.content.htmlList.length == 0"
        @click="templateConfigVisibleHandle('link')"
        type="primary"
        size="mini"
        plain
        >链接</el-button
      >
      <el-button
        :disabled="rich_text_pro_info.content.content.htmlList.length == 0"
        @click="templateConfigVisibleHandle('icon')"
        type="primary"
        size="mini"
        plain
        >图标</el-button
      >

      <el-button @click="addCellCou('SINGLE')" type="primary" size="mini"
        >单输入</el-button
      >
      <el-button @click="addCellCou('DOUBLE')" type="primary" size="mini"
        >双输入</el-button
      >
      <el-button @click="addCellCou('TRIPLE')" type="primary" size="mini"
        >三输入</el-button
      >
      <el-button @click="addCellCou('image')" type="primary" size="mini"
        >图片</el-button
      >
    </div>
    <div
      class="html-box"
      v-if="
        (rich_text_pro_info.content.multiple == false ||
          rich_text_pro_info.content.webhookId_key.length > 0) &&
        rich_text_pro_info.content.content.htmlList.length > 0
      "
    >
      <div
        v-for="(cell, index) in rich_text_pro_info.content.content.htmlList"
        :key="index"
      >
        <div class="image-cell" v-if="cell.type == 'image'">
          <div class="cell-in-content-image">
            <div class="rich-text-outer-box">
              <img v-if="cell.dynamic == 'false'" :src="cell.src" height="100%" alt="" srcset="" />
              <span v-if="cell.dynamic == 'true'" class="el-icon-picture-outline"></span>
            </div>
            <div @click="templateConfigVisibleHandle('url', index)" class="rich-text-url-upload">
              URL
            </div>
            <el-upload
              class="rich-text-image-upload"
              :show-file-list="showFileList"
              :action="importFileUrl"
              :before-upload="beforeImageMUpload"
              :on-success="
                (response, file, fileList) =>
                  handlePictureUploadSuccess(response, file, fileList, index)
              "
            >
              <i class="el-icon-upload"></i>
            </el-upload>
          </div>
          <span @click="deleteHtmlCell(index)" class="el-icon-delete"></span>
        </div>
        <div class="single-cell html-cell" v-if="cell.type == 'SINGLE'">
          <div class="cell-in-content">
            <ckeditor
              :editor="editorInline"
              :config="editorConfig"
              v-model="cell.list[0]"
              @focus="onEditorFocus"
            ></ckeditor>
          </div>
          <span @click="deleteHtmlCell(index)" class="el-icon-delete"></span>
        </div>
        <div class="double-cell html-cell" v-if="cell.type == 'DOUBLE'">
          <div
            v-for="(innerCell, innerCellIndex) in cell.list"
            :key="innerCellIndex"
            class="cell-in-content"
          >
            <ckeditor
              :config="editorConfig"
              :editor="editorInline"
              v-model="cell.list[innerCellIndex]"
              @focus="onEditorFocus"
            ></ckeditor>
          </div>
          <span @click="deleteHtmlCell(index)" class="el-icon-delete"></span>
        </div>
        <div class="triple-cell html-cell" v-if="cell.type == 'TRIPLE'">
          <div
            v-for="(innerCell, innerCellIndex) in cell.list"
            :key="innerCellIndex"
            class="cell-in-content"
          >
            <ckeditor
              :config="editorConfig"
              :editor="editorInline"
              v-model="cell.list[innerCellIndex]"
              @focus="onEditorFocus"
            ></ckeditor>
          </div>
          <span @click="deleteHtmlCell(index)" class="el-icon-delete"></span>
        </div>
      </div>
    </div>
    <div
      class="rich_text_pro_cell_row"
      v-if="
        rich_text_pro_info.content.multiple == false ||
        rich_text_pro_info.content.webhookId_key.length > 0
      "
    >
      <span class="rich_text_pro_cell_title">按钮</span>
      <div
        v-for="(buttonCell, buttonCellIndex) in rich_text_pro_info.content
          .content.buttonList"
        :key="buttonCellIndex"
        class="rich_text_pro_cell_content button_box"
      >
        <el-input
          size="mini"
          v-model="buttonCell.name"
          placeholder="请输入名称"
        ></el-input>
        <el-button
          v-if="buttonCell.name !== ''"
          @click="rtpAddBottondiaVisible(buttonCellIndex, buttonCell)"
          type="primary"
          size="mini"
          >点击事件</el-button
        >
        <span
          @click="deleteRtpBtn(buttonCellIndex)"
          class="el-icon-delete"
        ></span>
      </div>
      <span @click="addRtpBtn" class="rtp-add-btn">添加按钮</span>
    </div>
  </div>
</template>
<script>
function holder(data) {
  return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
  return holder("_" + webHooId + "." + key);
};

import {
  lastEditor,
  currentEditor,
  changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
  let lastIndex = key.lastIndexOf("]");
  return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
  return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
  name: "Rich_text_pro_info",
  props: [
    "rich_text_pro_info",
    "webhookMultivaluedCascList_",
    "activeARichtextproIndex",
  ],
  data() {
    return {
      uploadSrc: "",
      showFileList: false,
      importFileUrl: location.origin + "/api/oss",
      modelList: [
        {
          name: "自定义消息卡片模版",
          id: "1",
        },
      ],
      // currentEditor: null,
      // lastEditor: null,
      editorDataInline: "",
      // editor: CKEDITOR.ClassicEditor,
      editorInline: CKEDITOR.InlineEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          'alignment',
          "link",
          "bulletedList",
          "numberedList",
          "|",
          // "indent",
          // "outdent",
          // "|",
          // "imageUpload",
          // "blockQuote",
          // "insertTable",
          // "mediaEmbed",
          "undo",
          "redo",
        ],

        // askPluginListener: [
        //   beforeButtonInsert,
        //   beforePlaceholderInsert,
        //   beforeLoopBlockInsert,
        // ], // 注册监听
      },
    };
  },
  methods: {
    webhookMultivaluedCascChange(){
      console.log("change+++++++++++++++++++++")
      this.rich_text_pro_info.content.content.htmlList = [];
      this.rich_text_pro_info.content.content.buttonList = [];
    },
    multipleChange(){
      console.log("change+++++++++++++++++++++")
      this.rich_text_pro_info.content.content.htmlList = [];
      this.rich_text_pro_info.content.content.buttonList = [];
    },
    templateConfigVisibleHandle(type, cellIndex) {
      let param = { type: type };
      if (type == "url") {
        param.cellIndex = cellIndex;
      }
      param.webhookId_key = this.rich_text_pro_info.content.webhookId_key;
      param.activeARichtextproIndex = this.activeARichtextproIndex;
      param.multiple = this.rich_text_pro_info.content.multiple;
      param.lastEditor = lastEditor;
      this.$emit("templateConfigVisibleHandle", param);
    },
    rtpAddBottondiaVisible(index, info) {
      let webHookSCope = this.rich_text_pro_info.content.webhookId_key;
      console.log('webHookSCope',webHookSCope);
      // console.log(index, info,this.rich_text_pro_info);
      this.$emit("rtpAddBottondiaVisible", { index: index, info: info ,webHookSCope:webHookSCope});

      let param = { type: 'placeholder-pre' };
      param.webhookId_key = this.rich_text_pro_info.content.webhookId_key;
      param.activeARichtextproIndex = this.activeARichtextproIndex;
      param.multiple = this.rich_text_pro_info.content.multiple;
      // param.lastEditor = lastEditor;
      this.$emit("templateConfigVisibleHandle", param);
    },
    addImageUrl(index){

    },
    addCellCou(type) {
      switch (type) {
        case "image":
          this.rich_text_pro_info.content.content.htmlList.push({
            type: "image",
            src:'',
            dynamic: ''
          });
          break;
        case "SINGLE":
          this.rich_text_pro_info.content.content.htmlList.push({
            type: "SINGLE",
            list: [""],
          });
          break;
        case "DOUBLE":
          this.rich_text_pro_info.content.content.htmlList.push({
            type: "DOUBLE",
            list: ["", ""],
          });
          break;
        case "TRIPLE":
          this.rich_text_pro_info.content.content.htmlList.push({
            type: "TRIPLE",
            list: ["", "", ""],
          });
          break;
        default:
          break;
      }
    },
    deleteHtmlCell(index) {
      this.rich_text_pro_info.content.content.htmlList.splice(index, 1);
    },
    addRtpBtn() {
      this.rich_text_pro_info.content.content.buttonList.push({
        name: "",
        scope: "NODE",
        value: "",
        assignments: [
          {
            webhookId_key: "",
            _full_entity_entity: "",
          },
        ],
      });
    },
    deleteRtpBtn(index) {
      this.rich_text_pro_info.content.content.buttonList.splice(index, 1);
    },
    // 图片上传成功
    handleIconUploadSuccess(response, file, fileList) {
      if (response.code == 0) {
        console.log(response.data);
      }
    },
    handlePictureUploadSuccess(response, file, fileList, index) {
      if (response.code == 0) {
        // this.addImage(response.data);
        this.rich_text_pro_info.content.content.htmlList[index].src =
          response.data;
        this.rich_text_pro_info.content.content.htmlList[index].dynamic =
          'false';
        console.log(response.data);
        this.rich_text_pro_info.content.content.htmlList = [
          ...this.rich_text_pro_info.content.content.htmlList,
        ];
      }
    },
    beforeImageMUpload(file) {
      const isJPG =
        file.type === "image/JPG" ||
        file.type === "image/JPEG" ||
        file.type === "image/PNG" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/GIF" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error(
          "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
        );
      }
      if (!isLt2M) {
        this.$message.error(
          "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
        );
      }
      return isJPG && isLt2M;
    },
    onEditorFocus(zh, editor) {
      changeEditor(editor);
    },
    addEntityPlace(data) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: { name: data.label, data: entityDataHolder(data.value) },
      });
    },
    addWebHookSinglePlace(data, webhookId) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: {
          name: data.name,
          data: webHookDataHolder(webhookId, data.value),
        },
      });
    },
    addWebHookPlaceWithScope(data) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: {
          name: data.name,
          data: webHookDataHolderWithScope(data.value),
        },
      });
    },
    addImage(url) {
      console.log(currentEditor)
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "img",
        options: {
          dynamic:'true',
          src: url,
          alt: "alt",
          height: "100px",
        },
      });
    },
    addIcon(url) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "img",
        options: {
          dynamic:'false',
          src: url,
          alt: "alt",
          width: "20px",
          height: "20px",
        },
      });
    },
    addImageData() {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "img",
        // 这里用webhook返回值举例
        options: {
          src: webHookDataHolder(51, "data.img"),
          alt: "alt",
          width: "100px",
          height: "100px",
        },
      });
    },
    addHref(data) {
      console.log(data, 11111111);
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "a",
        options: data,
      });
    },
    addHrefData() {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "a",
        // 这里用webhook返回值举例
        options: { href: webHookDataHolder(51, "data.img"), name: "超链接" },
      });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.$on("addEntityPlace", function (data) {
        console.log(data);
        let command = currentEditor.commands.get("insertAskComponent");
        command.execute({
          tag: "span",
          options: { name: data.label, data: entityDataHolder(data.value) },
        });
      });
    });
  },
};
</script>
<style lang="less">
#rich_text_pro_v2 {
  a {
    color: blue !important;
  }
  a:visited {
    color: blue !important;
  }
  a:link {
    color: blue !important;
  }
  .rich_text_pro_cell_row {
    display: flex;
    flex-direction: column;
    .rich_text_pro_cell_title {
      text-align: left;
      margin-top: 8px;
    }
    .rich_text_pro_cell_content {
      margin-top: 8px;
      .el-select {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .button_box {
      margin-top: 0px;
      background-color: white;
      display: flex;
      padding: 8px;
      .el-button {
        margin-left: 8px;
      }
      .el-icon-delete {
        margin-top: 8px;
        margin-left: 8px;
        color: tomato;
        cursor: pointer;
      }
    }
  }
  .rtp-add-btn {
    display: block;
    height: 30px;
    line-height: 30px;
    color: #366AFF;
    background-color: white;
    cursor: pointer;
  }
  .rich_text_pro_cell_col {
    display: flex;
    flex-direction: row;

    .rich_text_pro_cell_title {
      text-align: left;
      margin-right: 20px;
      line-height: 19.2px;
      height: 19.2px;
      margin-top: 8px;
    }
    .rich_text_pro_cell_content {
      margin-top: 8px;
      .el-select {
        width: 100%;
      }
    }
  }
  .tamplate_image_box {
    margin: 8px 0;
    border-radius: 8px;
    background-color: rgba(226, 160, 60, 0.3);
    display: flex;
    padding: 8px;
    .tamplate_image {
      border-radius: 4px;
      overflow: hidden;
      width: 260px;
    }
    .tamplate_des {
      color: salmon;
      background-color: white;
      padding: 8px;
      margin-left: 8px;
      border-radius: 4px;
      text-align: left;
      p {
        margin: 4px 0;
        font-size: 12px;
      }
    }
  }
  .handle-insert-box {
    display: flex;
    justify-content: flex-start;
    margin: 8px 0 18px 0;
    background-color: rgba(0, 89, 255, 0.05);
    padding: 4px 0;
    .rich-text-image-upload {
      margin: 0 8px;
    }
  }
  .html-box {
    background-color: white;
    padding: 8px 0;
    .html-cell {
      display: flex;
      .cell-in-content {
        flex: auto;
        margin: 8px;
        display: flex;
        justify-content: space-between;
        background-color: rgba(0, 89, 255, 0.05);
        border: solid 1px rgba(0, 89, 255, 0.2);
        .ck-content {
          width: 100%;
        }
      }
      .el-icon-delete {
        height: 51px;
        line-height: 51px;
        color: tomato;
        margin-right: 16px;
        cursor: pointer;
      }
    }
    .image-cell {
      margin: 8px;
      height: 150px;
      display: flex;
      .cell-in-content-image {
        flex: auto;
        display: flex;
        .rich-text-outer-box {
          max-width: 450px;
          flex: auto;
          background-color: rgba(0, 89, 255, 0.05);
          .ck-content {
            height: 148px !important;
            border: solid 1px rgba(0, 89, 255, 0.2);
          }
          span {
            color: rgba(0, 89, 255, 0.2);
            font-size: 150px;

          }
        }
        .rich-text-url-upload {
          overflow: hidden;
          width: 50px;
          flex: none;
          background-color: rgba(0, 89, 255, 0.1);
          height: 150px;
          color: rgba(0, 89, 255, 0.5);
          line-height: 140px;
          border-right: solid 1px rgba(0, 89, 255, 0.2);
          cursor: pointer;
        }
        .rich-text-image-upload {
          overflow: hidden;
          width: 50px;
          flex: none;
          background-color: rgba(0, 89, 255, 0.1);
          height: 150px;
          line-height: 150px;
          .el-upload {
            width: 50px !important;
            .el-icon-upload {
              color: rgba(0, 89, 255, 0.5);
              font-size: 30px;
            }
          }
        }
      }
      .el-icon-delete {
        flex: none;
        height: 51px;
        line-height: 51px;
        color: tomato;
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>