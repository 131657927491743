<!--推荐标注意图弹框-->
<template>
    <div class="tagging-dialog">
        <popup @closeEvent="close" v-if="show">
                <div slot="popup-name">{{titleType === 'again' ? $t('trainList.questionList.remark') : $t('trainList.questionList.label')}}</div>
                <div slot="popup-tip">{{$t('trainList.labelIntent')}}</div>
                <div slot="popup-con">
                    <div :class="['question-name',questionType !== 'IMAGE' ? '' : 'image-box']" >
                        <div class="one-column-ellipsis text" v-if="questionType !== 'IMAGE'">{{questionName}}</div>
                        <el-image
                            v-else
                            :src="JSON.parse(questionName).urls[0]"
                            :fit="'cover'">
                        </el-image>
                    </div>
                   
                    <div class="select-intent-content">
                        <div class="top-content">
                            <span class="choose-tips">{{$t('trainList.chooseIntentional')}}</span>
                            <span>{{$t('trainList.chooseIntentionalTip')}}<span :class="['add-intent',limitUse ? 'botLimitUse' : '']" @click="addIntent">{{$t('trainList.newAddIntent')}}</span></span>
                        </div>
                        <!-- <el-cascader
                            width="100%"
                            v-model="intentId"
                            :filterable="true"
                            :options="intentTree4Radio"
                            @change="changeIntent"
                            placeholder="请搜索选择意图"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                label: 'name',
                                value: 'id',
                            }"
                        ></el-cascader> -->
                        <el-popover
                            ref="searchIntentPoprver"
                            placement="bottom"
                            width="502"
                            trigger="manual"
                            popper-class="search-intent-poprver"
                            :visible-arrow="false"
                            v-model="visible">
                            <el-input
                                :placeholder="$t('trainList.filter')"
                                v-model="filterText"
                                slot="reference"
                                @click.native="openTree" >
                                <i slot="suffix" class="el-icon-arrow-down" @click.stop="openTree"></i>
                            </el-input>
                            <el-tree
                                style="width:502px;max-height:300px;overflow-x:hidden;overflow-y:auto;"
                                :data="intentTree4Radio"
                                :props="defaultProps"
                                default-expand-all
                                :filter-node-method="filterNode"
                                node-key="id"
                                ref="tree">
                                <span :class="['custom-tree-node',  data.type === 'intent'  ? '' : 'disabled']" slot-scope="{node,data}"   @click="onNodeClick(data,node)">
                                    <span class="custom-tree-node-left">
                                        <span class="data-label">{{ data.name }}</span>
                                    </span>
                                </span>
                            </el-tree>
                        </el-popover>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button @click="close" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
                    <el-button class="confirm-btn" type="primary" @click="tagIntent('dia')" round  style="padding:0 !important;">{{$t('common.confirm')}}</el-button>
                </div>
        </popup>
        <addIntentPopup 
            ref="addIntentPopup" 
            :botId="botId" 
            :currentBotInfo="currentBotInfo" 
            :markData="markData" 
            :questionName="questionName" 
            :questionType="questionType"
            @close="close">
        </addIntentPopup>
    </div>
</template>

<script>
import Popup from '../../components/popup.vue';
import addIntentPopup from './component/addIntent.vue'
export default {
    props:["currentBotInfo"],
    components:{Popup,addIntentPopup},
    data() {
        return {
            show: false,
            skillList: [],
            skillId: '',
            keyWord: '',
            intentId: '',
            classId: 1,
            intentList: [],
            classList: [],
            intentName: '',
            type: '',
            tId: '',
            isIdentify: '',
            isRight: '',
            loading: false,
            nodeTree: [],
            intentTree4Radio: [],
            botId: "",
            titleType:'',
            questionName:"",
            limitUse: true,
            markData:{},
            filterText:'',
            visible:false,
            defaultProps: {
                children: 'children',
                label: 'name',
                id:"id",
                isLeaf: 'isLeafNode',
            },
            isCheck:true,
            questionType:"",

            waitMarkList: [], // 批量 标注 代标注问题标注前意图id 和 问题id 列表 
        }
    },
    watch: {
        filterText(val) {
            if(!this.isCheck){
                this.visible = true;
            }
            this.$refs.tree.filter(val);
        },
        show(n){
            this.visible = false
            this.isCheck = false;
            this.intentId = '';
            this.filterText = '';
        }
    },
    mounted() {
        this.isCheck = false;
        this.getIntentList();
    },
    // 20210812 改版 
    methods: {
        onNodeClick(data,node){
            if(data.type === "recommendIntent" || data.type === "skill" || data.type === "class") return false;
            this.filterText = data.name;
            this.intentId = data.id;
            this.isCheck = true;
            this.visible = false;
        },
        openTree(){
            this.filterText = '';
            this.visible = !this.visible;
        },
        filterNode(value, data) {
            if (!value) return true;
            if(data.name.indexOf(value) !== -1){
                if(data.id === "recommendIntent"){
                    return false;
                } 
                return true;
            }
            // return data.name.indexOf(value) !== -1;
        },
        //新建意图
        addIntent(){
            // this.show = false;
            this.visible = false;
            this.$refs.addIntentPopup.addIntent();
        },
        getLimitMsg(){
            this.FetchGet(
                this.requestUrl.bot.selectLimitMsg + '?uid=' + localStorage.getItem("_uid")
                ).then((res) => {
                    if (res.code === "0") {
                        this.limitUse = !res.data.canCreateIntent;
                        localStorage.setItem("_limitMsg", JSON.stringify(res.data));
                    }
                });
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId,data) {
            this.botId = bId;
            this.FetchGet(this.requestUrl.trainList.intentTree, { bId: bId })
                .then((res) => {
                    if (res.code === "0") {
                        this.nodeTree = [...res.data];
                        let nodeTree = JSON.parse(JSON.stringify(res.data));
                        nodeTree.forEach((item,index) => {
                            item.type = 'skill';
                            item.children && item.children.forEach((v,i) => {
                                v.type = 'class';
                                v.children && v.children.forEach((_Item,_Index) => {
                                    _Item.type = 'intent';
                                })
                            })
                        })
                        // '?apiKey='+this.currentBotInfo.apiKey+'&question='+this.questionName
                        this.FetchPost(this.requestUrl.trainList.recommendedIntent,{
                            apiKey:this.currentBotInfo.apiKey,
                            question:this.questionName,
                            type:data.type,
                            botId:bId
                        })
                            .then((res2) => {
                                if (res2.code === "0") {
                                    console.log(res2,'res');
                                    let recommandIntent = [{
                                        name:'推荐标注意图',
                                        id:'recommendIntent',
                                        type:'recommendIntent',
                                        children:[]
                                    }];
                                    if( res2.data && res2.data.length > 0){
                                        res2.data.forEach((item,index) => {
                                            recommandIntent[0].children.push(Object.assign(item,{
                                                type:'intent'
                                            }))
                                        })
                                    } else {
                                        recommandIntent = [];
                                    }
                                    
                                    this.intentTree4Radio = [...recommandIntent,...nodeTree];
                                } else {
                                    this.intentTree4Radio = [...nodeTree];
                                }
                            })
                            .catch((err) => {
                                this.intentTree4Radio = [...nodeTree];
                                this.$message(err);
                                this.intentTree4Radio = [...nodeTree];
                            });
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取意图名称
        changeIntent(val){
            this.intentTree4Radio.forEach(skill => {
                if (skill.children != null) {
                    skill.children.forEach(_class => {
                        if (_class.children != null) {
                            _class.children.forEach(intent => {
                                if (intent.id == val) {
                                    this.intentName = intent.name;
                                }
                            })
                        }
                    })
                }
            })
        },
        open(row, isRight, type, questionIds,titleType) {
            console.log(row,'row');
            this.questionType = row.type;
            this.titleType = titleType;
            this.questionName = row.question;
            this.loading = true;
            this.show = true;
            this.getSkill();
            this.isRight = isRight;
            this.type = type;
            if (type) {
                this.tId = questionIds.join(',');
                this.isIdentify = row[0].isIdentify;
            } else {
                this.tId = row.id;
                this.isIdentify = row.isIdentify;
            }
            this.markData = {
                isRight:this.isRight,
                type:this.type,
                isIdentify:this.isIdentify,
                intentId:null,
                intentName:"",
                tId:this.tId
            }
            if (type) {
                this.waitMarkList = [];
                row.forEach((item) => {
                    this.waitMarkList.push({
                        intentId: item.intentId,
                        id: item.id
                    })
                })
            } else {
                console.log(row.intentId);
                this.waitMarkList = [{
                    intentId: row.intentId,
                    id: row.id
                }];
            }
            this.getLimitMsg();
        },
        close() {
            this.show = false;
            this.$emit('close');
            this.classList = [];
            this.intentList = [];
            this.skillList = [];
            this.keyWord = '';
            this.tId = '';
        },
        // 机器人知识库列表
        getSkill(id) {
            this.FetchGet(this.requestUrl.trainList.skill, {
                bid: this.botId
            }).then(res => {
                if (res.code === "0") {
                    res.data.forEach((v, i) => {
                        v.checked = false;
                        if (i === 0) v.checked = true;
                    })
                    this.skillList = res.data;
                    this.skillId = id || res.data[0].id;
                    this.getClass();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        // 意图列表
        getIntentList() {
            this.classId !== 1 && this.FetchGet(this.requestUrl.trainList.intentList, {
                // keyWord: this.keyWord,
                typeId: this.classId
            }).then(res => {
                if (res.code === "0") {
                    res.data.forEach((val, ind) => {
                        val.checked = false
                        // if (ind === 0) val.checked = true
                    });
                    this.intentList = res.data;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
        // 选择意图
        selectIntent(item) {
            this.intentList.forEach(v => {
                v.checked = false;
                if (v.id === item.id) v.checked = true;
            })
            this.intentId = item.id;
            this.intentName = item.name;
        },
        // 搜索意图
        searchIntent() {
            this.FetchGet(this.requestUrl.trainList.skillIntent, {
                skillId: this.skillId,
                keyWord: this.keyWord
            }).then(res => {
                if (res.code === "0") {
                    res.data.forEach((val, ind) => {
                        val.checked = false
                        if (ind === 0) {
                            val.checked = true;
                            this.classId = val.typeId;
                            // this.keyWord = '';
                        }
                        this.classList.forEach(v => {
                            v.checked = false;
                            if (v.id === this.classId) v.checked = true;
                        })
                    });
                    this.intentList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取分类
        getClass(id) {
            this.FetchGet(this.requestUrl.trainList.intentTypes, {
                skillId: this.skillId,
                // keyWord: this.keyWord
            }).then(res => {
                if (res.code === "0") {
                    this.classList = res.data;
                    this.selectClass(res.data[0])
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 选择分类下的意图
        selectClass(item) {
            this.classList.forEach(v => {
                v.checked = false;
                if (v.id === item.id) v.checked = true;
            })
            this.classId = item.id;
            this.getIntentList();
        },
        // 标注
        tagIntent(source) {
            console.log(this.waitMarkList);
            // 单条标注
            if (this.type === 0) {
                if (this.intentId == "" && source == "dia") {
                    this.$message.error(this.$t('addIntent.intentRequired'));
                } else {
                    if (this.waitMarkList[0] && this.waitMarkList[0].intentId != null) {
                        if (this.waitMarkList[0].intentId == this.intentId) {
                            this.isRight = true;
                        }
                    }
                    this.FetchPost(this.requestUrl.trainList.mark, {
                        intentId: this.intentId,
                        intentName: this.filterText,
                        isIdentify: this.isIdentify,
                        isRight: this.isRight,
                        tId: this.tId
                    }).then(res => {
                        if (res.code === "0") {
                            this.intentId = "";
                            this.close();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
                
            // 批量标注
            } else {
                let isRightIds = [];
                this.waitMarkList.forEach(item => {
                    if (this.intentId == item.intentId) {
                        isRightIds.push(item.id);
                    }
                })

                this.FetchPost(this.requestUrl.trainList.markBatch + '?' + new URLSearchParams({
                    intentId: this.intentId,
                    intentName: this.filterText,
                    isIdentify: this.isIdentify,
                    isRight: this.isRight,
                    tIds: this.tId
                }), isRightIds).then(res => {
                    if (res.code === "0") {
                        this.close();
                    } else {
                        this.$message.error(res.message);
                    }
                });

                // this.FetchPost(this.requestUrl.trainList.markBatch, {
                //     intentId: this.intentId,
                //     intentName: this.filterText,
                //     isIdentify: this.isIdentify,
                //     isRight: this.isRight,
                //     tIds: this.tId
                // }, [1, 2]).then(res => {
                //     if (res.code === "0") {
                //         this.close();
                //     } else {
                //         this.$message.error(res.message);
                //     }
                // });
            }
        }
    }
}
</script>
<style lang="less">
.el-popover.search-intent-poprver{
    z-index: 3333 !important;
    .el-tree{
        overflow-y: auto;
        color: #606266;
        .el-tree-node__content{
            height: 33px!important;
            display: flex;
            align-items: center;
        }
        .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer !important;
            height: 33px;
            &.disabled{
                cursor: not-allowed !important;
            }
        }
    }
}
</style>
<style lang="less">
.tagging-dialog{
    text-align: left;
    .question-name{
        background-color: #FBFCFD;
        padding: 0 10px;
        border-radius: 5px;
        .text{
            height: 38px;
            display: flex;
            align-items: center;
        }
        .el-image{
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
        &.image-box{
            height: 120px;
            display: flex;
            align-items: center;
        }
    }
    
    .select-intent-content{
        width: 100%;
        margin: 16px 0 32px;
        display: flex;
        flex-direction: column;
        .top-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            color: #A3ADC6;
            
            .choose-tips{
                color: #000;
            }
            .add-intent{
                color: #366AFF;
                cursor: pointer;
                margin-left: 2px;
                &.botLimitUse{
                    opacity: 0.5;
                    cursor: no-drop;
                }
            }
        }
        .el-cascader {
            width: 100%;
        }
        .el-input{
            cursor: pointer;
            .el-input__suffix{
                right: 10px;
                top: 10px;
                .el-icon-arrow-down{
                    font-size: 18px;
                }
            }
        }
        
        
    }
    .el-dialog__header{
        padding: 0!important;
    }
    .select-title{
        text-align: left;
        height: 32px;
        line-height: 32px;
    }
    .select-intent{
        display: flex;
        border: 1px solid #DCDFE6;
        margin-top: 2px;
        border-radius: 4px;
        text-align: left;
        > div{
            max-height: 50vh;
            overflow: auto;
            p{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: 32px;
                padding: 0 5px;
                line-height: 32px;
                border-bottom: 1px solid #DCDFE6;
                cursor: pointer;
            }
            p:hover{
                background: #366AFF;
                color: #ffffff;
            }
            p:last-child{
                border-bottom: none;
            }
            .ischecked{
                background: #366AFF;
                color: #ffffff;
            }
        }
        .knowledge-base{
            width: 150px;
            border-right: 1px solid #DCDFE6;
        }
        .intention{
            flex: 1;
            height: auto;
        }
    }
    .intent-footer{
        margin-top: 10px;
        text-align: right;
    }
}
</style>