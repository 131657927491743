<template>
    <div class="node-de">
        <div class="node-de-title">
            <span
                >{{$t('trainList.questionList.nodeLocation')}}：{{ intentInfo.intentName }} / {{
                    intentInfo.nodeName
                }}</span
            >
        </div>
        <div class="node-de-tree">
            <el-tree
                :data="[intentInfo]"
                node-key="id"
                indent="40"
                icon-class="“none”"
                :props="treeProps"
                default-expand-all
                :expand-on-click-node="false"
            >
                <div class="custom-tree-node" slot-scope="{ node, data }">
                    <div class="name-leaf">
                        <span v-if="data.type == 'intent'" class="type-intent"
                            >【{{$t('trainList.intent')}}】{{ node.label }}</span
                        >
                        <span
                            v-if="
                                data.type != 'intent' && data.id != activeNodeId
                            "
                            class="type-node"
                            >【{{$t('trainList.questionList.node')}}】{{ node.label }}</span
                        >
                        <span
                            v-if="
                                data.type != 'intent' && data.id == activeNodeId
                            "
                            class="active-type-node"
                            >【{{$t('trainList.questionList.node')}}】{{ node.label }}</span
                        >
                    </div>
                </div>
            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    props: ["activeItem"],
    components: {},
    data() {
        return {
            intentInfo: {},
            treeProps: {
                label: "name",
                children: "childrenNode",
            },
            activeNodeId: "",
        };
    },
    watch: {},
    mounted() {
        let activeItemObj = this.activeItem;
        this.activeNodeId = activeItemObj.nodeId;
        this.FetchGet(
            this.requestUrl.feedback.dialogProcess +
                "?intentId=" +
                activeItemObj.intentId
        ).then((res) => {
            if (res.code === "0") {
                let tampObj = {
                    id: activeItemObj.intentId,
                    type: "intent",
                    name: activeItemObj.intentName,
                    intentName: activeItemObj.intentName,
                    childrenNode: [res.data.initNode],
                };
                this.intentInfo = tampObj;
                const findNodeName = (arr) => {
                    if (arr != null) {
                        arr.forEach((item) => {
                            if (item.id == this.activeNodeId) {
                                this.intentInfo.nodeName = item.name;
                            } else {
                                findNodeName(item.childrenNode);
                            }
                        });
                    }
                };
                findNodeName(this.intentInfo.childrenNode);
            } else {
                this.$message.error(res.message);
            }
        });
    },
    methods: {},
};
</script>

<style lang="less">
.el-tree-node__content {
    // margin: 5px 0 !important;
}
.custom-tree-node {
    cursor: default !important;
    .name-leaf {
        display: inline-block;
        margin: 5px 0;
        padding: 0 8px;
        .type-intent {
            display: inline-block;
            color: #f56c6c;
            text-align: center;
        }
        .type-node {
            display: inline-block;
            color: #909399;
            text-align: center;
        }
        .active-type-node {
            display: inline-block;
            color: #366AFF;
            text-align: center;
            font-weight: 800;
        }
    }
}

.node-de {
    height: 100%;
    flex: none;
    width: 360px;
    border: solid 1px #dcdfe6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .node-de-title {
        flex: none;
        height: 32px;
        text-align: left;
        padding: 0 8px;
        border-bottom: solid 1px #dcdfe6;
        span {
            display: inline-block;
            width: 340px;
            height: 32px;
            line-height: 32px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .node-de-tree {
        margin-top: 8px;
        flex: auto;
        overflow: auto;
    }
}
</style>