<template>
    <div class="relation-recommend-item">
        <div class="relation-recommend-switch">
            <span class="relation-recommend-switch-pre">是否启用关联推荐</span>
            <span class="relation-recommend-switch-after"
                >(暂不支持小程序、网页版)</span
            >
            <el-switch
                size="mini"
                v-model="unit.content.recommend.status"
                active-color="#366AFF"
                inactive-color="#C0C4CC"
            >
            </el-switch>
        </div>
        <div
            v-if="
                unit.content.recommend.status ||
                (!unit.content.recommend.status &&
                    !isInitRec(unit.content.recommend))
            "
            class="relation-recommend-content"
        >
            <div class="relation-recommend-content-des">
                <div class="relation-recommend-content-des-title">
                    关联意图推荐描述
                </div>
                <el-input
                    size="small"
                    v-model="unit.content.recommend.description"
                    placeholder="请输入关联意图推荐描述"
                ></el-input>
            </div>
            <div
                v-if="unit.content.recommend.list.length != 0"
                class="relation-recommend-content-title"
            >
                <span class="relation-recommend-content-title-pre"
                    >关联意图</span
                >
                <span class="relation-recommend-content-title-aft"
                    >推荐文案</span
                >
            </div>

            <div class="relation-recommend-content-list">
                <div
                    v-for="(recommendIntent, recommendIntentIndex) in unit
                        .content.recommend.list"
                    :key="recommendIntentIndex"
                    class="relation-recommend-content-item"
                >
                    <div class="relation-recommend-content-item-intent">
                        <el-cascader
                            size="small"
                            :show-all-levels="false"
                            @change="
                                intentSelectedValue(
                                    $event,
                                    recommendIntentIndex
                                )
                            "
                            v-model="recommendIntent.ext.intentValue"
                            placeholder="请搜索或选择意图"
                            :options="intentSAkillTypeOptionsNoChildren"
                            filterable
                        ></el-cascader>
                    </div>
                    <div class="relation-recommend-content-item-text-type">
                        <el-select
                            size="small"
                            v-model="recommendIntent.type"
                            placeholder="请选择"
                            @change="
                                recommendIntentCypeChange(
                                    $event,
                                    recommendIntentIndex
                                )
                            "
                        >
                            <el-option
                                v-for="item in [
                                    { value: 0, label: '自定义' },
                                    { value: 1, label: '跟随意图' },
                                ]"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="relation-recommend-content-item-text">
                        <el-input
                            size="small"
                            :disabled="recommendIntent.type == 1"
                            v-model="recommendIntent.text"
                            placeholder="请输入"
                        ></el-input>
                    </div>
                    <div class="relation-recommend-content-item-handle">
                        <!-- <span
                            v-if="recommendIntentIndex == 0"
                            class="el-icon-circle-plus handle-icon"
                            @click="addText(recommendIntentIndex)"
                        ></span> -->
                        <span
                            class="
                                el-icon-circle-close
                                handle-icon handle-icon-delete
                            "
                            @click="deleteText(recommendIntentIndex)"
                        ></span>
                    </div>
                </div>
            </div>
            <div
                v-if="unit.content.recommend.list.length == 0"
                class="empty-intent-r"
            >
                暂无关联意图设置
            </div>
            <span
                class="bottom-plus-btn el-icon-circle-plus handle-icon"
                @click="addText"
            ></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["unit", "intentSAkillTypeOptionsNoChildren", "index"],
    data() {
        return {};
    },
    methods: {
        intentSelectedValue($event, recommendIntentIndex) {
            this.unit.content.recommend.list[recommendIntentIndex].id =
                $event[1];
            if (
                this.unit.content.recommend.list[recommendIntentIndex].type ==
                "1"
            ) {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    this.filterRecoText(
                        this.unit.content.recommend.list[recommendIntentIndex]
                            .id
                    );
            } else {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    "";
            }
        },
        recommendIntentCypeChange(val, recommendIntentIndex) {
            this.unit.content.recommend.list[recommendIntentIndex].type = val;
            if (val == "1") {
                if (
                    this.unit.content.recommend.list[recommendIntentIndex].id !=
                    ""
                ) {
                    this.unit.content.recommend.list[
                        recommendIntentIndex
                    ].text = this.filterRecoText(
                        this.unit.content.recommend.list[recommendIntentIndex]
                            .id
                    );
                } else {
                    this.unit.content.recommend.list[
                        recommendIntentIndex
                    ].text = "";
                }
            } else {
                this.unit.content.recommend.list[recommendIntentIndex].text =
                    "";
            }
        },
        filterRecoText(id) {
            let name = "";
            this.intentSAkillTypeOptionsNoChildren.forEach((item) => {
                item.children.forEach((intent) => {
                    if (intent.id == id) {
                        name =
                            intent.exampleQuestion == ""
                                ? intent.label
                                : intent.exampleQuestion;
                    }
                });
            });
            return name;
        },
        // 添加文本答案输入框
        addText(index) {
            this.unit.content.recommend.list.push({
                id: "",
                type: 0,
                text: "",
                ext: {
                    intentValue: [],
                },
            });
        },
        // 删除文本答案输入框
        deleteText(index) {
            this.unit.content.recommend.list.splice(index, 1);
        },
        // 判断是否还是初始化推荐
        isInitRec(rec) {
            let flag = true;
            if (rec.description != "") {
                flag = false;
            } else {
                if (rec.list.length != 0) {
                    rec.list.forEach(item => {
                        if (item.id != "" || item.text != "") {
                            flag = false;
                        }
                    })
                } 
            }
            return flag;
        },
    },
};
</script>

<style lang="less" scoped>
.relation-recommend-item {
    margin-top: 8px;
    .relation-recommend-switch {
        display: flex;
        justify-content: flex-start;
        height: 20px;
        line-height: 20px;
        .relation-recommend-switch-pre {
            margin-right: 4px;
        }
        .relation-recommend-switch-after {
            color: #606266;
            font-size: 10px;
            margin-right: 8px;
        }
    }
    .relation-recommend-content {
        margin-top: 6px;
        .relation-recommend-content-des {
            margin-right: 50px;
            .relation-recommend-content-des-title {
                text-align: left;
                margin-bottom: 8px;
            }
        }
        .relation-recommend-content-title {
            display: flex;
            justify-content: flex-start;
            text-align: left;
            margin-top: 12px;
            .relation-recommend-content-title-pre {
                flex: none;
                width: 150px;
            }
        }
        .bottom-plus-btn {
            margin-top: 8px;
            font-size: 26px;
        }
        .empty-intent-r {
            margin-top: 16px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
        }
        .relation-recommend-content-list {
            margin-top: 4px;
            .relation-recommend-content-item {
                margin-top: 4px;
                display: flex;
                justify-content: flex-start;
                .relation-recommend-content-item-intent {
                    flex: none;
                    width: 146px;
                }
                .relation-recommend-content-item-text-type {
                    margin-left: 4px;
                    width: 100px;
                    flex: none;
                }
                .relation-recommend-content-item-text {
                    flex: auto;
                    margin-left: 4px;
                }
                .relation-recommend-content-item-handle {
                    width: 40px;
                    margin-left: 6px;
                    margin-right: 16px;
                    flex: none;
                    .handle-icon {
                        width: 40px;
                        flex: none;
                        font-size: 24px;
                        margin-top: 2px;
                    }
                    .handle-icon-delete {
                        color: red;
                    }
                }
            }
        }
    }
}
</style>