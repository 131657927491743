<template>
  <div>
    <vue-ueditor-wrap
        v-model="unit.content.html"
        :config="editorConfig"
    ></vue-ueditor-wrap>
  </div>
</template>
<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import TurndownService from 'turndown';

const turnDownService = new TurndownService();
export default {
  name: 'UEditorRichText',
  components: {VueUeditorWrap},
  props: ['unit'],
  mounted() {
    console.debug('ueditor comment mounted',this.unit);
  },
  data() {
    return {
      markdown: '',
      editorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 200,
        // 字数统计
        wordCount: false,
        // 元素路径
        elementPathEnabled: false,
        // 初始容器宽度
        initialFrameWidth: "98%",
        // 上传文件接口, 报错属于正常，若需要验证可使用(也是盗大神的)http://35.201.165.105:8000/controller.php
        // 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
        // serverUrl: "https://open.guoranbot.com/ueditor",
        // serverUrl: "http://test.open.abitai.com",
        serverUrl: "/ueditor-api/",
        errorHandler: (e) => {
          console.log(e);
        },

        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: [
          [
            "undo", //撤销
            "redo", //重做
            "bold", //加粗
            "indent", //首行缩进
            "snapscreen", //截图
            "italic", //斜体
            "underline", //下划线
            "strikethrough", //删除线
            "subscript", //下标
            "superscript", //上标
            "source", //源代码
            "removeformat", //清除格式
            "fontfamily", //字体
            "fontsize", //字号
            "paragraph", //段落格式
            "simpleupload", //单图上传
            "link", //超链接
            "emotion", //表情
            // "insertvideo", //视频
            "justifyleft", //居左对齐
            "justifyright", //居右对齐
            "justifycenter", //居中对齐
            "justifyjustify", //两端对齐
            "forecolor", //字体颜色
            "backcolor", //背景色
            // "insertorderedlist", //有序列表
            // "insertunorderedlist", //无序列表
            "fullscreen", //全屏
            "attachment", //附件
            "imagecenter", //居中
            // "edittip ", //编辑提示
            // "inserttable", //插入表格
          ],
        ],
        fontfamily: [
          { label: "", name: "songti", val: "宋体,SimSun" },
          { label: "仿宋", name: "fangsong", val: "仿宋,FangSong" },
          {
            label: "仿宋_GB2312",
            name: "fangsong",
            val: "仿宋_GB2312,FangSong",
          },
          {
            label: "",
            name: "kaiti",
            val: "楷体,楷体_GB2312, SimKai",
          },
          {
            label: "",
            name: "yahei",
            val: "微软雅黑,Microsoft YaHei",
          },
          { label: "", name: "heiti", val: "黑体, SimHei" },
          { label: "", name: "lishu", val: "隶书, SimLi" },
          { label: "", name: "andaleMono", val: "andale mono" },
          {
            label: "",
            name: "arial",
            val: "arial, helvetica,sans-serif",
          },
          {
            label: "",
            name: "arialBlack",
            val: "arial black,avant garde",
          },
          { label: "", name: "comicSansMs", val: "comic sans ms" },
          { label: "", name: "impact", val: "impact,chicago" },
          {
            label: "",
            name: "timesNewRoman",
            val: "times new roman",
          },
        ],
      }
    }
  },
  watch: {
    unit: {
      handler(newVal, oldVal) {
        this.markdown = turnDownService.turndown(newVal.content.html);
        // console.debug('ueditor value change ,html:', newVal.content.html, 'markdown:', this.markdown);
        this.unit.content.markdown = this.markdown;
      },
      immediate: true,
      deep: true
    }
  }
}
</script>