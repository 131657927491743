<template>
    <div id="displayWhole">
        <el-drawer :title="entity.name" :visible.sync="diavisible" size="250px" direction="rtl" :modal="false"  >
            <div class="entity">
                <div class="intent-inner">
                    <div class="word-list">
                        <div class="word-cell" v-for="( content,i) in entity.content" :key="i">
                            <span class="standard-word">{{content.standardWord}}</span>
                            <span class="synonyms">{{Array.isArray(content.synonyms)?content.synonyms.join(','):""}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    export default {
        props: ["displayWhole", "entity"],
        data() {
            return {
                diavisible: false
            };
        },
        mounted() {
            this.diavisible = this.displayWhole;
        },
        updated() {
            this.$emit("changeDisplayWholeVisible", this.diavisible);
        },
        watch: {
            displayWhole: function () {
                this.diavisible = this.displayWhole;
            }
        }
    };
</script>
<style lang="less" scoped>
    @import "./../../assets/less/entity/displayWholeDrawer.less";
</style>

