<template>
    <div class="adv-search-cpnt">
        <div class="adv-search-cpnt-content">
            <el-row>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.questionType')}}</div>
                        <div class="adv-s-cell-b">
                            <el-select  v-model="queryType" :placeholder="$t('common.selectPlaceholder')" ref="queryTypeSelect">
                                <el-option
                                    v-for="item in queryTypeOptions"
                                    :key="item.value"
                                    :label="$t('trainList.questionList.' + item.value)"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.recognizedIntent')}}</div>
                        <div class="adv-s-cell-b">
                            <el-cascader
                                style="width: 100%"
                                :options="intentTree"
                                :collapse-tags="true"
                                key="advIntentSearch"
                                :props="props"
                                :show-all-levels="false"
                                :placeholder="$t('advSearch.recognizedPlaceholder')"
                                v-model="intentIds"
                                filterable
                            ></el-cascader>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.lastTime')}}</div>
                        <div class="adv-s-cell-b">
                            <el-date-picker
                                ref="lastMsgTimePicker"
                                prefix-icon="guoran-a-18-15 iconfont"
                                v-model="lastMsgTime"
                                type="daterange"
                                align="right"
                                :unlink-panels="true"
                                range-separator="至"
                                :start-placeholder="$t('common.startDate')"
                                :end-placeholder="$t('common.endDate')"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.dept')}}</div>
                        <div class="adv-s-cell-b">
                            <!-- <el-cascader
                                v-model="orgnation"
                                :key="cascaderKey"
                                :options="orgTree"
                                :collapse-tags="true"
                                :props="{
                                    multiple: false,
                                    checkStrictly: true,
                                    emitPath: false,
                                }"
                                @change="orgCascaderChange"
                                clearable
                            ></el-cascader> -->
                            <div class="input-divs" @click="openStaffPopup(item,index)">
                                <div v-if="memberInfo4Client.clientInfo && memberInfo4Client.clientInfo.length > 0" class="one-column-ellipsis">
                                        <span class="have-checked-intent" v-for="(item,index) in memberInfo4Client.clientInfo" :key="index">
                                            <span
                                                class="data-label"
                                                v-if="item.selfType === 0 || item.selfType === 1">
                                                <span v-if="item.staffType !== 'staff-t'">{{item.label}}</span>
                                                <span v-else>
                                                    <open-data :type="item.selfType == 0 ? 'departmentName' : 'userName'" :openid="item.label"></open-data>
                                                </span>
                                            </span>
                                            <span v-else>{{item.name}}</span>
                                            <span v-if="index+1 <  memberInfo4Client.clientInfo.length">,</span>
                                        </span>
                                        
                                </div>
                                <div class="placeholder-text" v-else>{{$t('common.selectPlaceholder')}}</div>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.operator')}}</div>
                        <div class="adv-s-cell-b">
                            <el-select
                                v-model="noteUserIds"
                                multiple
                                filterable
                                :placeholder="$t('common.selectPlaceholder')"
                            >
                                <el-option
                                    v-for="item in noteUsers"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.finallyMarkTime')}}</div>
                        <div class="adv-s-cell-b">
                            <el-date-picker
                                ref="lastNoteTimePicker"
                                prefix-icon="guoran-a-18-15 iconfont"
                                v-model="lastNoteTime"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                :start-placeholder="$t('common.startDate')"
                                :end-placeholder="$t('common.endDate')"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <div class="adv-search-cell">
                        <div class="adv-s-cell-t">{{$t('advSearch.isMarked')}}</div>
                        <div class="adv-s-cell-b">
                            <el-radio-group  v-model="noteStetus">
                                <el-radio :label="2">{{$t('advSearch.all')}}</el-radio>
                                <el-radio :label="1">{{$t('advSearch.marked')}}</el-radio>
                                <el-radio :label="0">{{$t('advSearch.unmarked')}}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="adv-s-footer">
            <div style="width:1px;height:1px;" class="hidden-dom"></div>
            <el-button size="small" @click="closeAdvSerch">{{$t('common.cancel')}}</el-button>
            <el-button size="small" @click="submitAdvSerch" type="primary">{{$t('advSearch.screen')}}</el-button>
        </div>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
            <div slot="popup-name">{{$t('advSearch.dept')}}</div>
            <div slot="popup-con" class="member-contanier">
                <div class="choose-style">
                    <div>{{$t('advSearch.deptTip')}}</div>
                    <div class="radio-box">
                        <el-radio v-model="chooseStyle" label="all">{{$t('advSearch.all')}}</el-radio>
                        <el-radio v-model="chooseStyle" label="self">{{$t('advSearch.custom')}}</el-radio>
                    </div>
                </div>
                <staffCustomer
                    v-if="chooseStyle === 'self'"
                    dataType="staff"
                    chooseType="dept"
                    :isMultiple="false"
                    :selectClient="selectClient"
                    @onNodeClick="onNodeClick"
                    :clientValue="clientValue"
                    :memberInfo4Client="memberInfo4Client"
                    ref="staffCustomer"
                ></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancel" plain  class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff"  class="confirm-btn">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
function startTime(time) {
    const nowTimeDate = new Date(time)
    return new Date(nowTimeDate.setHours(0, 0, 0, 0))
}
// 获取任意一天的结束时间
function endTime(time) {
    const nowTimeDate = new Date(time)
    return new Date(nowTimeDate.setHours(23, 59, 59, 999))
}
import Popup from '../../../components/popup.vue';
import staffCustomer from '../../../components/staffCustomer.vue';
import OpenData from "../../../components/openData.vue";
export default {
    props: ["botId","advSearchData",'advSearchdrawer'],
    data() {
        return {
            noteStetus: 2,
            lastNoteTime: "",
            noteUsers: [],
            noteUserIds: [],
            defaultExpandAll: true,
            orgTree: [],
            orgnation: [],
            flatDepartments: [],
            cascaderKey: 1,
            lastMsgTime: "",
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨日",
                        onClick(picker) {
                            // var day1 = new Date();
                            // day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
                            // var s1 =
                            //     day1.getFullYear() +
                            //     "-" +
                            //     (day1.getMonth() + 1) +
                            //     "-" +
                            //     day1.getDate();

                            // const end = new Date(s1) + 24 * 60 * 60 * 1000;
                            // const start = new Date(s1);
                            // picker.$emit("pick", [start, end]);

                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            let start = startTime(date);
                            let end = endTime(date);
                            picker.$emit("pick", [start, start]);
                        },
                    },
                    {
                        text: "今日",
                        onClick(picker) {
                            // var day1 = new Date();
                            // day1.setTime(day1.getTime());
                            // var s1 =
                            //     day1.getFullYear() +
                            //     "-" +
                            //     (day1.getMonth() + 1) +
                            //     "-" +
                            //     day1.getDate();
                            // const end = new Date();
                            // const start = new Date(s1);
                            // picker.$emit("pick", [start, end]);
                            const end = new Date();
                            const start = startTime(new Date().getTime());
                            picker.$emit("pick", [start, start]);
                        },
                    },
                    {
                        text: "近7日",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            end.setTime(
                                end.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "近30日",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            end.setTime(
                                end.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            intentIds: [],
            intentTree: [],
            props: {
                multiple: true,
                value: "id",
                label: "name",
                emitPath: false,
            },
            queryType: "100",
            queryTypeOptions: [
                {
                    value: "100",
                    text: "全部问题",
                },
                {
                    value: 0,
                    text: "不识别问题",
                },
                {
                    value: 1,
                    text: "已识别问题",
                },
                {
                    value: 2,
                    text: "不识别推荐",
                },
                 {
                    value: 3,
                    text: "关键词推荐",
                },
                {
                    value: 'WORK_ORDER',
                    text: "触发转工单问题",
                },
                {
                    value: 'CUSTOMER',
                    text: "触发转人工问题",
                },
                {
                    value: 'FEEDBACK_IS_USEFUL',
                    text: "触发有用的问题",
                },
                {
                    value: 'FEEDBACK_IS_USELESS',
                    text: "触发无用的问题",
                },
            ],
            dialogMemberTree:false,
            memberInfo4Client:{
                clientInfo:[], // 选中数据
                memberId:null
            },
            departmentId:[],
            memberId:[],
            upDownDepartmentId:[],
            upDownMemberId:[],
            chooseStyle:"all",
            checkedList:[],
            chooseStyleOld:"all",
        };
    },
    components:{staffCustomer,Popup,OpenData},
    watch: {
        advSearchdrawer(n){
            if(n){
                if(this.advSearchData.orgnation && this.advSearchData.orgnation.length > 0){
                    this.memberInfo4Client.clientInfo = this.advSearchData.orgnation;
                }
            }
        }
    },
    mounted() {
        this.getIntentList();
        this.getDepartmentTree();
        this.getAnnotator();
        this.$nextTick(() => {
            this.$refs.queryTypeSelect.blur();
        })
    },
    methods: {
        cancel(){
            this.chooseStyle = this.chooseStyleOld
            this.dialogMemberTree = false;
        },
        openStaffPopup(){
            this.chooseStyleOld = this.chooseStyle;
            this.dialogMemberTree = true;
        },
        preserStaff(){
            if(this.chooseStyle === 'all'){
                this.checkedList = [];
                this.memberInfo4Client.memberId = null;
            } else {
                this.memberInfo4Client.memberId = this.checkedList[0].id;
            }
            this.memberInfo4Client.clientInfo = this.checkedList || [];
            
            this.dialogMemberTree = false;
        },
         onNodeClick(node,type,memberInfo){
            this.checkedList = [];
            if(node.checked){
                this.checkedList.push(node);
            } else {
                 this.checkedList.forEach((v,i) => {
                    if(node.id == v.id){
                        this.checkedList.splice(i,1)
                    }
                 })
            }
            this.$refs.staffCustomer.checkMemberList = []
        },
        // 问题类型失去焦点
        queryTypeFocus(text){
            document.querySelector('.hidden-dom').click();
            this.$forceUpdate();
        },
        closeAdvSerch() {
            this.$emit("closeAdvSerch");
        },
        clearAdvConditionAll() {
            this.queryType = "100";
            this.intentIds = [...[]];
            this.lastMsgTime = "";
            // this.orgnation = [...[]];
            this.memberInfo4Client.clientInfo = [];
            this.noteUserIds = [...[]];
            this.lastNoteTime = "";
            this.noteStetus = 2;
        },
        clearAdvConditionCell(type) {
            switch (type) {
                case "queryTypeObj":
                    this.queryType = "100";
                    break;
                case "intents":
                    this.intentIds = [...[]];
                    break;
                case "lastMsgTime":
                    this.lastMsgTime = "";
                    break;
                case "orgnation":
                    // this.orgnation = [...[]];
                    this.memberInfo4Client.clientInfo = [];
                    break;
                case "noteUser":
                    this.noteUserIds = [...[]];
                    break;
                case "lastNoteTime":
                    this.lastNoteTime = "";
                    break;
                case "noteStetus":
                    this.noteStetus = 2;
                    break;
                default:
                    break;
            }
        },
        add24Hours(dateStr) {
            // 解析输入时间字符串
            let date = new Date(dateStr);

            // 延后23小时59分钟59秒59毫秒
            date.setMilliseconds(date.getMilliseconds() + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999);

            // 转换为 ISO 8601 格式
            return date.toISOString();
        },
        submitAdvSerch() {
            let param = {};
            if (this.queryType != 100 && typeof(this.queryType) !== 'string') {
                param.queryTypeObj = this.queryTypeOptions[this.queryType + 1];
            } else {
                if(this.queryType != 100){
                    param.queryTypeObj = {
                        text:"",
                        value:this.queryType
                    }
                }
                // WORK_ORDER 触发转工单问题，CUSTOMER触发转人工问题，FEEDBACK_IS_USEFUL反馈有用问题，FEEDBACK_IS_USELESS反馈无用问题
                if(this.queryType === 'WORK_ORDER'){
                    param.queryTypeObj.text = '触发转工单问题'
                } else if(this.queryType === 'CUSTOMER'){
                    param.queryTypeObj.text = '触发转人工问题'
                } else if(this.queryType === 'FEEDBACK_IS_USEFUL'){
                    param.queryTypeObj.text = '反馈有用问题'
                } else if(this.queryType === 'FEEDBACK_IS_USELESS'){
                    param.queryTypeObj.text = '反馈无用问题'
                }
                    
            }
            if (this.intentIds.length != 0) {
                param.intents = this.formatIntentIdsInfo(this.intentIds);
            }
            if (this.lastMsgTime && this.lastMsgTime != ""  && this.lastMsgTime.length > 0) {
                param.lastMsgTime = this.lastMsgTime;
                param.lastMsgTime[1] = this.add24Hours(param.lastMsgTime[1]);
            }
            if (this.memberInfo4Client.clientInfo == null || this.memberInfo4Client.clientInfo.length === 0) {
                this.memberInfo4Client.clientInfo = [];
            }
            if (this.memberInfo4Client.clientInfo.length != 0) {
                param.orgnation = this.memberInfo4Client.clientInfo;
            }
            if (this.noteUserIds.length != 0) {
                param.noteUser = this.formatNoteUserInfo(this.noteUserIds);
            }
            if (this.lastNoteTime && this.lastNoteTime != "" && this.lastNoteTime.length > 0) {
                param.lastNoteTime = this.lastNoteTime;
                param.lastNoteTime[1] = this.add24Hours(param.lastNoteTime[1]);
            }
            if (this.noteStetus != 2) {
                param.noteStetus = {
                    name: ["未标注过", "标注过", ""][this.noteStetus],
                    status: this.noteStetus,
                };
            }
            this.memberInfo4Client.clientInfo = [];
            this.$emit("submitAdvSerch", JSON.stringify(param));
        },
        formatNoteUserInfo(ids) {
            let tampArr = [];
            this.noteUsers.forEach((item) => {
                if (ids.indexOf(item.id) != -1) {
                    tampArr.push(item);
                }
            });
            return tampArr;
        },
        formatorgInfo(ids) {
            let tampArr = [];
            this.flatDepartments.forEach((item) => {
                if ([ids].indexOf(item.id) != -1) {
                    tampArr.push(item);
                }
            });
            return tampArr;
        },
        formatIntentIdsInfo(intentIds) {
            let intentsInfo = [];
            this.intentTree.forEach((skill) => {
                if (skill.children != null) {
                    skill.children.forEach((_class) => {
                        if (_class.children != null) {
                            _class.children.forEach((item) => {
                                if (intentIds.indexOf(item.id) != -1) {
                                    intentsInfo.push({
                                        id: item.id,
                                        name: item.name,
                                    });
                                }
                            });
                        }
                    });
                }
            });
            return intentsInfo;
        },
        orgCascaderChange() {},
        // 获取部门树
        getDepartmentTree() {
            let flatDepartments = [];
            this.FetchGet(this.requestUrl.bindV2.departmentTree).then((res) => {
                if (res.code === "0") {
                    let arr = res.data;
                    if (arr[0].total > 300) {
                        this.defaultExpandAll = false;
                    } else {
                        this.defaultExpandAll = true;
                    }
                    const formatDepartmentTree = (arr) => {
                        arr.forEach((item) => {
                            flatDepartments.push(item);
                            item.name = item.label;
                            item.value = item.id;
                            item.checked = false;
                            if (
                                item.children &&
                                item.children != null &&
                                item.children.length != 0
                            ) {
                                item.children = formatDepartmentTree(
                                    item.children.filter(
                                        (cell) => cell.type != 0
                                    )
                                );
                            }
                            if (JSON.stringify(item.children) == "[]") {
                                item.children = null;
                            }
                        });
                        return arr;
                    };
                    this.orgTree = formatDepartmentTree(
                        arr.filter((item) => item.type != 0)
                    );
                    this.flatDepartments = flatDepartments;
                    this.cascaderKey++;
                } else {
                    this.$message.error(res.message);
                    this.cascaderKey++;
                }
            });
        },
        getAnnotator() {
            this.FetchGet(this.requestUrl.trainList.getAnnotator, {
                botId: this.botId
            }).then(
                (res) => {
                    if (res.code === "0") {
                        let data = res.data;
                        this.noteUsers = data.map(item => {
                            return {
                                id: item.annotatorId,
                                name: item.annotatorName
                            }
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        getIntentList() {
            this.FetchGet(
                this.requestUrl.intent.getIntentTree +
                    "?bId=" +
                    this.botId +
                    "&filterType=1"
            ).then((res) => {
                if (res.code === "0") {
                    let data = res.data;
                    this.intentTree = data;
                    this.$nextTick(() => {
                        // 添加这段代码
                        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                    });

                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },
};
</script>
<style lang="less">
    .el-cascader-menu__wrap{
      height: 204px!important;
    }
</style>
<style lang="less" scoped>
.adv-search-cpnt {
    .adv-search-cpnt-content{
        padding: 48px 48px 25px 48px;
        border-bottom: 1px solid #E0E6F7;
    }
    .el-col-12:first-child{
        margin-right: 22px;
        width: 436px;
    }
    .el-row:last-child{
        .adv-search-cell{
            margin-bottom: 0;
            .adv-s-cell-b{
                height: 38px;
                background-color: #FBFCFD;
                display: flex;
                align-items: center;
                padding: 0 10px;
                border-radius: 5px;
            }
        }
    }
    .adv-search-cell {
        line-height: 42px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
        .adv-s-cell-t {
            width: 120px;
            height: 15px;
            line-height: 15px;
            flex: none;
            text-align: left;
            color: #616161;
            margin-bottom: 10px;
        }
        .adv-s-cell-b {
            text-align: left;
            flex: auto;
            .el-date-picker {
                width: 100% !important;
            }
            /deep/.el-select,/deep/.el-cascader,/deep/.el-input,/deep/.el-date-editor{
                width: 100%;
                height: 38px !important;
                .el-input__inner,.el-cascader__search-input,.el-range-input {
                    &::-webkit-input-placeholder{
                        color: #A9B3C6 !important;
                    }
                }
            }
            .el-input{
                height: 38px !important;
                .el-input__inner {
                    height: 38px !important;
                    &::-webkit-input-placeholder{
                        color: #A9B3C6 !important;
                    }
                }
                
            }
            /deep/.el-radio-group{
                display: flex;
                align-items: center;
                .el-radio{
                    display: flex;
                    align-items: center;
                    .el-radio__input,.el-radio__inner{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .adv-s-footer {
        padding-right: 15px;
        height: 66px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .el-button--default,.el-button--primary{
            width: 99px;
            height: 37px;
            background: #366AFF;
            border-radius: 19px;
            line-height: 37px;
            color: #FFFFFF;
            padding: 0;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .el-button--default{
            background: #FFFFFF;
            border: 2px solid #E0E6F7;
            color: #000000;
        }
    }
    .input-divs{
        width: 436px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #DCDFE6;
        border-radius: 5px;
        line-height: 36px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        // align-items: center;
        cursor: pointer;
        .placeholder-text{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #A9B3C6;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
        .have-checked-intent{
            margin-right: 4px;
        }
        .el-icon-arrow-down{
            font-size: 14px;
            color: #C0C4CC;
            margin-left: 4px;
            margin-right: 0;
        }
        .one-column-ellipsis{
            text-align: left;
            width: 400px;
            color: #606266;
        }
    }
    .choose-consultant-popup{
        .choose-style{
            text-align: left;
            >div{
                font-size: 14px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 20px;
            }
            .radio-box{
                height: 40px;
                background: #FBFCFD;
                border-radius: 5px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                .el-radio{
                    width: 40%;
                }
            }
        }
    }
}
</style>