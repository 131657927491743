<template>
    <div id="superIntentConfig-intent-add">
        <!-- 新建意图/编辑意图 -->
        <popup id="open-super-intnet" @closeEvent="addIntentDiaVisible = false" v-show="addIntentDiaVisible">
            <div slot="popup-name">{{$t('botIntent.newIntention')}}</div>
            <div slot="popup-tip">{{$t('addIntent.tip')}}</div>
            <div slot="popup-con">
                <div :class="['question-name', questionType !== 'IMAGE' ? '' : 'image-box']">
                    <div class="one-column-ellipsis text" v-if="questionType !== 'IMAGE'">{{ questionName }}</div>
                    <el-image v-else :src="JSON.parse(questionName).urls[0]" :fit="'cover'">
                    </el-image>
                </div>
                <div style="text-align: left" class="copy-intent-btn">
                    <el-button @click="copyIntentDia" icon="guoran-tongyichicun-fuzhi iconfont" size="mini" plain round>
                      {{$t('botIntent.copy')}}
                    </el-button>
                </div>
                <ul class="addIntentInfo">
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title">{{$t('botIntent.intentName')}} <span class="red-text">*</span> </span>
                            <el-input v-model="intentName" :maxlength="maxLengthLimit" placeholder="请输入意图名称"></el-input>
                        </div>
                    </li>
                    <li class="item-config">
                      <div class="block-add-intent">
                        <span class="title">{{$t('botIntent.intentDes')}}</span>
                        <el-input
                            type="textarea"
                            :rows="3"
                            v-model="intentDescription"
                            :placeholder="$t('botIntent.desPlaceholder')"
                        ></el-input>
                      </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent">
                            <span class="title">{{$t('botIntent.exampleQuestion')}}<span class="red-text">*</span></span>
                            <el-input v-model="exampleQuestion" :maxlength="maxLengthLimit"
                                :placeholder="$t('addIntent.exampleQuestionTip1') + maxLengthLimit + $t('addIntent.exampleQuestionTip2')"></el-input>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent share-radio">
                            <span class="title">{{$t('botIntent.sharingIntent')}}</span>
                            <el-radio-group v-model="isShare">
                                <el-radio :label="1">{{$t('botIntent.yes')}}</el-radio>
                                <el-radio :label="0">{{$t('botIntent.no')}}</el-radio>
                            </el-radio-group>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="classification">
                            <span class="title">{{$t('botIntent.knowledgeBaseAndClassification')}}</span>
                            <el-cascader :disabled="isDefault" v-if="addIntentDiaVisible" v-model="classification"
                                style="width: 100%" :props="{ emitPath: false }" :options="classificationOption.filter(item => {
                                    return item.label !== '特殊规则';
                                })" @expand-change="handleChange2"></el-cascader>
                        </div>
                    </li>
                </ul>
                <ul class="superIntentConfig-add-inetent" v-show="this.handleTypeC == 'add' &&
                    RP_Visible('OPEN_INTENT_TEMPLATE_UPDATE')
                    ">
                    <li class="item-config">
                        <div class="block-add-intent open-super-intent">
                            <span class="title">{{$t('botIntent.openSuperIntent')}}</span>
                            <div class="right">
                                <div class="evaluate-table-switch">
                                    <el-switch @click.native.prevent="changeEnable('switch')" :width="42"
                                        v-model="currentSuperIntentList.enable" active-color="#366AFF"
                                        inactive-color="#E2E2E2">
                                        >
                                    </el-switch>
                                    <span class="switch-open-icon" @click="changeEnable('open')"
                                        v-if="currentSuperIntentList.enable"><i class="iconfont guoran-a-16-17"></i></span>
                                    <span class="switch-close-icon" @click="changeEnable('close')"
                                        v-if="!currentSuperIntentList.enable"><i
                                            class="arsenal_icon arsenalcuo1"></i></span>
                                </div>
                                <div class="desc">
                                  {{$t('botIntent.openSuperIntentTip')}}
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item-config">
                        <div class="block-add-intent" v-show="currentSuperIntentList.enable">
                            <span class="title">
                                <i class="iconfont guoran-tongyichicun-shili"></i>
                                {{$t('botIntent.selectType')}}</span>
                            <el-select :key="intentClassKey" v-model="selectData.name" @visible-change="
                                selectVisibleChange($event)
                                " @focus="superIntentFocus()" @change="selectTrigger()" filterable :placeholder="$t('common.selectPlaceholder')">
                                <el-option :class="[clearStatus ? 'clearStatus' : 'no']" v-for="item in superIntentList"
                                    :key="item.id" :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                    </li>
                    <li class="exmplebox item-config" v-show="currentSuperIntentList.enable &&
                        selectData.examples != ''
                        ">
                        <span class="title">{{$t('botIntent.superIntentExample')}}</span>
                        <div class="exmple">
                            <div v-for="item in selectData.examples" :key="item.id" class="text">
                                <div></div>
                                {{ item }}
                            </div>
                        </div>
                    </li>
                    <li class="item-config" v-show="currentSuperIntentList.enable &&
                        selectData.examples != ''
                        ">
                        <div class="blockdes">
                            <span class="title">
                                <i class="iconfont guoran-tongyichicun-shili"></i>
                                {{$t('botIntent.superIntentExampleTip1')}}
                            </span>
                            <span class="des">{{$t('botIntent.superIntentExampleTip2')}}</span>
                        </div>
                        <div class="config">
                            <div class="pattern" v-for="(item, index) in selectData
                                .templateConfig.pattern" :key="index">
                                <span class="keyword-recommend">
                                    <el-input @click.stop.native="changeInputSuper($event, item, index, 'focus')"
                                        @input="changeInputSuper($event, item, index)" v-model.trim="item.value" size="small"
                                        :placeholder="$t('botIntent.botSettingNew.keywordRequired')" v-show="item.isFillIn == true &&
                                            item.type != 1
                                            "></el-input>
                                    <botKeywordRecommend :key="'superIntentConfigs' + index"
                                        :showBotKeywordRecommend.sync="item.showBotKeywordRecommend" :dataObj="item"
                                        :dataIndex="index" :keyword="item.value" :left="0" :positionTop="36"
                                        @showBotKeywordRecommend="showBotKeywordRecommendFn"
                                        @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                        @addToInput="addToInputSuper($event, item, index)">
                                    </botKeywordRecommend>
                                </span>
                                <span class="word" v-show="item.type == 1 &&
                                    item.isFillIn == false
                                    ">{{ item.words[0] }}</span>
                            </div>
                        </div>
                        <div class="pattern config-example">
                            <div class="title"><span>{{$t('botIntent.configExample')}}</span></div>
                            <div class="bottom-example">
                                <div :class="[
                                    'pattern',
                                    item.isFillIn ? 'border' : '',
                                ]" v-for="(item, index) in selectData
    .templateConfig.example" :key="index">
                                    <span :class="[
                                        item.type != '1'
                                            ? 'word input'
                                            : 'word',
                                    ]" v-for="(
                                            todo, cindex
                                        ) in selectData.templateConfig.example[
                                            index
                                        ].words.join(',')" :key="cindex">{{ todo }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div slot="dialog-footer">
                <el-button @click="addIntentDiaVisible = false" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitAddBot" round :loading="addIntentLoading"
                    class="confirm-btn save-mark">{{$t('addIntent.saveMarked')}}</el-button>
            </div>
        </popup>
        <!-- 可能与已有的以下意图相似 -->
        <popup @closeEvent="closeSimilIntentDialogVisible" v-show="similIntentDialogVisible" :haveTips="false"
            id="same-intnet-go-look">
            <div slot="popup-name">{{$t('addIntent.prompt')}}</div>
            <div slot="popup-con">
                <div class="intent-link-title">
                  {{$t('addIntent.promptTip')}}
                </div>
                <div>
                    <div v-for="intent in similIntentList" :key="intent.id" class="intent-link-cell">
                        <span class="intent-link-cell-name">{{ intent.name }}{{ intent.intentID }}</span>
                        <el-button @click="gotoNewIntentPage(intent)" type="text">{{$t('addIntent.toView')}}</el-button>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeSimilIntentDialogVisible" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="addIntentExiSimil" class="confirm-btn">{{$t('botIntent.continueCreate')}}</el-button>
            </div>
        </popup>
        <!-- 复制意图 -->
        <CopyIntent :botId="currentBotInfo.id" :visible.sync="copyIntentDialogVisible" @closeCopyIntent="closeCopyIntent"
            @switchSkillCopy="switchSkillCopy" />
    </div>
</template>

<script>
import popup from "../../../components/popupNew.vue";
import CopyIntent from "../../botIndex/component/CopyIntent.vue";
import botKeywordRecommend from '../../intentQa/components/botKeywordRecommend.vue'
export default {
    components: { popup, CopyIntent, botKeywordRecommend },
    data () {
        return {
            addIntentDiaVisible: false,
            intentName: "",
            intentDescription:"",
            exampleQuestion: "",
            isDefault: false,
            handleTypeC: "add", // 意图操作弹窗类型
            intentTableData: [],
            refApiKey: "8f68f1c4f5c949b387e2ed32aa3fa6f4",
            superIntentList: [{ id: "", name: "" }],
            currentSuperIntentList: {
                corpusTemplate: { examples: [""], example: "" },
                name: "diyige",
            },
            maxLengthLimit: 50,
            isShare: 0,
            classification: "",
            classificationOption: [],
            addIntentLoading: false,
            similIntentDialogVisible: false,
            similIntentList: [],
            copyIntentDialogVisible: false,
            selectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                    ],
                },
            },
            startSelectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                            showBotKeywordRecommend: false
                        },
                    ],
                },
            },
        }
    },
    props: {
        botId: {
            type: Number,
            default: null
        },
        currentBotInfo: {
            type: Object,
            default () {
                return []
            }
        },
        markData: {
            type: Object,
            default () {
                return []
            }
        },
        questionName: {
            type: String,
            default: ""
        },
        questionType: {
            type: String,
            default: ""
        }
    },
    watch: {
        addIntentDiaVisible (n) {
            if (n) {
                this.$nextTick(() => {
                    document.querySelector('#superIntentConfig-intent-add .popup-floating-layer-new').addEventListener('click', () => {
                        if (this.isIntentFocus) {
                            this.selectData.templateConfig.pattern.forEach((item, index) => {
                                this.$set(item, 'showBotKeywordRecommend', false)
                                item.showBotKeywordRecommend = false;
                            })
                            this.isIntentFocus = false;
                            this.$forceUpdate();
                        }
                    })
                })
            } else {
                document.querySelector('#superIntentConfig-intent-add .popup-floating-layer-new').removeEventListener('click', () => { })
            }
        },
    },
    methods: {
        // 复制意图成功后
        switchSkillCopy () {
            this.copyIntentDialogVisible = false;
            this.addIntentDiaVisible = false;
            this.similIntentDialogVisible = false;
            this.$emit('close')
        },
        // 点击从现有意图复制
        copyIntentDia () {
            // 获取共享意图
            this.FetchGet(this.requestUrl.bot.botSkillIntentTree, {
                isShare: 1,
            }).then((res) => {
                if (res.code === "0") {
                    let data = [];
                    res.data.forEach(item => {
                        if (item.id != this.$route.query.id) {
                            data.push(item);
                        }
                    })
                    if (data.length === 0) {
                        this.$message.error('暂无共享意图');
                    } else {
                        this.addIntentDiaVisible = false;
                        this.$nextTick(() => {
                            this.copyIntentDialogVisible = true;
                        });
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        closeCopyIntent () {
            this.copyIntentDialogVisible = false;
        },
        // 获取技能list
        getSkillList (botId, setDefaSkill, type) {
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: botId,
                filterInvisible: false,
            }).then((res) => {
                if (res.code === "0") {
                    let list = res.data.map((res) => {
                        return { label: res.name, value: res.id, children: [] };
                    });
                    this.classificationOption = JSON.parse(JSON.stringify(list))
                    this.getTypeList(this.classificationOption[0].value);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取分类list
        getTypeList (skillId, setDefaClass, type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: skillId,
            }).then((res) => {
                if (res.code === "0") {
                    for (let i = 0; i < this.classificationOption.length; i++) {
                        for (let j = 0; j < res.data.length; j++) {
                            if (this.classificationOption[i].value === skillId) {
                                let test = res.data.map((res) => {
                                    return { label: res.name, value: res.id };
                                });
                                this.classificationOption[i].children = JSON.parse(JSON.stringify(test));
                            }
                        }
                    }
                    this.classification = res.data[0].id;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        handleChange2 (value) {
            this.getTypeListChildren(value[0]);
        },
        getTypeListChildren (id, type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: id,
            }).then((res) => {
                if (res.code === "0") {
                    this.classificationOption.forEach((item, index) => {
                        if (item.value === id) {
                            let test = res.data.map((res) => {
                                return { label: res.name, value: res.id };
                            });
                            item.children = JSON.parse(JSON.stringify(test));
                        }
                    })
                }
            });
        },
        changeEnable (datas) {
            if (datas === 'open') {
                this.currentSuperIntentList.enable = false;
            } else if (datas === 'close') {
                this.currentSuperIntentList.enable = true;
            }
            this.intentClassKey++
            this.getSuperIntentList();
        },
        // 获取超级意图列表
        getSuperIntentList () {
            this.FetchGet(this.requestUrl.superIntent.getSuperIntentList).then(
                (res) => {
                    if (res.code === "0") {
                        this.superIntentList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        // 添加意图
        addIntent () {
            this.addIntentLoading = false;
            this.getSkillList(this.currentBotInfo.id)
            this.intentName = "";
            this.intentDescription = "";
            this.exampleQuestion = "";
            this.isDefault = false;
            this.handleTypeC = "add";
            this.currentSuperIntentList.enable = false;
            this.selectData.name = '';
            this.clearStatus = true;
            this.intentClassKey = 1;
            this.selectData.examples = [];
            let templateConfig = {
                pattern: [
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false
                    },
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false
                    },
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false
                    },
                ],
                example: [
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                        showBotKeywordRecommend: false
                    },
                ],
            }
            this.selectData.templateConfig = templateConfig;
            this.addIntentDiaVisible = true;
            let mainId = localStorage.getItem("_mainId");
            if (
                (mainId === "90df4764122240de939331d372546c28" ||
                    mainId === "e2f80aecaf7c11eabdcd506b4b2f3acc" ||
                    mainId === "1419b09cb2ce11ea8f0900163e0964d5") &&
                this.currentBotInfo.refApiKey
            ) {
                this.isShare = 1;
            } else {
                this.isShare = 0;
            }
            this.getSuperIntentList();
        },
        // 超级意图列表获取焦点后清空所选内容
        superIntentFocus () {
            this.selectData = this.startSelectData;
            this.getSuperIntentList();
        },
        selectVisibleChange (callback) {

            if (callback) {
                this.clearStatus = true;
                this.selectData = this.startSelectData;
            } else {
                this.clearStatus = false;
                this.selectTrigger();
            }
        },
        // 选中超级意图执行方法
        selectTrigger (id) {
            let that = this;
            this.clearStatus = false;
            for (var i = 0; i < that.superIntentList.length; i++) {
                if (that.superIntentList[i].name == that.selectData.name) {
                    that.selectData = that.superIntentList[i];
                }
            }
            if (this.currentSuperIntentList.enable) {
                this.$nextTick(() => {
                    let ele = document.querySelector('#superIntentConfig-intent-add .popup-container-new');
                    ele.scrollTop = ele.scrollTop + 100;
                })
            }
        },
        // 获取超级意图列表
        getSuperIntentList () {
            this.FetchGet(this.requestUrl.superIntent.getSuperIntentList).then(
                (res) => {
                    if (res.code === "0") {
                        this.superIntentList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        showBotKeywordRecommendFn () {
            this.$nextTick(() => {
                let ele = document.querySelector('#superIntentConfig-intent-add .popup-container-new');
                ele.scrollTop = ele.scrollTop + 100;
            })
        },
        // 超级意图 显隐推荐词下拉框
        changeInputSuper (datas, item, index) {
            this.isIntentFocus = true;
            this.selectData.templateConfig.pattern.forEach((v, i) => {
                v.showBotKeywordRecommend = false;
            })
            if (item.value !== '') {
                item.showBotKeywordRecommend = true;
            }
            this.$forceUpdate();
        },
        closeBotKeywordRecommend (item) {
            item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        //超级意图 关键词的近义词 , 单个添加
        addToInputSuper (datas, item, index) {
            item.value = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        // 关闭意图后 , 未达标意图和健康度的意图详情需要更新
        updateHeathlyIntent () {
            this.$eventBus.$emit("bot-heathly-visible", {
                flag: false,
                type: "updateIntentList"
            });
        },
        addIntentExiSimil () {
            let intentName = this.intentName;
            let exampleQuestion = this.exampleQuestion;
            let templateId = this.currentSuperIntentList.enable ? this.selectData.id : '';
            this.FetchPost(this.requestUrl.intent.addIntent, {
                name: intentName,
                description:this.intentDescription,
                exampleQuestion: exampleQuestion,
                isShare: this.isShare,
                tid: this.classification,
                apiKey: this.currentBotInfo.apiKey,
                templateId: templateId,
                config: JSON.stringify(this.superIntentInputWords),
            }).then((res) => {
                if (res.code === "0") {
                    // this.$message.success("意图添加成功！");
                    this.markIntent(res.data);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 关闭相似意图
        closeSimilIntentDialogVisible () {
            this.similIntentDialogVisible = false;
            this.addIntentDiaVisible = true;
        },
        // 相似意图--去看看
        gotoNewIntentPage (item) {
            let routeUrl = this.$router.resolve({
                path: "/main/intentQa",
                query: {
                    bid: this.currentBotInfo.id,
                    bName: this.currentBotInfo.name,
                    intentId: item.id,
                    intentName: item.name,
                    refApiKey: this.currentBotInfo.refApiKey,
                    isDefault: item.isDefault,
                    apiKey: this.currentBotInfo.apiKey
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                "/#" +
                routeUrl.resolved.fullPath,
                "_blank"
            );
        },
        markIntent (intentId) {
            let params = {
                intentId: intentId,
                intentName: this.intentName,
                description:this.intentDescription,
                isIdentify: this.markData.isIdentify,
                isRight: this.markData.isRight,
                tId: this.markData.tId
            }
            // 单条标注
            if (this.markData.type === 0) {
                this.FetchPost(this.requestUrl.trainList.mark, params).then(res => {
                    if (res.code === "0") {
                        this.markSuccess(intentId);
                    } else {
                        this.$message.error(res.message);
                    }
                });
                // 批量标注
            } else {
                this.FetchPost(this.requestUrl.trainList.markBatch, params).then(res => {
                    if (res.code === "0") {
                        this.markSuccess(intentId);
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        markSuccess (intentId) {
            this.addIntentDiaVisible = false;
            this.similIntentDialogVisible = false;
            this.$emit('close')
            this.$message({
                dangerouslyUseHTMLString: true,
                type: 'warning',
                message: '<span>请及时完善新建意图的对话流程哦~</span><span style="color:#366AFF;cursor: pointer;margin-left:4px;" id="go-perfect">去完善</span>'
            });
            document.getElementById("go-perfect").onclick = () => {
                console.log('go-perfect');
                this.$router.push({
                    name: "intentQa",
                    query: {
                        bid: this.currentBotInfo.id,
                        bName: this.currentBotInfo.name,
                        intentId: intentId,
                        intentName: this.intentName,
                        refApiKey: this.currentBotInfo.refApiKey,
                        isDefault: false,
                        apiKey: this.currentBotInfo.apiKey,
                    },
                });
            };

        },
        // 提交新建意图
        submitAddBot () {
            let intentName = this.intentName;
            let exampleQuestion = this.exampleQuestion;
            let isShare = this.isShare;
            let pattern = this.selectData.templateConfig.pattern;
            this.superIntentInputWords = [];
            // return
            if (this.currentSuperIntentList.enable == true) {
                for (var i = 0; i < pattern.length; i++) {
                    if (pattern[i].words.length != 0) {
                        let content = pattern[i].words[0].split(" ");

                        this.superIntentInputWords.push({
                            varName: pattern[i].varName,
                            type: pattern[i].type,
                            words: content,
                            isFillIn: pattern[i].isFillIn,
                        });
                    } else if (pattern[i].value) {
                        let value = pattern[i].value
                            .replace(/，/gi, ",")
                            .split(",");
                        this.superIntentInputWords.push({
                            varName: pattern[i].varName,
                            type: pattern[i].type,
                            words: value,
                            isFillIn: pattern[i].isFillIn,
                        });
                    } else if (this.selectData.id == "") {
                        this.$message.error(this.$t('botIntent.selectTypeMsg'));
                        return;
                    } else {
                        this.$message.error(
                            this.$t('botIntent.nullText')
                        );
                        return;
                    }
                }
            }

            if (Boolean(intentName.length) && Boolean(exampleQuestion && exampleQuestion.length)) {
                // 提交意图信息
                this.addIntentLoading = true;
                this.FetchGet(this.requestUrl.intent.getSimilarIntent, {
                    name: intentName,
                    description:this.intentDescription,
                    exampleQuestion: exampleQuestion,
                    tid: this.classification,
                    apiKey: this.currentBotInfo.apiKey,
                })
                    .then((res) => {
                        if (res.code === "0") {
                            this.updateHeathlyIntent()
                            if (res.data.length == 0) {
                                this.addIntentExiSimil();

                            } else {
                                this.similIntentList = res.data;
                                this.addIntentDiaVisible = false;
                                this.similIntentDialogVisible = true;
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .finally(() => {
                        this.addIntentLoading = false;
                    });
            } else {
                this.$message.error(this.$t('botIntent.intentNameNull'));
            }
        },

    }
}
</script>

<style lang="less" scoped>
#superIntentConfig-intent-add {
    #open-super-intnet {
        z-index: 3334 !important;
    }

    /deep/ #popup-assembly {
        width: 750px;
    }

    .question-name {
        background-color: #FBFCFD;
        padding: 0 10px;
        border-radius: 5px;
        margin-bottom: 20px;

        .text {
            height: 38px;
            display: flex;
            align-items: center;
        }

        .el-image {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }

        &.image-box {
            height: 120px;
            display: flex;
            align-items: center;
        }
    }

    .guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe {
        color: #FF9555;
        font-size: 50px;
    }

    .tips-dialog-title {
        margin-top: 10px;
    }

    .item-config {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 20px 0px;
        font-size: 14px;

        .title {
            color: #616161;
        }

        .guoran-tongyichicun-shili {
            color: #366AFF;
        }

        .block-add-intent {
            width: 100%;
            display: flex;
            flex-direction: column;

            .title {
                text-align: left;
                margin-bottom: 10px;
                display: flex;
                align-items: centers;
            }
        }

        .blockdes {
            width: 100%;
            display: flex;
            flex-direction: column;

            .title {
                text-align: left;
                margin-bottom: 4px;
                width: auto;
                color: #616161;

                i {
                    margin-right: 5px;
                }
            }

            .des {
                color: #A9B3C6;
                text-align: left;
            }

            .arsenal_icon {
                color: #366AFF;
            }
        }

        .config {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            background: #F6F8FD;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            position: relative;

            .pattern {
                text-align: left;
                font-size: 14px;
                margin-right: 0;
                padding: 3px 0;
                margin-left: 6px;

                /deep/.el-input {
                    flex: auto;
                    min-width: 120px;
                    max-width: 350px;
                }

                .el-input__inner {
                    // flex: auto;
                    // border-top: none;
                    // border-left: none;
                    // border-right: none;
                    // border-radius: 5px 5px 0 0;
                    // border-color: #366AFF;
                    border-bottom: solid 2px #366AFF;
                }

                .word {
                    font-size: 12px;
                    flex: none;
                    text-align: left;
                    white-space: nowrap;
                    margin: 0 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .keyword-recommend {
                    position: relative;
                }
            }

        }

        .example {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            color: #aaaaaa;

            .pattern {
                width: auto;
                text-align: left;
                margin-right: 20px;

                .word {
                    text-align: left;
                    margin-top: 20px;
                }

                .input {
                    //text-decoration:underline;
                    font-size: 14px;
                    border-bottom: 1px solid #aaaaaa;
                }
            }
        }

        .evaluate-table-switch {
            display: flex;
            flex-direction: row;
            height: 25px;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            text-align: center;
            align-items: center;

            .el-switch {
                text-align: center;
            }

            .switch-open-icon {
                position: absolute;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                left: 3px;

                i {
                    color: white;
                    font-size: 16px;
                }
            }

            .switch-close-icon {
                position: absolute;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                left: 21px;

                i {
                    color: #6E6B7B;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            i {
                color: #366AFF;
                font-size: 18px;
            }
        }

        .classification {
            width: 100%;
            display: flex;
            flex-direction: column;

            .title {
                text-align: left;
                margin-bottom: 10px;
            }
        }

        .switch {
            text-align: left;
            flex: none;
            width: 150px;
        }

        .titleDes {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            em {
                text-align: right;
                margin-right: 45px;
                font-size: 12px;
                color: #909399;
            }
        }

        .config-example {
            margin-top: 30px;
            border: 1px solid #E0E6F7;
            border-radius: 5px;

            .title {
                height: 35px;
                border-bottom: 1px solid #E0E6F7;
                line-height: 35px;
                text-align: center;
                width: 100%;
            }
        }

        .bottom-example {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            .pattern {
                height: 33px;
                line-height: 33px;
                padding: 0 12px;
                margin: 0 3px;

                &.border {
                    background: #F6F8FD;
                    border: 1px solid #ACC2FF;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #366AFF;
                }

            }
        }
    }

    .line {
        margin: 30px 0;
        height: 2px;
        border-bottom: 1px dotted #E0E6F7;
    }

    .exmplebox {
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 14px;

        .title {
            width: 100%;
            text-align: center;
            height: 35px;
            line-height: 35px;
            border-bottom: 1px solid #E0E6F7;

        }

        .exmple {
            text-align: left;
            flex: none;
            height: 120px;
            overflow-y: scroll;
            overflow-x: hidden;
            width: calc(100% - 20px);
            padding: 10px;

            .text {
                margin: 0px 0px 10px 0px;
                color: #A9B3C6;
                display: flex;
                height: 30px;
                align-items: center;

                >div {
                    width: 6px;
                    height: 6px;
                    background: #A9B3C6;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        }
    }

    .flexrow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .copy-intent-btn {
        .guoran-tongyichicun-fuzhi {
            margin-right: 5px;
        }
    }

    .red-text {
        color: red;
        font-size: 14px;
        margin-left: 3px;
    }

    .block-add-intent .right {
        display: flex;
        flex-direction: column;

        .desc {
            font-size: 12px;
            text-align: left;
            margin-left: 0;
            margin-top: 5px;
        }
    }

    .share-radio {
        .el-radio-group {
            display: flex;
            align-items: center;
            height: 40px;
            background: #FBFCFD;
            border-radius: 5px;
            text-align: left;
            padding-left: 10px;
        }
    }

    .open-super-intent .right {
        height: 40px;
        background: #FBFCFD;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 12px;

        .desc {
            color: #A9B3C6;
            margin-left: 12px;
            height: 24px;
        }

    }

    .evaluate-table-switch {
        display: flex;
        flex-direction: row;
        height: 25px;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        text-align: center;
        align-items: center;

        .el-switch {
            text-align: center;
        }

        .switch-open-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;

            i {
                color: white;
                font-size: 16px;
            }
        }

        .switch-close-icon {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: 21px;

            i {
                color: #6E6B7B;
                font-size: 14px;
                font-weight: 600;
            }
        }

        i {
            color: #366AFF;
            font-size: 18px;
        }
    }

    /deep/.save-mark {
        border-color: #366AFF !important;
        padding: 0 30px !important;
        min-width: 99px !important;
        width: auto !important;

        &.is-loading {
            border-color: #7aa3ff !important;
        }
    }

    #same-intnet-go-look {
        .guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe {
            color: #FF9555;
            font-size: 50px;
        }

        .intent-link-title {
            margin: 16px 0;
            text-align: left;
            font-weight: 800;
        }

        .intent-link-cell {
            display: flex;
            justify-content: space-between;
            line-height: 40px;
        }
    }
}



</style>