<template>
    <div class="training_records">
        <div class="record_close" @click="close">
            <i class="iconfont guoran-tongyichicun-guanbi1"></i>
        </div>
        <div class="rescord_head">
            <div class="left">
                <section class="icon_bg">
                    <i class="iconfont guoran-tongyichicun-xunlianjilu"></i>
                </section>
              {{$t('trainList.trainingRecords')}}
            </div>
            <div class="right">
                <div class="previous" @click="previousPage">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="next" @click="nextPage">
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <el-table class="table" :data="tableData" row-key="id">
            <el-table-column :label="$t('trainList.trainNode.trainVersion')" width="80" class-name="rescord_table_version">
                <template slot-scope="scope">
                    <i class="iconfont guoran-tongyichicun-banben"></i>
                    {{ scope.row.version }}
                </template>
            </el-table-column>
            <el-table-column label="训练时间" align="center" class-name="table_updateTime">
                <template slot="header" slot-scope="scope">
                    <div class="createTime_header" @click="sortChange('recordTimeSort')">
                        <span>{{$t('trainList.trainNode.trainTime')}}</span>
                        <section>
                            <i class="el-icon-caret-top" :class="recordTimeSort == 'ASC' && 'active'"></i>
                            <i class="el-icon-caret-bottom" :class="recordTimeSort == 'DESC' && 'active'"></i>
                        </section>
                    </div>
                </template>
                <template slot-scope="scope">
                    <i class="guoran-a-18-15 iconfont" v-if="scope.row.createTime && scope.row.createTime !== ''"></i>
                    <span>{{ new Date(scope.row.createTime).Format("yyyy-MM-dd hh:mm") }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="updateTimeStr" width="140" label="训练问题数" align="center">
                <template slot="header" slot-scope="scope">
                    <div class="createTime_header" @click="sortChange('questionCountSort')">
                        <span>{{$t('trainList.trainQuestionNum')}}</span>
                        <section>
                            <i class="el-icon-caret-top" :class="questionCountSort == 'ASC' && 'active'"></i>
                            <i class="el-icon-caret-bottom" :class="questionCountSort == 'DESC' && 'active'"></i>
                        </section>
                    </div>
                </template>
                <template slot-scope="scope">
                    {{ scope.row.workOrderToKnowledgeTotal }}
                </template>
            </el-table-column>
            <el-table-column prop="" :label="$t('trainList.operation')" align="left" width="80" class-name="order_operation">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="$t('trainList.view')" placement="top" :key="scope.row.id"
                        :open-delay="300">
                        <el-button type="text" @click="openRecordsDetail(scope.row)"
                            icon="iconfont guoran-dianjichakantuli">
                        </el-button>
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data () {
        return {
            tableData: [],
            questionCountSort: '',
            recordTimeSort: 'DESC',
            pageNumber: 0,
        }
    },
    mounted () {
        this.getTrainList()
    },
    methods: {
        previousPage () {
            if (this.pageNumber == 1) {
                this.$message({
                    message: "已经是第一页",
                    type: "warning"
                })
                return
            }
            this.pageNumber -= 1;
            this.getTrainList();
        },
        nextPage () {
            if (this.hasNextPage) {
                this.$message({
                    message: "已经是最后一页",
                    type: "warning"
                })
                return
            }
            this.pageNumber += 1;
            this.getTrainList();
        },
        close () {
            this.$emit("close")
        },
        openRecordsDetail (data) {
            this.$emit("close")
            this.$emit("openRecordsDetail", data)
        },
        sortChange (type) {
            this[type] = this[type] == 'ASC' ? 'DESC' : 'ASC';
            this.getTrainList()
        },
        getTrainList () {
            this.$http.get("/api/intent/workOrderToKnowledge/trainList?pageNumber=" + this.pageNumber + '&pageSize=10' + '&questionSort=' + this.questionCountSort + '&recordTimeSort=' + this.recordTimeSort).then(res => {
                if (res.data.code == '0') {
                    this.tableData = res.data.data.list
                    this.hasNextPage = res.data.data.isLastPage
                }
            })
        },
        tableSort () {
        }
    }
}
</script>

<style lang="less" scoped>
.training_records {
    position: absolute;
    top: 60px;
    right: 110px;
    width: 510px;
    height: 412px;
    background: #FFFFFF;
    box-shadow: 0px 1px 9px 0px rgba(57, 63, 79, 0.21);
    border-radius: 5px;

    .record_close {
        width: 24px;
        height: 24px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
        border-radius: 50%;
        position: absolute;
        top: -12px;
        right: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
            font-size: 10px;
            color: #616161;
        }
    }

    .rescord_head {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0 12px;
        justify-content: space-between;
        box-sizing: border-box;

        .left {
            display: flex;
            align-items: center;

            .icon_bg {
                width: 22px;
                height: 22px;
                background: #366AFF;
                border-radius: 50%;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 3px;

                i {
                    font-size: 12px;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;

            .previous {
                flex: none;
                height: 28px;
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-right: none;
                width: 34px;
                border-top-left-radius: 14px;
                border-bottom-left-radius: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                    color: #717B90;
                    font-size: 16px;
                }
            }

            .next {
                flex: none;
                height: 28px;
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                width: 34px;
                border-top-right-radius: 14px;
                border-bottom-right-radius: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                    color: #717B90;
                    font-size: 16px;
                }
            }
        }
    }

    .el-table {
        height: calc(100% - 40px);
    }

    /deep/.el-table {
        &::before {
            z-index: inherit;
        }

        .el-table__fixed,
        .el-table__fixed-right {
            bottom: 0px;
            height: 100%;

            &::before {
                height: 0;
            }
        }

        .el-table__fixed-right-patch {
            background-color: #F6F8FD;
        }

        .el-table__header-wrapper {
            height: 50px;
            line-height: 50px;
            background-color: #F6F8FD;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;

            .el-table__header,
            tr,
            .has-gutter,
            th {
                background-color: #F6F8FD !important;
                background: #F6F8FD;
            }


        }

        .el-table__header {
            .first-filter-header-cell {
                display: flex;
                align-items: center;

                .el-select {
                    width: 140px;
                    margin-right: 20px;

                    .el-input {
                        width: 140px;

                        .el-input__inner {
                            height: 32px;
                            width: 140px;
                        }

                        .el-input__suffix {
                            top: -2px;
                        }
                    }
                }

                .same-question-sort-tips {
                    color: #000;
                }

                .evaluate-table-switch {
                    display: flex;
                    flex-direction: row;
                    height: 20px;
                    justify-content: space-between;
                    cursor: pointer;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    padding: 0;

                    .el-switch {
                        text-align: center;
                        display: flex;
                    }

                    .el-switch__core {
                        height: 20px;
                        border-radius: 12px;
                    }

                    .switch-open-icon {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 2px;

                        i {
                            color: white;
                            font-size: 16px;
                        }
                    }

                    .switch-close-icon {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 2px;

                        i {
                            color: #6E6B7B;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    i {
                        color: #366AFF;
                        font-size: 18px;
                    }
                }
            }
        }

        .el-table__body-wrapper {
            height: calc(100% - 50px);
            overflow-y: auto;

            // 滚动条样式
            &::-webkit-scrollbar {
                height: 6px;
                width: 6px;
                background-color: rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px !important;
                background-color: #BFCEEC;
            }


        }

        th .cell {
            height: 50px;
            line-height: 50px;
        }

        .el-switch {
            display: inline;
            line-height: unset;
            height: 24px;
            padding: 0;
        }

        .el-table__header {
            th {
                padding: 0;
                height: 50px;
                line-height: 50px;
                background-color: #F6F8FD;

                div {
                    line-height: 50px;
                }

            }
        }

        .el-table__body {
            .table-fixed {

                /deep/.el-table__fixed,
                .el-table__fixed-right {
                    bottom: 0px;
                    height: 100%;

                    &::before {
                        height: 0;
                    }
                }

                /deep/.el-table__fixed-right-patch {
                    background-color: #F6F8FD;
                }
            }

            .normal-row,
            .image-row {
                .cell {
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    // padding: 0;
                    height: 60px;
                    line-height: 60px;

                    .guoran-a-18-15 {
                        margin-right: 6px;
                        color: #D2D8E3;
                        margin-top: 2px;
                        font-size: 17px;
                    }
                }


            }

            .image-row {
                .cell {
                    height: 110px;
                    line-height: 110px;
                }
            }

            .show-source-other-bot {
                .cell {
                    height: 120px;
                    line-height: 100px;
                }
            }

            .intent-name-td {
                .cell {
                    display: block;
                }
            }

            tr td {
                padding: 0;
                height: 60px;
                cursor: pointer;
            }

            .user-icon {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 6px;
            }

            .name-icon {
                width: 26px;
                height: 26px;
                background: #366AFF;
                border-radius: 50%;
                margin-right: 6px;
                text-align: center;
                line-height: 26px;
                color: #fff;
                display: inline-block;
            }

            .el-button {
                font-weight: 400;
            }

            .selection-column-box {
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                align-items: center;

                .el-checkbox {
                    margin-right: 10px;

                    .el-checkbox__inner {
                        width: 16px;
                        height: 16px;
                        border: 2px solid #EBEDF4;
                        border-radius: 2px;

                        &::after {
                            top: 0px;
                            border-width: 2px;
                        }

                    }

                    .is-checked .el-checkbox__inner {
                        border: none;
                        border: 2px solid #366AFF;
                    }
                }

                .selection-column {
                    display: flex;

                    .one-column-ellipsis {
                        line-height: normal;
                        margin-right: 6px;
                    }

                    &.image-cell {
                        display: flex;
                    }

                    .el-image {
                        width: 80px;
                        height: 80px;
                        border-radius: 10px;
                        cursor: pointer;
                        margin-left: 4px;
                    }

                    .is-new-move-intent {
                        display: inline-block;
                        width: 7px;
                        height: 7px;
                        background: #FE5965;
                        border-radius: 50%;
                        margin-right: 2px;
                    }

                    .image-row-cell {
                        display: flex;
                        align-items: center;
                    }
                }

                .source-other-bot {
                    width: 120px;
                    height: 18px;
                    background: #799CFF;
                    border-radius: 3px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 18px;
                    text-align: center;
                    margin-top: 5px;
                    padding-bottom: 10px;

                    i {
                        color: #B2C6FF;
                    }
                }
            }

            .order_operation {
                .cell {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #366AFF;
                    // justify-content: flex-start;

                    .iconfont {
                        font-size: 16px;
                        margin-right: 14px;
                    }

                    .guoran-tongyichicun-kaiqi1 {
                        font-size: 18px;
                    }

                    .guoran-tongyichicun-guanbi1 {
                        font-size: 13px;
                    }

                    .guoran-tongyichicun-hulve {
                        font-size: 17px;
                        color: #C9D0E5;
                    }

                    .intent-name {
                        line-height: 20px;
                        margin-right: 4px;
                        text-align: left;
                        // display: inline-block;
                        width: 68px;
                    }
                }
            }

            .q-first-c {
                display: flex;
                flex-wrap: wrap;
            }

            .labels {
                height: 18px;
                line-height: 18px;
                background: #EFF2FF;
                border: 1px solid #DBE4FF;
                border-radius: 2px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                margin-bottom: 6px;
                text-align: center;
                margin-right: 6px;

                &.worker {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    width: 55px;
                }

                &.artificial {
                    background-color: #E4E5FF;
                    border-color: #E4E5FF;
                    color: #848AFF;
                    width: 55px;
                }

                &.no-use {
                    background-color: #FFDFE1;
                    border-color: #FFDFE1;
                    color: #FE5965;
                    width: 75px;
                }

                &.use {
                    background-color: #D4F1F0;
                    border-color: #D4F1F0;
                    ;
                    color: #00C2BB;
                    width: 75px;
                }

                &.long-info {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    min-width: 145px;
                    padding: 0 8px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .two-column {
                display: flex;
                align-items: flex-end;

                .left,
                .right {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .left {
                    margin-right: 10px;
                }

                &.start {
                    align-items: flex-start;
                }
            }

            &.max-h {
                height: 100px;
                line-height: 100px;
            }
        }

        .rescord_table_version {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: #A1B9FF;
                margin-right: 4px;
            }
        }

        .el-select,
        .el-input {
            padding-left: 0;
        }

        .reset-tag,
        .view-info {
            padding: 0;
        }

        .reset-tag {
            margin-right: 14px;
        }

        .correct,
        .intent-name {
            color: #366AFF;
        }

        .correct {
            margin-right: 14px;
        }

        .open-sort {
            // float: left;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            padding-left: 0;

            // .el-switch {
            //     margin-top: -10px;
            //     display: inline-block;
            //     line-height: unset;
            //     height: 30px;
            // }
            p {
                height: 20px;
                line-height: 20px;
            }

            .el-switch__label {
                line-height: 20px;
            }

            margin-left: 10px;

            .el-switch {
                height: 24px;

                .el-switch__core {
                    height: 24px;
                    border-radius: 12px;
                }

                .el-switch__core:after {
                    width: 14px;
                    height: 14px;
                    left: 5px;
                    top: 3px;
                }

                .el-switch__label--left {
                    i {
                        color: #6e6b7b;
                        font-weight: 800;
                        font-size: 14px;
                    }

                    position: absolute;
                    left: 15px;
                    top: 1px;
                    z-index: 2;

                }

                .el-icon-check {
                    color: #F6F8FD;
                    font-weight: 800;
                    position: absolute;
                }

                &.is-checked {
                    .el-switch__core:after {
                        left: 40px;
                        top: 3px;
                    }

                    .el-icon-check {
                        position: absolute;
                        left: 5px;
                        top: 3px;
                        z-index: 2;
                        font-size: 14px;
                        color: #fff;
                        font-weight: 800;
                    }

                    .el-icon-close {
                        color: #fff;
                    }
                }

            }
        }
    }

}

/deep/.table_updateTime {
    .cell {
        width: 100%;
        height: 36px;
        display: flex !important;
        align-items: center;

        .iconfont {
            color: #D2D8E3;
            margin-right: 5px;
        }
    }
}

.createTime_header {
    display: flex;
    align-items: center;
    cursor: pointer;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        i {
            margin: -3px 0;
        }

        .active {
            color: #366AFF;
        }
    }
}
</style>