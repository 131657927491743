<template>
  <div id="action_alert_iframe">
    <div class="simple-cell-satisfaction">
      <div class="title">
        <span>弹窗链接描述</span>
      </div>
      <div class="jump-cell">
        <el-input
          size="mini"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          v-model="unit.content.description"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>
    <div class="simple-cell-satisfaction">
      <div class="title">
        <span>弹窗链接</span>
        <span style="font-size：12px;color: #E6A23C">（请确保链接在测试窗及用户咨询页面能够正常显示）</span>
      </div>
      <div class="action-confirm-des">
        <div class="single-cell html-cell">
          <div class="cell-in-content">
            <ckeditor
              :editor="editorInline"
              :config="editorConfig"
              v-model="unit.content.template"
              @focus="onEditorFocus"
            ></ckeditor>
          </div>
          <div
            @click="templateConfigVisibleHandle('alert-placeholder')"
            class="insert-param"
          >
            插入变量
          </div>
        </div>
      </div>
    </div>
    <div class="simple-cell-satisfaction-r">
      <div class="title">
        <span>弹窗宽度</span>
      </div>
      <div class="action-confirm-des">
        <el-input
          size="mini"
          v-model="unit.content.width"
        ></el-input>
      </div>
    </div>
    <div class="simple-cell-satisfaction-r">
      <div class="title">
        <span>弹窗高度</span>
      </div>
      <div class="action-confirm-des">
        <el-input
          size="mini"
          v-model="unit.content.height"
        ></el-input>
      </div>
    </div>
    <div v-if="false" class="simple-cell-satisfaction-r">
      <div class="title">
        <span>是否在 iframe 中显示滚动条</span>
      </div>
      <div class="action-confirm-des">
        <el-radio-group size="mini" v-model="unit.content.scrolling">
          <el-radio label="yes">yes</el-radio>
          <el-radio label="no">no</el-radio>
          <el-radio label="auto">auto</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
function holder(data) {
  return "${" + data + "}";
}

/**
 * webhook 独立数据(单值,无所属作用域)占位使用
 *
 * @param webHooId webHooId
 * @param key 插入的数据
 * @return {string}
 */
const webHookDataHolder = function (webHooId, key) {
  return holder("_" + webHooId + "." + key);
};

import {
  lastEditor,
  currentEditor,
  changeEditor,
} from "../../ChartFlowContext";

/**
 * webhook 存在作用域的数据占位
 * @param key eg: location[*].name
 * @return {string} ${_item.name}
 */
const webHookDataHolderWithScope = function (key) {
  let lastIndex = key.lastIndexOf("]");
  return holder("_item" + key.substring(lastIndex + 1));
};

/**
 * 实体占位
 * @param name eg: 性别
 * @return {string} ${性别}
 */
const entityDataHolder = function (name) {
  return holder(name);
};

import CKEDITOR from "ckeditor";
export default {
  name: "action_alert_iframe",
  props: ["unit", "nodeOptions", "intentOptions", "unitIndex"],
  data() {
    return {
      jumpTypeOptions: [
        {
          value: "NODE",
          label: "跳转到节点",
        },
        {
          value: "INTENT",
          label: "跳转到意图",
        },
      ],
      editorDataInline: "",
      editorInline: CKEDITOR.InlineEditor,
      editorConfig: {
        toolbar: ["undo", "redo"],
      },
    };
  },
  methods: {
    jumpTypeValueChange() {
      this.unit.content.goToAction.value = "";
    },
    templateConfigVisibleHandle(type) {
      let param = { type: type };
      param.unitIndex = this.unitIndex;
      param.lastEditor = lastEditor;
      param.multiple = false;
      param.webhookId_key = [""];
      this.$emit("templateConfigVisibleHandle", param);
    },
    onEditorFocus(zh, editor) {
      changeEditor(editor);
    },
    addConfirmDataResponse() {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span-editable",
        options: {
          name: "单个webhook返回值",
          editable: false,
          data: webHookDataHolder(51, "data.name"),
        },
      });
    },

    addWebHookSinglePlace(data, webhookId) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span",
        options: {
          name: data.name,
          data: webHookDataHolder(webhookId, data.value),
        },
      });
    },

    addConfirmDataEntity(data, editable) {
      let command = currentEditor.commands.get("insertAskComponent");
      command.execute({
        tag: "span-editable",
        options: {
          name: data.name,
          editable: editable,
          data: entityDataHolder(data.name),
          type: data.inputType,
          editable_class: "arsenal_icon arsenalkebianji",
        },
      });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#action_alert_iframe {
  .simple-cell-satisfaction-r {
    margin-top: 8px;
    display: flex;
    .title {
      text-align: left;
      margin-bottom: 8px;
      margin-right: 32px;
      line-height: 20px;
      height: 20px;
    }
  }
  .simple-cell-satisfaction {
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 0px;
      text-align: left;
      margin-bottom: 8px;
    }
    .action-confirm-des {
      .html-cell {
        border-radius: 6px;
        display: flex;
        background-color: white;
        min-height: 50px;
        .cell-in-content {
          flex: auto;
          display: flex;
          justify-content: space-between;
          .ck-content {
            width: 100%;
          }
        }
        .insert-param {
          flex: none;
          width: 20px;
          background-color: #366AFF;
          color: white;
          cursor: pointer;
          writing-mode: vertical-lr;
          padding: 15px;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
}
</style>
