<template>
    <div id="action_enforce">
        <div v-show="type == 'action_enforce'" class="top-tip">
            执行该组件时，需要等待用户再发一句话给机器人后再执行跳转动作，并且强制进行跳转，多个跳转之间随机跳转一个。
        </div>
        <div v-show="type == 'action_random_jump'" class="top-tip">
            执行该组件时，多个跳转之间随机跳转一个。
        </div>
        <div class="goto-list">
            <div
                v-for="(cell, index) in unit.content.gotoList"
                :key="index"
                class="jump-cell"
            >
                <div class="left-select">
                    <el-select
                        style="width: 120px"
                        v-model="cell.scope"
                        size="small"
                        @change="jumpTypeValueChange($event, index)"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in filterJumpTypeOptions(
                                jumpTypeOptions
                            )"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>

                <div class="right-select">
                    <el-select
                        v-show="cell.scope === 'NODE'"
                        v-model="cell.value"
                        @change="nodeSelectChange"
                        :popper-append-to-body="false"
                        filterable
                        size="small"
                        placeholder="请输入选择名称"
                    >
                        <el-option
                            v-for="item in nodeOptions"
                            :popper-class="
                                'pop_my' + item.classPop + item.parentNum
                            "
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                    <el-cascader
                        v-show="cell.scope === 'INTENT'"
                        v-model="cell.value"
                        size="small"
                        :options="intentTree4Radio"
                        :props="{
                            expandTrigger: 'hover',
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                        }"
                    ></el-cascader>
                    <!-- <el-input
                        autosize
                        v-show="unit.content.negativeFeedback.scope === 'TEXT'"
                        type="textarea"
                        v-model="unit.content.negativeFeedback.value"
                        maxlength="2000"
                        placeholder="请输入文字答案"
                    ></el-input> -->
                </div>
                <span
                    v-if="index == 0"
                    class="el-icon-circle-plus handle-icon"
                    @click="addGoto"
                ></span>
                <span
                    v-if="index !== 0"
                    class="el-icon-circle-close handle-icon handle-icon-delete"
                    @click="deleteGoto(index)"
                ></span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "type",
        "isStartOrEnd",
    ],
    data() {
        return {
            jumpTypeOptions: [
                {
                    value: "NODE",
                    label: "跳转到节点",
                },
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
            ],
            // unit: {
            //     conditionList: [],
            //     content: {
            //         gotoList: [
            //             {
            //                 scope: "NODE", // INTENT NODE
            //                 value: "",
            //             },
            //         ],
            //     },
            //     type: "action_enforce",
            // },
        };
    },
    methods: {
        filterJumpTypeOptions(value) {
            if (this.isStartOrEnd) {
                return [
                    {
                        value: "INTENT",
                        label: "跳转到意图",
                    },
                ];
            } else {
                return value;
            }
        },
        jumpTypeValueChange(e, index) {
            console.log(e, index);
            this.unit.content.gotoList[index].value = "";
        },
        addGoto() {
            if (this.isStartOrEnd) {
                this.unit.content.gotoList.push({
                    scope: "INTENT", // INTENT NODE
                    value: "",
                });
            } else {
                this.unit.content.gotoList.push({
                    scope: "NODE", // INTENT NODE
                    value: "",
                });
            }
        },
        deleteGoto(index) {
            this.unit.content.gotoList.splice(index, 1);
        },
    },
};
</script>
<style lang="less" scoped>
#action_enforce {
    .top-tip {
        background-color: rgba(226, 160, 60, 0.3);
        border-radius: 6px;
        padding: 8px;
        text-align: left;
        color: #303133;
    }
    .goto-list {
        margin-top: 8px;
        .jump-cell {
            display: flex;
            justify-content: space-between;
            margin-top: 4px;
            .left-select {
                flex: none;
                width: 120px;
                .el-select {
                    width: 100%;
                }
            }
            .right-select {
                margin-left: 16px;
                flex: auto;
                .el-select {
                    width: 100%;
                }
                .el-cascader {
                    width: 100%;
                }
            }
            .handle-icon {
                width: 40px;
                flex: none;
                margin-left: 8px;
                font-size: 24px;
                margin-top: 2px;
            }
            .handle-icon-delete {
                color: tomato;
            }
        }
    }
}
</style>