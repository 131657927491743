<template>
    <div id="fast-select">
        <div class="fast-select-des">
            <span>
                用户在聊天页面低部出现快捷按钮，可配置相关问题或执行相关动作。</span
            >
        </div>
        <div class="fast-select-content">
            <div
                v-for="(item, formIndex) in unit.content.list"
                :key="formIndex"
                class="fast-select-content-cell"
            >
                <div class="move-box">
                    <el-tooltip
                        content="拖动"
                        placement="bottom"
                        effect="light"
                    >
                        <span
                            class="arsenal_icon arsenalpaixu icon-handle-fast"
                        ></span>
                    </el-tooltip>
                </div>
                <div class="fast-select-content-cell-form">
                    <div class="select-cell">
                        <span class="select-cell-title">名称</span>
                        <el-input
                            size="small"
                            v-model="item.text"
                            maxlength="20"
                            placeholder="请输入名称"
                        ></el-input>
                    </div>
                    <div class="select-cell">
                        <span class="select-cell-title">执行动作</span>
                        <div class="select-cell-sub-content">
                            <div class="select-cell-sub-content-pre">
                                <el-select
                                    v-model="item.action.scope"
                                    @change="changeScope(formIndex)"
                                    size="small"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in jumpTypeAddSendOptionsFast"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="select-cell-sub-content-aft">
                                <el-input
                                    v-if="
                                        item.action.scope ===
                                            'SEND_MSG_TO_BOT' ||
                                        item.action.scope ===
                                            'SEND_MSG_TO_USER' ||
                                        item.action.scope === 'LINK'
                                    "
                                    type="textarea"
                                    autosize
                                    :placeholder="
                                        item.action.scope === 'LINK'
                                            ? '请输入链接'
                                            : '请输入发送内容'
                                    "
                                    v-model="item.action.value"
                                >
                                </el-input>
                                <el-select
                                    v-if="item.action.scope === 'NODE'"
                                    v-model="item.action.value"
                                    :popper-append-to-body="false"
                                    filterable
                                    size="small"
                                    placeholder="请输入选择名称"
                                >
                                    <el-option
                                        v-for="item in nodeOptions"
                                        :key="item.id"
                                        :popper-class="
                                            'pop_my' +
                                            item.classPop +
                                            item.parentNum
                                        "
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                                <el-cascader
                                    v-show="item.action.scope === 'INTENT'"
                                    v-model="item.action.value"
                                    size="small"
                                    :options="intentTree4Radio"
                                    :props="{
                                        expandTrigger: 'hover',
                                        emitPath: false,
                                        label: 'name',
                                        value: 'id',
                                    }"
                                ></el-cascader>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-a-handle-box">
                    <span
                        v-if="formIndex == 0"
                        class="el-icon-circle-plus handle-icon"
                        @click="addToEntityCell"
                    ></span>
                    <span
                        v-if="formIndex != 0"
                        class="
                            el-icon-circle-close
                            handle-icon handle-icon-delete
                        "
                        @click="deleteToEntityCell(formIndex)"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sortable from "sortablejs";
export default {
    name: "fastSelect",
    props: [
        "unit",
        "intentOptions",
        "nodeOptions",
        "intentTree4Radio",
        "jumpTypeAddSendOptions",
        "isStartOrEnd",
    ],
    data() {
        return {
            jumpTypeAddSendOptionsFast: [
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_BOT",
                    label: "向机器人发送内容",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "向用户发送内容",
                },
                {
                    value: "LINK",
                    label: "跳转链接",
                },
            ],
        };
    },
    methods: {
        changeScope(formIndex) {
            console.log(this.unit, formIndex);
            this.unit.content.list[formIndex].action.value = "";
        },
        addToEntityCell() {
            this.unit.content.list.push({
                text: "",
                action: {
                    scope: this.isStartOrEnd ? "INTENT" : "NODE",
                    value: "",
                },
            });
            this.$nextTick(() => {
                this.rowDrop();
            });
        },
        deleteToEntityCell(index) {
            this.unit.content.list.splice(index, 1);
        },
        /**
         * 列表元素拖动
         * @method rowDrop
         * @param {}
         * @return {}
         */

        rowDrop() {
            const box = document.querySelector(".fast-select-content");
            const _this = this;
            new Sortable(box, {
                ghostClass: "blue-background-class",
                handle: ".icon-handle-fast",
                animation: 500,
                sort: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                    // 更新items数组
                    let item = _this.unit.content.list.splice(oldIndex, 1);
                    _this.unit.content.list.splice(newIndex, 0, item[0]);
                    // 下一个tick就会走patch更新
                },
            });
            this.unit.content.list = _this.unit.content.list;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.rowDrop();
        });
        if (!this.isStartOrEnd) {
            this.jumpTypeAddSendOptionsFast.unshift({
                value: "NODE",
                label: "跳转到节点",
            });
        }
    },
};
</script>
<style lang="less" scoped>
#fast-select {
    .fast-select-des {
        background-color: rgb(250, 236, 216);
        padding: 6px 8px;
        border-radius: 5px;
        margin: 8px 0;
        text-align: left;
        font-size: 12px;
    }
    .fast-select-content {
        .fast-select-content-cell {
            margin-bottom: 4px;
            background-color: white;
            border-radius: 6px;
            display: flex;
            justify-content: flex-start;
            .move-box {
                flex: none;
                width: 30px;
                text-align: center;
                display: flex;
                align-items: center;
                padding-left: 12px;
                cursor: pointer;
                span {
                    font-size: 14px;
                    color: #366AFF;
                    font-weight: 900;
                }
            }
            .fast-select-content-cell-form {
                flex: auto;
                .select-cell {
                    .select-cell-title {
                        margin: 6px 0 3px;
                        font-size: 12px;
                        display: block;
                        text-align: left;
                    }
                    .select-cell-sub-content {
                        display: flex;
                        justify-content: flex-start;
                        margin-bottom: 12px;
                        .select-cell-sub-content-pre {
                            flex: none;
                            width: 150px;
                            margin-right: 6px;
                        }
                        .select-cell-sub-content-aft {
                            flex: auto;
                            .el-input,
                            .el-select,
                            .el-cascader {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .d-a-handle-box {
                flex: none;
                width: 40px;
                text-align: left;
                display: flex;
                align-items: center;
                .handle-icon {
                    width: 40px;
                    flex: none;
                    margin-left: 8px;
                    font-size: 20px;
                    margin-top: 2px;
                }
                .handle-icon-delete {
                    color: red;
                }
            }
        }
    }
}
</style>