<template>
  <div id="entityManage">
    <!-- <div class="content-header">
      <div class="leftInfo">
        <span class="headerName">{{ activePageInfo.headerName }}</span>
        <div class="header-r-box">
          <el-input
            autocomplete="off"
            new-password="true"
            size="medium"
            placeholder="请输入实体名称"
            v-model="activePageInfo.entityName"
            @change="search"
          >
            <i class="el-icon-search" slot="suffix" @click="search"> </i>
          </el-input>
        </div>
      </div>
    </div> -->
    <page-top-header>
        <div slot="top-header-left" class="top-header-left">
            <i class="iconfont guoran-tongyichicun-shitiguanli"></i>
            <span class="page-name">{{$t('entityManagement.title')}}</span>
        </div>
        <div slot="top-header-right" class="top-header-right">
            <el-input
            autocomplete="off"
            new-password="true"
            size="medium"
            suffix-icon="el-icon-search"
            :placeholder="$t('entityManagement.namePlaceholder')"
            v-model="activePageInfo.entityName"
            @change="search"> </el-input>
        </div>
    </page-top-header>
    <div class="entityManage-content">
      <div class="tabs-bots">
        <div class="tabs-bots-header">
          <div class="bots-name" :class=" defaultShow ? 'active':'' " @click="switchDefaultShow()">{{$t('entityManagement.currency')}}</div>
          <div class="bots-name" v-for="item in botList" :key="item.id" :class="item.id == activeBot ? 'active' : ''" @click="switchBot(item.id)">
            <span :title="item.name">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="tabs-skill">
        <div class="tabs-skill-header" v-show="defaultShow">
          <div class="skill-name" :class="!isDefault && activeSkill == '0'? 'active':'' " @click="switchSkill(0,false)">{{$t('entityManagement.customCurrency')}}</div>
          <div class="skill-name" :class="isDefault && activeSkill == '0'? 'active':'' " @click="switchSkill(0,true)">{{$t('entityManagement.officialCurrency')}}</div>
        </div>
        <div class="tabs-skill-header" v-show="!defaultShow">
            <div v-for="item in activeBotSkillList" :key="item.id">
                <div v-show="item.name != '寒暄库'" class="skill-name"  :class="item.id == activeSkill ? 'active' : ''" @click="switchSkill(item.id)">
                    <span :title="item.name">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <div class="tabs-skill-content">
            <ul class="entity-list">
                <li class="entity cursor" @click="addIntent" v-if="!isDefault && ((defaultShow && RP_Visible('OPEN_ENTITY_COMMON_ADD')) || (!defaultShow && RP_Visible('OPEN_ENTITY_ADD')))">
                    <div class="add-entity-inner">
                        <span class="addIntentBtn">
                            <em class="el-icon-circle-plus"></em>
                        </span>
                        <span class="addBotText">{{$t('entityManagement.addEntity')}}</span>
                    </div>
                </li>
                <li class="entity" v-for="(item, index) in entityList" :key="item.id"  @click="edit(index, -1, !isDefault && ((defaultShow && RP_Visible('OPEN_ENTITY_COMMON_UPDATE')) || (!defaultShow && RP_Visible('OPEN_ENTITY_UPDATE'))))">
                    <div class="intent-inner">
                        <div class="intent-handle-box">
                            <em class="el-icon-edit" @click.stop="edit(index)" v-if="!isDefault && ((defaultShow && RP_Visible('OPEN_ENTITY_COMMON_UPDATE')) || (!defaultShow && RP_Visible('OPEN_ENTITY_UPDATE')))"></em>
                            <em class="el-icon-delete" @click.stop="deleteEntity(index)" v-if="!isDefault && ((defaultShow && RP_Visible('OPEN_ENTITY_COMMON_DELETE')) || (!defaultShow && RP_Visible('OPEN_ENTITY_DELETE')))"></em>
                            <el-tooltip placement="top">
                                <div slot="content">
                                  {{$t('entityManagement.updateTime')}}：{{item.uTime}}
                                <br />
                                  {{$t('entityManagement.createTime')}}：{{item.cTime}}
                                </div>
                                <em class="el-icon-more" v-if="!isDefault"></em>
                            </el-tooltip>
                        </div>
                        <div class="entity-name">{{item.name}}</div>
                        <div v-if="item.type=='DICTIONARY'">
                            <div class="word-list">
                                <div class="word-cell" v-for="( content,i) in item.content" :key="i" v-if="i<2">
                                    <div v-if="item.type==='DICTIONARY'">
                                        <span class="standard-word">{{item.content[i].standardWord}}</span>
                                        <span class="synonyms">{{Array.isArray(content.synonyms)?content.synonyms.join(','):""}}</span>
                                    </div>
                                    <div v-if="item.type==='MODEL'">
                                        <span class="standard-word-model">{{item.content[i].standardWord}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="intent-handle-box-bottom"><em @click.stop="clickDisplayWhole(index)" v-if="!(!isDefault && ((defaultShow && RP_Visible('OPEN_ENTITY_COMMON_UPDATE')) || (!defaultShow && RP_Visible('OPEN_ENTITY_UPDATE')))) && item.type==='DICTIONARY'">{{$t('entityManagement.viewAll')}}</em></div>
                        </div>
                        <div style="text-align:left" v-else>
                            <span class="standard-word regular-desc">{{item.content[0]&&item.content[0].description | ellipsis}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
      </div>
    </div>
    <AddIntntDia
      ref="addEntityDia"
      :addIntentDiaVisible="addIntentDiaVisible"
      :entity="currentEntity"
      :skills="skillsOptionsTamp"
      :isEdit="isEdit"
      handleType="add"
      @changeAddIntentVisible="changeAddIntentVisible"
      @afterSave="afterSave"
    ></AddIntntDia>
    <el-drawer
        :title="currentEntity.name"
        :visible.sync="displayWhole"
        direction="rtl">
        <div class="word-list">
            <div class="word-cell" v-for="( content,i) in currentEntity.content" :key="i">
                <span class="standard-word">{{content.standardWord}}</span>
                <span class="synonyms">{{Array.isArray(content.synonyms)?content.synonyms.join(','):""}}</span>
            </div>
        </div>
    </el-drawer>
    <el-dialog id="deleteConfirm" :close-on-click-modal="false" :visible.sync="deleteVisible" :title="$t('entityManagement.deleteEntity')" width="421px">
      <span class="content">{{$t('entityManagement.deleteEntityTip')}}</span>
      <div class="buttons">
        <el-button plain size="mini" @click="deleteEntityCancel">{{$t('common.cancel')}}</el-button>
        <el-button type="danger" plain size="mini" @click="deleteEntityOk">{{$t('common.deleteText')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTopHeader from '../../components/page-top-header.vue'
import BotNameAndTolick from "./../../components/BotNameAndToLink";
// import AddIntntDia from "../entity/addEntity";
import AddIntntDia from "../entity/addEntityupdate.vue";
import DispalyWhole from "../entity/displayWholeDrawer";
import BotSetting from "../botIndex/BotSettingNew.vue";
export default {
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 108) {
        return value.slice(0, 108) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      activePageInfo: {
        // 当前页跳转信息
        name: "返回全部实体",
        // gotoPageName: "botIndex",
        //botId: 1,
        headerName: "实体管理",
        entityName: "",
      },
      activeTab: "custom",
      intentName: "",
      activeClass: null,
      activeBot: null,
      skills: [],
      skillsOptions: [],
      skillsOptionsTamp: [],
      entityList: [],
      currentEntity: {},
      activeName: "first",
      stretchValue: false,
      disabled_input: true,
      addIntentDiaVisible: false, // 点击添加意图，弹窗状态
      displayWhole: false, // 显示全部内容
      intentTableData: [],
      // 分页
      currentPage: 1,
      pageSize: 15,
      total: 50,
      isEdit: null,
      deleteVisible: false,
      isOpen: null,
      botList: [],
      defaultShow: true,
      activeBotSkillList: [],
      activeSkill: null,
      isDefault: false,
    };
  },
  components: {
    BotNameAndTolick,
    AddIntntDia,
    DispalyWhole,
    BotSetting,
    pageTopHeader
  },
  methods: {
    /* search(value) {
      // console.log("search",value,this.activePageInfo.entityName)
      this.$refs[this.activeTab].search(this.activePageInfo.entityName);
    }, */
    tabClick() {
      this.$refs[this.activeTab].getEntityList();
    },
    switchDefaultShow(){
        this.defaultShow = true;
        this.activeBot = null;
        this.switchSkill(0,false)
    },
    switchBot(botId, from){
        this.activeBot = botId;
        console.log('this.activeBot'+this.activeBot);
        this.defaultShow = false;
        this.getSkillList(botId, from);
    },
    switchSkill(skillID,isDefault){
        console.log('isDefault',isDefault);
        if(isDefault == true){
            this.isDefault = true;
            console.log('isDefault',this.isDefault);
            this.activeSkill = skillID;
            this.getEntityList();
        }else{
            this.isDefault = false;
            this.activeSkill = skillID;
            this.getEntityList();
        }
    },
    getEntityList() {
      let params = {
        isDefault: this.isDefault,
        skillId: this.activeSkill
      };
      this.FetchGet(this.requestUrl.entity.getEntityList, params)
        .then(res => {
          if (res.code === "0") {
            res.data.list.forEach(item => {
              if (item.content == null) {
                item.content = [];
              } else {
                item.content.forEach(cell => {
                  if (!cell.description) {
                    cell.description = "";
                  }
                })
              }

              if (item.description == undefined || item.description == null) {
                item.description = "";
              }
            })
            this.entityList = res.data.list;
            if (this.entityList) {
              this.entityList.forEach(i => this.$set(i, "visible", false));
              this.entityList.forEach(item => {
                item.uTime = this.dateFormat('YYYY-mm-dd HH:MM:SS', (new Date(item.updateTime)));
                item.cTime = this.dateFormat('YYYY-mm-dd HH:MM:SS', (new Date(item.createTime)));
              });
            }
            this.entityList = [...this.entityList];
          } else {
            console.log("getEntityList", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log("getEntityList", err);
        });
    },
    getEntitySkills() {
      this.FetchGet(this.requestUrl.entity.getEntitySkills, {
        isDefault: this.isDefault,
      })
        .then((res) => {
          if (res.code === "0") {
            this.skills = res.data;
            this.skillsOptions = JSON.parse(JSON.stringify(res.data));
            
            if (this.skills) {
              if (this.activeSkill == null || this.activeSkill==-1) {
                this.activeSkill = this.skills[0].skillId;
              }
              this.getEntityList();
            }
          } else {
            console.log("getEntitySkills", res);
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message(err);
        });
    },
    // 获取机器人列表
    getBotList() {
      this.FetchGet(this.requestUrl.bot.getBotList, {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.code === "0") {
          this.botList = res.data.list;
          if (this.$route.query.bId) {
            this.activeBot = this.$route.query.bId;
            this.defaultShow = false;
            this.switchBot(this.activeBot, "getBotList");
          }
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    // 获取技能list
    getSkillList(botId, from) {
      this.FetchGet(this.requestUrl.skill.getSkillList, {
        bid: botId,
      }).then((res) => {
        if (res.code === "0") {
          this.activeBotSkillList = res.data;
          if (this.activeBotSkillList.length !== 0) {
            let isOnlyChatSkill=this.activeBotSkillList.length==1 && this.activeBotSkillList[0].name=='寒暄库';
            if(!isOnlyChatSkill){
              this.activeSkill = this.activeBotSkillList[0].id;
            }else{
              this.activeSkill = -1;
            }
            if (from == "getBotList" && this.$route.query.skillId) {
              this.activeSkill = this.$route.query.skillId;
            }
            this.isDefault = false;

            this.getEntityList();
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    clickDisplayWhole(index) {
      console.log("clickDisplayWhole",index);
      this.displayWhole = true;
      this.currentEntity = Object.assign({}, this.entityList[index]);
      console.log('this.currentEntity',this.currentEntity)
    },
    addIntent() {
      this.isEdit = false;
      this.skillsOptionsTamp = JSON.parse(JSON.stringify(this.skillsOptions));
      if (this.RP_Visible('OPEN_ENTITY_COMMON_ADD') && !this.RP_Visible('OPEN_ENTITY_ADD')) {
        this.skillsOptionsTamp.length = 1;
      }
      if (!this.RP_Visible('OPEN_ENTITY_COMMON_ADD') && this.RP_Visible('OPEN_ENTITY_ADD')) {
        this.skillsOptionsTamp.shift();
      }
      this.currentEntity = {
        id: null,
        skillId: this.activeSkill==-1?null:this.activeSkill,
        type: "DICTIONARY",
        isDefault: this.isDefault,
        content: []
      };
      console.log(337);
      this.addIntentDiaVisible = true;

      // 新版新增
      this.$refs.addEntityDia.addEntityDiaVisible = true;
      this.$refs.addEntityDia.entityObject = JSON.parse(JSON.stringify(this.currentEntity));
    },
    // type value 是否可以点击整个实体卡片跳转
    edit(index, type, value) {
      if (type == -1) {
        if (!value) {
          return
        }
      }
      console.log('index:',index);
      this.isEdit = true;
      this.skillsOptionsTamp = JSON.parse(JSON.stringify(this.skillsOptions));
      this.currentEntity = Object.assign({}, this.entityList[index]);
      this.currentEntity.skillId = this.activeSkill;
      this.addIntentDiaVisible = true;

      // 新版新增
      localStorage.setItem("activeEntityObj", JSON.stringify(this.entityList[index]))
      this.$router.push({ name: "editEntity", params: {
          isDefault: this.isDefault,
          bId: this.activeBot,
          skillId: this.activeSkill
        }
      });
    },
    deleteEntity(index) {
      this.deleteVisible = true;
      this.currentEntity = this.entityList[index];
      this.currentEntity.index = index;
    },
    deleteEntityOk() {
      this.FetchDelete(
        this.requestUrl.entity.deleteEntity,
        this.currentEntity.id
      )
        .then(res => {
          if (res.code === "0") {
            this.entityList.splice(this.currentEntity.index, 1);
            this.deleteVisible = false;
            this.getEntitySkills();
          } else {
            console.log("deleteEntityOk", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteEntityCancel() {
      this.deleteVisible = false;
    },
    openStatusChange(index) {
      this.isOpen = this.entityList[index].isOpen;
      if (this.isOpen) {
        let current = this.entityList[index];
        this.FetchPut(this.requestUrl.entity.updateEntity, current.id, current)
          .then(res => {
            if (res.code === "0") {
            } else {
              console.log("openStatusChangeConfirm", res);
              this.$message.error(res.message);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.entityList[index].isOpen = !this.entityList[index].isOpen;
        this.entityList[index].visible = true;
      }
    },
    openStatusChangeCancel(index) {
      let current = this.entityList[index];
      current.visible = false;
      this.entityList.splice(index, 1, current);
    },
    openStatusChangeConfirm(index) {
      let current = this.entityList[index];
      current.isOpen = this.isOpen;
      this.FetchPut(this.requestUrl.entity.updateEntity, current.id, current)
        .then(res => {
          if (res.code === "0") {
            current.visible = false;
            this.entityList.splice(index, 1, current);
          } else {
            console.log("openStatusChangeConfirm", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickDisplayWhole(index) {
      console.log("clickDisplayWhole");
      this.currentEntity = Object.assign({}, this.entityList[index]);
      this.displayWhole = true;
    },
    changeDisplayWholeVisible(value) {
      this.displayWhole = value;
    },
    changeAddIntentVisible(value) {
      this.addIntentDiaVisible = value;
    },
    afterSave(value) {
      this.getEntitySkills();
      if (this.isEdit) {
        let i = this.entityList.indexOf(value);
        if (i != -1) {
          this.entityList.splice(i, 1, value);
        }
      } else {
        this.entityList.push(value);
      }
      this.$router.push({ name: "editEntity", params: {
          isDefault: this.isDefault,
          bId: this.activeBot,
          skillId: this.activeSkill
      } });
    },
    // 跳转意图操作页
    gotoIntentHandle(id) {
      console.log(id);
      this.$router.push({
        name: "intentQa"
      });
    },
    // 分页操作
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    search(value) {
      let params = {
        isDefault: this.isDefault,
        name: value,
        skillId: this.activeSkill
      };
      this.FetchGet(this.requestUrl.entity.getEntityList, params)
        .then(res => {
          if (res.code === "0") {
            this.entityList = res.data.list;
          } else {
            console.log("getEntityList", res);
            this.$message.error(res.message);
          }
        })
        .catch(err => {
          console.log("getEntityList", err);
        });
    }
  },
  mounted() {
    this.getBotList();
    this.switchSkill(0,false)
    // 获取skills列表
    this.getEntitySkills();
  },
};
</script>

<style lang="less" scoped>
@import "./../../assets/less/entity/entityManagement.less";
</style>