<template>
    <div id="feedback">
        <div class="feedBackTitle">
            <span class="title">{{$t('botIntent.fastSelectSetting.title')}}</span>
           <div class="right">
                <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                    <el-switch
                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                        @click.native.prevent="changefastSelect('switch')"
                        :width="42"
                        v-model="fastSelectObjs.fastSelect"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2">
                    >
                    </el-switch>
                    <span
                    class="switch-open-icon"
                    @click="changefastSelect('open')"
                    v-if="fastSelectObjs.fastSelect"
                    ><i class="iconfont guoran-a-16-17"></i
                    ></span>
                    <span
                    class="switch-close-icon"
                        @click="changefastSelect('close')"
                    v-if="!fastSelectObjs.fastSelect"
                    ><i class="arsenal_icon arsenalcuo1"></i
                    ></span>
                </div>
                <i class="iconfont guoran-guanzhuren"></i>
                <span class="des">{{$t('botIntent.fastSelectSetting.openTip')}}</span>
           </div>
        </div>
        <div class="feedBackTitle">
            <span class="title">{{$t('botIntent.fastSelectSetting.group')}}</span>
            <div class="right">
                <div :class="['evaluate-table-switch',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']">
                    <el-switch
                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                        @click.native="changefastGroup('switch')"
                        @input="handleInput($event)"
                        :width="42"
                        v-model="fastSelectObjs.group"
                        active-color="#366AFF"
                        inactive-color="#E2E2E2">
                    >
                    </el-switch>
                    <span
                    class="switch-open-icon"
                    @click.stop="changefastGroup('open')"
                    v-if="fastSelectObjs.group"
                    ><i class="iconfont guoran-a-16-17"></i
                    ></span>
                    <span
                    class="switch-close-icon"
                        @click.stop="changefastGroup('close')"
                    v-if="!fastSelectObjs.group"
                    ><i class="arsenal_icon arsenalcuo1"></i
                    ></span>
                </div>
                <i class="iconfont guoran-guanzhuren"></i>
                <span class="des">{{$t('botIntent.fastSelectSetting.groupTip')}}</span>
            </div>
        </div>
        <div class="fs-content">
           <div class="group-content" >
                <div class="group-item" v-for="(groupItem,groupIndex) in fastSelectObjs.fastSelectSettingGroups" :key="groupItem.selfId" :data-id="JSON.stringify(groupItem)" >
                    <div class="sort-change">
                        <div class="sort-change-box all" 
                            v-if="fastSelectObjs.group && (!groupItem.fastSelectVisibleRanges || groupItem.fastSelectVisibleRanges.length === 0 || (groupItem.fastSelectVisibleRanges[0]&&groupItem.fastSelectVisibleRanges[0].type&&groupItem.fastSelectVisibleRanges[0].type === 4))">
                            <i :class="['iconfont guoran-a-16-10 group-sort',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']" @mouseenter="rowDropGroup(groupItem,groupIndex)"></i>
                            <div class="item first all">{{$t('botIntent.fastSelectSetting.all')}}</div>
                            <i :class="['iconfont guoran-a-16-15',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']" @click="chooseDeptMember(groupItem,groupIndex)"></i>
                        </div>
                        <div  class="sort-change-box"  v-if="groupItem.fastSelectVisibleRanges.length > 0 && groupItem.fastSelectVisibleRanges[0].type !== 4 && fastSelectObjs.group">
                            <div :class="['item',nameIndex===0 ? 'first' : '']" v-for="(nameItem,nameIndex) in groupItem.fastSelectVisibleRanges" :key="nameItem.id">
                                <i :class="['iconfont guoran-a-16-10 group-sort',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']" 
                                    v-if="nameIndex === 0"
                                    @mouseenter="rowDropGroup(groupItem,groupIndex)"></i>
                                <div v-if="nameItem.isWeWorkThirdData || (nameItem.bindType===0&&nameItem.wechatType===0)" class="name">
                                    <span>
                                        <!-- // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段 -->
                                        <open-data :type="nameItem.selfType == 0 ? 'departmentName' : 'userName'" :openid="nameItem.label"></open-data>
                                    </span>
                                </div>
                                <div v-else class="name">{{nameItem.label?nameItem.label:nameItem.name }}</div>
                            </div>
                            <i :class="['iconfont guoran-a-16-15',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']"  @click="chooseDeptMember(groupItem,groupIndex)"  ></i>
                        </div>
                        <i v-if="fastSelectObjs.group" :class="['el-icon-delete del-group',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']" @click="delGroup(groupIndex)"></i>
                    </div>
                    <div class="fs-content-list">
                        <div class="fs-content-list-title">
                            <!-- <span class="fs-content-list-cell-sort-pre"></span> -->
                            <span class="fs-content-list-cell-sort"></span>
                            <span class="fs-content-list-cell-name">{{$t('botIntent.fastSelectSetting.fastSelectName')}}</span>
                            <span class="fs-content-list-cell-action">{{$t('botIntent.fastSelectSetting.executeAfterClick')}}</span>
                            <span class="fs-content-list-cell-handle">{{$t('botIntent.fastSelectSetting.operation')}}</span>
                        </div>
                        <div :class="['fs-content-list-content-o','fs-content-list-content-sort'+groupIndex]" v-if="groupItem.list.length > 0">
                            <div
                                :data-id="JSON.stringify(item)"
                                v-for="(item,index) in groupItem.list"
                                :key="item.selfId" 
                                class="fs-content-list-content"
                            >
                                <div class="fs-content-list-cell-sort num">
                                    {{ item.orderNum }}
                                </div>
                                <div class="fs-content-list-cell-name">
                                    <el-input
                                        :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                        @input="changeInput($event,item)"
                                        v-model="item.text"
                                        maxlength="20"
                                        :placeholder="$t('botIntent.fastSelectSetting.namePlaceholder')"
                                    ></el-input>
                                </div>
                                <div class="fs-content-list-cell-action">
                                    <div class="select-cell-sub-content">
                                        <div class="select-cell-sub-content-pre">
                                            <el-select
                                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                                v-model="item.action.scope"
                                                @change="changeScope(item,index)"
                                                :placeholder="$t('common.selectPlaceholder')"
                                            >
                                                <el-option
                                                    v-for="item in jumpTypeAddSendOptions"
                                                    :key="item.value"
                                                    :label="$t('botIntent.fastSelectSetting.' + item.value)"
                                                    :value="item.value"
                                                ></el-option>
                                            </el-select>
                                        </div>
                                        <div class="select-cell-sub-content-aft">
                                            <el-input
                                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                                v-if="
                                                    item.action.scope ===
                                                        'SEND_MSG_TO_BOT' ||
                                                    item.action.scope ===
                                                        'SEND_MSG_TO_USER' ||
                                                    item.action.scope === 'LINK'
                                                "
                                                type="textarea"
                                                autosize
                                                :placeholder="item.action.scope ===
                                                        'SEND_MSG_TO_BOT' ||
                                                    item.action.scope ===
                                                        'SEND_MSG_TO_USER'?'请输入发送内容':'请输入链接'"
                                                v-model="item.action.value"
                                            >
                                            </el-input>
                                            <el-cascader
                                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                                :placeholder="$t('botIntent.searchOrSelectIntent')"
                                                v-show="item.action.scope === 'INTENT'"
                                                v-model="item.action.value"
                                                :options="intentTree4Radio"
                                                :props="{
                                                    emitPath: false,
                                                    label: 'name',
                                                    value: 'id',
                                                }"
                                            ></el-cascader>
                                        </div>
                                    </div>
                                </div>
                                <div class="fs-content-list-cell-handle">
                                    <span :class="['iconfont guoran-a-16-10 item-sort',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']" @mouseenter="rowDropList(groupItem,groupIndex,item,index)"></span>
                                    <span
                                        :class="['el-icon-delete cursor',!RP_Visible('OPEN_CONFIG_BOT_SET') ? 'disabled' : '']"
                                        @click="deleteCell(groupItem,index)"
                                    ></span>
                                </div>
                            </div>
                        </div>
                        <div class="add-fast-select">
                            <el-button
                                :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                                @click="addList(groupItem,groupIndex)"
                                icon="guoran-a-18-35 iconfont"
                                size="medium"
                                round
                                >{{$t('botIntent.fastSelectSetting.addFastSelect')}}</el-button
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-group-btn">
                <el-button
                    :disabled="!RP_Visible('OPEN_CONFIG_BOT_SET')"
                    v-if="fastSelectObjs.group"
                    @click="addGroup"
                    icon="guoran-a-18-35 iconfont"
                    size="medium"
                    round>{{$t('botIntent.fastSelectSetting.addGroup')}}
                </el-button>
            </div>
        </div>
        <chooseDeptMember 
            :checkedData="checkedData"
            :showPopup.sync="showPopup" 
            @chooseDeptMemberSuccess="chooseDeptMemberSuccess"
        ></chooseDeptMember>
    </div>
</template>
<script>
import OpenData from "../../../components/openData.vue";
import Sortable from "sortablejs";
import {v4 as uuidv4} from "uuid";
import chooseDeptMember from './chooseDeptMember.vue'
export default {
    components:{chooseDeptMember,OpenData},
    name: "fastSelect",
    props: ["fastSelectObj"],
    data() {
        return {
            name: "fastSelect",
            intentTree4Radio: [],
            showPopup:false,
            fastSelectObjs:{},
            jumpTypeAddSendOptions: [
                {
                    value: "INTENT",
                    label: "跳转到意图",
                },
                {
                    value: "SEND_MSG_TO_BOT",
                    label: "向机器人发送内容",
                },
                {
                    value: "SEND_MSG_TO_USER",
                    label: "向用户发送内容",
                },
                {
                    value: "LINK",
                    label: "跳转链接",
                },
            ],
            groupIndex:0,
            checkedData:[],
            keyId:''
        };
    },
    mounted() {
        this.getIntentListByBotId(this.$route.query.id);
    },
    watch: {
        fastSelectObj: {
            handler(n) {
                console.log(n,'mmmmmmmmmmmm快捷选择呢11111');
                this.fastSelectObjs = JSON.parse(JSON.stringify(n))
            },
            deep: true,
            // immediate:true
        },
        fastSelectObjs: {
            handler(n) {
                console.log(n,'mmmmmmmmmmmm快捷选择呢');
                this.$emit("changeFastSelectSaveStatus",n);
            },
            deep: true,
        },
    },
    methods: {
        changefastSelect(datas){
            if(this.RP_Visible('OPEN_CONFIG_BOT_SET')){
                if(datas === 'open'){
                    this.fastSelectObjs.fastSelect = false;
                } else if (datas === 'close'){
                    this.fastSelectObjs.fastSelect = true;
                }
            }
        },
        handleInput(event) {
            console.log(event);
            // intercept the change event of the value
            // suppose the value type is boolean
            if (!event) {
                this.fastSelectObjs.group = !event;
            } else {
                this.fastSelectObjs.group = !event;
            }
        },
        changefastGroup(datas){
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
            if(datas === 'switch'){
                if(this.fastSelectObjs.group){
                    this.$confirm('分组关闭后，将只保留第一个分组配置，且所有人快捷选择不分组，是否关闭？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.fastSelectObjs.group = false;
                        let first = this.fastSelectObjs.fastSelectSettingGroups[0]
                        this.fastSelectObjs.fastSelectSettingGroups.splice(1,this.fastSelectObjs.fastSelectSettingGroups.length);
                        this.fastSelectObjs.fastSelectSettingGroups[0].fastSelectVisibleRanges = [];
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消关闭'
                        });   
                    })
                } else {
                    this.fastSelectObjs.group = true;
                }
            } else if(datas === 'open'){
                if(this.fastSelectObjs.group){
                     this.$confirm('分组关闭后，将只保留第一个分组配置，且所有人快捷选择不分组，是否关闭？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.fastSelectObjs.group = false;
                        this.fastSelectObjs.fastSelectSettingGroups.splice(1,this.fastSelectObjs.fastSelectSettingGroups.length);
                        this.fastSelectObjs.fastSelectSettingGroups[0].fastSelectVisibleRanges = [];
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消关闭'
                        });   
                    })
                } else {
                    this.fastSelectObjs.group = true;
                }

            }else if (datas === 'close'){
                this.fastSelectObjs.group = true;
            } 



            
        },
        
        delGroup(groupIndex){
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return
            this.$confirm('确定删除该分组吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fastSelectObjs.fastSelectSettingGroups.splice(groupIndex,1);
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        
        // 打开选择部门/成员弹框
        chooseDeptMember(groupItem,groupIndex){
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return
            this.checkedData = groupItem.fastSelectVisibleRanges || [];
            this.showPopup = true;
            this.groupIndex = groupIndex;
        },
        // 选择成员/部门成功
        chooseDeptMemberSuccess(datas){
            this.fastSelectObjs.fastSelectSettingGroups[this.groupIndex].fastSelectVisibleRanges = [...datas];
            console.log( this.fastSelectObjs,'选择成员/部门成功');
            console.log( this.fastSelectObjs.fastSelectSettingGroups[this.groupIndex].fastSelectVisibleRanges);
            this.showPopup = false;
            this.$forceUpdate();
        },
        changeScope(item,index) {
            item.action.value = "";
            this.$forceUpdate();
        },
        changeInput(event,item){
            console.log(event,item);
            this.$forceUpdate();
        },
        deleteCell(groupItem,index) {
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return
           groupItem.list.splice(index, 1);
           this.$forceUpdate();
        },
        addList(groupItem,groupIndex){
            groupItem.list.push({
                text: '',
                selfId:uuidv4().toString().replaceAll("-", ""),
                orderNum:groupItem.list.length+1,
                action: {
                    scope: 'INTENT',
                    value: '',
                },
            })
            this.$forceUpdate();
        },
        addGroup(){
            console.log( this.fastSelectObjs);
            this.fastSelectObjs.fastSelectSettingGroups.push({
                list:[{
                    text: '',
                    selfId:uuidv4().toString().replaceAll("-", ""),
                    orderNum:1,
                    action: {
                        scope: 'INTENT',
                        value: '',
                    },
                }],
                selfId:uuidv4().toString().replaceAll("-", ""),
                fastSelectVisibleRanges:[],
            })
            this.$forceUpdate();
        },
        /**
         * 列表元素拖动---组中item
         * @method rowDrop
         * @param {}
         * @return {}
         */

        rowDropList(groupItem,groupIndex,items,indexs) {
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return
             const el = document.querySelector(".fs-content-list-content-sort"+groupIndex);
             console.log(el,'elelelelelel');
             let copy = JSON.parse(JSON.stringify(groupItem))
             let sortables =  new Sortable(el, {
                ghostClass: "blue-background-class",
                handle: ".item-sort",
                animation: 200,
                forceFallback: false,  
                onEnd:(event)=>{
                    let arr = sortables.toArray()
                    let testArr = []
                    console.log(arr,'arrarr');
                     arr.forEach((item,index) => {
                        let obj = JSON.parse(item);
                        obj.orderNum = index+1;
                        testArr.push(obj);
                    })
                    groupItem.list = [...[],...testArr];
                    this.$set(groupItem,'list',testArr)
                    console.log(groupItem,'groupItem');
                },
            });
        },
         /**
         * 列表元素拖动---组
         * @method rowDrop
         * @param {}
         * @return {}
         */


        rowDropGroup() {
            if(!this.RP_Visible('OPEN_CONFIG_BOT_SET'))return;
            const box = document.querySelector(".group-content");
            let sortables =  new Sortable(box, {
                animation: 200,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: '.group-sort',
                forceFallback: false,  
                onEnd:(event)=>{
                    let arr = sortables.toArray()
                    let testArr = []
                    testArr = arr.length > 0 ? arr.map(item => {
                        return JSON.parse(item)
                    }) : []
                    console.log(testArr,'testArrtestArrtestArr');
                    // this.fastSelectObjs.fastSelectSettingGroups = [...testArr];
                    this.$set(this.fastSelectObjs,'fastSelectSettingGroups',testArr)
                    this.$forceUpdate();
                },
            });
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId(bId) {
            this.FetchGet(this.requestUrl.trainList.intentTree, { bId: bId })
                .then((res) => {
                    if (res.code === "0") {
                        let nodeTree = JSON.parse(JSON.stringify(res.data));
                        for (let i = nodeTree.length - 1; i >= 0; i--) {
                            for (
                                let j = nodeTree[i].children.length - 1;
                                j >= 0;
                                j--
                            ) {
                                for (
                                    let k =
                                        nodeTree[i].children[j].children
                                            .length - 1;
                                    k >= 0;
                                    k--
                                ) {
                                    for (
                                        let l =
                                            nodeTree[i].children[j].children
                                                .length - 1;
                                        l > -0;
                                        l--
                                    ) {
                                        let intent =
                                            nodeTree[i].children[j].children[l];
                                        nodeTree[i].children[j].children[l] = {
                                            id: intent.id,
                                            name: intent.name,
                                        };
                                    }
                                }
                            }
                            this.intentTree4Radio = nodeTree;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
   
};
</script>
<style lang="less" scoped>
#feedback {
   /deep/ .el-input,.el-cascader,.el-select,.el-textarea{
        .el-input__inner,.el-textarea__inner{
            height: 38px !important;
        }
    }
    .fs-content {
        margin-top: 12px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .group-item{
            background: #FBFCFD;
            border-radius: 5px;
            width: 100%;
            margin-bottom: 20px;
            .sort-change{
                // min-height: 50px;
                display: flex;
                width: 100%;
                 background: #F6F8FD;
               
                .sort-change-box{
                    width: 95%;
                    
                    border-radius: 5px 5px 0px 0px;
                    padding-left: 20px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    color: #366AFF;
                    padding: 10px;
                    .guoran-a-16-15{
                        margin-bottom: 5px;
                        cursor: pointer;  
                        &.disabled{
                            cursor: no-drop;
                        } 
                    }
                    .group-sort{
                        margin-right: 10px;
                        margin-bottom: 5px;
                         &.disabled{
                            cursor: no-drop;
                        } 
                    }
                    .item{
                        margin-bottom: 5px;
                        height: 24px;
                        background: #FFFFFF;
                        border: 1px solid #A1B9FF;
                        border-radius: 12px;
                        color: #366AFF;
                        font-size: 12px;
                        margin-right: 7px;
                        padding: 0 12px;
                        line-height: 24px;
                      .name{
                        font-size: 12px;
                      }
                        &.first{
                            border: none;
                            background-color: transparent;
                            padding: 0;
                            display: flex;
                            margin-right: 0;
                            .name{
                                    height: 24px;
                                background: #FFFFFF;
                                border: 1px solid #A1B9FF;
                                border-radius: 12px;
                                color: #366AFF;
                                font-size: 12px;
                                margin-right: 7px;
                                padding: 0 12px;
                                line-height: 24px;
                            }

                        }
                        &.all{
                            margin-bottom: 5px;
                            height: 24px;
                            background: #FFFFFF;
                            border: 1px solid #A1B9FF;
                            border-radius: 12px;
                            color: #366AFF;
                            font-size: 12px;
                            margin-right: 7px;
                            padding: 0 12px;
                            line-height: 24px;
                        }
                    }
                }
                .del-group{
                    // width: 5%;
                    font-size:18px;
                    color: #366AFF;
                    padding: 10px 10px 0 0;
                    cursor: pointer;
                    &.disabled{
                        cursor: not-allowed;
                    }
                }
            }
        }
        .fs-content-list {
            // border: 1px solid #ddd;
            border-radius: 10px;
            padding: 12px;
            flex: auto;
           
            .fs-content-list-title {
                display: flex;
                justify-content: flex-start;
                // border-bottom: 1px solid #ddd;
                padding-bottom: 6px;
                margin-bottom: 8px;
                .fs-content-list-cell-sort-pre {
                    width: 30px;
                    flex: none;
                }
                .fs-content-list-cell-sort {
                    width: 70px;
                    flex: none;
                    text-align: center;
                    
                    
                }
                
                .fs-content-list-cell-name {
                    width: 150px;
                    flex: none;
                    margin-right: 8px;
                }
                .fs-content-list-cell-action {
                    flex: auto;
                }
                .fs-content-list-cell-handle {
                    width: 60px;
                    flex: none;
                    text-align: center;
                }
            }
            .fs-content-list-content {
                display: flex;
                justify-content: flex-start;
                padding-bottom: 6px;
                // height: 30px;
                // line-height: 30px;
                align-items: center;
                .fs-content-list-cell-sort-pre {
                    width: 30px;
                    text-align: center;
                    span {
                        cursor: pointer;
                        color: #409eff;
                        font-size: 20px;
                    }
                }
                .num{
                    width: auto !important;
                    padding: 0 6px;
                    height: 18px;
                    background: #FFFFFF;
                    border: 1px solid #366AFF;
                    border-radius: 5px;
                    line-height: 18px;
                    margin-right: 13px;
                    color: #366AFF;
                 }
                .fs-content-list-cell-sort {
                    width: 70px;
                    flex: none;
                    text-align: center;
                }
                .fs-content-list-cell-name {
                    width: 150px;
                    flex: none;
                    margin-right: 8px;
                }
                .fs-content-list-cell-action {
                    flex: auto;
                    .select-cell-sub-content {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        // margin-bottom: 12px;
                        .select-cell-sub-content-pre {
                            flex: none;
                            width: 150px;
                            margin-right: 6px;
                        }
                        .select-cell-sub-content-aft {
                            flex: auto;
                            .el-input,
                            .el-select,
                            .el-cascader {
                                width: 100%;
                            }
                        }
                    }
                }
                .fs-content-list-cell-handle {
                    flex: none;
                    text-align: center;
                    display: flex;
                    color: #366AFF;
                    align-items: center;
                    margin-left: 11px;
                    span {
                        font-size: 18px;
                        margin-left: 5px;
                        &.disabled{
                            cursor: no-drop;
                        }
                    }
                    .iconfont{
                        cursor: pointer;
                    }
                }
            }
            .add-fast-select {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                .el-button{
                    width: 124px;
                    height: 36px;
                    line-height: 38px;
                    background: #FFFFFF;
                    border: 1px solid #A1B9FF;
                    text-align: center;
                    padding: 0;
                    color: #366AFF;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .add-group-btn {
           display: flex;
            justify-content: center;
            margin-bottom: 20px;
            .el-button{
                width: 100px;
                height: 36px;
                background: #FFFFFF;
                border: 1px solid #A1B9FF;
                text-align: center;
                padding: 0;
                color: #366AFF;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .feedBackTitle {
         display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
        .right{
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
        }
        .guoran-guanzhuren{
            margin:0 10px;
             color: #FF9555;
        }
         /deep/ .el-switch.is-disabled .el-switch__core, .el-switch.is-disabled .el-switch__label{
            cursor: pointer;
        } 
        .el-switch.is-disabled{
        //    opacity: 1;
        }
        .feedBackTitlePre {
            width: 80px;
            flex: none;
            height: 100%;
            font-size: 16px;
            font-weight: bold;
            color: #606266;
            margin-right: 16px;
        }
        .feedBackTitleAft {
            margin-left: 16px;
            text-align: left;
            color: #d7d7d7;
        }
        .title{
            white-space: nowrap;
            font-size: 16px;
            color: #000000;
            font-weight: 700;
        }
        .des{
            text-align: left;
            color: #999999;
            font-size: 14px;
        }
    }
    .mgt10 {
        margin-top: 12px;
    }
    .evaluate-table-switch {
            display: flex;
            flex-direction: row;
            height: 25px;
            justify-content: space-between;
            cursor: pointer;
            position: relative;
            text-align: center;
            align-items: center;
    
            .el-switch {
                text-align: center;
            }
    
            .switch-open-icon {
                position: absolute;
                z-index: 999;
                top: 50%;
                transform: translateY(-50%);
                left: 3px;
    
                i {
                    color: white;
                    font-size: 16px;
                }
            }
    
            .switch-close-icon {
                position: absolute;
                z-index: 999;
                top: 50%;
                transform: translateY(-50%);
                left: 21px;
                i {
                    color: #6E6B7B;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
    
            i {
                color: #366AFF;
                font-size: 18px;
            }
            &.disabled{
                i,.el-switch__input,.el-switch__core{
                    cursor: no-drop;
                }
            }
        }
}
</style>
