<!--待标注问题--聊天记录-->
<template>
    <div class="question-list-box">
        <div class="question-list-content">
            <div class="question-list-title">
                <span v-if="viewType == 'question'">
                    <span class="label">{{$t('trainList.detailList.detailMarked')}}：</span>
                    <span>{{$t('trainList.detailList.question')}}：
                        <span v-if="activeItem.type !== 'IMAGE'">{{activeItem.question}}</span>
                        <span v-else>[{{$t('trainList.detailList.image')}}]</span>
                        ，</span>
                    <span class="frequency">{{$t('trainList.detailList.hits')}}：{{ activeItem.hits }}</span>
                </span>
                <span v-if="viewType == 'feedback'">
                    {{$t('trainList.detailList.detailFeedback')}}：{{ activeItem.showStaticDetail }}
                </span>
            </div>
            <div class="question-list">
                <el-table
                    v-if="viewType == 'feedback'"
                    v-loading="loading"
                    :data="filterList(questionList)"
                    @cell-mouse-enter="cellEnter"
                    highlight-current-row
                >
                    <el-table-column
                        show-overflow-tooltip
                        :label="$t('trainList.detailList.feedBackName')"
                        width="120"
                        align="left"
                        prop="userName"
                        class-name="user-name"
                    >
                        <template slot-scope="scope">
                             <span
                                v-if="scope.row.weWorkThirdAuthUser"
                            >
                                <ww-open-data
                                    type="userName"
                                    :openid="scope.row.userName"
                                ></ww-open-data>
                            </span>
                            <span v-else>
                                {{ scope.row.userName }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="content"
                        :label="$t('trainList.detailList.feedbackSituation')"
                        align="left"
                        width="150"
                        class-name="feedback-box"
                    >
                        <template slot="header" slot-scope="scope">
                            <div>
                                <!-- <span
                                    style="
                                        display: inline-block;
                                        margin-right: 12px;
                                    "
                                    >反馈结果</span
                                > -->
                                <el-dropdown
                                    szie="mini"
                                    @command="handleCurrentSortType"
                                >
                                    <span class="el-dropdown-link">
                                        <span
                                            style="
                                                display: inline-block;
                                                margin-right: 12px;
                                            "
                                            >{{$t('trainList.detailList.feedbackResults')}}</span
                                        >
                                        <span
                                            style="
                                                color: #909399;
                                                fontsize: 12px;
                                            "
                                            >{{ currentSortTypeName }}</span
                                        >
                                        <i
                                            class="
                                                el-icon-arrow-down
                                                el-icon--right
                                            "
                                        ></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="0"
                                            >{{$t('trainList.detailList.all')}}</el-dropdown-item
                                        >
                                        <el-dropdown-item command="1"
                                            >{{$t('trainList.useful')}}</el-dropdown-item
                                        >
                                        <el-dropdown-item command="2"
                                            >{{$t('trainList.useless')}}</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <span>{{ scope.row.content }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        :label="$t('trainList.updateTime')"
                        :render-header="renderDate"
                        align="left"
                        width="170"
                    >
                    </el-table-column>
                    <el-table-column
                        prop=""
                        :label="$t('trainList.operation')"
                        align="left"
                        :width="$i18n.locale === 'en' ? '100' : '50'"
                        class-name="operation"
                    >
                        <template slot-scope="scope">
                            <!-- <el-button
                                type="text"
                                class="reset-tag"
                                @click="handleItem(scope.row)"
                                >查看消息记录</el-button
                            > -->
                            <el-tooltip  class="item" effect="dark" :content="$t('trainList.detailList.viewMessageRecords')" placement="top" :key="scope.row.id" :open-delay="300">
                                <el-button
                                    type="text"
                                    class="reset-tagc iconfont guoran-tongyichicun-dianjichakantuli"
                                    @click="handleItem(scope.row)">
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table
                    v-loading="loading"
                    v-if="viewType == 'question'"
                    :data="filterList(questionList)"
                    @cell-mouse-enter="cellEnter"
                    highlight-current-row
                    class="question-list-table"
                >
                
                    <el-table-column
                        show-overflow-tooltip
                        :label="$t('trainList.detailList.userName')"
                        align="left"
                        class-name="user-name"
                        prop="userName"
                    >
                        <template slot-scope="scope">
                            <!-- <span>{{ scope.row.userName }}</span> -->
                            <span
                                v-if="scope.row.weWorkThirdAuthUser"
                            >
                                <ww-open-data
                                    type="userName"
                                    :openid="scope.row.userName"
                                ></ww-open-data>
                            </span>
                            <span v-else>
                                {{ scope.row.userName }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        show-overflow-tooltip
                        :label="$t('trainList.detailList.identificationResults')"
                        align="left"
                        prop="intentName"
                    >
                        <template>
                            <span
                                v-if="
                                    activeItemObj.button &&
                                    [4, 5].indexOf(activeItemObj.button) > -1 &&
                                    (activeItemObj.unrecognizedAction == null ||
                                        activeItemObj.unrecognizedAction == 0)
                                "
                                style="color: #FE5965"
                                >{{$t('trainList.questionList.unrecognized')}}</span
                            >
                            <span
                                v-if="
                                    activeItemObj.button &&
                                    [4, 5].indexOf(activeItemObj.button) > -1 &&
                                    activeItemObj.unrecognizedAction == 1
                                "
                                style="color: #f56c6c"
                                >{{$t('trainList.questionList.KeywordRecommend')}}</span
                            >
                            <span
                                v-if="
                                    activeItemObj.button &&
                                    [4, 5].indexOf(activeItemObj.button) > -1 &&
                                    activeItemObj.unrecognizedAction == 2
                                "
                                style="color: #FF7200"
                                >{{$t('trainList.questionList.noRecognizingRecommend')}}</span
                            >
                            <template v-else>
                                <span v-if="[4, 5].indexOf(activeItemObj.button) === -1">{{ activeItemObj.intentName }}</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="updateTime"
                        :label="$t('trainList.updateTime')"
                        align="left"
                        width="170"
                    >
                    <template slot-scope="scope">
                        <i class="guoran-a-18-15 iconfont" style="font-size:17px;"></i>
                        <span>{{scope.row.updateTime}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                        prop=""
                        :label="$t('trainList.operation')"
                        align="center"
                        :width="$i18n.locale === 'en' ? '100' : '50'"
                        class-name="operation"
                    >
                        <template slot-scope="scope">
                            <el-tooltip  class="item" effect="dark" :content="$t('trainList.detailList.viewMessageRecords')" placement="top" :key="scope.row.id" :open-delay="300">
                                <el-button
                                    type="text"
                                    class="reset-tagc iconfont guoran-tongyichicun-dianjichakantuli"
                                    @click="handleItem(scope.row, scope.$index)">
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="chat-list">
            <div class="chat-list-title">
                <span class="msg-list-title">{{$t('trainList.detailList.messageRecording')}}</span>
                <div v-if="activeUserIndex !== -1" class="user-name-title">
                    <div class="first-name" v-if="!weWorkThirdAuthUser">{{userName.slice(0,1)}}</div>
                    <span v-if="weWorkThirdAuthUser" class="one-column-ellipsis">
                        <ww-open-data
                            type="userName"
                            :openid="userName"
                        ></ww-open-data>
                    </span>
                    <span v-else class="one-column-ellipsis">
                        {{ userName }}
                    </span></div
                >
            </div>
            <div class="chat-list-content">
                <ChatList ref="chatList" :feedbackChatUrl="feedbackChatUrl" />
            </div>
        </div>
    </div>
</template>

<script>
import ChatList from "./ChatContent";
import wwOpenData from "./../../../../src/components/openData.vue";
export default {
    props: ["activeItem", "viewType"],
    components: { ChatList },
    data() {
        return {
            userName: "",
            userId:"",
            weWorkThirdAuthUser: "",
            activeUserIndex: -1,
            feedbackChatUrl: "",
            questionList: [],
            currentSortType: 5, // 0:有用升序 1:有用降序 2: 无用升序 3:无用降序
            currentSortTypeName: "全部",
            sortTypeOption: ["全部", "有用", "无用"],
            orderType: 0, //  0：时间倒序 1：时间正序 2：频次倒序 3：频次正序 4：相似问法
            orderByType: "desc",
            activeItemObj:{}
        };
    },
    watch: {},
    mounted() {
        this.getFeedbackList();
    },
    methods: {
        filterList(list) {
            let arr = [];
            if (this.currentSortTypeName == "全部") {
                arr = list;
            } else {
                list.forEach((item) => {
                    if (
                        this.currentSortTypeName == item.content ||
                        (this.currentSortTypeName == "无用" &&
                            item.content == "去报障")
                    ) {
                        arr.push(item);
                    }
                });
            }
            return arr;
        },
        getFeedbackList() {
            let activeItemObj = this.activeItem;
            this.activeItemObj = activeItemObj;
            if (this.viewType == "feedback") {
                this.FetchGet(this.requestUrl.feedback.answerFeedbackDetail, {
                    apiKey: activeItemObj.apiKey,
                    intentId: activeItemObj.intentId,
                    md5Actions: activeItemObj.md5Actions,
                    nodeId: activeItemObj.nodeId,
                    page: 1,
                    size: 100,
                    orderBy: "time",
                    orderByType: this.orderByType,
                }).then((res) => {
                    if (res.code === "0") {
                        this.questionList = res.data == null ? [] : res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
            if (this.viewType == "question") {
                this.FetchGet(
                    this.requestUrl.trainList.getListByIntentIdentifyId,
                    {
                        intentIdentifyId: activeItemObj.id,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.questionList = res.data == null ? [] : res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        handleCurrentSortType(command) {
            this.currentSortType = command;
            this.currentSortTypeName = this.sortTypeOption[Number(command)];
        },
        renderDate(h) {
            return h("div", [
                h("span", this.$t('trainList.updateTime')),
                h(
                    "span",
                    {
                        class: "caret-wrapper",
                    },
                    [
                        h("i", {
                            class: "sort-caret ascending",
                            style: {
                                borderBottomColor:
                                    this.orderByType === "asc"
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    this.orderByType = "asc";
                                    this.getFeedbackList();
                                },
                            },
                        }),
                        h("i", {
                            class: "sort-caret descending",
                            style: {
                                borderTopColor:
                                    this.orderByType === "desc"
                                        ? "#366AFF"
                                        : "#C0C4CC",
                            },
                            on: {
                                click: () => {
                                    this.orderByType = "desc";
                                    this.getFeedbackList();
                                },
                            },
                        }),
                    ]
                ),
            ]);
        },
        handleItem(item, index) {
            console.log(item, index);
            this.activeUserIndex = index;
            this.userName = item.userName;
            this.userId = item.userId;
            this.weWorkThirdAuthUser = item.weWorkThirdAuthUser;
            if (this.viewType == "feedback") {
                this.feedbackChatUrl =
                    this.requestUrl.feedback.messageSessionHistory +
                    item.apiKey +
                    "/" +
                    item.userId +
                    "/" +
                    item.sessionId +
                    "?clientType=OPEN";
                this.$refs.chatList.getFeedbackChat(
                    this.feedbackChatUrl,
                    item.feedbackUid
                );
            }
            if (this.viewType == "question") {
                this.feedbackChatUrl =
                    this.requestUrl.feedback.messageSessionHistory +
                    item.apiKey +
                    "/" +
                    item.sourceId +
                    "/" +
                    item.sessionId +
                    "?clientType=OPEN";
                this.$refs.chatList.getFeedbackChat(
                    this.feedbackChatUrl,
                    item.msgId
                );
            }
        },
    },
};
</script>

<style lang="less">
.media-body {
    img {
        max-width: 260px;
    }
}
.question-list-box {
    display: flex;
    // height: 100%;
    // width: 100%;
    // margin-left: 10px;
    border-radius: 4px;
    .question-list-content {
        // height: 100%;
        flex: none;
        width: 490px;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: 1px solid #E0E6F7;
        border-radius: 5px 0px 0px 5px;
        border-right: none;
        .question-list-title {
            height: 50px;
            min-height: 50px;
            // text-align: left;
            padding: 0 18px;
            display: flex;
            align-items: center;
            border-bottom: solid 1px #E0E6F7;
            background-color: #fff;
            span {
                // display: inline-block;
                // height: 32px;
                // line-height: 32px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .label{
                color: #A9B3C6;
            }
            .frequency{
                color: #00C2BB;
                margin-left: 5px;
            }
        }
        .question-list {
            flex: auto;
            overflow: auto;
            .el-table__header-wrapper{
                .user-name{
                    padding-left: 20px;
                    text-align: left;
                    .cell{
                        padding: 0 !important;
                    }
                }
                .feedback-box{
                    .cell,.el-dropdown{
                        padding: 0;
                    }
                }
            }
             .el-table__body-wrapper{
                 .el-table__body{
                     width: 100% !important;
                 }
                .user-name{
                    padding-left: 20px;
                }
                .cell{
                    padding: 0 !important;
                }
                .operation .cell{
                    justify-content: center;
                }
            }
            .question-list-table{
                .el-table__header-wrapper{
                    .cell{
                        padding: 0;
                    }
                }
            }
        }
    }
    .chat-list {
        width: 440px;
        // height: 100%;
        flex: none;
        display: flex;
        flex-direction: column;
        border: 1px solid #E0E6F7;
        border-radius: 5px 5px 5px 0px;
        .chat-list-title {
            width: calc(100% - 35px);
            flex: none;
            height: 50px;
            text-align: left;
            padding: 0 16px 0 20px;
            border-bottom: solid 1px #dcdfe6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #5682FF;
            color: #fff;
            .user-name-title {
                flex: auto;
                text-align: right;
                height: 50px;
                max-width: 200px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .first-name{
                    width: 26px;
                    height: 26px;
                    background: #366AFF;
                    border-radius: 50%;
                    color: #FFFFFF;
                    font-size: 12px;
                    text-align: center;
                    line-height: 26px;
                    margin-right: 6px;
                }
                
                    
            }
            .msg-list-title {
                display: inline-block;
                flex: none;
                height: 32px;
                line-height: 32px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            
        }
        .chat-list-content {
            flex: auto;
            overflow: auto;
            background-color: #f5f5f5;

            .ig-view-cli{
                display: flex;
                flex-direction: column;
            }
        }
    }
}
</style>