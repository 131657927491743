<template>
    <div class="feedbackDetailBox">
        <div class="feedbackDetailTitle">
            <el-button v-if="viewTypeParent == 'feedback'" @click="editFeedbackDetailA" size="mini" type="primary"
                >{{$t('trainList.questionList.modifyAnswers')}}</el-button
            >
            <el-button @click="closeFeedbackDetailBox" size="mini"
                >{{$t('common.close')}}</el-button
            >
        </div>
        <div class="feedbackDetailView">
            <node-de v-if="viewTypeParent == 'feedback'" :activeItem="activeItem" />
            <DetailList :viewType="viewTypeParent" :activeItem="activeItem" />
        </div>
    </div>
</template>

<script>
import NodeDe from "./NodeDe.vue";
import DetailList from "./DetailList";
export default {
    props: ["activeItem", "viewTypeParent"],
    components: { NodeDe, DetailList },
    data() {
        return {};
    },
    watch: {},
    mounted() {
        // console.log(this.activeItem);
    },
    methods: {
        editFeedbackDetailA() {
            let item = this.activeItem;
            this.$router.push({
                name: "chatFlow",
                query: {
                    id: item.botId,
                    bid: item.botId,
                    bName: item.botName,
                    intentId: item.intentId,
                    intentName: item.intentName,
                    refApiKey: item.apiKey,
                },
            });
        },
        closeFeedbackDetailBox() {
            this.$emit("closeFeedbackDetailBox");
        },
    },
};
</script>

<style lang="less">
.feedbackDetailBox {
    max-width: 100vw;
    position: absolute;
    // right: 8px;
    // width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .feedbackDetailTitle {
        // margin: 16px 16px 0;
        // flex-direction: row;
        // display: flex;
        // justify-content: flex-end;
        // height: 28px;
        // flex: none;
        padding-right: 20px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .el-button{
            height: 29px;
            background: #FFFFFF;
            border: 1px solid #E0E6F7;
            border-radius: 14px;
            padding:0 18px;
            text-align: center;
            line-height: 29px;
            font-size: 12px;
            color: #000000;
        }
    }
    .feedbackDetailView {
        height: calc(100vh - 90px);
        margin: 0 20px;
        flex: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>