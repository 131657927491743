<template>
    <div :class="[isE() ? 'ebot-phone-config' : 'phone-config']">
        <div class="top-self" v-if="setType !== 'bot-setting-fastSelect' && phoneTitle !== ''">
            <div class="text-tips">  
                <span>{{phoneTitle}}</span>
                <div class="jiao"></div>
            </div>
            <div class="right-icon guoran-tongyichicun-24-08-yonghutouxiang iconfont"></div>
        </div>
        <div class="guess-qa" v-if="isShowRecommnd && setType !== 'bot-setting-fastSelect'">
            <div class="bot-icon iconfont guoran-askbotdandutubiao2"></div>
            <div class="right-content">
                <div class="right-list">
                    <div class="jiao-recommend"></div>
                    <div v-if="currentTab !== 'bot-setting-feedback'" :class="['title',qaList.length > 0 ? 'phone-config-have-line' : '']">{{recommndTitle}}</div>
                   <div class="qa-list-content">
                      <div class="scroll-box">
                            <div :class="['item',currentTab === 'bot-setting-feedback' ? 'feedback' : '']" v-for="(item,index) in qaList" :key="index">
                                <span>{{item}}</span>
                                <i class="iconfont guoran-right" v-if="currentTab !== 'bot-setting-feedback'"></i>
                            </div>
                      </div>

                      <div class="mask-scroll"></div>
                   </div>
                </div>
                <div class="is-use" v-if="setType === 'bot-setting-feedback' && isShowAanswer">
                    <div class="used">{{$t('botIntent.used')}}</div>
                    <div class="no-use">{{$t('botIntent.noUse')}}</div>
                </div>
            </div>
        </div>
        <div class="bottom-config">
            <div class="bottom-config-keyword" v-if="setType === 'bot-setting-fastSelect' && isShowQuick">
                <div v-for="(item,index) in quickList" :key="index">
                    <div class="item" v-if="item !== ''">{{item}}</div>
                </div>
               
            </div>
            <div class="bottom-config-set">
                <div class="speak iconfont guoran-arsenalchess-pawn-solid1"></div>
                <div class="input">{{$t('botIntent.textPlaceholder')}}</div>
                <div class="add-btn iconfont guoran-tongyichicun-16-13-xinjian"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
           
        }
    },
    props:{
        phoneTitle:{
            type:String,
            default:"" // 关键词标题
        },
        setType:{
            type:String,
            default:"bot-setting-noDescernIntentON"
        },
        isShowRecommnd:{ // 是否显示机器人回答
            type:Boolean,
            default:false
        },
        recommndTitle:{ // 机器人回答标题
            type:String,
            default:"猜您可能想问"
        },
        isShowAanswer:{ // 是否显示回答的有用无用
            type:Boolean,
            default:false
        },
        isShowQuick:{// 是否显示快捷选择
            type:Boolean,
            default:false
        },
        qaList:{ // 机器人回答列表
            type:Array,
            default(){
                return []
            }
        },
        settingList:{
            type:Object,
            default(){
                return {}
            }
        },
        quickList:{
            type:Array,
            default(){
                return []
            }
        },
        currentTab:{
            type:String,
            default:''
        }
    },
}
</script>

<style scoped lang="less">
.phone-config, .ebot-phone-config{
    position: relative;
    width: 292px;
    height: 619px;
    background: url(../../../assets/images/phone-example.png);
    margin-left: 12px;
    .top-self{
        text-align: right;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 100px;
        right: 10px;
        font-size: 14px;
        .text-tips{
            padding: 0 15px;
            height: 40px;
            background: #366AFF;
            border-radius: 50px 0px 50px 50px;
            color: #fff;
            line-height: 40px;
            position: relative;
            .jiao{
                position: absolute;
                top: 0;
                right: -8px;
                width: 0;
                height: 0;
                border-top: 8px solid #366AFF;
                border-right: 8px solid transparent;
            }
        }
        .right-icon{
            width: 33px;
            height: 33px;
            background: #EDF0F6;
            border-radius: 50%;
            color: #B7C3DA;
            font-size: 18px;
            text-align: center;
            line-height: 33px;
            margin-left: 10px;
        }
    }
    .guess-qa{
        margin-top: 25px;
        display: flex;
        position: absolute;
         top: 170px;
        left: 10px;
       
        .bot-icon{
            text-align: center;
            line-height: 33px;
            color:#366AFF;
            font-size: 32px;
            margin-right: 7px;
            
        }
        .right-content{
            .right-list{
                position: relative;
                width: 230px;
                background: #F5F7FB;
                border: 1px solid #E9ECF2;
                border-radius: 0 40px 40px 40px;
                .mask-scroll{
                    position: absolute;
                    width: 6px;
                    height: 21px;
                    background-color: #fff;
                    right: 0px;
                    bottom: -3px;
                }
                .jiao-recommend{
                    position: absolute;
                    top: 0;
                    left: -7px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid #F5F7FB;
                    border-left: 10px solid transparent; 
                }
                .title{
                    padding: 0 15px;
                    line-height: 40px;
                    height: 40px;
                    font-size:14px;
                    color: #222222;
                    text-align: left;
                    display: -webkit-box;
                      /*设置为弹性盒子*/
                    -webkit-line-clamp: 1;
                    /*最多显示3行*/
                    overflow: hidden;
                    /*超出隐藏*/
                    text-overflow: ellipsis;
                    /*超出显示为省略号*/
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                    &.phone-config-have-line{
                        border-bottom: 1px solid #EEEEEE;
                    }
                }
                .qa-list-content{
                    padding-bottom: 10px;
                    max-height: 300px;
                    .scroll-box{
                        max-height: 300px;
                        overflow-y: auto;
                    }
                   
                }
                .item{
                    padding: 0 20px;
                    height: 35px;
                    line-height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #366AFF;
                    font-size: 12px;
                    &.feedback{
                        color: #616161;
                    }
                    span{
                        text-align: left;
                         display: -webkit-box;
                        /*设置为弹性盒子*/
                        -webkit-line-clamp: 1;
                        /*最多显示3行*/
                        overflow: hidden;
                        /*超出隐藏*/
                        text-overflow: ellipsis;
                        /*超出显示为省略号*/
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                    }
                    i{
                        font-size: 10px;
                    }
                }
            }
            .is-use{
                margin-top: 10px;
                display: flex;
                margin-left: 30px;
                div{
                    width: 76px;
                    height: 28px;
                    background: #FFFFFF;
                    border-radius: 14px;
                    text-align: center;
                    line-height: 28px;
                    font-size: 12px;
                    &.used{
                        border: 1px solid #BBCDFF;
                        color: #366AFF;
                        margin-right: 14px;
                    }
                    &.no-use{
                        color: #616161;
                        background: #FFFFFF;
                        border: 1px solid #E0E6F7;
                    }
                }
            }

        }
        

    }
    .bottom-config{
        position: absolute;
        bottom: 0;
        width: 276px;
        // height: 72px;
        background: #EEF1F7;
        padding: 8px;
        .bottom-config-keyword{
            padding-right: 10px;
            overflow-x: auto;
            height: 25px;
            display: flex;
            width: 100%;
            align-items: center;
            padding-bottom: 5px;
            &::-webkit-scrollbar {
                height: 2px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                height: 2px;
            }
            .item{
                font-size: 9px;
                font-weight: bold;
                color: #000000;
                padding: 0 10px;
                height: 23px;
                white-space: nowrap;
                background: #FFFFFF;
                border-radius: 11px;
                line-height: 23px;
                margin-right: 6px;
            }
        }
        .bottom-config-set{
            display: flex;
            align-items: center;
            margin-top: 6px;
            height: 30px;
            // justify-content: space-between;
            padding: 0 10px;
            .speak{
                color: #81A2FF;
                font-size: 20px;
                width: 28px;
                height: 28px;
                border: 2px solid #81A2FF;
                border-radius: 50%;
                text-align: center;
                line-height: 28px;
            }
            .input{
                width: 180px;
                height: 30px;
                background: #FFFFFF;
                border-radius: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                padding-left: 10px;
                text-align: left;
                line-height: 30px;
                margin: 0 8px;
            }
            .guoran-tongyichicun-16-13-xinjian{
                width: 28px;
                height: 28px;
                background: #366AFF;
                border: 1px solid #E4E7EB;
                border-radius: 50%;
                color: #FFFFFF;
                font-size: 12px;
                text-align: center;
                line-height: 28px
            }
        }
    }

}
.ebot-phone-config {
    background: url(../../../assets/images/echatBackground.png) !important; 
}
</style>