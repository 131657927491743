<template>
    <div class="work_order_train_detail">
        <div class="wotd_head">
            <section>
                <i class="iconfont guoran-tongyichicun-xunlianjilu"></i>
                {{$t('trainList.trainNode.trainDetail')}}
            </section>
            <span @click="close">
                <i class="iconfont guoran-tongyichicun-guanbi1"></i>
            </span>
        </div>
        <div class="wotd_tips">
            <div class="tips">
                <span class="tips_label">{{$t('trainList.trainNode.versionNumber')}}: </span><span> {{ trainVersionData.version }} </span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="tips">
                <span class="tips_label">
                    <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
                    {{$t('trainList.trainNode.trainTime')}}:
                </span>
                <span> {{ new Date(trainVersionData.createTime).Format("yyyy-MM-dd hh:mm") }}</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="tips">
                <span class="tips_label"><i class="iconfont guoran-tongyichicun-biaozhu"></i> {{$t('trainList.numberAnnotatedOrder')}}</span>:
                <span> {{ trainVersionData.workOrderToKnowledgeTotal }}</span>
            </div>
        </div>
        <div class="wotd_search">
            <el-input v-model="serchText" @keyup.enter.native="getTrainByWorkOrderList" size="small" :placeholder="$t('trainList.searchOrderQuestion')">
                <i slot="suffix" class="el-icon-search"></i>
            </el-input>
        </div>
        <el-table class="table" v-loading="loading" :data="tableData" @cell-mouse-enter="cellEnter"
            @cell-mouse-leave="cellLeave" :row-class-name="tableRowClassName" row-key="id">
            <el-table-column :label="$t('trainList.orderQuestion')" align="left" class-name="table_desc">
                <template slot-scope="scope">
                    <div v-if="scope.row.workOrderDesc && scope.row.workOrderDesc.search('<img') !== -1" class="has-img"
                        @click.stop="openShowImage(scope.row.workOrderDesc)">
                        <el-popover placement="top-start" width="200" trigger="hover" :ref="scope.row.id"
                            @after-enter="onPopoverShow(scope.row)">
                            <div @click.stop="openShowImage(scope.row.workOrderDesc)">
                                <img :src="scope.row.loadImg" style="width: 200px;height: 200px;object-fit: contain">
                            </div>
                            <i slot="reference" class="iconfont guoran-a-18_huaban1fuben5"></i>
                        </el-popover>
                    </div>
                    <div v-if="scope.row.workOrderDesc">
                        <el-tooltip :content="scope.row.workOrderDesc | filterDesTip" placement="top" effect="light">
                            <div v-if="compuDesTipLength(scope.row.workOrderDesc) >= 30" id="description"
                                v-html="replaceImgDes(scope.row.workOrderDesc)" :disabled="false"></div>
                        </el-tooltip>
                        <div v-if="compuDesTipLength(scope.row.workOrderDesc) < 30" id="description"
                            v-html="replaceImgDes(scope.row.workOrderDesc)" :disabled="false"></div>
                    </div>
                    <div v-if="!scope.row.workOrderDesc">--</div>

                    <el-popover popper-class="table_prpper" placement="right" :visible-arrow="false" trigger="manual"
                        v-model="scope.row.isActive">
                        <el-button type="text" @click="openDetail(scope.row)" class="view-info">{{$t('trainList.viewDetail')}}</el-button>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column :label="$t('trainList.attentionDiagram')" align="left" width="200" class-name="intention_name">
                <template slot-scope="scope">
                    <div class="intention_name_top">
                        {{ scope.row.tagIntentName }}
                    </div>
                    <div class="intention_floder">
                        {{ scope.row.tagIntentFullPath }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('trainList.operator')" align="left" width="150" class-name="operator_view">
                <template slot-scope="scope">
                    <img v-if="scope.row.operatorPhoto && scope.row.operatorPhoto !== ''" :src="scope.row.operatorPhoto"
                        alt="" class="user-icon">
                    <div class="name-icon" v-else-if="scope.row.operatorName && scope.row.operatorName !== ''">
                        {{ scope.row.operatorName.slice(0, 1) }}</div>
                    <span class="operator_name">{{ scope.row.operatorName }} </span>
                </template>
            </el-table-column>
            <el-table-column prop="updateTimeStr" :label="$t('trainList.operationTime')" align="left" width="160" class-name="table_updateTime">
                <template slot-scope="scope">
                    <i class="guoran-a-18-15 iconfont" v-if="scope.row.updateTime && scope.row.updateTime !== ''"></i>
                    <span>{{ new Date(scope.row.updateTime).Format("yyyy-MM-dd hh:mm") }} </span>
                </template>
            </el-table-column>
            <el-table-column prop="" :label="$t('trainList.operation')" align="left" width="80" class-name="order_operation">
                <template slot-scope="scope">
                    <i @click="trainRemove(scope.row)" class="guoran-tongyichicun-16-09-shanchu2 iconfont"></i>
                </template>
            </el-table-column>
        </el-table>
        <viewPictureVue :previewIndex="previewIndex" :previewImageUrl="previewImageUrl" @closeView="showPreview = false"
            v-if="showPreview">
        </viewPictureVue>
        <pagination :pageSize="page.pageSize" :currentPage="page.currentPage" :total="page.total"
            @currentChange="pageChange">
        </pagination>
        <errorDialog class="import-error-dialog" @closeEvent="showDialog = false" v-if="showDialog">
            <div slot="popup-container" class="popup-container">
                <div class="error-title">{{$t('trainList.removeTrainOrder')}}</div>
                <div class="error-prompt">{{$t('trainList.removeTrainOrderTip')}}</div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="showDialog = false" plain round>{{$t('common.cancel')}}</el-button>
                <el-button class="down-load" @click="confirmBtn" round>{{$t('common.confirm')}}</el-button>
            </div>
        </errorDialog>
    </div>
</template>

<script>
import errorDialog from '../../../components/errorDialog.vue';
import pagination from "../../../components/Pagination.vue";
import viewPictureVue from '../../../components/viewPicture.vue';
export default {
    components: {
        pagination,
        viewPictureVue,
        errorDialog
    },
    data () {
        return {
            serchText: '',
            tableData: [],
            trainVersionData: {},
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0,
            },
            previewImageUrl: '',
            showPreview: false,
            showDialog: false,
            removeData: {}
        }
    },
    filters: {
        filterDesTip (str) {
            return str.replace(/<img[^>]*>/gm, '').replace(/<figure(?:(?!<\/figure>).|\n)*?<\/figure>/gm, '').replace(/<p>/gm, '').replace(/<\/p>/gm, '').replace(/&nbsp;/gm, '').replace(/<span[^>]*\s+class="[^"]*\ask-component-img-box\b[^>]*">.*<\/span>/g, '【图片】').replace('<span class="ask-component-placeholder-container"></span>', '').replace(/<br[^>]*>/gm, '');
        },
    },
    mounted () {
    },
    methods: {
        openShowImage (description) {
            let desc = JSON.parse(JSON.stringify(description))
            if (desc) {
                let imgReg = /<img.*?(?:>|\/>)/gi
                let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
                let arr = desc.match(imgReg)
                let srcArr = []
                if (arr) {
                    for (let i = 0; i < arr.length; i++) {
                        let src = arr[i].match(srcReg)
                        if (src && src[1] && src[1].indexOf('http') !== -1) {
                            srcArr.push(src[1])
                        }
                    }
                }
                this.previewImageUrl = srcArr
                this.showPreview = true
                return srcArr
            }
        },
        compuDesTipLength (str) {
            return str.replace(/<img[^>]*>/gm, '').replace(/<figure(?:(?!<\/figure>).|\n)*?<\/figure>/gm, '').replace(/<p>/gm, '').replace(/<\/p>/gm, '').replace(/&nbsp;/gm, '').length;
        },
        replaceImgDes (des) {
            let imgReg = /<img.*?(?:>|\/>)/gi
            return des.replace(imgReg, '')
        },
        close () {
            this.$emit('closeDrawer')
        },
        cellEnter (row) {
            this.tableData.forEach((v) => {
                v.isActive = v.id == row.id;
            });
        },
        cellLeave () {
            this.tableData.forEach((v) => {
                v.isActive = false;
            });
        },
        getTrainByWorkOrderList () {
            let url = "/api/intent/workOrderToKnowledge/train/workOrder"
            url += '?trainId=' + this.trainVersionData.id + '&pageSize=' + this.page.pageSize + '&pageNumber=' + this.page.currentPage + '&keyword=' + this.serchText
            this.$http.get(url).then(res => {
                if (res.data.code == 0) {
                    console.log(res, 'resresres');
                    this.tableData = res.data.data.list
                    this.page.total = res.data.data.total
                }
            })
        },
        pageChange () {
            this.page.currentPage = this.page.currentPage + 1
            this.getTrainByWorkOrderList()
        },
        openDetail (row) {
            this.$emit("openDetail", row)
        },
        trainRemove (row) {
            this.removeData = row
            this.showDialog = true
        },
        confirmBtn () {
            this.$http.post("/api/intent/workOrderToKnowledge/train/remove?workOrderId=" + this.removeData.workOrderId).then(res => {
                if (res.data.code == 0) {
                    this.$message.success(this.$t('trainList.removeSuccess'))
                    this.showDialog = false
                    this.getTrainByWorkOrderList()
                    this.$emit("trainRemove")
                }
            })
        },
        tableRowClassName () {

        }
    }
}
</script>

<style lang="less" scoped>
.work_order_train_detail {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 20px;

    .wotd_head {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 14px;
            color: #616161;
        }
    }

    .wotd_tips {
        width: 100%;
        margin: 10px 0;
        padding: 0 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 12px;

        .tips {
            color: #616161;

            .tips_label {
                color: #A3ADC6;

                i {
                    font-size: 12px;
                    color: #366AFF;
                }
            }
        }
    }

    .wotd_search {
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #E0E6F7;
        margin: 10px 0;

        /deep/.el-input__inner {
            border: none;
        }

        .el-icon-search {
            margin-top: 8px;
            margin-right: 8px;
            font-size: 16px;
            color: #366AFF;
        }
    }

    /deep/.el-table {
        margin-top: 10px;
        height: calc(100% - 174px);

        &::before {
            z-index: inherit;
        }

        .el-table__fixed,
        .el-table__fixed-right {
            bottom: 0px;
            height: 100%;

            &::before {
                height: 0;
            }
        }

        .el-table__fixed-right-patch {
            background-color: #F6F8FD;
        }

        .el-table__header-wrapper {
            height: 50px;
            line-height: 50px;
            background-color: #F6F8FD;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;

            .el-table__header,
            tr,
            .has-gutter,
            th {
                background-color: #F6F8FD !important;
                background: #F6F8FD;
            }


        }

        .el-table__header {
            .first-filter-header-cell {
                display: flex;
                align-items: center;

                .el-select {
                    width: 140px;
                    margin-right: 20px;

                    .el-input {
                        width: 140px;

                        .el-input__inner {
                            height: 32px;
                            width: 140px;
                        }

                        .el-input__suffix {
                            top: -2px;
                        }
                    }
                }
            }
        }

        .el-table__body-wrapper {

            // 滚动条样式
            &::-webkit-scrollbar {
                height: 6px;
                width: 6px;
                background-color: rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px !important;
                background-color: #BFCEEC;
            }


        }

        th .cell {
            height: 50px;
            line-height: 50px;
        }

        .el-switch {
            display: inline;
            line-height: unset;
            height: 24px;
            padding: 0;
        }

        .el-table__header {
            th {
                padding: 0;
                height: 50px;
                line-height: 50px;
                background-color: #F6F8FD;

                div {
                    line-height: 50px;
                }

            }
        }

        .el-table__body {
            .table-fixed {

                /deep/.el-table__fixed,
                .el-table__fixed-right {
                    bottom: 0px;
                    height: 100%;

                    &::before {
                        height: 0;
                    }
                }

                /deep/.el-table__fixed-right-patch {
                    background-color: #F6F8FD;
                }
            }

            .normal-row,
            .image-row {
                .cell {
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    // padding: 0;
                    height: 60px;
                    line-height: 60px;

                    .guoran-a-18-15 {
                        margin-right: 6px;
                        color: #D2D8E3;
                        margin-top: 2px;
                        font-size: 17px;
                    }
                }


            }

            .image-row {
                .cell {
                    height: 110px;
                    line-height: 110px;
                }
            }

            tr td {
                padding: 0;
                height: 60px;
                cursor: pointer;
            }

            .user-icon {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 6px;
            }

            .name-icon {
                width: 26px;
                height: 26px;
                background: #366AFF;
                border-radius: 50%;
                margin-right: 6px;
                text-align: center;
                line-height: 26px;
                color: #fff;
                display: inline-block;
            }

            .el-button {
                font-weight: 400;
            }

            .order_operation {
                .cell {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #366AFF;
                    // justify-content: flex-start;

                    .iconfont {
                        font-size: 16px;
                        margin-right: 14px;
                    }

                    .guoran-tongyichicun-kaiqi1 {
                        font-size: 18px;
                    }

                    .guoran-tongyichicun-guanbi1 {
                        font-size: 13px;
                    }

                    .guoran-tongyichicun-hulve {
                        font-size: 17px;
                        color: #C9D0E5;
                    }

                    .intent-name {
                        line-height: 20px;
                        margin-right: 4px;
                        text-align: left;
                        // display: inline-block;
                        width: 68px;
                    }
                }
            }

            .q-first-c {
                display: flex;
                flex-wrap: wrap;
            }

            .labels {
                height: 18px;
                line-height: 18px;
                background: #EFF2FF;
                border: 1px solid #DBE4FF;
                border-radius: 2px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                margin-bottom: 6px;
                text-align: center;
                margin-right: 6px;

                &.worker {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    width: 55px;
                }

                &.artificial {
                    background-color: #E4E5FF;
                    border-color: #E4E5FF;
                    color: #848AFF;
                    width: 55px;
                }

                &.no-use {
                    background-color: #FFDFE1;
                    border-color: #FFDFE1;
                    color: #FE5965;
                    width: 75px;
                }

                &.use {
                    background-color: #D4F1F0;
                    border-color: #D4F1F0;
                    ;
                    color: #00C2BB;
                    width: 75px;
                }

                &.long-info {
                    background-color: #DBE4FF;
                    border-color: #DBE4FF;
                    color: #366AFF;
                    min-width: 145px;
                    padding: 0 8px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .two-column {
                display: flex;
                align-items: flex-end;

                .left,
                .right {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .left {
                    margin-right: 10px;
                }

                &.start {
                    align-items: flex-start;
                }
            }

            &.max-h {
                height: 100px;
                line-height: 100px;
            }
        }

        .el-select,
        .el-input {
            padding-left: 0;
        }

        .reset-tag,
        .view-info {
            padding: 0;
        }

        .reset-tag {
            margin-right: 14px;
        }

        .correct,
        .intent-name {
            color: #366AFF;
        }

        .correct {
            margin-right: 14px;
        }

        .open-sort {
            // float: left;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            padding-left: 0;

            // .el-switch {
            //     margin-top: -10px;
            //     display: inline-block;
            //     line-height: unset;
            //     height: 30px;
            // }
            p {
                height: 20px;
                line-height: 20px;
            }

            .el-switch__label {
                line-height: 20px;
            }

            margin-left: 10px;

            .el-switch {
                height: 24px;

                .el-switch__core {
                    height: 24px;
                    border-radius: 12px;
                }

                .el-switch__core:after {
                    width: 14px;
                    height: 14px;
                    left: 5px;
                    top: 3px;
                }

                .el-switch__label--left {
                    i {
                        color: #6e6b7b;
                        font-weight: 800;
                        font-size: 14px;
                    }

                    position: absolute;
                    left: 15px;
                    top: 1px;
                    z-index: 2;

                }

                .el-icon-check {
                    color: #F6F8FD;
                    font-weight: 800;
                    position: absolute;
                }

                &.is-checked {
                    .el-switch__core:after {
                        left: 40px;
                        top: 3px;
                    }

                    .el-icon-check {
                        position: absolute;
                        left: 5px;
                        top: 3px;
                        z-index: 2;
                        font-size: 14px;
                        color: #fff;
                        font-weight: 800;
                    }

                    .el-icon-close {
                        color: #fff;
                    }
                }

            }
        }
    }

    /deep/.intention_name {
        font-size: 14px;
        margin-bottom: 12px;

        .intention_floder {
            font-size: 12px;
            color: #A9B3C6;
        }
    }

    /deep/.table_updateTime {
        .cell {
            width: 100%;
            height: 36px;
            display: flex !important;
            align-items: center;

            .iconfont {
                color: #D2D8E3;
                margin-right: 5px;
            }
        }
    }

    #pagination {
        bottom: -3px;
        height: 64px;
        z-index: 10 !important;
        border-radius: 0 !important;
    }
}

/deep/.table_desc {

    .cell {
        display: flex;
        align-items: center;
        padding-left: 20px;

        .table_prpper {
            width: 114px;
            min-width: 114px;
            height: 47px;
            background: #FFFFFF;
            box-shadow: 0px 0px 9px 0px rgba(57, 63, 79, 0.21);
            border-radius: 5px;
            padding: 0;
            text-align: center;
            line-height: 47px;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: 400;
                color: #366AFF;
                font-size: 14px;
                font-family: Microsoft YaHei;
            }
        }

        .el-checkbox {
            margin-right: 10px;

            .el-checkbox__inner {
                width: 16px;
                height: 16px;
                border: 2px solid #EBEDF4;
                border-radius: 2px;

                &::after {
                    top: 0px;
                    border-width: 2px;
                }

            }

            .is-checked .el-checkbox__inner {
                border: none;
                border: 2px solid #366AFF;
            }
        }

        .has-img {
            display: flex;
            align-items: center;

            /deep/.item-c {
                margin-top: 0;
                margin-right: 0;
            }

            i {
                font-size: 18px;
                color: #D2D8E3;
                margin-right: 6px;
                display: flex;
            }
        }

        #description {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-height: 20px;

            /deep/ h4 {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            /deep/p {
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                white-space: nowrap;
                line-height: 20px;
                max-width: 410px;
            }

            /deep/video {
                height: 0 !important;
            }

            /deep/img {
                display: none;
                pointer-events: none !important;
            }

            /deep/a {
                pointer-events: none !important;
            }
        }
    }

}

.import-error-dialog {
    .popup-container {
        .error-title {
            color: #FE5965;
            text-align: center;
        }

        .error-prompt {
            color: #000000;
            text-align: center;
            font-size: 14px;
            margin-bottom: 12px;
            margin-top: 12px;
        }
    }
}

/deep/.operator_view {
    .cell {
        width: 100%;
        height: 36px;
        display: flex !important;
        align-items: center;

        .operator_name {
            width: calc(100% - 32px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>